import {
    AvFeedback, AvForm,
    AvGroup,
    AvInput
} from "availity-reactstrap-validation";
import React from 'react';
import { toastr } from "react-redux-toastr";
import Select from "react-select";
import {
    Button,
    FormGroup,
    Label
} from "reactstrap";
import GenericErrorAlert from "../../../../components/GenericErrorAlert";
import Loader from '../../../../components/Loader';
import { genericFetcherFactory } from '../../../../utils/requestUtils';
import { ItemTypes } from '../../tabMenuUtils';
import { removeAllSpecialCharsAndCleanName } from "../../../../utils/formUtils";


const FormModes = {
    NEW: "new",
    EDIT: "edit"
}

export { FormModes };

export default class TabMenuItemEditForm extends React.PureComponent {
    constructor(props) {
        super(props);
        this.sumbitForm = this.sumbitForm.bind(this);
        this.updateAuthGroups = this.updateAuthGroups.bind(this);
        this.focusTextInput = this.focusTextInput.bind(this);

        this.state = {
            initSuccess: null,
            authGroupsOptions: [],
            authGroupsSelectedOptions: null,
        }
    }

    updateAuthGroups(authGroupList) {
        this.setState({ authGroupsSelectedOptions: authGroupList });
    }
  

    componentDidMount() {
        let newState = { initSuccess: false };
        if (this.props.tabMenuItemData.itemType === ItemTypes.SECTION) {
            genericFetcherFactory("/api/tableaus/AuthGroups/internal", "AUTH_GROUPS_INTERNAL", "Fetch Auth Groups Failed")().then((result) => {
                if (result.success == true) {
                    let authGroups = result.data;
                    let authGroupsOptions = authGroups.map(aGroup => { return { value: aGroup.id, label: aGroup.name } });
                    let selectedAuthGroupIds = this.props.tabMenuItemData.authGroupIds;

                    if (selectedAuthGroupIds !== null && selectedAuthGroupIds.length > 0) {
                        newState.authGroupsSelectedOptions = authGroupsOptions.filter(groupObject => { return selectedAuthGroupIds.find(group => group == groupObject.value) })
                    }

                    newState.authGroupsOptions = authGroupsOptions;
                    newState.initSuccess = true;
                   
                    this.setState(newState);
                } else {
                    this.setState({ initSuccess: false })
                }
            }).catch((error) => {
                toastr.error("Get Auth Group Failed", "Error: " + error);
                this.setState({ initSuccess: false });
                return;
            });
        } else {
            this.setState({ initSuccess: true });
        }
    }

    sumbitForm(event, errors, values) {
        // Validate everything else
        if (errors.length != 0) {
            return;
        }

        let tabObject = { ...this.props.tabMenuItemData };

        if (this.props.mode == FormModes.NEW) {
            // All data will exist
            if (this.props.tabMenuItemData.itemType === ItemTypes.SECTION) {
                if (this.state.authGroupsSelectedOptions == null || this.state.authGroupsSelectedOptions.length < 1) {
                    toastr.warning("Cannot save section", "Please choose at least one auth group");
                    return;
                }
            }
            
            tabObject.tabName = values.tabName;

            if(this.props.tabMenuItemData.itemType === ItemTypes.MENU_ITEM){
                tabObject.permalink = values.permalink;   
            }
            
            tabObject.url = values.url;
            tabObject.authGroupIds = this.state.authGroupsSelectedOptions != null ? this.state.authGroupsSelectedOptions.map(item => { return item.value }) : null;
            if (values.showToolbar) {
                tabObject.showToolbar = true;
            }
            this.props.updateCallback(tabObject);
        } else if (this.props.mode == FormModes.EDIT) {
            let changeMade = false;

            if(this.props.tabMenuItemData.itemType === ItemTypes.MENU_ITEM){
                if (this.props.tabMenuItemData.permalink != values.permalink) {
                    changeMade = true;
                }
                tabObject.permalink = values.permalink;
            }
            
            if (this.props.tabMenuItemData.tabName != values.tabName) {
                changeMade = true;
            }

            tabObject.tabName = values.tabName;

            if (this.props.tabMenuItemData.itemType === ItemTypes.SECTION) {
                if (this.state.authGroupsSelectedOptions === null || this.state.authGroupsSelectedOptions.length < 1) {
                    toastr.warning("Cannot save section", "Please choose at least one auth group");
                    return;
                }
                let authGroupIds = this.state.authGroupsSelectedOptions.map(group => { return group.value; });

                if (!this.areArraysEqual(authGroupIds, this.props.tabMenuItemData.authGroupIds)) {
                    changeMade = true;
                }

                tabObject.authGroupIds = authGroupIds;
            } else {
                if (this.props.tabMenuItemData.url != values.url) {
                    changeMade = true;
                }
                tabObject.url = values.url;
                if (this.props.tabMenuItemData.showToolbar != values.showToolbar) {
                    changeMade = true;
                }
                tabObject.showToolbar = values.showToolbar;
            }

            if (changeMade === false) {
                toastr.warning("Cannot save tab menu item", "No changes were made");
                return;
            } else {
                this.props.updateCallback(tabObject);
            }
        }
    }

    areArraysEqual(arr1, arr2) {
        if (arr1 instanceof Array && arr2 instanceof Array) {
            if (arr1 === arr2) {
                return true;
            }

            if (arr1.length !== arr2.length) {
                return false;
            }

            let allFound = true;
            arr1.forEach(item => {
                if (!arr2.includes(item)) {
                    allFound = false;
                }
            })

            return allFound;

        } else {
            return false;
        }
    }

    defualtPermalink(){
        let mainSection = this.props.sectionData.find((item) => { return item.tabId === this.props.tabMenuItemData.parentId });
        let mainSectionName = removeAllSpecialCharsAndCleanName(mainSection.tabName);
        let mainTabName = removeAllSpecialCharsAndCleanName(this.props.tabMenuItemData.tabName);
        let permalink =  mainSectionName +"-"+ mainTabName;
        return permalink.toLowerCase().trim();
    }

    focusTextInput(eventValue){
        // Focus the text input using the raw DOM API
        let mainSection = this.props.sectionData.find((item) => { return item.tabId === this.props.tabMenuItemData.parentId });
        let mainSectionName = removeAllSpecialCharsAndCleanName(mainSection.tabName);
        let createDefualtPermalink = mainSectionName.toLowerCase() + "-" + eventValue;

        this.setState({newTabDefaultPermalink: createDefualtPermalink});
    };


    render() {
        if (this.state.initSuccess === true) {
            let permalink = "";
            if(!this.props.tabMenuItemData.permalink && this.props.tabMenuItemData.itemType === ItemTypes.MENU_ITEM && this.props.mode  == FormModes.EDIT){
                permalink = this.defualtPermalink();
            }else if(this.props.mode == FormModes.NEW){
                permalink = this.state.newTabDefaultPermalink;
            }else{
                permalink = this.props.tabMenuItemData.permalink;
            }

            return (
                <AvForm onSubmit={this.sumbitForm} className="form-group-wrap" >
                    <AvGroup>
                        <Label for="tabName">{this.props.tabMenuItemData.itemType} Name</Label>
                        <AvInput name="tabName" id="tabName" required value={this.props.tabMenuItemData.tabName} onChange={(e) => this.focusTextInput(e.target.value)} />
                        <AvFeedback>Tab name is required</AvFeedback>
                    </AvGroup>
                    {this.props.tabMenuItemData.itemType === ItemTypes.SECTION ?
                        <FormGroup>
                            <Label>Auth Groups </Label>
                            <Select
                                className="react-select-container"
                                classNamePrefix="react-select"
                                value={this.state.authGroupsSelectedOptions}
                                onChange={this.updateAuthGroups}
                                options={this.state.authGroupsOptions}
                                isMulti
                            />
                        </FormGroup>
                        :
                        <>
                        {this.props.tabMenuItemData.itemType !== ItemTypes.TAB &&
                            <AvGroup>
                                <Label for="permalink">Permalink</Label>
                                <AvInput name="permalink" id="permalink" value={permalink} pattern="^[0-9A-Za-z-]+$" required />
                                <AvFeedback>Only use '-' as special characters</AvFeedback>
                            </AvGroup>
                        }
                            <AvGroup>
                                <Label for="url">Url</Label>
                                <AvInput name="url" id="url" value={this.props.tabMenuItemData.url} pattern="https?://.+" required />
                                <AvFeedback>Please fill in a valid URL</AvFeedback>
                            </AvGroup>
                            <AvGroup className="d-flex justify-content-around">
                                <Label for="showToolbar">Show Toolbar</Label>
                                <AvInput className="position-relative" type="checkbox" id="showToolbar" name="showToolbar" value={this.props.tabMenuItemData.showToolbar} />
                            </AvGroup>
                        </>
                    }
                    <Button className="submit-btn" color="primary">Submit</Button>
                </AvForm>
            )
        } else if (this.state.initSuccess === false) {
            return (<GenericErrorAlert />);
        } else {
            return (<Loader />);
        }
    }

}

TabMenuItemEditForm.defaultProps = {
    tabMenuItemData: {
        tabId: null,
        permalink: null,
        tabName: "",
        url: "",
        parentId: null,
        authGroupIds: null,
        showToolbar: false

    },
    itemType: ItemTypes.SECTION,
    mode: FormModes.NEW
}