import { Formik, useField } from "formik";
import React from "react";
import { Button, Form, InputGroup, Row } from "react-bootstrap";
import { toastr } from "react-redux-toastr";
import * as Yup from "yup";
import Loader from "../../../components/Loader";
import { VideoThumbnails } from "../../../components/VideoThumbnails";
import { CACHE_EXPIRATION_DEFAULTS } from "../../../services/qsCache";
import { addHours } from "../../../utils/dateUtils";
import { genericCachedFetcherFactory } from "../../../utils/requestUtils";
import { UploadFacebookImgForm } from "./UploadFacebookImgForm";
import { UploadFacebookVideoForm } from "./UploadFacebookVideoForm";




export const PostTypes = {

    VIDEO: 'video',
    LINK: 'link'
}

export const ThumbnailTypes = {
    SUGGESTED: 'suggested',
    CUSTOM: 'custom',
    //AUTO: 'auto'
}
export const submitType = {
    PREVIEW: 'preview',
    CREATE_POST: 'create post',
}

export const defualtPostData = {
    postType: PostTypes.VIDEO,
    callToAction: null,
    callToActionLink: "",
    caption: "",
    title: "",
    description: "",
    message: "",
    imageUrl: "",
    videoId: "",
    videoUrl: null
}
export const PostToolTextarea = ({ ...props }) => {
    // useField() returns [formik.getFieldProps(), formik.getFieldMeta()]
    // which we can spread on <input> and alse replace ErrorMessage entirely.
    const [field, meta] = useField(props);
    return (
        <Form.Group className="postTool__textarea-wrap">
            <Form.Label htmlFor={field.id}>{props.label} </Form.Label>
            <textarea className="form-control" {...field} {...props} />
            {meta.touched && meta.errors ? (
                <Form.Control.Feedback type="invalid">{meta.errors}</Form.Control.Feedback>
            ) : null}
        </Form.Group>
    );
};
export default class FacebookPostToolForm extends React.PureComponent {
    constructor(props) {
        super(props);

        this.submitForm = this.submitForm.bind(this);
        this.setImageSelectorMethod = this.setImageSelectorMethod.bind(this);
        this.state = {
            thumbnailSetType: (props.postData.imageUrl !== null ? ThumbnailTypes.CUSTOM : ThumbnailTypes.SUGGESTED),
            uploadInProcess: false,
            submitType: null,
            callToActionTypeList: [],
            currentEditPost: { ...defualtPostData },
            initSuccess: null
        }
    }

    componentDidMount() {
        genericCachedFetcherFactory("/api/facebook-interface/PostTool/CallToActionTypes", "FETCH_ENUM", "Fail to catch CTA types", addHours(CACHE_EXPIRATION_DEFAULTS.Accounts))()
            .then(result => {
                if (result.success) {
                    let callToActionTypeList = result.data.map(type => {
                        return {
                            label: type.replace("VALUE_", ""),
                            value: type
                        }
                    });
                    this.setState({ initSuccess: true, callToActionTypeList: callToActionTypeList });
                }
            });
    }

    videoUploaded(videoId) {
        this.setState({ uploadInProcess: false });
        toastr.success("Video Upload Finished");
    }

    imgUploaded(imageObj) {

        this.setState({ uploadInProcess: false });
        toastr.success("Image Upload Finished");
    }

    setSuggestedImage(imageObj) {

        this.setState({ uploadInProcess: false });
    }

    setImageSelectorMethod(option) {
        if (option.value === ThumbnailTypes.AUTO) {
            let currentEditPost = { ...this.state.currentEditPost };
            currentEditPost.imageUrl = null;
            this.setState({ currentEditPost: currentEditPost, thumbnailSetType: option.value });
        } else {
            this.setState({ thumbnailSetType: option.value });

        }

    }

    submitForm(values) {
        if (values.postType === PostTypes.VIDEO && values.videoId === null) {
            toastr.error("Video is missing for this post type");
            return;
        }

        if (this.state.submitType === submitType.PREVIEW) {
            this.props.previewCallback(values);
            return;
        }

        if (this.state.submitType === submitType.CREATE_POST) {
            this.props.submitPostCallback(values);
            return;
        }
    }

    render() {
        let radioOptoins = [
            { label: 'Suggested', value: ThumbnailTypes.SUGGESTED },
            { label: 'upload new thumbnail', value: ThumbnailTypes.CUSTOM },
            // { label: 'Automatic', value: ThumbnailTypes.AUTO }
        ];
        let postTypesOptions = [{ value: PostTypes.VIDEO, label: "video" }, { value: PostTypes.LINK, label: "Link" }];

        if (this.state.initSuccess === true) {
            return (
                <Formik
                    initialValues={this.props.postData}
                    validationSchema={Yup.object().shape({
                        postType: Yup.string().required("post Type is required"),
                        title: Yup.string().required("Headline is required"),
                        description: Yup.string().required("Description is requrired"),
                        message: Yup.string().required("Post Text is required"),
                        callToAction: Yup.string().required("Call to Action type is requrired"),
                        callToActionLink: Yup.string().matches(
                            /((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/,
                            'Please enter valid URL'
                        ).required("Display link is required").required("Destination URL is required"),
                        imageUrl: Yup.string().required('image is required'),
                        caption: Yup.string().required("Display link is required"),
                        videoId: Yup.string().when("postType", {
                            is: (val) => val === PostTypes.VIDEO,
                            then: Yup.string().required("Post must contain video")
                        })

                    })}
                    onSubmit={(values) => this.submitForm(values)}
                    enableReinitialize={true}
                >
                    {({ handleSubmit, handleChange, handleBlur, errors, touched, values, setFieldValue }) => (
                        <Form onSubmit={handleSubmit}>
                            <Row className="gx-1 mt-3">
                                <Form.Group className="col gx-1">
                                    <Form.Label htmlFor="postType">Post Type</Form.Label>
                                    <Form.Control as="select"
                                        name="postType"
                                        id="postType"
                                        value={values.postType}
                                        onBlur={handleBlur}
                                        onChange={(opt, e) => {
                                            setFieldValue("postType", opt.target.value);

                                        }}
                                        isInvalid={!!errors.postType && !!touched.postType}
                                    >
                                        {postTypesOptions.map(type => { return <option value={type.value}>{type.label}</option> })}

                                    </Form.Control>
                                    {!!touched.postType &&
                                        <Form.Control.Feedback type="invalid">{errors.postType}</Form.Control.Feedback>
                                    }
                                </Form.Group>
                                <Form.Group className="col gx-1">
                                    <Form.Label htmlFor="title">Headline</Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="title" id="title"
                                        value={values.title}
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        isInvalid={!!errors.title && !!touched.title}
                                    />
                                    {!!touched.title &&
                                        <Form.Control.Feedback type="invalid">{errors.title}</Form.Control.Feedback>
                                    }
                                </Form.Group>
                            </Row>
                            <Row className="gx-1 mt-3">

                                <Form.Group className="col gx-1">
                                    <Form.Label htmlFor="description">Description</Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="description" id="description"
                                        value={values.description}
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        isInvalid={!!errors.description && !!touched.description}
                                    />
                                    {!!touched.description &&
                                        <Form.Control.Feedback type="invalid">{errors.description}</Form.Control.Feedback>
                                    }
                                </Form.Group>
                            </Row>
                            <Row>
                                <div class="col-xl-4 d-flex">
                                    <Form.Group className="postTool__textarea-wrap">
                                        <Form.Label htmlFor="message">Post Text</Form.Label>
                                        <Form.Control
                                            as="textarea"
                                            name="message" id="message"
                                            value={values.message}
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            isInvalid={!!errors.message && !!touched.message}
                                            style={{ width: "100%", height: "calc( 100% - 30px )" }}
                                        />
                                        {!!touched.message &&
                                            <Form.Control.Feedback type="invalid">{errors.message}</Form.Control.Feedback>
                                        }
                                    </Form.Group>
                                </div>
                                <div class="col-xl-8">
                                    <Form.Group className="col gx-1">
                                        <Form.Label htmlFor="callToAction">Call to Action</Form.Label>

                                        <Form.Control as="select"
                                            name="callToAction" id="callToAction"
                                            value={values.callToAction}
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            isInvalid={!!errors.callToAction && !!touched.callToAction}
                                        >
                                            <option value=""> </option>
                                            {this.state.callToActionTypeList.map(action => { return (<option value={action.value}>{action.label}</option>) })}
                                        </Form.Control>
                                        {!!touched.callToAction &&
                                            <Form.Control.Feedback type="invalid">{errors.callToAction}</Form.Control.Feedback>
                                        }
                                    </Form.Group>
                                    <Form.Group className="col gx-1">
                                        <Form.Label htmlFor="callToActionLink">Destination Url</Form.Label>
                                        <Form.Control
                                            type="text"
                                            name="callToActionLink" id="callToActionLink"
                                            value={values.callToActionLink}
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            isInvalid={!!errors.callToActionLink && !!touched.callToActionLink}
                                        />
                                        {!!touched.callToActionLink &&
                                            <Form.Control.Feedback type="invalid">{errors.callToActionLink}</Form.Control.Feedback>
                                        }
                                    </Form.Group>

                                    <Form.Group className="col gx-1">
                                        <Form.Label htmlFor="caption">Display Link</Form.Label>
                                        <Form.Control
                                            type="text"
                                            name="caption" id="caption"
                                            value={values.caption}
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            isInvalid={!!errors.caption && !!touched.caption}
                                        />
                                        {!!touched.caption &&
                                            <Form.Control.Feedback type="invalid">{errors.caption}</Form.Control.Feedback>
                                        }

                                    </Form.Group>
                                </div>
                            </Row>
                            {values.postType === PostTypes.VIDEO &&
                                <Row>
                                    {/* {this.props.access_token} */}
                                    <hr></hr>
                                    <UploadFacebookVideoForm
                                        accessToken={this.props.accessToken}
                                        successCallback={(videoId) => {
                                            setFieldValue("videoId", videoId);
                                        }
                                        }
                                        videoIdCallback={(videoId) => this.setState({ uploadInProcess: true })}
                                        videoId={values.videoId}
                                        acc={"act_" + this.props.account.accountPlatformId}
                                    />

                                    {(values.videoId !== null) &&
                                        <>
                                            <hr></hr>
                                            <div className="postTool__thumbnail-wrap row">
                                                <Form.Label className="col-12">Choose Thumbnail From: </Form.Label>
                                                <div className="postTool__thumbnailOptions col-12">
                                                    <InputGroup>
                                                        {radioOptoins.map((option) => {
                                                            return (
                                                                <>
                                                                    <InputGroup.Radio
                                                                        id={option.value}
                                                                        value={option.value}
                                                                        name="thumbnailMethod"
                                                                        className='form-check-input'
                                                                        aria-label={option.label}
                                                                        onChange={() => this.setImageSelectorMethod(option)}
                                                                    />{option.label}
                                                                </>
                                                            );
                                                        })}
                                                    </InputGroup>
                                                </div>

                                                <div className={this.props.previewHtml !== null ? "col-12 postTool__previewMode postTool__thumbnail-choose" : "col-7 postTool__thumbnail-choose"}>
                                                    {this.state.thumbnailSetType === ThumbnailTypes.SUGGESTED &&
                                                        <VideoThumbnails
                                                            accessToken={this.props.accessToken}
                                                            selectedCallback={(image) => {
                                                                setFieldValue("imageUrl", image);
                                                                this.setSuggestedImage(image)
                                                            }
                                                            }
                                                            videoId={values.videoId}
                                                        />
                                                    }
                                                    {this.state.thumbnailSetType === ThumbnailTypes.CUSTOM &&
                                                        <UploadFacebookImgForm
                                                            accessToken={this.props.accessToken}
                                                            successCallback={(image) => {
                                                                setFieldValue("imageUrl", image.url);
                                                                this.imgUploaded(image)
                                                            }
                                                            }
                                                            imageUrl={values.imageUrl}
                                                            acc={"act_" + this.props.account.accountPlatformId}
                                                        />
                                                    }
                                                </div>
                                                <div className={this.props.previewHtml !== null ? "col-12 postTool__thumbnail-preview" : "col-5 postTool__thumbnail-preview"}>
                                                    <img style={this.props.previewHtml !== null ? { maxWidth: "50%" } : { maxWidth: "85%" }} src={values.imageUrl}></img>
                                                </div>
                                            </div>
                                        </>
                                    }
                                </Row>
                            }
                            {values.postType === PostTypes.LINK &&
                                <>
                                    <hr></hr>
                                    <Row className="postTool__thumbnail-wrap">
                                        <div className={this.props.previewHtml !== null ? "col-12 postTool__previewMode postTool__thumbnail-choose" : "col-7 postTool__thumbnail-choose"}>
                                            <UploadFacebookImgForm
                                                accessToken={this.props.accessToken}
                                                successCallback={(image) => {
                                                    setFieldValue("imageUrl", image.url);
                                                    this.imgUploaded(image)
                                                }
                                                }
                                                imageUrl={values.imageUrl}
                                                acc={"act_" + this.props.account.accountPlatformId}
                                            />
                                        </div>
                                        <div className={this.props.previewHtml !== null ? "col-12 postTool__thumbnail-preview" : "col-5 postTool__thumbnail-preview"}>
                                            <img style={this.props.previewHtml !== null ? { maxWidth: "50%" } : { maxWidth: "85%" }} src={values.imageUrl}></img>
                                        </div>
                                    </Row>
                                </>
                            }

                            <Row>
                                <div className="postTool__thumbnail-buttons">
                                    <Button class="submit-btn" type="submit" variant="primary" onClick={() => this.setState({ submitType: submitType.CREATE_POST })}>Submit</Button>
                                    <Button class="ml-2" type="submit" variant="info" onClick={() => this.setState({ submitType: submitType.PREVIEW })}>Preview</Button>
                                </div>
                            </Row>
                        </Form>
                    )
                    }
                </Formik >
            )
        } else {
            return (
                <Loader />)
        }
    }
}