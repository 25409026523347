import { combineReducers } from "redux";

import sidebar from "./sidebarReducers";
import layout from "./layoutReducer";
import theme from "./themeReducer";
import userContext from './userContextReducer';
import tableau from './tableauReducers';
import cms from './cmsReducers';
import slackChannel from './slackChannelReducers';


import { reducer as toastr } from "react-redux-toastr";

export default combineReducers({
  userContext,
  tableau,
  sidebar,
  layout,
  theme,
  cms,
  slackChannel,
  toastr
});
