
import React from "react";
import SockJsClient from 'react-stomp';

export class PushNotifications extends React.Component {
  constructor(props) {
    super(props);
  }

  sendMessage(msg) {
    this.clientRef.sendMessage('/consumer/' + this.props.topic, JSON.stringify({ content: msg }));
  }

  render() {
    // const url = window.location.protocol + "//" + window.location.host + '/websocket';
    let envPrefix = "";
    if (process.env.NODE_ENV === "development") {
        envPrefix = "https://localhost:8050";
    }   else { 
        envPrefix = window.location.protocol + "//" + window.location.host
    }
    const url =envPrefix + '/websocket';
    return (
      <div>
        <SockJsClient url={url} topics={['/topic/' + this.props.topic]}
          onMessage={(msg) => {
            this.props.messageCallBack(msg)
          }}
          ref={(client) => { this.clientRef = client }} />
      </div>
    );
  }
}