import React from "react";

const generateStepButton = (step, wizProps) => {
    let stepTitles = ["Load Post", "Manage Expired Adsets", "Switch Posts", "Review and Revert"];
    let isStepEnabled;

    switch (step) {
        case 1:
            isStepEnabled = true;
            break;
        case 2:
            isStepEnabled =
                wizProps.stepData.originalPostId
                && wizProps.stepData.originalPostId.length > 0
                && wizProps.stepData.expiredAdsets != null;
            break;
        case 3:
            isStepEnabled =
                wizProps.stepData.originalPostId
                && wizProps.stepData.originalPostId.length > 0
                && wizProps.stepData.expiredAdsets != null
                && wizProps.stepData.extendedAdsets != null;
            break;
        case 4:
            isStepEnabled =
                wizProps.stepData.originalPostId
                && wizProps.stepData.originalPostId.length > 0
                && wizProps.stepData.expiredAdsets != null
                && wizProps.stepData.extendedAdsets != null
                && wizProps.stepData.newPostId
                && wizProps.stepData.newPostId.length > 0
                && wizProps.stepData.switchResult != null;
            break;
    }

    let onClick = isStepEnabled ? () => wizProps.goToStep(step) : null;

    let stepJsx = <li
        key={`step-${step}`}
        onClick={onClick}>
        <div className={`nav-item ${wizProps.currentStep == step ? "selected" : isStepEnabled ? "enabled" : "disabled"}`}>{stepTitles[step - 1]}</div>
    </li>

    return stepJsx;
}

export default (props) => {
    const wizardSteps = [];
    for (let i = 1; i <= 4; i++) {
        wizardSteps.push(generateStepButton(i, props));
    }

    return (
        <div className={"wizard-nav-container"}>
            <ul>{wizardSteps}</ul>
        </div>
    );
};