import CacheObject from "./CacheObject";

export default class CacheController {
    private static PREFIX = "qsAppCache_";

    static createCacheObject(key: string, object: any, expiration: Date | null = null) {
        return new CacheObject(key, object, expiration);
    }

    static convertToCacheObject(object: any) {
        return new CacheObject(object.key, object.data, object.expiration === null ? null : new Date(object.expiration));
    }

    static isDataStale(cacheObj: CacheObject): boolean {
        let expiration = cacheObj.getExpiration();
        if (expiration === null || expiration === undefined) {
            return false;
        } else {
            return new Date().getTime() > expiration.getTime();
        }
    }

    static getKeyWithPrefix(key: string): string {
        return (this.PREFIX + key);
    }

    static hasKey(key: string): boolean {
        key = this.getKeyWithPrefix(key);
        let cacheObj = localStorage.getItem(key);
        return cacheObj !== null && !this.isDataStale(this.convertToCacheObject(JSON.parse(cacheObj)));
    }

    static getObject(key: string): any {
        key = this.getKeyWithPrefix(key);
        let cacheObjSerialized = localStorage.getItem(key);
        if (cacheObjSerialized === null) {
            return null;
        } else {
            let cacheObj = this.convertToCacheObject(JSON.parse(cacheObjSerialized));
            if (!this.isDataStale(cacheObj)) {
                return cacheObj.getData();
            } else {
                localStorage.removeItem(key);
                return null;
            }
        }
    }

    static putObject(key: string, object: any, expiration: Date | null = null): void {
        key = this.getKeyWithPrefix(key);
        let cacheObj = this.createCacheObject(key, object, expiration);
        localStorage.setItem(key, JSON.stringify(cacheObj));
    }

    static deleteObject(key: string): void {
        key = this.getKeyWithPrefix(key);
        localStorage.removeItem(key);
    }

    static clear(): void {
        localStorage.clear();
    }
}