import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import {
    landing as landingRoutes,
    dashboard as dashboardRoutes,
} from "./index";

import DashboardLayout from "../layouts/Dashboard";

import ScrollToTop from "../components/ScrollToTop";

const childRoutes = (Layout, routes) =>
    routes.map(({ children, path, component: Component }, index) =>
        children ? (
            // Route item with children
            children.map(({ path, component: Component }, index) => (
                <Route
                    key={index}
                    path={path}
                    exact
                    render={props => (
                        <Layout>
                            <Component {...props} />
                        </Layout>
                    )}
                />
            ))
        ) : (
                // Route item without children
                <Route
                    key={index}
                    path={path}
                    exact
                    render={props => (
                        <Layout>
                            <Component {...props} />
                        </Layout>
                    )}
                />
            )
    );

const Routes = () => (
    <Router basename="/dashboard/">
        <ScrollToTop>
            <Switch>
                {childRoutes(DashboardLayout, landingRoutes)}
                {childRoutes(DashboardLayout, dashboardRoutes)}
                {/* <Route
          render={() => (
            <AuthLayout>
              <Page404 />
            </AuthLayout>
          )}
        /> */}
            </Switch>
        </ScrollToTop>
    </Router>
);

export default Routes;