import { setUserStatus } from "../../redux/actions/cmsActions";
import store from '../../redux/store/index';
import { genericFetcherFactory } from '../../utils/requestUtils';
export class UserStatusUtils {
    constructor() {
        this.handleEditedProject = this.handleEditedProject.bind(this)
        this.userInitStatus = this.userInitStatus.bind(this)
        this.checkUserEdit = this.checkUserEdit.bind(this)
    }

    userInitStatus() {
        let unsubscribe = store.subscribe(() => { });
        let stateUserStatus = { ...store.getState().cms.userStatus };

        genericFetcherFactory(
            "/api/users/UserDataCMS/getAll",
            "GET_USER_STATUS",
            "Failed to get user status",
            "GET",
            {
                method: 'GET',
                body: null
            })().then(res => {
                if (res.success !== true) {
                    console.log('userInitStatus', 'failed', res)
                    stateUserStatus.fetchedUserDataLoaded = false;
                    store.dispatch(setUserStatus({ ...stateUserStatus }));
                } else {
                    let fetchedUserData = res.data;
                    let inEditMode = this.handleEditedProject(fetchedUserData ,false);
                    stateUserStatus.fetchedUserData = fetchedUserData;
                    stateUserStatus.inEditMode = inEditMode;
                    stateUserStatus.fetchedUserDataLoaded = true;
                    stateUserStatus.processedUserDataReady = true;
                    store.dispatch(setUserStatus({ ...stateUserStatus }));

                }
                unsubscribe();
            }
            );
    }

    handleEditedProject(usersData, updateStore=true) {
        let unsubscribe = store.subscribe(() => { });
        let stateUserStatus = { ...store.getState().cms.userStatus };

        stateUserStatus.processedUserDataReady = false;
        store.dispatch(setUserStatus({ ...stateUserStatus }));

        let inEditMode = stateUserStatus.inEditMode || [],
            fetchedUserData = stateUserStatus.fetchedUserData;

        if (usersData.length >= 1) {
            //add all projects to currently edited array on init
            usersData.map(data => {
                if (data.projectName !== null) {
                    inEditMode.push(data.projectName)
                }
            })
        } else {
            if (usersData.isEditing) {
                //add a single project to currently edited array & full data array
                if (!inEditMode.includes(usersData.projectName)) {
                    inEditMode.push(usersData.projectName);
                    fetchedUserData.push(usersData);
                }
            } else {
                //remove a single project from currently edited array
                let index = inEditMode.indexOf(usersData.projectName);
                inEditMode.splice(index, 1);
                stateUserStatus.inEditMode = inEditMode;

                //remove a single project from full data array
                let indexProjToRemove;
                fetchedUserData.filter((data, index) => {
                    if (usersData.projectName === data.projectName) {
                        indexProjToRemove = index;
                    }
                })
                fetchedUserData.splice(indexProjToRemove, 1)
            }
        }
        stateUserStatus.processedUserDataReady = true;
        if(updateStore){
            store.dispatch(setUserStatus({ ...stateUserStatus }));
        }
        unsubscribe();
        return inEditMode;
    }

    checkUserEdit(project) {
        let stateUserStatus = { ...store.getState().cms.userStatus };
        let userLogin;
        stateUserStatus.fetchedUserData.filter(data => {
            if (project == data.projectName) {
                userLogin = data.userLogin.replace('@qualityscore.co', '');
            }
        })
        return userLogin
    }

}