import React from "react";
import merge from 'lodash/merge';
import cloneDeep from 'lodash/cloneDeep';
import { genericFetcherFactory } from '../../utils/requestUtils';
import { setCmsData, setCMSTemplateData } from "../../redux/actions/cmsActions";
import store from '../../redux/store/index';
import { cmsUtils } from './utils';

const sharedComponents = 'shared-components';
export class SharedComponentUtils {
    constructor() {
        this.utils = new cmsUtils();
        this.fetchSharedComponentsList = this.fetchSharedComponentsList.bind(this);
        this.getSharedComponentFromGitToS3 = this.getSharedComponentFromGitToS3.bind(this);
        this.getComponentDataFromS3 = this.getComponentDataFromS3.bind(this);
        this.updateAllPagesComponents = this.updateAllPagesComponents.bind(this);
        this.mergeCompData = this.mergeCompData.bind(this);
        this.mergeAllCompsBeforeDeploy = this.mergeAllCompsBeforeDeploy.bind(this);
    }

    fetchSharedComponentsList() {
        let unsubscribe = store.subscribe(() => { });
        let cmsData = { ...store.getState().cms.cmsData };

        store.dispatch(setCmsData({ ...cmsData, 'sharedComponentsListIsLoaded': false }));

        if (cmsData.sharedComponentsAvailable !== null) {
            store.dispatch(setCmsData({ ...cmsData, 'sharedComponentsListIsLoaded': true }));
        } else {
            let payload = {
                folder: sharedComponents,
                type: 'data'
            }
            genericFetcherFactory(
                "/lambda-proxy/lambdaProxy/lambdas3filelist",
                "LIST",
                "Failed to fetch list",
                "POST",
                {
                    method: 'POST',
                    body: JSON.stringify(payload)
                })().then(res => {
                    if (res.success !== true) {
                        cmsData = { ...store.getState().cms.cmsData };
                        store.dispatch(setCmsData({
                            ...cmsData,
                            'sharedComponent_error': 'Data not found'
                        }));
                    } else {
                        cmsData = { ...store.getState().cms.cmsData };
                        let sharedComponentsAvailable;
                        sharedComponentsAvailable = { ...cmsData.sharedComponentsAvailable };
                        sharedComponentsAvailable = res.data.filter(comp => comp !== sharedComponents);

                        store.dispatch(setCmsData({
                            ...cmsData,
                            'sharedComponentsListIsLoaded': true,
                            'sharedComponentsAvailable': sharedComponentsAvailable
                        }));

                    }
                }
                );
        }

        unsubscribe();
    }

    getSharedComponentFromGitToS3(component, scali_session_id) {
        let unsubscribe = store.subscribe(() => { });
        let cmsData = { ...store.getState().cms.cmsData };

        // copy shared-components jsons from git to s3
        let payload = {
            MERGE_GIT: "copyComponent",
            FOLDER_NAME: cmsData.template || "shared-components",
            COMPONENT: component,
            scali_session_id: scali_session_id ? scali_session_id : cmsData.template + "_" + cmsData.deploySelectedComponent
        }
        if (payload.COMPONENT !== null) {
            genericFetcherFactory(
                "/jenkins-api/jobTrigger/CMS-Merge",
                "jenkins - merge",
                "Failed to to run CMS-Merge jenkins",
                "POST",
                {
                    method: 'POST',
                    body: JSON.stringify(payload),
                    headers: { 'Content-Type': 'application/json' }
                })().then(
                    (response) => {
                        if (response.success === false) {
                            store.dispatch(setCmsData({ ...cmsData, 'copy_shared-components_to-s3': false }));
                        }
                    }
                )
        }

        unsubscribe();
    }

    async getComponentDataFromS3(files) {
        let unsubscribe = store.subscribe(() => { });
        let cmsData = { ...store.getState().cms.cmsData };

        //download shared-components jsons from s3
        let sharedComponentsFullData = [],
            versions = [],
            fetchLoop = [];
        for (const file of files) {
            let payload = {
                folder: sharedComponents,
                file: file + ".json"
            }
            fetchLoop.push(
                genericFetcherFactory(
                    "/lambda-proxy/lambdaProxy/lambdas3download",
                    "component data",
                    "Failed to fetch component data",
                    "POST",
                    {
                        method: 'POST',
                        body: JSON.stringify(payload)
                    })().then(res => {
                        if (res.success !== true) {
                            store.dispatch(setCmsData({
                                ...cmsData,
                                sharedComponentDataIsLoaded: false
                            }));
                        } else {
                            return JSON.parse(res.data);
                        }
                    }
                    )
            )
        }
        let responses = [];
        responses = await Promise.all(fetchLoop);
        sharedComponentsFullData = { ...cmsData.sharedComponentsFullData };

        responses.forEach(sharedComponentData => {
            let file = Object.keys(sharedComponentData)[0];
            // Components data
            sharedComponentsFullData[file] = sharedComponentData[file];
        })

        cmsData = { ...store.getState().cms.cmsData };
        cmsData.sharedComponentDataIsLoaded = true;
        cmsData.sharedComponentsFullData = sharedComponentsFullData;
        store.dispatch(setCmsData({ ...cmsData }));

        unsubscribe();
        return true;
    }

    updateAllPagesComponents(data, compsToMerge) {
        let unsubscribe = store.subscribe(() => { });
        let cmsData = { ...store.getState().cms.cmsData },
            CMSTemplateData = { ...store.getState().cms.CMSTemplateData };

        return new Promise((resolve, reject) => {
            let pages = CMSTemplateData.templatePageLists[cmsData.template];
            let objForUpdate = {};
            let comps = Object.keys(data);
            for (const page of pages) {
                let thisPageData = {};
                let name = cmsData.template + '_' + page;
                let mergedData = this.mergeCompData(CMSTemplateData.pristineDataModel[[name]], compsToMerge);
                thisPageData = merge(thisPageData, mergedData);
                let sharedComponents, componentsSaved, componentsToEdit;
                componentsToEdit = cloneDeep(CMSTemplateData.pristineDataModel[[name]].componentsToEdit);
                sharedComponents = cloneDeep(CMSTemplateData.pristineDataModel[[name]].sharedComponents);
                componentsSaved = cloneDeep(CMSTemplateData.pristineDataModel[[name]].componentsSaved);
                for (const comp of comps) {
                    if (!CMSTemplateData.pristineDataModel[[name]].sharedComponents.includes(comp)) {
                        componentsToEdit.push(comp);
                        sharedComponents.push(comp);
                        componentsSaved.push(comp);
                    }
                }
                thisPageData = merge(thisPageData, { 'componentsToEdit': componentsToEdit });
                thisPageData = merge(thisPageData, { 'sharedComponents': sharedComponents });
                thisPageData = merge(thisPageData, { 'componentsSaved': componentsSaved });
                objForUpdate[name] = [cloneDeep(thisPageData), true, 'pristineDataModel'];
            }

            store.dispatch(setCMSTemplateData(this.utils.updateSuccessCallback(objForUpdate)));
            resolve();

            unsubscribe();
        })

    }

    mergeCompData(thisPageData, compsToMerge) {
        let unsubscribe = store.subscribe(() => { });
        let cmsData = { ...store.getState().cms.cmsData };

        let mergedData = [],
            thisComp;
        for (const comp of compsToMerge) {
            thisComp = cloneDeep(cmsData.sharedComponentsFullData[comp]);
            let thisPageDataComp = Object.keys(thisPageData).includes(comp) ? cloneDeep(thisPageData[comp]) : '';
            mergedData[comp] = merge(thisComp, thisPageDataComp);
            mergedData[comp].version = cmsData.sharedComponentsFullData[comp].version;
        }
        unsubscribe();
        return mergedData;
    }

    mergeAllCompsBeforeDeploy() {
        let unsubscribe = store.subscribe(() => { });
        let cmsData = { ...store.getState().cms.cmsData },
            CMSTemplateData = { ...store.getState().cms.CMSTemplateData };

        let pages = Object.keys(CMSTemplateData.pristineDataModel);
        let pristineDataModel = cloneDeep(CMSTemplateData.pristineDataModel);
        let concatSharedComps = [],
            comps = [];
        pages.forEach(page => {
            if (page.includes(cmsData.template)) {
                if (page === cmsData.template + "_" + cmsData.currentPage) {
                    pristineDataModel[page] = cloneDeep(CMSTemplateData.dataModel);
                    CMSTemplateData.pristineDataModel[page] = cloneDeep(CMSTemplateData.dataModel);
                }
                //concat all shared components
                comps = pristineDataModel[page].sharedComponents.filter(comp => { return !concatSharedComps.includes(comp) });
                concatSharedComps = concatSharedComps.concat(comps);

                //remove sharedComponents from data
                delete pristineDataModel[page].sharedComponents;
            }
        })

        CMSTemplateData.concatSharedComps = concatSharedComps;
        store.dispatch(setCMSTemplateData({ ...CMSTemplateData, pristineDataModelForDeploy: pristineDataModel, dataReadyForDeploy: true }));

        unsubscribe();
    }

}

