// Sidebar
export const SIDEBAR_VISIBILITY_TOGGLE = "SIDEBAR_VISIBILITY_TOGGLE";
export const SIDEBAR_VISIBILITY_SHOW = "SIDEBAR_VISIBILITY_SHOW";
export const SIDEBAR_VISIBILITY_HIDE = "SIDEBAR_VISIBILITY_HIDE";
export const SIDEBAR_STICKY_TOGGLE = "SIDEBAR_STICKY_TOGGLE";
export const SIDEBAR_STICKY_ENABLE = "SIDEBAR_STICKY_ENABLE";
export const SIDEBAR_STICKY_DISABLE = "SIDEBAR_STICKY_DISABLE";
export const SIDEBAR_SAVE_ROUTES = "SIDEBAR_SAVE_ROUTES";

// Layout
export const LAYOUT_BOXED_TOGGLE = "LAYOUT_BOXED_TOGGLE";
export const LAYOUT_BOXED_ENABLE = "LAYOUT_BOXED_ENABLE";
export const LAYOUT_BOXED_DISABLE = "LAYOUT_BOXED_DISABLE";

// Theme
export const THEME_TOGGLE = "THEME_TOGGLE";

// Context
export const LOAD_USER = "LOAD_USER";
export const LOG_IN = "LOG_IN";
export const LOG_OUT = "LOG_OUT";
export const UPDATE_ALLOWED_MODULES = "UPDATE_ALLOWED_MODULES";
export const IMPERSONATE_USER = "IMPERSONATE_USER";
export const PERSONAL_DASHBOARD = "PERSONAL_DASHBOARD";
export const PERMALINK_LIST = "PERMALINK_LIST";
export const ACCOUNTS = "ACCOUNTS";
export const PRODUCTS = "PRODUCTS";
export const PAGES = "PAGES";


// Tableau
export const SET_TABLEAU_MENUS = 'SET_TABLEAU_MENUS';

// Facebook
export const SET_FACEBOOK_USER_DATA = "SET_FACEBOOK_USER_DATA";

//CMS
export const SET_CMS_TEMPLATE_DATA = "SET_CMS_TEMPLATE_DATA";
export const SET_CMS_DATA = "SET_CMS_DATA";
export const SET_USER_STATUS_DATA = "SET_USER_STATUS_DATA";
export const SET_DEPLOY_SHARED_COMPONENT = "SET_DEPLOY_SHARED_COMPONENT";

//slack Channels

export const SET_CHANNEL_DETAILS_LIST = "SET_CHANNEL_DETAILS_LIST";
export const SET_ALERTS_CHANNELS_ENTITIES = "SET_ALERTS_CHANNELS_ENTITIES";
