import { selectIcons } from "./QSIcons";

export const Platforms = {
    FACEBOOK: "Facebook",
    GOOGLE: "Google",
    BING: "Bing",
    TABOOLA: "Taboola",
    TWITTER: "Twitter",
    DV360: "DV360",
    SNAPCHAT: "Snapchat"
}

export function getPlatformIcon(platformName) {
    switch (platformName) {
        case Platforms.FACEBOOK:
            return selectIcons.facebook;
        case Platforms.GOOGLE:
            return selectIcons.google;
        case Platforms.BING:
            return selectIcons.bing;
        case Platforms.TABOOLA:
            return selectIcons.taboola;
        case Platforms.TWITTER:
            return selectIcons.twitter;
        case Platforms.DV360:
            return selectIcons.DV360;
        case Platforms.SNAPCHAT:
            return selectIcons.SNAPCHAT;
        default:
            return "";
    }
}