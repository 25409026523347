import React from "react";
import { connect } from "react-redux";
import { toggleSidebar } from "../redux/actions/sidebarActions";
import { navBarIcons as NavbarIcons } from "../utils/QSIcons";

import {
    Collapse,
    Navbar,
    Nav,
    UncontrolledDropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    UncontrolledTooltip
} from "reactstrap";

import { Settings } from "react-feather";

import CacheController from "../services/cache/CacheController";
import { toastr } from "react-redux-toastr";
import FacebookContextComponent from "./FacebookContextComponent";
import ImpersonateUser from "./ImpersonateUser";
import EditPersonalDashboard from "./EditPersonalDashboard";

class NavbarComponent extends React.Component {
    constructor(props) {
        super(props);
    }
    render() {
        return (
            <Navbar color="white" light expand>
                <span
                    className="sidebar-toggle d-flex mr-2"
                    onClick={() => {
                        this.props.dispatch(toggleSidebar());
                    }}
                >
                    <i className="hamburger align-self-center" />
                </span>

                <Collapse navbar>
                    {this.props.impersonatedUser != null &&
                        <div className="ms-4 font-weight-bolder bg-primary" style={{ padding: "10px" }}>Impersonating {this.props.impersonatedUser.userLogin}</div>
                    }
                    <Nav className="ms-auto" navbar>
                        {this.props.user.userData.isExternalUser === false &&
                            <>
                                <div id="facebook-status-icon" className="mr-3">
                                    {this.props.facebookUserData.isLoggedIn === true ? NavbarIcons.facebookOn : NavbarIcons.facebookOff}
                                </div>

                                <UncontrolledTooltip
                                    placement="bottom"
                                    target="facebook-status-icon"
                                >
                                    {this.props.facebookUserData.isLoggedIn === true ? "Logged in to Facebook" : "Not logged in to Facebook. You can login via the context menu on the right"}
                                </UncontrolledTooltip>
                            </>
                        }

                        <UncontrolledDropdown nav inNavbar>
                            <span className="d-inline-block d-sm-none">
                                <DropdownToggle nav caret>
                                    <Settings size={18} className="align-middle" />
                                </DropdownToggle>
                            </span>
                            <span className="d-none d-sm-inline-block">
                                <DropdownToggle nav caret>
                                    <img
                                        src={this.props.user ? this.props.user.userPicture : ""}
                                        className="avatar img-fluid rounded-circle mr-1"
                                        alt="User Name"
                                    />
                                    <span className="text-dark">{this.props.user ? this.props.user.userName : "Mr. Error"}</span>
                                </DropdownToggle>
                            </span>
                            <DropdownMenu right>
                                <DropdownItem onClick={() => { CacheController.clear(); toastr.info("Caches cleared"); }}>Clear Caches</DropdownItem>
                                <DropdownItem divider />
                                {(this.props.user.userData.isExternalUser === false) &&
                                    <>
                                        <DropdownItem><FacebookContextComponent shouldRender={true} /></DropdownItem>
                                        {process.env.NODE_ENV !== "development" &&
                                            <DropdownItem><ImpersonateUser /></DropdownItem>
                                        }
                                        <DropdownItem><EditPersonalDashboard /></DropdownItem>
                                    </>
                                }
                                <DropdownItem onClick={() => { CacheController.clear(); window.location = "/logout"; }}>Sign out</DropdownItem>
                            </DropdownMenu>
                        </UncontrolledDropdown>
                    </Nav>
                </Collapse>
            </Navbar>
        );
    }
}

export default connect(store => ({
    app: store.app,
    user: store.userContext.user,
    facebookUserData: store.userContext.facebookUserData,
    impersonatedUser: store.userContext.impersonatedUser
}))(NavbarComponent);
