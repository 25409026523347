


import { Formik } from "formik";
import React from 'react';
import { Button, Form, Row } from "react-bootstrap";
import { toastr } from "react-redux-toastr";
import { Label } from 'reactstrap';
import * as Yup from "yup";
import AccountDropdown from "../../../components/AccountDropdown";
import { CACHE_EXPIRATION_DEFAULTS } from "../../../services/qsCache";
import { addHours } from "../../../utils/dateUtils";
import { genericCachedFetcherFactory, genericFetcherFactory } from '../../../utils/requestUtils';
import { listFolderInS3 } from "../../../utils/S3Utils";
import { AccountIsActive } from "../../prods-and-accounts/account/accountUtils";


const FormModes = {
    NEW: "new",
    EDIT: "edit"
}
const S3BUCKET = "cloudberrybackup-qs";
const PREFIX = "CBB_QS2016/D$/Public/BackupRemote";

export { FormModes };

export default class UploadFolderManagementEditForm extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            accounts: [],
            media: [],
            renamedFolder: "",
            validFolder: false,
            notifySlack: this.props.mediaData.notifySlack,
            selectedAccount: null
        };

        this.sumbitForm = this.sumbitForm.bind(this);
        this.isFolderExistInS3 = this.isFolderExistInS3.bind(this);
        this.accountChanged = this.accountChanged.bind(this);
        this.updateStateCallback = this.updateStateCallback.bind(this);
    }

    componentDidMount() {
        this.fetchMedia();
        this.fetchAccounts();
    }

    updateStateCallback(key, value) {
        this.setState({ [key]: value });
    }

    async fetchMedia() {
        genericFetcherFactory(`/api/media/Media`, "FETCH_MEDIA", "Failed to fetch media")()
            .then(result => {
                if (result.success === true) {
                    this.setState({ ...this.state, media: result.data, initSuccess: true });
                } else {
                    this.setState({ ...this.state, initSuccess: false })
                }
            });
    }

    async fetchAccounts() {
        genericCachedFetcherFactory("/api/accounts/Accounts", "ACCOUNTS", "Fetch accounts failed",
            addHours(CACHE_EXPIRATION_DEFAULTS.Accounts))()
            .then(result => {
                if (result.success == true) {
                    this.setState({ initSuccess: true, accounts: result.data })
                } else {
                    this.setState({ initSuccess: false });
                }
            })
    }

    accountChanged(account) {
        if (account !== undefined) {
            this.setState({ selectedAccount: account });
        }
    }

    async isFolderExistInS3(folder) {
        //replace '\' with '/' and remove ""
        let folderFromUser = folder.replace(/\\/g, '/').replace(/"/g, '').trim();

        //check if folder exist in S3
        const isFolderExist = await listFolderInS3(S3BUCKET, PREFIX + folderFromUser, 1)

        this.setState({ renamedFolder: folderFromUser, validFolder: isFolderExist });
        return this.state.validFolder;
    }

    sumbitForm(values) {
        (async () => {
            //Folder name validation in s3
            if (await this.isFolderExistInS3(values.folder) === false) {
                toastr.error("Folder doesn't exist in S3!");
                return;
            } else {
                toastr.info("Folder Exist in S3!");
            }

            let newMediaData = {
                accountId: this.state.selectedAccount.accountId, 
                folder: this.state.renamedFolder,
                accountName: this.state.selectedAccount.accountName,
                notifySlack: !values.notifySlack ? false : values.notifySlack

            }
            this.props.submitCallback(newMediaData);

        })();
    }

    render() {
        return (
            <div>
                <Formik
                    initialValues={this.props.mediaData}
                    validationSchema={Yup.object().shape({
                        folder: Yup.string().required("Folder name is required"),
                    })}
                    onSubmit={(values) => this.sumbitForm(values)}
                >
                    {({ handleSubmit, handleChange, handleBlur, errors, touched, values }) => (
                        <Form onSubmit={handleSubmit}>
                            <Row className="gx-1 mt-3">
                                <Form.Group className="col gx-1">
                                    <div class="form-group" style={{ width: "400px" }}>
                                        <Label>Account Name</Label>
                                        <AccountDropdown
                                            updateStateCallback={this.updateStateCallback}
                                            accountChanged={this.accountChanged}
                                            selectedAccount={this.state.selectedAccount}
                                            platform='FACEBOOK'
                                            itemActive={AccountIsActive.ALL} />
                                    </div>

                                </Form.Group>
                                {this.state.selectedAccount && <>
                                    <Form.Group className="col gx-1 col-sm-6">
                                        <Form.Label htmlFor="folder">Folder Name</Form.Label>
                                        <Form.Control
                                            type="text"
                                            name="folder"
                                            id="folder"
                                            value={values.folder}
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            isInvalid={!!errors.folder && !!touched.folder}
                                        />
                                        {!!touched.folder &&
                                            <Form.Control.Feedback type="invalid">{errors.folder}</Form.Control.Feedback>
                                        }
                                    </Form.Group>

                                    <Form.Group className="col gx-1 mt-4">
                                        <Form.Check
                                            type="switch"
                                            label="Notify Slack"
                                            name="notifySlack" id="notifySlack"
                                            value={values.notifySlack}
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                        />
                                    </Form.Group>

                                    <Button type="submit" className="submit-btn col-auto align-self-start gx-1" variant="primary">Submit</Button>
                                </>
                                }
                            </Row>
                        </Form>
                    )}
                </Formik>
            </div>
        )
    }
}

UploadFolderManagementEditForm.defaultProps = {
    mediaData: {
        accountId: null,
        folder: "",
        id: null,
        accountName: "",
        notifySlack: null
    },
    mode: FormModes.NEW,
    onSubmit: () => { }
}