import React from "react";
import { connect } from 'react-redux';
import Loader from './components/Loader';
import { LayoutProvider } from "./contexts/LayoutContext";
import { SidebarProvider } from "./contexts/SidebarContext";
import { ThemeProvider } from "./contexts/ThemeContext";
import AuthLayout from "./layouts/Auth";
import SignIn from './pages/SignIn';
import { routesFromTabs } from "./pages/tableaus/tabMenuUtils";
import { hideSidebar, saveRoutes } from "./redux/actions/sidebarActions";
import { setTableauMenus } from './redux/actions/tableauActions';
import { impersonateUser, loadUser, logIn, logOut, setPersonalDashboard, updateAllowedModules } from './redux/actions/userContextActions';
import routes from "./routes/index";
import Routes from "./routes/Routes";
import { CACHE_EXPIRATION_DEFAULTS, prefetchEntities } from "./services/qsCache";
import ChartJsDefaults from "./utils/ChartJsDefaults";
import { addHours } from "./utils/dateUtils";
import { genericCachedFetcherFactory, genericFetcherFactory } from "./utils/requestUtils";

import { HelmetProvider, Helmet } from "react-helmet-async";



class App extends React.Component {
    constructor(props) {
        super(props);
    }

    async componentDidMount() {
        if (this.props.loggedIn !== true) {
            let success = false;
            let authMods = [];

            let getUserFetchResult = await genericFetcherFactory("/getUser", "GET_USER", "Failed to get user data", "GET", null, false)();
            if (getUserFetchResult.success) {
                let user = getUserFetchResult.data;

                if (user.authenticated === true) {
                    genericFetcherFactory("/impersonateApi/getCurrentlyImpersonated", "IMPERSONATED_USER", "Failed to get impersonated user", "GET", null, true, false)()
                        .then(result => {
                            if (result.success === true) {
                                if (result.data != null) {
                                    this.props.impersonateUser({ userLogin: result.data.email, userData: result.data.userData });
                                }
                            } else {
                                console.error(result.message);
                            }
                        });

                    user.pictureUrl = user.pictureUrl ? user.pictureUrl : "/images/smiley.png";
                    user.name = user.name ? user.name : "User";

                    this.props.loadUser({
                        userPicture: user.pictureUrl,
                        userName: user.name,
                        userLogin: user.email,
                        userData: user.userData
                    });

                    let combinedRoutes = [...routes];
                    // user.userData.isExternalUser = true;
                    if (user.userData.isExternalUser) {
                        this.props.updateAllowedModules([]);
                        this.props.hideSidebar();
                        success = true;
                    } else {
                        if (this.props.user) {
                            let [getAuthModsFetchResult, tableauMenuFetchResult, getUserPersonalDashboard] = await Promise.all([
                                genericCachedFetcherFactory("/getAuthorizedModules", "AUTHMODULES", "Error in getting authorized modules", addHours(CACHE_EXPIRATION_DEFAULTS.AuthModules), "GET", null, true, false)(),
                                genericFetcherFactory("/api/tableaus/TabMenuItems/Internal", "TABLEAUMENUS", "Fetch Tableau Menus Failed")(),
                                genericFetcherFactory(`/api/users/personalDashboard/getUserPeronalPermalinkList/${this.props.user.userData.userId}`, "GET_PERSONAL_TABS", "Error getting user personal tabs.")()
                            ]);

                            if (getAuthModsFetchResult.success === true) {
                                let authMods = await getAuthModsFetchResult.data;
                                this.props.updateAllowedModules(authMods);

                                success = true;
                            } else {
                                console.error(getAuthModsFetchResult.message);
                            }

                            if (tableauMenuFetchResult.success === true) {
                                let tabMenuItems = await tableauMenuFetchResult.data;
                                this.props.setTableauMenus(tabMenuItems);
                                combinedRoutes = combinedRoutes.concat(routesFromTabs(tabMenuItems));
                            }
                            if (getUserPersonalDashboard.success === true) {
                                let data = getUserPersonalDashboard.data;
                                this.props.setPersonalDashboard(data);
                            }
                        }
                    }

                    this.props.saveRoutes(combinedRoutes);
                } else {
                    console.error("Error in getting user");
                }
            }

            if (success === true) {
                this.props.logIn();
                prefetchEntities(authMods);
            } else {
                this.props.logOut();
            }
        }
    }

    render() {
        return (
            <HelmetProvider>
                <Helmet
                    titleTemplate="%s | Scali by QS"
                    defaultTitle="Scali by QS"
                />
                <ThemeProvider theme="dark">
                    <SidebarProvider>
                        <LayoutProvider>
                            <ChartJsDefaults />
                            {this.props.loggedIn === null &&
                                <Loader />
                            }
                            {this.props.loggedIn === true && <Routes />
                            }
                            {this.props.loggedIn === false &&

                                <AuthLayout>
                                    <SignIn />
                                </AuthLayout>
                            }
                        </LayoutProvider>
                    </SidebarProvider>
                </ThemeProvider>
            </HelmetProvider >
      
        )
    }
}

export default connect((store) => {
    return {
        loggedIn: store.userContext.loggedIn,
        user: store.userContext.user,
        allowedModules: store.userContext.allowedModules,
        personalDashboard: store.userContext.personalDashboard
    }
},
    { loadUser, logIn, logOut, updateAllowedModules, hideSidebar, setTableauMenus, saveRoutes, impersonateUser, setPersonalDashboard })(App)
