import React from "react";
import { Container, Row, Spinner } from "reactstrap";
import loading from '../assets/img/loading.gif';
import Typist from 'react-typist';
import TypistLoop from 'react-typist-loop';

export default class Loader extends React.PureComponent {
  constructor(props) {
    super(props);
    this.contentArray = this.props.content || [
      'DATA IS COMING...',
      'RUN DATA, RUN!'
    ]
  }

  render() {
    return (

    <Container fluid className="vh-50 d-flex">
      <Row className="justify-content-center align-self-center w-100 text-center loader">
        <img src={loading} alt="loading" />
          <div id="loading-text" className={this.props.width}>                  
              <TypistLoop interval={100}>
                  {this.contentArray.map(text => <Typist cursor={{ show: false, }} key={text} startDelay={0}>{text}
                  <Typist.Backspace count={20}  delay={700} />
                  </Typist>)}
              </TypistLoop>
          </div>
      </Row>
    </Container>
    )
  }
}

