import { Formik } from "formik";
import isEqual from 'lodash/isEqual';
import React from 'react';
import { Button, Form, Row } from "react-bootstrap";
import { toastr } from "react-redux-toastr";
import * as Yup from "yup";
import TermsInput from './TermsInput';

export const FormModes = {
    NEW: "new",
    EDIT: "edit"
};

export default class CAEditForm extends React.PureComponent {
    constructor(props) {
        super(props);

        this.changeField = this.changeField.bind(this);
        this.submitForm = this.submitForm.bind(this);

        let ca = props.customAudience;

        this.state = {
            customAudience: {
                customAudienceName: ca.customAudienceName,
                minVideoLength: ca.dtoCustomAudienceVideoFilterConditions.minVideoLength,
                maxVideoLength: ca.dtoCustomAudienceVideoFilterConditions.maxVideoLength,
                containedMessageTerms: {
                    operandType: ca.dtoCustomAudienceVideoFilterConditions.containedOperand,
                    values: ca.dtoCustomAudienceVideoFilterConditions.containedMessageTerms
                },
                notContainedMessageTerms: {
                    operandType: ca.dtoCustomAudienceVideoFilterConditions.notContainedOperand,
                    values: ca.dtoCustomAudienceVideoFilterConditions.notContainedMessageTerms
                },
                eventName: ca.eventName,
                retentionDays: ca.retentionDays
            }
        };
    }

    handleNumericValue(value) {
        if (value == null || value == "") {
            return null;
        }

        return parseInt(value);
    }

    changeField(fieldName, newValue) {
        let newCustomAudience = { ...this.state.customAudience };
        newCustomAudience[fieldName] = newValue;
        this.setState({ customAudience: newCustomAudience });
    }

    submitForm(values) {
        let pendingData = this.convertToAudience(values);

        if (this.props.mode == FormModes.NEW) {
            this.props.onSubmit(pendingData);
        } else if (this.props.mode == FormModes.EDIT) {
            let changeMade = false;
            let originalData = this.props.customAudience;
            let delta = {
                customAudienceId: this.props.customAudience.customAudienceId,
                filterId: this.props.customAudience.filterId,
                customAudienceName: null,
                dtoCustomAudienceVideoFilterConditions: {
                    minVideoLength: null,
                    maxVideoLength: null,
                    containedOperand: null,
                    containedMessageTerms: null,
                    notContainedOperand: null,
                    notContainedMessageTerms: null
                },
                eventName: null,
                retentionDays: null
            };

            if (pendingData.customAudienceName !== originalData.customAudienceName) {
                delta.customAudienceName = pendingData.customAudienceName;
                changeMade = true;
            }

            if (pendingData.dtoCustomAudienceVideoFilterConditions.minVideoLength !== originalData.dtoCustomAudienceVideoFilterConditions.minVideoLength) {
                delta.dtoCustomAudienceVideoFilterConditions.minVideoLength = pendingData.dtoCustomAudienceVideoFilterConditions.minVideoLength;
                changeMade = true;
            }

            if (pendingData.dtoCustomAudienceVideoFilterConditions.maxVideoLength !== originalData.dtoCustomAudienceVideoFilterConditions.maxVideoLength) {
                delta.dtoCustomAudienceVideoFilterConditions.maxVideoLength = pendingData.dtoCustomAudienceVideoFilterConditions.maxVideoLength;
                changeMade = true;
            }

            if (!isEqual(pendingData.dtoCustomAudienceVideoFilterConditions.containedMessageTerms, originalData.dtoCustomAudienceVideoFilterConditions.containedMessageTerms)
                || !isEqual(pendingData.dtoCustomAudienceVideoFilterConditions.notContainedMessageTerms, originalData.dtoCustomAudienceVideoFilterConditions.notContainedMessageTerms)) {
                delta.dtoCustomAudienceVideoFilterConditions.containedOperand = pendingData.dtoCustomAudienceVideoFilterConditions.containedOperand;
                delta.dtoCustomAudienceVideoFilterConditions.notContainedOperand = pendingData.dtoCustomAudienceVideoFilterConditions.notContainedOperand;
                delta.dtoCustomAudienceVideoFilterConditions.containedMessageTerms = pendingData.dtoCustomAudienceVideoFilterConditions.containedMessageTerms;
                delta.dtoCustomAudienceVideoFilterConditions.notContainedMessageTerms = pendingData.dtoCustomAudienceVideoFilterConditions.notContainedMessageTerms;
                changeMade = true;
            }
            else {
                if (pendingData.dtoCustomAudienceVideoFilterConditions.containedOperand !== originalData.dtoCustomAudienceVideoFilterConditions.containedOperand) {
                    delta.dtoCustomAudienceVideoFilterConditions.containedOperand = pendingData.dtoCustomAudienceVideoFilterConditions.containedOperand;
                    changeMade = true;
                }

                if (pendingData.dtoCustomAudienceVideoFilterConditions.notContainedOperand !== originalData.dtoCustomAudienceVideoFilterConditions.notContainedOperand) {
                    delta.dtoCustomAudienceVideoFilterConditions.notContainedOperand = pendingData.dtoCustomAudienceVideoFilterConditions.notContainedOperand;
                    changeMade = true;
                }
            }

            if (pendingData.eventName !== originalData.eventName) {
                delta.eventName = pendingData.eventName;
                changeMade = true;
            }

            if (pendingData.retentionDays !== originalData.retentionDays) {
                delta.retentionDays = pendingData.retentionDays;
                changeMade = true;
            }

            if (changeMade === false) {
                toastr.warning("Cannot save Custom Audience", "No changes were made");
            } else {
                this.props.onSubmit(delta);
            }
        } else {
            console.error(`Unknown form mode: ${this.props.mode}`)
        }
    }

    convertToAudience(audienceState) {
        return {
            customAudienceName: audienceState.customAudienceName,
            dtoCustomAudienceVideoFilterConditions: {
                minVideoLength: parseInt(audienceState.minVideoLength),
                maxVideoLength: audienceState.maxVideoLength != null ? parseInt(audienceState.maxVideoLength) : null,
                containedOperand: this.state.customAudience.containedMessageTerms.operandType,
                containedMessageTerms: this.state.customAudience.containedMessageTerms.values,
                notContainedOperand: this.state.customAudience.notContainedMessageTerms.operandType,
                notContainedMessageTerms: this.state.customAudience.notContainedMessageTerms.values
            },
            eventName: audienceState.eventName,
            retentionDays: parseInt(audienceState.retentionDays)
        };
    }

    render() {
        return (
            <Formik
                initialValues={this.state.customAudience}
                validationSchema={Yup.object().shape({
                    customAudienceName: Yup.string().required(),
                    eventName: Yup.string().min(1).required(),
                    minVideoLength: Yup.number().min(1).required(),
                    retentionDays: Yup.number().min(1).required()
                })}
                onSubmit={(values) => this.submitForm(values)}
            >
                {({ handleSubmit, handleChange, handleBlur, errors, touched, values }) => (
                    <Form onSubmit={handleSubmit}>
                        <Row className="gx-1">
                            <Form.Group className="col gx-1">
                                <Form.Label htmlFor="customAudienceName">Audience Name</Form.Label>
                                <Form.Control
                                    type="text"
                                    name="customAudienceName" id="customAudienceName"
                                    value={values.customAudienceName}
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    isInvalid={!!errors.customAudienceName && !!touched.customAudienceName}
                                />
                                {!!touched.customAudienceName &&
                                    <Form.Control.Feedback type="invalid">Audience name is required</Form.Control.Feedback>
                                }
                            </Form.Group>

                            <Form.Group className="col gx-1">
                                <Form.Label htmlFor="eventName">Event Name</Form.Label>
                                <Form.Control as="select"
                                    name="eventName" id="eventName"
                                    value={values.eventName}
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    isInvalid={!!errors.eventName && !!touched.eventName}
                                >
                                    <option value="0" >Select Event</option>
                                    <option>video_watched</option>
                                    <option>video_completed</option>
                                    <option>video_view_10s</option>
                                    <option>video_view_15s</option>
                                    <option>video_view_25_percent</option>
                                    <option>video_view_50_percent</option>
                                    <option>video_view_75_percent</option>
                                </Form.Control>
                                {!!touched.eventName &&
                                    <Form.Control.Feedback type="invalid">Event Name must be selected</Form.Control.Feedback>
                                }
                            </Form.Group>

                            <Form.Group className="col gx-1">
                                <Form.Label htmlFor="minVideoLength">Min Video Length (s)</Form.Label>
                                <Form.Control
                                    type="number"
                                    name="minVideoLength" id="minVideoLength"
                                    value={values.minVideoLength}
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    isInvalid={!!errors.minVideoLength && !!touched.minVideoLength}
                                />
                                {!!touched.minVideoLength &&
                                    <Form.Control.Feedback type="invalid">Min video length must be positive</Form.Control.Feedback>
                                }
                            </Form.Group>
                            <Form.Group className="col gx-1">
                                <Form.Label htmlFor="maxVideoLength">Max Video Length (s)</Form.Label>
                                <Form.Control
                                    type="number"
                                    name="maxVideoLength" id="maxVideoLength"
                                    value={values.maxVideoLength}
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    isInvalid={!!errors.maxVideoLength && !!touched.maxVideoLength}
                                />
                                {!!touched.maxVideoLength &&
                                    <Form.Control.Feedback type="invalid">Max video length must be positive and larger than minimum video length</Form.Control.Feedback>
                                }
                            </Form.Group>
                        </Row>

                        <Row className="gx-1 mt-3">
                            <TermsInput label="Contains"
                                onChange={(value) => this.changeField("containedMessageTerms", value)}
                                values={this.state.customAudience.containedMessageTerms.values}
                                operandType={this.state.customAudience.containedMessageTerms.operandType}
                            />
                            <TermsInput label="Not Contains"
                                onChange={(value) => this.changeField("notContainedMessageTerms", value)}
                                values={this.state.customAudience.notContainedMessageTerms.values}
                                operandType={this.state.customAudience.notContainedMessageTerms.operandType}
                            />
                            <Form.Group className="col gx-1 mt-5">
                                <Form.Label htmlFor="retentionDays">Watched X Days ago</Form.Label>
                                <Form.Control
                                    type="text"
                                    name="retentionDays" id="retentionDays"
                                    value={values.retentionDays}
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    isInvalid={!!errors.retentionDays && !!touched.retentionDays}
                                />
                                {!!touched.retentionDays &&
                                    <Form.Control.Feedback type="invalid">Must be a positive number</Form.Control.Feedback>
                                }
                            </Form.Group>
                            <Button type="submit" className="submit-btn col-auto mt-6 align-self-start" variant="primary">Submit</Button>
                        </Row>

                    </Form>
                )}
            </Formik>
        )
    }
}

CAEditForm.defaultProps = {
    customAudience: {
        customAudienceId: null,
        filterId: null,
        customAudienceName: "",
        dtoCustomAudienceVideoFilterConditions: {
            minVideoLength: 20,
            maxVideoLength: null,
            containedOperand: null,
            containedMessageTerms: [],
            notContainedOperand: null,
            notContainedMessageTerms: []
        },
        eventName: null,
        retentionDays: null
    },
    mode: FormModes.NEW,
    onSubmit: () => { }
}