import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';

import React from "react";
import ReactDOM from "react-dom";

import App from "./App";

import { Provider } from "react-redux";

import store from "./redux/store/index";

import ReduxToastr from "react-redux-toastr";

ReactDOM.render(
    <Provider store={store}>
        {/* {process.env.NODE_ENV === "development" &&
            <div>
                <small>You are running this application in <b>{process.env.NODE_ENV}</b> mode.</small>
                <form>
                    <input type="hidden" defaultValue={process.env.REACT_APP_NOT_SECRET_CODE} />
                </form>
            </div>
        } */}
        <App />
        <ReduxToastr
            timeOut={10000}
            showCloseButton={true}
            newestOnTop={true}
            position="top-center"
            transitionIn="fadeIn"
            transitionOut="fadeOut"
            progressBar
            closeOnToastrClick
        />
    </Provider>,
    document.getElementById("root"));
