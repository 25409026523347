import React from "react";

import {
    Card,
    CardBody,
    CardHeader,
    CardTitle,
    Container,
    Modal,
    ModalBody,
    ModalHeader
} from "reactstrap";
import BootstrapTable from "react-bootstrap-table-next";
import * as Icon from "react-feather";

import { toastr } from "react-redux-toastr";
import { handleErrorResponse } from "../../../utils/ajaxErrorHandler";

import Loader from '../../../components/Loader'
import IndustryEditForm, { FormModes } from './IndustryEditForm';
import GenericErrorAlert from "../../../components/GenericErrorAlert";
import { genericCachedFetcherFactory, genericFetcherFactory } from "../../../utils/requestUtils";
import { addHours } from "../../../utils/dateUtils";

export default class Industries extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = { industries: [], initSuccess: null };
        this.updateIndustry = this.updateIndustry.bind(this);
    }


    updateIndustry(industryObj, industryId) {
        this.setState({ ...this.state, formSaving: true })
        genericFetcherFactory("/api/product/Industries/Industry/" + industryId, "INDUSTRIES", "Failed to update Industries", "PATCH",
        {
            method: "PATCH",
            body: JSON.stringify(industryObj)
        })().then((res) => {
            if (res.success) {
                let updatedIndustry =  res.data;
                let newIndustries = [...this.state.industries];
                let industryIndex = newIndustries.findIndex(item => (item.industryId === industryId));
                newIndustries.splice(industryIndex, 1, updatedIndustry);
                this.setState({ ...this.state, industries: newIndustries, formSaving: false });
                toastr.success("Update Success", updatedIndustry.industryName + " was updated successfully.")
                
            } else {
                this.setState({ ...this.state, formSaving: false });
                handleErrorResponse(res, "Failed to Update Industries");
            }
        }).catch(error => {
            console.log(error);
        });

    }

    fetchIndustries() {
        return genericCachedFetcherFactory("/api/product/Industries", "INDUSTRIES", "Failed to fetch INDUSTRIES data", addHours(1))().then(result => {
            if (result.success === true) {
                this.setState({ ...this.state, industries: result.data, initSuccess: true });
            } else {
                this.setState({ ...this.state, initSuccess: false })
                handleErrorResponse(result, "Fetch Failed");
            }
        });
    }

    componentDidMount() {
        this.fetchIndustries();
    }

    // Boostrap table configuration 
    // Expand row configuration for editing rows in the Industries table
    expandRow = {
        renderer: row => {
            return (
                <div className="ml-5 mr-5 mt-3 mb-3">
                    <IndustryEditForm mode={FormModes.EDIT} industryData={row} submitCallback={this.updateIndustry} />
                </div>
            )
        },
        expandColumnRenderer: ({ expanded }) => {
            if (expanded) {
                return (
                    <Icon.Edit className="feather align-middle" />
                );
            }
            return (
                <Icon.Edit2 className="feather align-middle" />
            );
        },
        expandHeaderColumnRenderer: ({ isAnyExpands }) => {
            return "";
        },
        showExpandColumn: true,
        expandByColumnOnly: true,
        expandColumnPosition: 'right'
    };

    // Column configuration for the industries table
    industriesColumns = [
        {
            dataField: "industryId",
            text: "ID",
            headerStyle: { width: "30px" },
        },
        {
            dataField: "industryName",
            text: "Industry Name",
            sort: true
        }
    ]

    render() {
        if (this.state.initSuccess === true) {
            return (
                <Container fluid className="p-0">
                    <Modal isOpen={this.state.formSaving === true} centered>
                        <ModalHeader>
                            Saving Industry...
                    </ModalHeader>
                        <ModalBody className="text-center m-3">
                            <Loader width="wide" />
                        </ModalBody>
                    </Modal>
                    <Card>
                        <CardHeader>
                            <CardTitle tag="h5" className="mb-0">
                                Industries
                            </CardTitle>
                        </CardHeader>
                        <CardBody>
                            <BootstrapTable
                                bootstrap4
                                keyField="industryId"
                                bordered={false}
                                striped
                                hover
                                data={this.state.industries ? this.state.industries : []}
                                columns={this.industriesColumns}
                                expandRow={this.expandRow}
                            />
                        </CardBody>
                    </Card>
                </Container>
            )
        } else if (this.state.initSuccess === false) {
            return (
                <GenericErrorAlert />
            );
        } else {
            return (
                <Card>
                    <CardHeader>
                        <CardTitle tag="h5" className="mb-0">
                            Industries
                        </CardTitle>
                    </CardHeader>
                    <Loader width="wide" />
                </Card>
            );
        }
    }
}