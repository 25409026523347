import React from "react";
import {
    CardBody,
    Container,
    CustomInput,
    Form,
    FormGroup
} from "reactstrap";
import { Badge, Card } from "react-bootstrap";
import Loader from '../../components/Loader';
import { genericFetcherFactory } from '../../utils/requestUtils';
import { connect } from 'react-redux';
import { setCmsData, setCMSTemplateData, setUserStatus } from "../../redux/actions/cmsActions"
import { cmsUtils } from './utils';
import { SharedComponentUtils } from './SharedComponentUtils';
import { UserStatusUtils } from './UserStatusUtils';
class Templates extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            error: null,
            templatesIsLoaded: null,
            fetchedTemplates: []
        };

        this.utils = new cmsUtils();
        this.SharedComponentUtils = new SharedComponentUtils();
        this.userStatusUtils = new UserStatusUtils();
    }

    componentDidMount() {
        this.fetchTemplates();
    }
   
    fetchTemplates() {
        let rest = {
            folder: "",
            file: "templates.json"
        }
        if (this.props.cmsData.templatesIsLoaded === null) {
            genericFetcherFactory(
                "/lambda-proxy/lambdaProxy/lambdas3download",
                "TEMPLATES",
                "Failed to fetch templates",
                "POST",
                {
                    method: 'POST',
                    body: JSON.stringify(rest)
                })().then(res => {
                    if (res.success !== true) {
                        this.setState({
                            templatesIsLoaded: false,
                            error: 'Data not found'
                        });
                        this.props.setCmsData({ ...this.props.cmsData, templatesIsLoaded: false });

                    } else {
                        let dataTemplates = JSON.parse(res.data);
                        this.props.setCMSTemplateData({ ...this.props.CMSTemplateData, dataTemplates: dataTemplates });
                        this.props.setCmsData({ ...this.props.cmsData, templatesIsLoaded: true });
                        this.setState({
                            templatesIsLoaded: true,
                            fetchedTemplates: dataTemplates
                        });
                        this.SharedComponentUtils.fetchSharedComponentsList()
                    }
                }
                )
        }
    }

    render() {
        const fetchList = this.props.CMSTemplateData.dataTemplates['templates'] || [];

        if (this.state.error !== null) {
            return (
                <Container fluid className="cms__inner">
                    <h1>Choose a Template:</h1>
                    <div>{this.state.error}</div>
                </Container>
            )
        } else if (this.props.cmsData.templatesIsLoaded === true && this.props.userStatus.fetchedUserDataLoaded === true && this.props.userStatus.processedUserDataReady === true) {
            return (
                <>
                    <Container fluid className="cms__inner">
                        <h1>Choose a Template:</h1>
                        <Form className="row cms__templates">
                            {fetchList.map(data => {
                                let tempalteInEdit = this.props.userStatus.inEditMode.includes(data.name) ? true : false;
                                return (
                                    <div className="col-12 col-sm-6 col-md-4 col-lg-3" key={data.name} >
                                        <Card className={tempalteInEdit ? 'disabled' : ''}     >
                                            <CardBody>
                                                {tempalteInEdit ? <Badge bg="info">Currently Edited by <span className="text-capitalize">{this.userStatusUtils.checkUserEdit(data.name)}</span></Badge> : ''}
                                                <FormGroup>
                                                    <CustomInput
                                                        type="radio"
                                                        className='form-check-input'
                                                        value={data.name}
                                                        id={data.name}
                                                        onChange={this.utils.handleChange}
                                                        name='template'
                                                        checked={this.props.cmsData.template == data.name}
                                                        validate={{ required: true }}
                                                        label={data.name}
                                                        title={'Edit ' + data.name}
                                                    />
                                                    <a href={data.link} target="_blank">
                                                        <span> Preview </span>
                                                    </a>
                                                    <img src={"https://dtjlhiahlv53j.cloudfront.net/thumbnails/" + data.image}></img>
                                                </FormGroup>
                                            </CardBody>
                                        </Card>
                                    </div>
                                );
                            })}
                        </Form>
                    </Container>
                </>
            );
        } else {
            return (
                <Container fluid className="cms__inner">
                    <h1>Choose a Template:</h1>
                    <Loader />
                </Container>
            )
        }
    }
}

export default connect(
    (store) => {
        return {
            CMSTemplateData: store.cms.CMSTemplateData,
            cmsData: store.cms.cmsData,
            userStatus: store.cms.userStatus,
            user: store.userContext.user
        }
    },
    { setCmsData, setCMSTemplateData, setUserStatus })(Templates)