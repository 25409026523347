import { Platforms } from "../prods-and-accounts/account/accountUtils";
import { selectIcons as QSIcons } from "../../utils/QSIcons";

export const DEFAULT_FUNNEL_ID = "DEFAULT";
export const PIXEL_FROM_DEFAULT_FUNNEL_ID = "DEFAULT";
export const PIXEL_MOCK_ID = "MOCK_PIXEL";
export const USE_DEFAULT_FUNNEL_KPI = "DEFAULT_KPI";

export const PIXEL_BASED_FUNNELS_PLATFORMS = [Platforms.FACEBOOK, Platforms.GOOGLE, Platforms.TIKTOK, Platforms.BING, Platforms.TABOOLA, Platforms.TWITTER, Platforms.DV360, Platforms.SNAPCHAT];

export const BASE_PIXEL_STRUCTURE = {
    Pre_LP: "Pre_LP",
    LP: "LP",
    After_LP: "After_LP",
    Lead: "Lead",
    Product_Page: "Product_Page",
    Initiate_Checkout: "Initiate_Checkout",
    Sale: "Sale",
    Joker: "Joker",
    Revenue: "Revenue"
};

export const FacebookFunnelPixels = { ...BASE_PIXEL_STRUCTURE };
export const GoogleFunnelPixels = { ...BASE_PIXEL_STRUCTURE };
export const BingFunnelPixels = { ...BASE_PIXEL_STRUCTURE };
export const TaboolaFunnelPixels = { ...BASE_PIXEL_STRUCTURE };
export const TikTokFunnelPixels = { ...BASE_PIXEL_STRUCTURE };
export const TwitterFunnelPixels = { ...BASE_PIXEL_STRUCTURE };
export const DV360FunnelPixels = { ...BASE_PIXEL_STRUCTURE };
export const SnapchatFunnelPixels = { ...BASE_PIXEL_STRUCTURE };

export const PixelLimits = {
    Pre_LP: 4,
    LP: 6,
    After_LP: 5,
    Lead: 1,
    Product_Page: 3,
    Initiate_Checkout: 3,
    Sale: 3,
    Joker: 3,
    Revenue: 1
}

export function comparePixels(funnelPixelsA, funnelPixelsB) {
    if (funnelPixelsA === funnelPixelsB) {
        return true;
    }

    // Check that if either is null then both must be null
    if ((funnelPixelsA === null || funnelPixelsB === null) && !(funnelPixelsA === null && funnelPixelsB === null)) {
        return false;
    }

    let pixelsAKeys = Object.keys(funnelPixelsA);
    let pixelsBKeys = Object.keys(funnelPixelsB);

    if (pixelsAKeys.length != pixelsBKeys.length) {
        return false;
    }

    // Iterate over every pixel type
    for (let i = 0; i < pixelsAKeys.length; i++) {
        let pixelType = pixelsAKeys[i];

        if (!pixelsBKeys.includes(pixelType)) {
            return false;
        }

        let funnelATypePixels = funnelPixelsA[pixelType];
        let funnelBTypePixels = funnelPixelsB[pixelType];

        if (funnelATypePixels.length != funnelBTypePixels.length) {
            return false;
        }

        for (let j = 0; j < funnelATypePixels.length; j++) {
            if (funnelATypePixels[j] !== funnelBTypePixels[j]) {
                return false;
            }
        }
    }

    return true;
}

export function deepCloneFunnel(funnel) {
    let clone = {};

    clone = { ...funnel };

    if (funnel.pixels) {
        clone.pixels = {};
        let keys = Object.keys(funnel.pixels);
        keys.forEach(key => {
            clone.pixels[key] = [...funnel.pixels[key]];
        });
    }

    return clone;
}

export function processFunnelDto(funnelObj, account, products, funnelGroups) {
    let funnel = {};

    funnel.funnelId = funnelObj.funnelId;
    funnel.funnelName = funnelObj.funnelName;

    funnel.accountId = funnelObj.accountId;
    funnel.accountName = account.accountName;

    funnel.targetRoi = funnelObj.targetRoi;
    funnel.targetRoiFromDefault = funnelObj.targetRoiFromDefault;
    funnel.targetCpa = funnelObj.targetCpa;
    funnel.targetCpaFromDefault = funnelObj.targetCpaFromDefault;

    funnel.mainKpi = funnelObj.mainKpi;
    funnel.kpiType = funnelObj.kpiType;

    let funnelGroupId = funnelObj.funnelGroupId;
    if (funnelGroupId != null) {
        funnel.funnelGroupId = funnelGroupId;
        let funnelGroup = funnelGroups.find(funnelGroup => funnelGroup.funnelGroupId == funnelObj.funnelGroupId);
        funnel.funnelGroupName = funnelGroup !== undefined ? funnelGroup.funnelGroupName : "[Not found]";
    } else {
        funnel.funnelGroupId = 0;
        funnel.funnelGroupName = "";
    }

    funnel.productId = funnelObj.productId;
    let funnelProductName = products.find((item) => { return item.productId == funnel.productId });
    funnel.productName = funnelProductName !== undefined ? funnelProductName.productName : "[Not Found]";

    funnel.showInReport = funnelObj.showInReport;
    funnel.isActive = funnelObj.isActive;

    funnel.fromDate = new Date(funnelObj.fromDate);
    funnel.toDate = new Date(funnelObj.toDate);

    funnel.fromDateString = funnel.fromDate.toLocaleDateString('en-GB');
    funnel.toDateString = funnel.toDate.toLocaleDateString('en-GB');

    funnel.pixels = funnelObj.pixels ? funnelObj.pixels : null;

    funnel._rctId = generateFunnelKey(funnel);

    return funnel;
}

export function findIntersectingFunnels(startDate, endDate, funnels) {
    return funnels.filter(funnel => funnel.fromDate < endDate && funnel.toDate > startDate)
}

export function generateFunnelKey(funnel) {
    let funnelFromDate = funnel.fromDate ? funnel.fromDate.toLocaleDateString() : "new";
    return funnelFromDate + funnel.funnelId;
}

export function getMainKpiDisplayForPixelBasedFunnels(funnel, defaultFunnels, accountPixels) {
    let kpiDisp;

    if (funnel.mainKpi === USE_DEFAULT_FUNNEL_KPI) {
        let defaultFunnel = null;
        let isDefaultFunnelAnArray = Array.isArray(defaultFunnels);

        if (defaultFunnels === null) {
            kpiDisp = "[Error]";
        } else if (isDefaultFunnelAnArray) {
            if (defaultFunnels < 1) {
                kpiDisp = "[No data - default funnel not found]";
            } else if (defaultFunnels.length === 1) {
                defaultFunnel = defaultFunnels[0];
            } else {
                kpiDisp = "Several default values in history range";
            }
        } else {
            defaultFunnel = defaultFunnels;
        }

        // If there is a default funnel mainKpi was not set yet and needs to be looked up
        if (defaultFunnel != null) {
            let kpiPixel = accountPixels.find(pixel => pixel.pixelId === defaultFunnel.mainKpi);
            kpiDisp = (kpiPixel === undefined ? "[Not Found]" : kpiPixel.pixelName) + " (from default)";

            if (kpiPixel && kpiPixel.isArchived === true) {
                kpiDisp = "[ARCHIVED]" + kpiDisp;
            }
        }
    }
    else {
        let kpiPixel = accountPixels.find(pixel => pixel.pixelId === funnel.mainKpi);
        kpiDisp = kpiPixel === undefined ? "[Not Found]" : kpiPixel.pixelName;

        if (kpiPixel && kpiPixel.isArchived === true) {
            kpiDisp = "[ARCHIVED]" + kpiDisp;
        }
    }

    return kpiDisp;
}

export function createHistoryTemplatePixelsFromDefaultFunnels(defaultFunnel, pixelStructure) {
    let pixelMap = {};
    let pixelKeys = Object.keys(pixelStructure);

    pixelKeys.forEach(pixelKey => {
        let approxLength = pixelMap[pixelKey] ? pixelMap[pixelKey].length : 0;
        let currFunnelLength = defaultFunnel.pixels[pixelKey] ? defaultFunnel.pixels[pixelKey].length : 0;

        let maxLength = Math.max(approxLength, currFunnelLength);

        if (approxLength === 0 && maxLength !== 0) {
            pixelMap[pixelKey] = [];
        }

        for (let i = 0; i < maxLength; i++) {
            if (defaultFunnel.pixels[pixelKey] === undefined) {
                pixelMap[pixelKey][i] = PIXEL_MOCK_ID;
            }
            else if (pixelMap[pixelKey][i] != defaultFunnel.pixels[pixelKey][i]) {
                pixelMap[pixelKey][i] = PIXEL_MOCK_ID;
            }
        }
    });

    return pixelMap;
}

export function getPlatformIcon(platformName) {
    switch (platformName) {
        case Platforms.FACEBOOK:
            return QSIcons.facebook;
        case Platforms.GOOGLE:
            return QSIcons.google;
        case Platforms.TIKTOK:
            return QSIcons.tiktok;
        case Platforms.BING:
            return QSIcons.bing;
        case Platforms.TABOOLA:
            return QSIcons.taboola;
        case Platforms.TWITTER:
            return QSIcons.twitter;
        case Platforms.DV360:
            return QSIcons.DV360;
        case Platforms.SNAPCHAT:
            return QSIcons.SNAPCHAT;
        default:
            return "";
    }
}

export function generateAccountsSelectOptions(accountsArray) {
    let facebookOptions = [];
    let googleOptions = [];
    let bingOptions = [];
    let taboolaOptions = [];
    let tiktokOptions = [];
    let blankOptions = [];
    let twitterOptions = [];
    let dv360Options = [];
    let snapchatOptions = []

    accountsArray.forEach(account => {
        let platform = account.platform ? account.platform.platformName : "";
        switch (platform) {
            case Platforms.FACEBOOK:
                facebookOptions.push({ value: account.accountId, icon: getPlatformIcon(account.platform.platformName), label: account.accountName });
                break;
            case Platforms.GOOGLE:
                googleOptions.push({ value: account.accountId, icon: getPlatformIcon(account.platform.platformName), label: account.accountName });
                break;
            case Platforms.TIKTOK:
                tiktokOptions.push({ value: account.accountId, icon: getPlatformIcon(account.platform.platformName), label: account.accountName });
                break;
            case Platforms.BING:
                bingOptions.push({ value: account.accountId, icon: getPlatformIcon(account.platform.platformName), label: account.accountName });
                break;
            case Platforms.TABOOLA:
                taboolaOptions.push({ value: account.accountId, icon: getPlatformIcon(account.platform.platformName), label: account.accountName });
                break;
            case Platforms.TWITTER:
                twitterOptions.push({ value: account.accountId, icon: getPlatformIcon(account.platform.platformName), label: account.accountName });
                break;
            case Platforms.DV360:
                dv360Options.push({ value: account.accountId, icon: getPlatformIcon(account.platform.platformName), label: account.accountName });
                break;
            case Platforms.SNAPCHAT:
                snapchatOptions.push({ value: account.accountId, icon: getPlatformIcon(account.platform.platformName), label: account.accountName });
                break;
            default:
                blankOptions.push({ value: account.accountId, icon: "", label: account.accountName });
        }
    })

    return [
        { label: "", options: blankOptions },
        { label: "Facebook", options: facebookOptions },
        { label: "Google", options: googleOptions },
        { label: "TikTok", options: tiktokOptions },
        { label: "Bing", options: bingOptions },
        { label: "Taboola", options: taboolaOptions },
        { label: "Twitter", options: twitterOptions },
        { label: "DV360", options: dv360Options },
        { label: "Snapchat", options: snapchatOptions },
    ];
}

export function isPixelBasedFunnelPlatform(platformName) {
    return PIXEL_BASED_FUNNELS_PLATFORMS.includes(platformName);
}

export const KpiTypeOptions = [
    { value: 'ROI', label: 'ROI' },
    { value: 'CPA', label: 'CPA' }
];
