import React from 'react';
import { Modal, Container, Card } from "react-bootstrap";
import ClientEditForm from './ClientEditForm';
import Loader from '../../../components/Loader'

import { withRouter } from "react-router";

import { handleErrorResponse } from "../../../utils/ajaxErrorHandler";
import { genericFetcherFactory } from "../../../utils/requestUtils";

class NewClient extends React.PureComponent {

    constructor(props) {
        super(props);
        this.state = {
            formSaving: false
        }

        this.upload = this.upload.bind(this);
    }

    

    upload(formData) {
        this.setState({ ...this.state, formSaving: true })
        genericFetcherFactory("/api/crm/Clients", "CLIENTS", "Failed to fetch from client's API Service", "POST", {
            method: "POST",
            body: formData
        })().then(res => {
            if (res.success == true) {
                this.props.history.push('/crm/clients')
            } else {
                this.setState({ ...this.state, formSaving: false });
                handleErrorResponse(res, "Save Failed");
            }
        })
    }

    render() {
        return (
            <>
                <Modal show={this.state.formSaving === true} centered size="lg">
                        <Modal.Header>
                        Saving Client...
                        </Modal.Header>
                        <Modal.Body className="text-center m-3">
                            <Loader width="wide" />
                        </Modal.Body>
                    </Modal>

                <Container fluid className="p-0">
                    <h1 className="h3 mb-3">Create new client</h1>
                    <Card>
                        <Card.Body>
                            <ClientEditForm submitCallback={this.upload} />
                        </Card.Body>
                    </Card>
                </Container>
            </>
        )
    }
}

export default withRouter(NewClient);