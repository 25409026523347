import React from 'react';
import { withRouter } from "react-router";
import {
    Card, Container,
    Modal
} from "react-bootstrap";
import Loader from '../../components/Loader';
import { genericFetcherFactory, handleErrorResponse } from "../../utils/requestUtils";
import DailyCapEditForm from './DailyCapEditForm';




class NewDailyCap extends React.PureComponent {

    constructor(props) {
        super(props);
        this.state = {
            formSaving: false
        }

        this.save = this.save.bind(this);
    }

    save(dailyCapObj) {
        this.setState({ ...this.state, formSaving: true })
        genericFetcherFactory("/api/facebook-interface/DailyCaps", "SAVING_DAILYCAP", "Failed to Save ", "POST", {
            method: "POST",
            body: JSON.stringify(dailyCapObj),
            headers: { 'Content-Type': 'application/json' }
        })().then(res => {
            if (res.success == true) {
                this.setState({ formSaving: false });

                this.props.history.push('/daily-cap-tool/daily-cap')
            } else {
                this.setState({ ...this.state, formSaving: false });
                handleErrorResponse(res, "Save Daily Cap Failed");
            }
        })
    }

    render() {
        return (
            <>
                <Modal show={this.state.formSaving === true} centered>
                    <Modal.Header>
                        Saving Daily Cap...
                    </Modal.Header>
                    <Modal.Body className="text-center m-3">
                        <Loader width="wide" />
                    </Modal.Body>
                </Modal>

                <Container fluid className="p-0">
                    <h1 className="h3 mb-3">Create new daily cap</h1>
                    <Card>
                        <Card.Body>
                            <DailyCapEditForm
                                mode={"new"}
                                dailyCapData={{
                                    id: null,
                                    entityId: 0,
                                    entityType: "account",
                                    cap: 0,
                                    weekendCap: 0
                                }} submitCallback={this.save} />
                        </Card.Body>
                    </Card>
                </Container>
            </>
        )
    }

}

export default withRouter(NewDailyCap);