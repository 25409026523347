import { CACHE_EXPIRATION_DEFAULTS } from "../../../services/qsCache";
import { addHours } from "../../../utils/dateUtils";
import { genericCachedFetcherFactory } from "../../../utils/requestUtils";


export const FormModes = {
    NEW: "new",
    EDIT: "edit"
}

export const AdditionalFetchObjectTypes = {
    PLATFORM_CHANNELS: "platformChannels",
    PRODUCTS: "products"
}

export const Platforms = {
    FACEBOOK: "Facebook",
    GOOGLE: "Google",
    BING: "Bing",
    TABOOLA: "Taboola",
    TIKTOK: "TikTok",
    TWITTER: "Twitter",
    DV360: "DV360",
    SNAPCHAT: "Snapchat"
}

export const AccountTypeMapping = {
    FACEBOOK: "Facebook",
    GOOGLE: "Google",
    BING: "Bing",
    TABOOLA: "Basic",
    TIKTOK: "TikTok",
    TWITTER: "Basic",
    DV360: "Basic",
    SNAPCHAT: "Basic"
}

export const AccountIsActive = {
    ACTIVE: true,
    NOT_ACTIVE: false,
    ALL: null
}

export const AttributionWindows = [
    { attrWindowId: 1, attrWindowName: "FB Default (28 click & 1 view)" },
    { attrWindowId: 2, attrWindowName: "1 Day Click" },
    { attrWindowId: 3, attrWindowName: "7 Days Click" },
    { attrWindowId: 4, attrWindowName: "28 Days Click" },
    { attrWindowId: 5, attrWindowName: "1 Day View" },
    { attrWindowId: 6, attrWindowName: "7 Days View" },
    { attrWindowId: 7, attrWindowName: "28 Days View" }
];

const FetchObjectTypes = {
    PLATFORMS: "platform",
    BRANDS: "brand",
    USERS: "user",
    CURRENCIES: "currency",
    TIMEZONES: "timezones",
    CAPSULES: "capsule",
}

export const RelatedEntityFetchers = {
    FetchObjectTypes: FetchObjectTypes,
    fetchPlatforms: genericCachedFetcherFactory("/api/accounts/Platforms", FetchObjectTypes.PLATFORMS, "Fetch Platforms Failed", addHours(CACHE_EXPIRATION_DEFAULTS.Accounts), "GET"),
    fetchBrands: genericCachedFetcherFactory("/api/crm/Brands", FetchObjectTypes.BRANDS, "Fetch Brands Failed", addHours(CACHE_EXPIRATION_DEFAULTS.Accounts), "GET"),
    fetchCurrencies: genericCachedFetcherFactory("/api/accounts/Currencies", FetchObjectTypes.CURRENCIES, "Fetch Currencies Failed", addHours(CACHE_EXPIRATION_DEFAULTS.Accounts), "GET"),
    fetchUsers: genericCachedFetcherFactory("/api/users/Users", FetchObjectTypes.USERS, "Fetch Users Failed", addHours(CACHE_EXPIRATION_DEFAULTS.Accounts), "GET"),
    fetchTimeZones: genericCachedFetcherFactory("/getTimezones", FetchObjectTypes.TIMEZONES, "Fetch Timezones Failed", addHours(CACHE_EXPIRATION_DEFAULTS.Accounts), "GET"),
    fetchCapsules: genericCachedFetcherFactory("/api/accounts/Capsules",FetchObjectTypes.CAPSULES,"Fetch Capsules Failed", addHours(CACHE_EXPIRATION_DEFAULTS.Accounts), "GET")
}

export const DeactivateAccountWindows = [
    "End of commercial agreement with client",
    "Client asked to pause",
    "Other"
];


export function getUserById(userId, useres) {
    let accountUser = useres.find((user) => { return user.userId == userId });
    return accountUser !== undefined ? accountUser : "[Not found]";
}

