import React from "react";
import { ChromePicker } from 'react-color';

export default class ColorPicker extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            color: this.props.dataValue,
            displayColorPicker: false
        };
    }

    handleClick = () => {
        this.setState({ displayColorPicker: !this.state.displayColorPicker })
    };

    handleClose = () => {
        this.setState({ displayColorPicker: false })
    };
    handleChange = (color) => {
        this.setState({ color: color.hex });
        let hslcolor = 'hsl(' + Math.round(color.hsl.h) + 'deg ' + Math.round(color.hsl.s * 100) + '% ' + Math.round(color.hsl.l * 100) + '%)';
        let hslValues = {};
        hslValues.h = (Math.round(color.hsl.h)).toString();
        hslValues.s = Math.round(color.hsl.s * 100) + '%';
        hslValues.l = Math.round(color.hsl.l * 100) + '%';
        this.props.updateCallback({
            [this.props.dataRefName]: [hslcolor, this.props.pathObj],
            [this.props.pathObj + ".hsl"]: [hslValues, (this.props.pathObj).slice(0, -5) + "hsl"]
        });

    };

    render() {
        return (
            <div className="cms_colorPicker">
                <div className="swatch" onClick={this.handleClick}>
                    <div className="color" style={{ background: `${this.state.color}`, }} />
                </div>
                {this.state.displayColorPicker &&
                    <div className="popover" >
                        <div className="cover" onClick={this.handleClose} />
                        <ChromePicker
                            color={this.state.color}
                            onChange={this.handleChange}
                        />
                    </div>
                }

            </div>
        )
    }
}