import React from "react";
import BootstrapTable from "react-bootstrap-table-next";
import { Type } from 'react-bootstrap-table2-editor';
import * as Icon from "react-feather";
import { connect } from 'react-redux';
import { toastr } from "react-redux-toastr";
import Select from "react-select";
import {
    Button, Container, Modal,
    ModalBody,
    ModalHeader,
    Spinner, UncontrolledTooltip
} from "reactstrap";
import { setPersonalDashboard, setPermalinkList } from '../redux/actions/userContextActions';
import { genericFetcherFactory } from "../utils/requestUtils";

const defaultUserPersonallDashboard = {
    userId: null,
    permalink: []
}

// Check if context was already loaded once in the application. 
const alreadyLoaded = () => {
    if (window.personallDashboardContextLoaded !== true) {
        window.personallDashboardContextLoaded = true;
        return false;
    }
    return true;
}

class EditPersonalDashboard extends React.PureComponent {
    constructor() {
        super();

        this.state = {
            isLoadedPermalink: null,
            openModal: false,
            userChoices: []
        }

        this.openModal = this.openModal.bind(this);
        this.addNewTabs = this.addNewTabs.bind(this);
        this.updatPermalinkList = this.updatPermalinkList.bind(this);
        this.removeRow = this.removeRow.bind(this);
    }

    componentDidMount() {
        if (alreadyLoaded()) return;
        this.getTabItemListFromDB();
    }

    //Tableau Service
    getTabItemListFromDB() {
        genericFetcherFactory("/api/tableaus/personalTabItem/getPermalinkList/",
            "GET_INTERNAL_TAB_ITEMS",
            "Failed to get permalink list from DB")()
            .then(result => {
                if (result.success == true) {
                    this.setState({ isLoadedPermalink: true });
                    let data = result.data;
                    this.props.setPermalinkList({ data });

                } else {
                    this.setState({ isLoadedPermalink: false });
                }
            })
    }

    openModal() {
        this.setState({ openModal: true });
    }

    addNewTabs(permlkList) {
        if (permlkList.length < 1) {
            toastr.warning("Please add at least one option");
        } else {
            defaultUserPersonallDashboard.permalink = permlkList.map(permalink => { return permalink.label });
            defaultUserPersonallDashboard.userId = this.props.user.userData.userId;
            genericFetcherFactory(`/api/users/personalDashboard/savePersonalPermalinkList/${this.props.user.userData.userId}`,
                "SAVE_PERSONAL_TABS",
                "Failed to save user personal tabs",
                "POST", {
                method: "POST",
                body: JSON.stringify(defaultUserPersonallDashboard),
                headers: { 'Content-Type': 'application/json' }
            })().then(result => {
                if (result.success == true) {
                    this.setState({ openModal: false, userChoices: [] });
                    this.props.setPersonalDashboard({ id: result.data.id, userId: result.data.userId, permalink: result.data.permalink });
                    toastr.info("Saved Personal Tabs Successfully!");
                }
            });
        }
    }

    updateMultiSelectList(value) {
        this.setState({ userChoices: value });
    }

    updatPermalinkList(permlkList) {
        if (permlkList.length < 1) {
            toastr.warning("Please add at least one option");
        } else {
            defaultUserPersonallDashboard.permalink = permlkList.map(permalink => { return permalink.label });
            defaultUserPersonallDashboard.userId = this.props.user.userData.userId;
            genericFetcherFactory(`/api/users/personalDashboard/updatPermalinkList/${this.props.user.userData.userId}`,
                "UPDATE_TABS",
                "Failed to update tabs list",
                "PATCH", {
                method: "PATCH",
                body: JSON.stringify(defaultUserPersonallDashboard),
                headers: {
                    "Content-Type": "application/json"
                }
            })().then(result => {
                if (result.success == true) {
                    let updatedPersonalDashboard = result.data;
                    let newList = this.props.personalDashboard.permalink;
                    let permalinkIndex = newList.findIndex(item => (item.id === updatedPersonalDashboard.id));
                    newList.splice(permalinkIndex, 1, updatedPersonalDashboard.permalink);
                    this.setState({ userChoices: [] });
                    this.props.setPersonalDashboard({ id: updatedPersonalDashboard.id, userId: updatedPersonalDashboard.userId, permalink: updatedPersonalDashboard.permalink });
                }
            });
        }
    }

    removeRow(row) {
        let confirmation = window.confirm("Are you sure you want to delete?");
        if (confirmation == true) {
            genericFetcherFactory(`/api/users/personalDashboard/deletePermalink/${this.props.user.userData.userId}`,
                "DELETE_TAB", "Failed to delete tab", "DELETE", {
                method: "DELETE",
                body: JSON.stringify(row),
                headers: { "Content-Type": "application/json" }
            })().then(result => {
                if (result.success == true) {
                    let personalDashboard = this.props.personalDashboard;
                    let newUserPersonalList = personalDashboard.permalink;
                    let permalinkIndex = newUserPersonalList.findIndex(_row => _row.id == row.id);
                    newUserPersonalList.splice(permalinkIndex, 1);
                    this.props.setPersonalDashboard({ id: personalDashboard.id, userId: personalDashboard.userId, permalink: newUserPersonalList });
                }
            });
        }
    }

    // Column configuration 
    permalinkColumns = [
        {
            dataField: "permalink",
            text: "TABS",
            sort: false,
            style: { cursor: "pointer" },
            editor: { type: Type.TEXT },
            formatter: (cell, row) => {
                return <div>{`${row}`}</div>;
            }
        },
        {
            headerStyle: { width: "20px", textAlign: "center" }, align: "center",
            formatter: (cell, row) => this.actionsFormatter(cell, row, this.removeRow)
        },

    ]
    actionsFormatter(cell, row, removeRow) {
        let removeFormatterId = "deleteIcon-" + row.id;
        return (
            <div tabindex="-1">
                <Icon.Trash2 id={removeFormatterId} style={{ cursor: "pointer" }} className="feather mr-2"
                    onClick={() => { removeRow(row) }} />
                <UncontrolledTooltip placement="top" target={removeFormatterId}>
                    Delete tab
                </UncontrolledTooltip>
            </div>
        )
    }

    render() {
        let contextMenuItem = <Button color="success" size="lg" onClick={this.openModal}>Set Personal Dashboard</Button>;
        let permalinkOptions = [];
        if (this.props.permalinkList.data && this.props.permalinkList.data.length > 0) {
            permalinkOptions = Object.entries(this.props.permalinkList.data).map(([key, value]) => { return { label: value, value: key } });
        }
        permalinkOptions.sort((a,b) => a.label > b.label ? 1 : -1 );
        return (<>
            <Container fluid className="p-0">
                <Modal isOpen={this.state.openModal === true}
                    toggle={() => this.setState({ openModal: false })}
                    centered>
                    <ModalHeader toggle={() => this.setState({ openModal: false })}>
                        <div>Choose your personal tabs</div>
                        <div></div>
                    </ModalHeader>
                    <ModalBody className="text-center m-3" >
                        <div className="form-group-wrap">
                            <div class="form-group">
                                {this.props.permalinkList.data && this.props.permalinkList.data.length > 0 &&
                                    <>
                                        <div class="form-group" style={{ width: "400px" }}>
                                            <Select
                                                placeholder="Select a new tab..."
                                                className="react-select-container"
                                                classNamePrefix="react-select"
                                                value={this.state.userChoices}
                                                options={permalinkOptions}
                                                onChange={(value) => this.updateMultiSelectList(value)}
                                                isMulti
                                                closeMenuOnSelect={false}
                                            />
                                        </div>
                                        <div >
                                            {this.props.personalDashboard && this.props.personalDashboard.userId && //show update btn if userPermission exists in db
                                                <div>
                                                    <>
                                                        <div class="form-group" style={{ maxWidth: "100px" }}>
                                                            <Button //className="mt-2"
                                                                onClick={() => this.updatPermalinkList(this.state.userChoices)}>
                                                                Update
                                                            </Button>
                                                        </div>
                                                        <div>
                                                            {this.props.personalDashboard.permalink.length > 0 && //show TABS if permalink exists in db
                                                                <>
                                                                    <BootstrapTable
                                                                        bootstrap4
                                                                        keyField="permalink"
                                                                        bordered={false}
                                                                        striped
                                                                        hover
                                                                        data={this.props.personalDashboard.permalink}
                                                                        columns={this.permalinkColumns}
                                                                    />
                                                                </>
                                                            }
                                                        </div>
                                                    </>
                                                </div>
                                            }
                                        </div>
                                        <div class="form-group" style={{ maxWidth: "100px" }}>
                                            {this.props.personalDashboard === false && // userPersonalDashboard table is empty
                                                <Button
                                                    onClick={() => this.addNewTabs(this.state.userChoices)}>
                                                    Add
                                                </Button>
                                            }
                                        </div>
                                    </>
                                }
                            </div>
                            {!this.props.permalinkList.data && this.state.isLoadedPermalink == false &&
                                "Failed to load tab list. Please try refreshing the page and if the problem persists contact support."
                            }
                            {!this.props.permalinkList.data && this.state.isLoadedPermalink == null &&
                                <Spinner color="dark" className="mr-2" />
                            }
                        </div>
                    </ModalBody>
                </Modal>
                {contextMenuItem}
            </Container >
        </>);
    }
}

export default connect((store) => {
    return {
        user: store.userContext.user,
        personalDashboard: store.userContext.personalDashboard,
        permalinkList: store.userContext.permalinkList
    }
},
    { setPersonalDashboard, setPermalinkList })(EditPersonalDashboard)