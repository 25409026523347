import React from 'react';
import * as Yup from "yup";
import { Formik } from "formik";
import {
    Modal,
    ModalBody,
    ModalHeader
} from "reactstrap";
import { Button, Container, Form, Row } from "react-bootstrap";
import { genericFetcherFactory } from '../../../utils/requestUtils';
import ContactEditForm from '../contact/ContactEditForm';
import { toastr } from "react-redux-toastr";
import { handleErrorResponse } from '../../../utils/ajaxErrorHandler';

const customStyles = {
    content: {
        top: '80%',
        left: '80%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-80%',
        transform: 'translate(-80%, -80%)'
    }
};

const FormModes = {
    NEW: "new",
    EDIT: "edit"
}

export { FormModes };

export default class BrandEditForm extends React.PureComponent {

    constructor(props) {
        super(props);
        this.sumbitForm = this.sumbitForm.bind(this);
        this.contactModalOpen = this.contactModalOpen.bind(this);
        this.contactModalClose = this.contactModalClose.bind(this);
        this.renderContactModal = this.renderContactModal.bind(this);
        this.newContactcallback = this.newContactcallback.bind(this);

        this.state = {
            imgValidity: true,
            imgToUpload: null,
            imgDisplay: "",
            modal: false,
            formSaving: false,
            contacts: [{ contactId: 0, contactName: "Select Contact" }]
        }

    }

    componentDidMount() {
        // On mount check if brand data was passed and update accordingly
        if (this.props.brandData.imgUrl) {
            this.setState({ ...this.state, imgDisplay: this.props.brandData.imgUrl });
        }

        genericFetcherFactory("/api/crm/Contacts", "CONTACTS", "Failed to fetch from Contacts API Service")().then(res => {
            if (res.success == true) {
                let result = res.data;
                this.setState({ ...this.state, contacts: [{ contactId: 0, contactName: "Select Contact" }, ...result] });
            } else {
                this.setState({ ...this.state, initSuccess: false })
                handleErrorResponse(res, "Failed to fetch Contacts");
            }
        })

        genericFetcherFactory("/api/crm/Clients", "CLIENTS", "Failed to fetch from Clients API Service")().then(res => {
            if (res.success == true) {
                let result = res.data;
                this.setState({ ...this.state, clients: [{ clientId: 0, clientName: "Select Client" }, ...result] });
            } else {
                this.setState({ ...this.state, initSuccess: false })
                handleErrorResponse(res, "Failed to fetch Contacts");
            }
        })

        genericFetcherFactory("/api/users/Users", "USERS", "Failed to fetch from Users API Service")().then(res => {
            if (res.success == true) {
                let result = res.data;
                this.setState({ ...this.state, users: [{ userId: 0, userLogin: "Select User" }, ...result] });
            } else {
                this.setState({ ...this.state, initSuccess: false })
                handleErrorResponse(res, "Failed to fetch Users");
            }
        })

    }

    newContactcallback(contactData) {
        genericFetcherFactory("/api/crm/Contacts", "CONTACTS", "Failed to save new contact", "POST",
            {
                method: "POST",
                body: JSON.stringify(contactData),
                headers: { "Content-Type": "application/json" }
            })().then((res) => {
                if (res.success) {
                    this.setState({ modal: false })
                    let newContacts = [...this.state.contacts]
                    let brandData = { ...this.props.brandData }
                    newContacts.push(res.data)
                    brandData.contactId = contactData.contactId;
                    brandData.contactId = contactData.contactName;
                    this.setState({ ...this.state, contacts: newContacts })
                    toastr.success("Update Success", brandData.contactName + " was updated successfully.")
                }
            }).catch((error) => {
                this.setState({ ...this.state, formSaving: false });
                toastr.error("Save Failed", "Error: " + error)
            });
    }

    changeFile(file) {
        // File was changed and supplied
        if (file) {
            let reader = new FileReader();
            reader.onload = (el) => {
                this.setState({
                    ...this.state,
                    imgToUpload: file,
                    imgDisplay: el.target ? el.target.result : "",
                    imgValidity: true,
                })
            }
            reader.readAsDataURL(file);
        }
        // File was changed and prompt was closed without selecting a file
        else {
            this.setState({
                ...this.state,
                imgToUpload: null,
                imgDisplay: "",
                imgValidity: false
            })
        }
    }

    /**
 * Does various validation, data manipulation and runs the callback provided on component's submitCallback function
 * with a FormData object constructed from the form.
 * @param {*} event 
 * @param {*} errors 
 * @param {*} values 
 */
    sumbitForm(values) {
        // Validate if image exists only in new brand creation. Image is not mandatory when editing.
        if (this.props.mode === FormModes.NEW && this.state.imgToUpload == null) { 
            this.setState({ ...this.state, imgValidity: false });
            return;
        }

        let formData = new FormData();

        // Logic for when adding a new brand
        if (this.props.mode == FormModes.NEW) {
            // All data will exist
            let brandData = {
                brandName: values.brandName,
                legalCompanyName: values.legalCompanyName,
                vatNumber: values.vatNumber,
                nameOnInvoice: values.nameOnInvoice,
                billingAddress: values.billingAddress,
                billingEmail: values.billingEmail,
                startDayOfWeek: values.startDayOfWeek,
                crsUserId: parseInt(values.crsUserId),
                clientId: parseInt(values.clientId),
                contactId: parseInt(values.contactId),
            };
            formData.append("logo", this.state.imgToUpload);
            formData.append("brandData", JSON.stringify(brandData));

            this.props.submitCallback(formData);
        }
        // Logic for when editing an existing brand
        else if (this.props.mode == FormModes.EDIT) {
            let changeMade = false;
            let brandData = {};

            // Iterate through the form data
            for (let prop in values) {
                // TODO: Change this check to use an object defition rather than relying on the components properties
                if (this.props.brandData[prop] !== undefined) {
                    // Check if the field was edited
                    if (values[prop] != this.props.brandData[prop]) {
                        changeMade = true;
                        brandData[prop] = values[prop];
                    }
                } else {
                    console.warn("field " + prop + " doesn't exist");
                }
            }

            brandData.clientId = parseInt(brandData.clientId);
            brandData.crsUserId = parseInt(brandData.crsUserId);
            brandData.contactId = parseInt(brandData.contactId);
            // Add brandDetails to the FormData object if it has actually changed
            if (changeMade === true) {
                // Append the brandId for completion
                brandData["brandId"] = this.props.brandData.brandId;
                formData.append("brandData", JSON.stringify(brandData));
            }

            // Handle the image if it was changed
            if (this.state.imgToUpload != null) {
                changeMade = true;
                formData.append("logo", this.state.imgToUpload);
            }

            // Finally check if there was actually a change made before proceeding to the callback
            if (changeMade === false) {
                toastr.warning("Cannot save brand", "No changes were made");
                return;
            } else {
                this.props.submitCallback(formData, this.props.brandData.brandId);
            }
        }
    }

    contactModalOpen() {
        this.setState({ modal: true });
    }

    contactModalClose() {
        this.setState({ modal: false });
    }

    render() {
        let clientsSelectOptions = "";
        if (this.state.clients) {
            clientsSelectOptions = this.state.clients
            .filter(client => client.isActive == true)
            .map(item => <option value={item.clientId}>{item.clientName}</option>);
        }

        let usersSelectOptions = "";
        if (this.state.users) {
            usersSelectOptions = this.state.users.map(item => <option value={item.userId}>{item.userLogin}</option>);
        }

        let contactsSelectOptions = "";
        if (this.state.contacts) {
            contactsSelectOptions = this.state.contacts.map(item => <option value={item.contactId}>{item.contactName}</option>);
        }

        const dayOfWeekEnum = Object.freeze({
            MONDAY: 'MONDAY',
            TUESDAY: 'TUESDAY',
            WEDNESDAY: 'WEDNESDAY',
            THURSDAY: 'THURSDAY',
            FRIDAY: 'FRIDAY',
            SATURDAY: 'SATURDAY',
            SUNDAY: 'SUNDAY'
        });

        return (
            <div>
                <Formik
                    initialValues={this.props.brandData}
                    validationSchema={Yup.object().shape({
                        brandName: Yup.string().required(),
                        legalCompanyName: Yup.string().required(),
                        vatNumber: Yup.string().required(),
                        nameOnInvoice: Yup.string().required(),
                        billingAddress: Yup.string().required(),
                        billingEmail: Yup.string().email('Please fill in a valid email').required("Email is required"),
                        startDayOfWeek: Yup.string().test('length', 'Start Day Of Week is required', val => val.toString() !== '0'),
                        clientId: Yup.number().moreThan(0, 'Client is required').required('Client is required'),
                        crsUserId: Yup.number().moreThan(0, 'User is required').required('User is required'),
                        contactId: Yup.number().moreThan(0, 'Contact is required').required('Contact is required')
                    })}
                    onSubmit={(values) => this.sumbitForm(values)}
                >
                    {({ handleSubmit, handleChange, handleBlur, errors, touched, values }) => (
                        <Form onSubmit={handleSubmit}>
                            <Row className="gx-1">
                                <img style={{ maxHeight: "150px", maxWidth: "150px" }} src={this.state.imgDisplay}></img>
                                <Form.Label>Brand Logo</Form.Label>
                                <Form.Control type="file" name="BrandImg" accept=".jpg,.jpeg,.png,.gif"
                                    onBlur={handleBlur}
                                    isInvalid={!!errors.BrandImg && !!touched.BrandImg}
                                    onChange={(e) => { this.changeFile(e.target.files["0"]) }}
                                />
                                <Form.Text className={this.state.imgValidity !== false ? "text-muted" : "text-danger"}>Please use a logo of up to 150x150 px. Bigger size will be automatically resized</Form.Text>
                            </Row>
                            <Row className="gx-1 mt-3">
                                <Form.Group className="col gx-1">
                                    <Form.Label htmlFor="brandName">Brand name</Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="brandName" id="brandName"
                                        value={values.brandName}
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        isInvalid={!!errors.brandName && !!touched.brandName}
                                    />
                                    {!!touched.brandName &&
                                        <Form.Control.Feedback type="invalid">Brand name is required</Form.Control.Feedback>
                                    }
                                </Form.Group>
                                <Form.Group className="col gx-1">
                                    <Form.Label htmlFor="legalCompanyName">Legal company name</Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="legalCompanyName" id="legalCompanyName"
                                        value={values.legalCompanyName}
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        isInvalid={!!errors.legalCompanyName && !!touched.legalCompanyName}
                                    />
                                    {!!touched.legalCompanyName &&
                                        <Form.Control.Feedback type="invalid">Legal company name is required</Form.Control.Feedback>
                                    }
                                </Form.Group>
                                <Form.Group className="col gx-1">
                                    <Form.Label htmlFor="vatNumber">VAT number</Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="vatNumber" id="vatNumber"
                                        value={values.vatNumber}
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        isInvalid={!!errors.vatNumber && !!touched.vatNumber}
                                    />
                                    {!!touched.vatNumber &&
                                        <Form.Control.Feedback type="invalid">VAT number is required</Form.Control.Feedback>
                                    }
                                </Form.Group>
                                <Form.Group className="col gx-1">
                                    <Form.Label htmlFor="nameOnInvoice">Name on invoice</Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="nameOnInvoice" id="nameOnInvoice"
                                        value={values.nameOnInvoice}
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        isInvalid={!!errors.nameOnInvoice && !!touched.nameOnInvoice}
                                    />
                                    {!!touched.nameOnInvoice &&
                                        <Form.Control.Feedback type="invalid">Name on invoice is required</Form.Control.Feedback>
                                    }
                                </Form.Group>
                                <Form.Group className="col gx-1">
                                    <Form.Label htmlFor="billingAddress">Billing address</Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="billingAddress" id="billingAddress"
                                        value={values.billingAddress}
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        isInvalid={!!errors.billingAddress && !!touched.billingAddress}
                                    />
                                    {!!touched.billingAddress &&
                                        <Form.Control.Feedback type="invalid">Billing address is required</Form.Control.Feedback>
                                    }
                                </Form.Group>
                                <Form.Group className="col gx-1">
                                    <Form.Label htmlFor="billingEmail">Billing email</Form.Label>
                                    <Form.Control
                                        name="billingEmail" id="billingEmail"
                                        value={values.billingEmail}
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        isInvalid={!!errors.billingEmail && !!touched.billingEmail}
                                    />
                                    {!!touched.billingEmail &&
                                        <Form.Control.Feedback type="invalid"> {errors.billingEmail}</Form.Control.Feedback>
                                    }
                                </Form.Group>
                                <Form.Group className="col gx-1">
                                    <Form.Label htmlFor="startDayOfWeek">Start Day Of Week</Form.Label>
                                    <Form.Control as="select"
                                        name="startDayOfWeek" id="startDayOfWeek"
                                        value={values.startDayOfWeek}
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        isInvalid={!!errors.startDayOfWeek && !!touched.startDayOfWeek}
                                    >
                                        <option value="0" >Select Day</option>
                                        <option value={dayOfWeekEnum.MONDAY}>MONDAY</option>
                                        <option value={dayOfWeekEnum.TUESDAY}>TUESDAY</option>
                                        <option value={dayOfWeekEnum.WEDNESDAY}>WEDNESDAY</option>
                                        <option value={dayOfWeekEnum.THURSDAY}>THURSDAY</option>
                                        <option value={dayOfWeekEnum.FRIDAY}>FRIDAY</option>
                                        <option value={dayOfWeekEnum.SATURDAY}>SATURDAY</option>
                                        <option value={dayOfWeekEnum.SUNDAY}>SUNDAY</option>
                                    </Form.Control>
                                    {!!touched.startDayOfWeek &&
                                        <Form.Control.Feedback type="invalid"> {errors.startDayOfWeek}</Form.Control.Feedback>
                                    }
                                </Form.Group>
                                <Form.Group className="col gx-1">
                                    <Form.Label htmlFor="clientId">Client</Form.Label>
                                    <Form.Control as="select"
                                        name="clientId" id="clientId"
                                        value={values.clientId}
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        isInvalid={!!errors.clientId && !!touched.clientId}
                                    >
                                        {clientsSelectOptions}
                                    </Form.Control>
                                    {!!touched.clientId &&
                                        <Form.Control.Feedback type="invalid">{errors.clientId}</Form.Control.Feedback>
                                    }
                                </Form.Group>
                                <Form.Group className="col gx-1">
                                    <Form.Label htmlFor="crsUserId">User</Form.Label>
                                    <Form.Control as="select"
                                        name="crsUserId" id="crsUserId"
                                        value={values.crsUserId}
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        isInvalid={!!errors.crsUserId && !!touched.crsUserId}
                                    >
                                        {usersSelectOptions}
                                    </Form.Control>
                                    {!!touched.crsUserId &&
                                        <Form.Control.Feedback type="invalid">{errors.crsUserId}</Form.Control.Feedback>
                                    }
                                </Form.Group>
                                <Form.Group className="col gx-1">
                                    <Form.Label htmlFor="contactId">Contact</Form.Label>
                                    <Form.Control as="select"
                                        name="contactId" id="contactId"
                                        value={values.contactId}
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        isInvalid={!!errors.contactId && !!touched.contactId}
                                    >
                                        {contactsSelectOptions}
                                    </Form.Control>
                                    {!!touched.contactId &&
                                        <Form.Control.Feedback type="invalid">{errors.contactId}</Form.Control.Feedback>
                                    }
                                </Form.Group>
                                <Button type="submit" className="submit-btn col-auto align-self-start gx-1" variant="primary">Submit</Button>
                            </Row>
                        </Form>
                    )}
                </Formik>
                <div>
                    <Button variant="secondary" className="mt-4" onClick={e => this.contactModalOpen(e)}>
                        Create New Contact
                    </Button>
                </div>
                {this.state.modal &&
                    this.renderContactModal(this.state.modal)
                }
            </div>
        )
    }

    renderContactModal(modal) {
        return (
            <div>
                <Modal isOpen={modal === true} style={customStyles} centered>
                    <Button variant="secondary" className='col-12' onClick={e => this.contactModalClose(e)}>
                        Close
                    </Button>
                    <ModalHeader>New Contact</ModalHeader>
                    <ModalBody className="text-center m-2 ">
                        <Container fluid className="mb-0">
                            <ContactEditForm submitCallback={this.newContactcallback} />
                        </Container>
                    </ModalBody>
                </Modal>
            </div>

        )
    }
}

BrandEditForm.defaultProps = {
    brandData: {
        brandId: null,
        brandName: "",
        legalCompanyName: "",
        vatNumber: "",
        nameOnInvoice: "",
        billingAddress: "",
        billingEmail: "",
        imgUrl: "",
        startDayOfWeek: 0,
        crsUserId: 0,
        clientId: 0,
        contactId: 0
    },
    mode: FormModes.NEW
}