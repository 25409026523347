import React from "react";

import { Container, Card, Button, Form, Row, OverlayTrigger, Tooltip, Modal } from "react-bootstrap";
import * as Icon from "react-feather";
import { toastr } from "react-redux-toastr";
import BootstrapTable from "react-bootstrap-table-next";
import cellEditFactory, { Type } from 'react-bootstrap-table2-editor';
import filterFactory, { textFilter, dateFilter } from 'react-bootstrap-table2-filter';
import 'react-bootstrap-table2-filter/dist/react-bootstrap-table2-filter.min.css';
import { Formik } from "formik";
import AccountDropdown from "../../components/AccountDropdown";
import { Label } from "reactstrap";
import * as Yup from "yup";
import { genericFetcherFactory } from "../../utils/requestUtils";
import { validUrlWithProtocolRegex } from "../../utils/formUtils";

export default class QRManagement extends React.PureComponent {

    constructor(props) {
        super(props);
        this.state = {
            qrCodes: [],
            initSuccess: null,
            qrSaving: false,
            showSwapAccountModal: false,
            rowForSwap: null,
            selectedAccountToSwap: null,
            isChanged: false,
            generatedLink: null,
            selectedAccount: null,
            image_source: null,
            adsList: [],
            urlTemplate: null,
            adsSelectOptions: [<option value="">Select Ad</option>]
        };
        this.accountChanged = this.accountChanged.bind(this);
        this.accountChangedForSwap = this.accountChangedForSwap.bind(this);
        this.toggleIsActive = this.toggleIsActive.bind(this);

        this.updateRows = this.updateRows.bind(this);
        this.prepareForInsert = this.prepareForInsert.bind(this);

        this.saveQR = this.saveQR.bind(this);
        this.removeQR = this.removeQR.bind(this);
        this.revertQRChanges = this.revertQRChanges.bind(this);
        this.saveChangedQRs = this.saveChangedQRs.bind(this);
        this.downloadQRImage = this.downloadQRImage.bind(this);

        this.addTestQR = this.addQR.bind(this);
        this.updateStateCallback = this.updateStateCallback.bind(this);
        this.timer = null;
    }

    fetchQRs() {
        genericFetcherFactory("/api/accountscd/api/v1/qrcode/", "FETCH_QRS", "Failed to fetch QRs")().then(result => {
            if (result.success) {
                let responseData = result.data;
                // console.log(responseData);
                responseData.map((qr) => {
                    return this.mapQrDtoFields(qr);
                })
                this.setState({ ...this.state, qrCodes: responseData });
                return Promise.resolve(true);
            } else {
                toastr.error("Failed to fetch QRs");
                return Promise.resolve(false);
            }
        });
    }

    fetchQRsByAccountPlatformId(id) {
        genericFetcherFactory("/api/accountscd/api/v1/qrcode/?accountPlatformId=" + id, "FETCH_QRS_BY_ACCOUNT_PLATFORM_ID", "Failed to fetch QRs")().then(result => {
            if (result.success) {
                let responseData = result.data;
                // console.log(responseData);
                responseData.map((qr) => {
                    return this.mapQrDtoFields(qr);
                })
                this.setState({ ...this.state, qrCodes: responseData });
                if (responseData.length === 0) {
                    toastr.info("No QRs found for this account");
                }
                return Promise.resolve(true);
            } else {
                toastr.error("Failed to fetch QRs");
                return Promise.resolve(false);
            }
        });
    }

    fetchAdsByAccountPlatformId(id) {
        genericFetcherFactory("/api/accountscd/api/v1/ads/" + id, "FETCH_ADS_BY_ACCOUNT_PLATFORM_ID", "Failed to fetch Ads")().then(result => {
            if (result.success) {
                let responseData = result.data;
                //console.log(responseData);
                let adsSelectOptions = responseData.map(item => <option key={item.adId} value={JSON.stringify(item)}>{item.adName}</option>);
                adsSelectOptions.unshift(<option value="">Select Ad</option>);
                this.setState({ ...this.state, adsList: responseData, adsSelectOptions: adsSelectOptions });
                if (responseData.length === 0) {
                    toastr.info("No Ads found for this account");
                }
                return Promise.resolve(true);
            } else {
                toastr.error("Failed to fetch Ads");
                return Promise.resolve(false);
            }
        });
    }

    fetchAccountUrlTemplateByAccountPlatformId(id) {
        genericFetcherFactory("/api/accountscd/api/v1/linkTemplates/" + id, "FETCH_URL_TEMPLATE_BY_ACCOUNT_PLATFORM_ID", "Failed to fetch URL template")().then(result => {
            if (result.success) {
                let responseData = result.data;
                //console.log(responseData);
                this.setState({ ...this.state, urlTemplate: responseData });
            } else {
                toastr.error("Failed to fetch a template");
                this.setState({ ...this.state, urlTemplate: null });
                return Promise.resolve(false);
            }
        });
    }

    syncQrs() {
        genericFetcherFactory("/api/accountscd/api/v1/qrcode/sync", "SYNC_QRS", "Failed to sync QRs")().then(result => {
            if (result.success) {
                let responseData = result.data;
                // console.log(responseData);
                toastr.success("QRs synced successfully");
                responseData.map((qr) => {
                    return this.mapQrDtoFields(qr);
                })
                this.setState({ ...this.state, qrCodes: responseData });
                return Promise.resolve(true);
            } else {
                toastr.error("Failed to sync QRs");
                return Promise.resolve(false);
            }
        });
    }

    saveQR(qr, changeAccountFlag = false) {
        this.setState({ qrSaving: true });
        genericFetcherFactory("/api/accountscd/api/v1/qrcode/?changeAccount=" + changeAccountFlag, "SAVE_QR", "Failed to save QR", "PUT",
            {
                method: "PUT",
                body: JSON.stringify(qr),
                headers: { "Content-Type": "application/json" }
            }
        )().then(result => {
            let state = { qrSaving: false };
            if (result.success) {
                let newQRCodes = [...this.state.qrCodes];
                let index = newQRCodes.findIndex(_qr => _qr.id === qr.id);
                if (index !== -1) {

                    qr.updated = result.data.updated;
                    qr = { ...qr, isChanged: false };
                    changeAccountFlag ? newQRCodes.splice(index, 1) : newQRCodes.splice(index, 1, qr);
                    state.qrCodes = newQRCodes;
                    this.resetAccountSwapData();
                    changeAccountFlag ? toastr.success("account changed successfully to for QR, the QR is removed from this view") : toastr.success("QR saved successfully");
                } else {
                    toastr.warning("QR was saved but view cannot be updated due to an error. Please refresh the page");
                }
            } else {
                toastr.error("QR save failed");
            }
            this.setState({ ...state });
        });
    }

    removeQR(qr) {
        let confirmation = window.confirm("Are you sure you want to delete this QR code?");
        if (confirmation === true) {
            this.setState({ qrSaving: true });
            genericFetcherFactory("/api/accountscd/api/v1/qrcode/" + qr.id, "DELETE_QR", "Failed to delete QR", "DELETE",
                {
                    method: "DELETE",
                    headers: { "Content-Type": "application/json" }
                }
            )().then(result => {
                let state = { qrSaving: false };
                if (result.success) {
                    toastr.success("QR deleted successfully");
                    let newQRCodes = [...this.state.qrCodes];
                    let index = newQRCodes.findIndex(_qr => _qr.id === qr.id);
                    if (index !== -1) {
                        newQRCodes.splice(index, 1);
                        state.qrCodes = newQRCodes;
                    }
                    this.setState({ ...state, qrCodes: newQRCodes });
                };
            });
        }
    }

    async addQR(writtenName, generatedLink) {
        genericFetcherFactory("/api/accountscd/api/v1/qrcode/", "ADD_QR", "Failed to add QR", "POST",
            {
                method: "POST",
                body: JSON.stringify({
                    name: writtenName,
                    campaign: { custom_url: generatedLink },
                    accountPlatformId: this.state.selectedAccount.accountPlatformId
                }),
                headers: { "Content-Type": "application/json" }
            }
        )().then(result => {
            if (result.success) {
                toastr.success("QR added successfully");
                let responseData = result.data;
                responseData = this.mapQrDtoFields(responseData)
                this.setState({ ...this.state, qrCodes: [responseData, ...this.state.qrCodes] })
                return Promise.resolve(true);
            } else {
                return Promise.resolve(false);
            }
        });

    }

    async showQR(qrId) {
        await this.fetchQrImage(qrId).then(result => {
            if (result.success) {
                this.setState({ ...this.state, image_source: result.data.url, isChanged: true });
            } else {
                toastr.error("Qr image download failed!")
            }
        });
    }

    async downloadQRImage(qrId) {
        await this.fetchQrImage(qrId).then(result => {
            if (result.success) {
                window.location.assign(result.data.urls.png);
            } else {
                toastr.error("Qr image download failed!")
            }
        });
    }

    async fetchQrImage(qrId) {
        return await genericFetcherFactory("/api/accountscd/api/v1/qrcode/image/" + qrId, "GET_QR_IMAGE", "Failed to get QR image")();
    }

    componentWillUnmount() {
        clearTimeout(this.timer);
    }

    updateStateCallback(key, value) {
        this.setState({ [key]: value });
    }

    mapIdFieldsFromUrl(qr, url) {
        qr.adId = this.isolateValueFromUrl(url, "ad_id_");
        qr.adGroupId = this.isolateValueFromUrl(url, "adset_id_");
        qr.campaignId = this.isolateValueFromUrl(url, "campaign_id_");
        qr.baseTargetUrl = url.split("?").length > 1 ? url.split("?")[0] : "";

        return qr;
    }

    isolateValueFromUrl(url, key) {
        const regex = new RegExp("(?<=" + key + ")[0-9]+", 'g');
        return url.match(regex) ? url.match(regex)[0] : "";
    }

    mapQrDtoFields(qr) {
        qr.state = qr.state === "A";
        this.mapIdFieldsFromUrl(qr, qr.campaign.custom_url);
        return qr;
    }

    accountChanged(account) {
        if (account !== undefined) {
            this.setState({ selectedAccount: account });
        }
        this.fetchQRsByAccountPlatformId(account.accountPlatformId);
        this.fetchAdsByAccountPlatformId(account.accountPlatformId);
        this.fetchAccountUrlTemplateByAccountPlatformId(account.accountPlatformId);
    }

    accountChangedForSwap(account) {
        if (account !== undefined) {
            this.setState({ selectedAccountToSwap: account });
        }
    }

    setGeneratedLink(baseUrl, campaignId, adsetId, adId) {
        let stringFormat = this.state.urlTemplate.template.replace("{$baseUrl}", baseUrl).replace("{$campaignId}", campaignId).replace("{$adsetId}", adsetId).replace("{$adId}", adId);
        this.setState({ generatedLink: stringFormat });
        return stringFormat;
    }

    resetAccountSwapData() {
        this.setState({ showSwapAccountModal: false, rowForSwap: null, selectedAccountToSwap: null });
    }

    cellSaved(oldValue, newValue, row, column, { state, updateRows }) {
        if (oldValue !== newValue) {
            let qrs = state.qrs;
            let index = qrs.findIndex(qr => qr.id === row.id);
            switch (column.dataField) {
                case "adId": qrs[index].campaign.custom_url = this.setGeneratedLink(row.baseTargetUrl, row.campaignId, row.adGroupId, newValue);
                    break;
                case "adGroupId": qrs[index].campaign.custom_url = this.setGeneratedLink(row.baseTargetUrl, row.campaignId, newValue, row.adId);
                    break;
                case "campaignId": qrs[index].campaign.custom_url = this.setGeneratedLink(row.baseTargetUrl, newValue, row.adGroupId, row.adId);
                    break;
                case "baseTargetUrl": qrs[index].campaign.custom_url = this.setGeneratedLink(newValue, row.campaignId, row.adGroupId, row.adId);
                    break;
                default: console.error("No action for column " + column.dataField);
                    break;
            }
            if (index !== -1) {
                let qr = { ...qrs[index], isChanged: true }
                qr.preSaveState = qr.preSaveState === undefined ? {} : qr.preSaveState;
                qr.preSaveState[column.dataField] = oldValue;
                qrs.splice(index, 1, qr);

                updateRows(qrs);
            }
        }
    }

    actionsHeaderFormatter({ enabled, addTest }) {
        return (<
            // <OverlayTrigger placement="top" key={"ot-add-test-button"} overlay={
            //     <Tooltip placement="top" id={"ot-add-test-button"}>
            //         {enabled ? "Add Test" : "You must select an account"}
            //     </Tooltip>
            // }>
            //     <Icon.PlusSquare id="add-test-button" style={{ cursor: "pointer" }} className="feather-lg mr-2"
            //         color={enabled ? "green" : "lightgrey"}
            //         onClick={enabled ? () => { addTest() } : null} />

            // </OverlayTrigger>
            ></>);
    }

    checkboxFormatter(cell, row, index, { toggleChecked }) {
        return <Form.Check
            type="switch"
            id={`active-${row.id}`}
            name={`active-${row.id}`}
            checked={row.state}
            onChange={event => toggleChecked(event.target.checked, row)}
        />
    }

    actionsFormatter(cell, row, { removeQR, saveQR, revertQRChanges, downloadQRImage }) {
        let removeFormatterId = "deleteIcon-" + row.id;
        let saveFormatterId = "saveIcon-" + row.id;
        let undoFormatterId = "undoIcon-" + row.id;
        let downloadId = "downloadQr-" + row.id;
        let swapAccountId = "swapAccount-" + row.id;

        return (
            <div className="qrEditButtons" tabIndex="-1">
                <span>
                    <OverlayTrigger placement="top" key={"ot-" + saveFormatterId} overlay={
                        <Tooltip placement="top" id={"ot-" + saveFormatterId}>
                            {row.isChanged ? "Save Test" : "No changes made"}
                        </Tooltip>
                    }>
                        <Icon.Save id={saveFormatterId} style={{ cursor: "pointer" }} className="feather mr-2" color={row.isChanged ? "black" : "lightgrey"}
                            onClick={row.isChanged ? () => { saveQR(row) } : null} />
                    </OverlayTrigger>
                </span>

                <span>
                    <OverlayTrigger placement="top" key={"ot-" + undoFormatterId} overlay={
                        <Tooltip placement="top" id={"ot-" + undoFormatterId}>
                            {row.isChanged ? "Undo Changes" : "No changes made"}
                        </Tooltip>
                    }>
                        <Icon.RotateCcw id={undoFormatterId} style={{ cursor: "pointer" }} className="feather mr-2" color={row.isChanged ? "black" : "lightgrey"}
                            onClick={row.isChanged ? () => { revertQRChanges(row) } : null} />
                    </OverlayTrigger>
                </span>

                <span>
                    <OverlayTrigger placement="top" key={"ot-" + removeFormatterId} overlay={
                        <Tooltip placement="top" id={"ot-" + removeFormatterId}>
                            Delete Test
                        </Tooltip>
                    }>
                        <Icon.Trash2 id={removeFormatterId} style={{ cursor: "pointer" }} className="feather mr-2"
                            onClick={() => { removeQR(row) }} />

                    </OverlayTrigger>
                </span>

                <span>
                    <OverlayTrigger placement="top" key={"ot-" + downloadId} overlay={
                        <Tooltip placement="top" id={"ot-" + downloadId}>
                            Download QR Code
                        </Tooltip>
                    }>
                        <Icon.Download id={downloadId} style={{ cursor: "pointer" }} className="feather mr-2"
                            onClick={() => { downloadQRImage(row.id) }} />
                    </OverlayTrigger>
                </span>
                <span>
                    <OverlayTrigger placement="top" key={"ot-" + swapAccountId} overlay={
                        <Tooltip placement="top" id={"ot-" + swapAccountId}>
                            Swap Account
                        </Tooltip>
                    }>
                        <Icon.RefreshCw id={swapAccountId} style={{ cursor: "pointer" }} className="feather mr-2"
                            onClick={() => { this.setState({ showSwapAccountModal: true, rowForSwap: row }) }} />
                    </OverlayTrigger>
                </span>
            </div>

        )
    }

    updateRows(qrs) {
        this.setState({ qrCodes: qrs });
    }

    toggleIsActive(checked, row) {
        let newQRCodes = [...this.state.qrCodes];
        let rowIndex = newQRCodes.findIndex(qr => qr.id === row.id);
        if (rowIndex !== -1) {
            let qr = newQRCodes[rowIndex]
            qr.preSaveState = qr.preSaveState === undefined ? {} : qr.preSaveState;
            qr.preSaveState.state = !checked;
            let updatedQRs = { ...newQRCodes[rowIndex], state: checked, isChanged: true, activate: checked };
            newQRCodes.splice(rowIndex, 1, updatedQRs);
            this.updateRows(newQRCodes);
        }
    }

    prepareForInsert() {
        this.addNewQRTemplate();
    }

    addNewQRTemplate() {
        let templateQRCode = {
            name: null,
            url: null,
            state: false,
        }
        templateQRCode.key = this.generateKey(templateQRCode);

        return templateQRCode;
    }

    revertQRChanges(qr) {
        let newQRs = [...this.state.qrCodes];
        let index = newQRs.findIndex(_qr => _qr.id === qr.id);
        if (index !== -1) {
            let theQr = { ...newQRs[index], isChanged: false }
            if (theQr.preSaveState === undefined) theQr.preSaveState = {};
            Object.entries(theQr.preSaveState).forEach(([key, value]) => {
                theQr[key] = value;
            });
            theQr.campaign.custom_url = this.setGeneratedLink(theQr.baseTargetUrl, theQr.campaignId, theQr.adGroupId, theQr.adId);
            newQRs.splice(index, 1, theQr);
            this.updateRows(newQRs);
        }
    }

    saveChangedQRs() {
        // this.setState({ qrSaving: true });
        // let testsList = this.state.abTests.filter(test => test.isChanged === true);
        // if (testsList.length < 0) {
        //     toastr.warning("No pending changes to save");
        //     this.setState({ qrSaving: false });
        // } else {
        //     genericFetcherFactory(`/api/facebook-interface/ABTests/bulkUpdate`, "UPDATE_BULK_TESTS", "Failed to save test", "PATCH",
        //         {
        //             method: "POST",
        //             body: JSON.stringify(testsList),
        //             headers: { "Content-Type": "application/json" }
        //         })().then(result => {
        //             let state = { qrSaving: false };
        //             if (result.success === true) {
        //                 let savedTests = result.data.map(test => this.processTest(test, this.state.accountFunnels, this.state.accountProducts));
        //                 let newAbTests = [...this.state.abTests];

        //                 let failedToReplace = [];

        //                 savedTests.forEach(test => {
        //                     let index = newAbTests.findIndex(_test => _test.key === test.key);
        //                     if (index !== -1) {
        //                         newAbTests.splice(index, 1, test);
        //                     } else {
        //                         failedToReplace.push(test);
        //                     }
        //                 });

        //                 if (failedToReplace.size > 0) {
        //                     toastr.warning("The view could not be properly updated. Check the console for details and please refresh the page to avoid inconsistency.");
        //                     console.log(failedToReplace);
        //                 }

        //                 state.abTests = newAbTests;
        //             }

        //             this.setState({ ...state });
        //         });
        // }
    }

    isValidUrlWithProtocol(string) {
        const urlWithProtocolRegex = validUrlWithProtocolRegex;
        return urlWithProtocolRegex.test(string);
    }

    columns = [
        {
            dataField: "name", text: "QR Name", sort: true, editable: true, filter: textFilter({ placeholder: '', style: { width: "80%" } }),
            editor: { type: Type.TEXT }
        },
        // {
        //     dataField: "ad", text: "Ad", 
        //     editor: { type: Type.SELECT, options: this.state.adsList },
        // },
        {
            dataField: "adId", headerStyle: { width: "85px" }, text: "Ad ID", sort: true, editable: true, style: { cursor: "pointer" }, filter: textFilter({ placeholder: '', style: { width: "80%" } }),
            editor: { type: Type.TEXT },
            validator: (newValue, row, column, done) => {
                this.timer = setTimeout(() => {
                    if (isNaN(newValue)) {
                        return done({
                            valid: false,
                            message: 'value should be numeric'
                        });
                    }
                    return done();
                }, 1000);
                return {
                    async: true
                };
            }
        },
        {
            dataField: "campaignId", headerStyle: { width: "85px" }, text: "Campaign ID", sort: true, style: { cursor: "pointer" }, editable: true, filter: textFilter({ placeholder: '', style: { width: "80%" } }),
            editor: { type: Type.TEXT },
            validator: (newValue, row, column, done) => {
                this.timer = setTimeout(() => {
                    if (isNaN(newValue)) {
                        return done({
                            valid: false,
                            message: 'value should be numeric'
                        });
                    }
                    return done();
                }, 2000);
                return {
                    async: true
                };
            }
        },
        {
            dataField: "adGroupId", headerStyle: { width: "85px" }, text: "Ad Group ID", sort: true, style: { cursor: "pointer" }, editable: true, filter: textFilter({ placeholder: '', style: { width: "80%" } }),
            editor: { type: Type.TEXT },
            validator: (newValue, row, column, done) => {
                this.timer = setTimeout(() => {
                    if (isNaN(newValue)) {
                        return done({
                            valid: false,
                            message: 'value should be numeric'
                        });
                    }
                    return done();
                }, 2000);
                return {
                    async: true
                };
            }
        },
        {
            dataField: "url", text: "Short Url", sort: false, editable: false, editor: { type: Type.TEXT }, filter: textFilter({ placeholder: '', style: { width: "80%" } })
        },
        {
            dataField: "baseTargetUrl", text: "Base Target Url", sort: true, editable: true, style: { cursor: "pointer" }, filter: textFilter({ placeholder: '', style: { width: "80%" } }),
            editor: { type: Type.TEXT },
            validator: (newValue, row, column, done) => {
                this.timer = setTimeout(() => {
                    if (!this.isValidUrlWithProtocol(newValue)) {
                        return done({
                            valid: false,
                            message: 'base url should be a valid url & contain protocol(e.g. https://)'
                        });
                    }
                    return done();
                }, 2000);
                return {
                    async: true
                };
            }
        },
        {
            dataField: "campaign.custom_url", text: "Full Target Url", sort: true, editable: false, filter: textFilter({ placeholder: '', style: { width: "80%" } }),
            editor: { type: Type.TEXT }
        },
        {
            dataField: "state", text: "Active", sort: true, headerStyle: { width: "45px" }, editable: false,
            style: { cursor: "pointer" }, formatter: (cell, row, rowIndex) => this.checkboxFormatter(cell, row, rowIndex, { toggleChecked: this.toggleIsActive })
        },
        {
            dataField: "updated", text: "Last Updated", sort: true, editable: false,
            editor: { type: Type.DATE },
            filter: dateFilter(),
            formatter: (cell) => `${new Date(cell).toLocaleString('en-GB', { hour12: false, })}`
        },
        {
            dataField: "", text: "", editable: false, isDummyField: true, headerStyle: { width: "80px", textAlign: "center" }, align: "center",
            formatter: (cell, row) => this.actionsFormatter(
                cell, row, { removeQR: this.removeQR, saveQR: this.saveQR, revertQRChanges: this.revertQRChanges, downloadQRImage: this.downloadQRImage }
            )
        }
    ];

    render() {
        return (
            <Container fluid className="p-0">
                <Modal show={this.state.showSwapAccountModal} onHide={this.closeSwapAccountModal}>
                    <Modal.Header closeButton>
                        <Modal.Title>Swap Account</Modal.Title>
                    </Modal.Header>
                    <Button color="secondary" size="small"
                        style={{ position: "absolute", top: "10px", right: "10px" }}
                        onClick={e => this.resetAccountSwapData()}>
                        Close
                    </Button>
                    <Modal.Body>
                        <AccountDropdown
                            selectedAccount={this.state.selectedAccountToSwap}
                            accountChanged={this.accountChangedForSwap}
                            platform='GOOGLE'
                        />
                        <p>Are you sure you want to swap the account for this QR?</p>

                        <Button onClick={() => {
                            this.state.rowForSwap.accountPlatformId = this.state.selectedAccountToSwap.accountPlatformId;
                            this.saveQR(this.state.rowForSwap, true);
                        }} color="primary">swap</Button>
                    </Modal.Body>
                </Modal>
                <Card>
                    <Card.Header>
                        <Card.Title tag="h5" className="mb-0">
                            <h2>QR Management</h2>
                        </Card.Title>
                    </Card.Header>
                    <Card.Body>
                        <div className="form-group" style={{ width: "400px", zIndex: 200, position: "relative" }}>
                            <Label>Account Name</Label>
                            <AccountDropdown
                                updateStateCallback={this.updateStateCallback}
                                accountChanged={this.accountChanged}
                                selectedAccount={this.state.selectedAccount}
                                platform='GOOGLE'
                            /><br />
                        </div>
                        {/*<button onClick={() => this.syncQrs()}>sync Qrs</button>*/}

                        {this.state.selectedAccount &&
                            <><h2>Add a new QR Code</h2>
                                <Formik
                                    initialValues={{ campaignId: "", adGroupId: "", adId: "", link: "", name: "", ad: '{"campaignId": "", "adGroupId": "", "adId": ""}' }}
                                    validationSchema={Yup.object().shape({
                                        // campaignId: Yup.number().required("Campaign ID is required"),
                                        // adGroupId: Yup.number().required("Ad Group ID is required"),
                                        // adId: Yup.number().required("Ad ID is required"),
                                        // link: Yup.url().required("Link is required"),
                                        name: Yup.string().required("QR Name is required"),
                                        link: Yup.string().matches(
                                            validUrlWithProtocolRegex,
                                            'Please enter valid URL'
                                        ).required("QR Name is required")
                                    })}
                                    onSubmit={(values, { resetForm }) => {

                                        // do your stuff 
                                        this.addQR(this.state.name, this.state.generatedLink);
                                        resetForm();

                                    }}
                                >
                                    {({ handleSubmit, handleChange, handleBlur, errors, touched, values }) => (
                                        <Form onSubmit={handleSubmit}>
                                            <Row className="gx-1 mt-3">
                                                <Form.Group className="col gx-1 col-sm-4">
                                                    <Form.Label htmlFor="name">QR Name</Form.Label>
                                                    <Form.Control
                                                        type="text"
                                                        name="name"
                                                        id="name"
                                                        value={values.name}
                                                        onBlur={handleBlur}
                                                        onChange={(e) => {
                                                            this.setState({ name: e.target.value });
                                                            handleChange(e);
                                                        }}
                                                        isInvalid={!!errors.name && !!touched.name}
                                                    />
                                                    {!!touched.folder &&
                                                        <Form.Control.Feedback type="invalid">{errors.folder}</Form.Control.Feedback>
                                                    }
                                                </Form.Group>
                                                <Form.Group className="col gx-1 col-sm-4">
                                                    <Form.Label htmlFor="link">Link</Form.Label>
                                                    <Form.Control
                                                        type="text"
                                                        name="link"
                                                        id="link"
                                                        value={values.link}
                                                        onBlur={handleBlur}
                                                        onChange={(e) => {
                                                            this.setGeneratedLink(e.target.value, JSON.parse(values.ad).campaignId, JSON.parse(values.ad).adGroupId, JSON.parse(values.ad).adId);
                                                            handleChange(e);
                                                        }}
                                                        isInvalid={!!errors.link && !!touched.link}
                                                    />
                                                    {!!touched.folder &&
                                                        <Form.Control.Feedback type="invalid">{errors.folder}</Form.Control.Feedback>
                                                    }
                                                </Form.Group>
                                                {/* <Form.Group className="col gx-1 col-sm-3">
                                            <Form.Label htmlFor="campaignId">Campaign ID</Form.Label>
                                            <Form.Control
                                                type="number"
                                                name="campaignId"
                                                id="campaignId"
                                                value={values.campaignId}
                                                onBlur={handleBlur}
                                                onChange={(e) => {
                                                    this.setGeneratedLink(values.link, e.target.value, values.adGroupId, values.adId);
                                                    handleChange(e);
                                                  }}
                                                isInvalid={!!errors.campaignId && !!touched.campaignId}
                                            />
                                            {!!touched.folder &&
                                                <Form.Control.Feedback type="invalid">{errors.folder}</Form.Control.Feedback>
                                            }
                                        </Form.Group>
                                        <Form.Group className="col gx-1 col-sm-3">
                                            <Form.Label htmlFor="adGroupId">Ad Group ID</Form.Label>
                                            <Form.Control
                                                type="number"
                                                name="adGroupId"
                                                id="adGroupId"
                                                value={values.adGroupId}
                                                onBlur={handleBlur}
                                                onChange={(e) => {
                                                    this.setGeneratedLink(values.link, values.campaignId, e.target.value, values.adId);
                                                    handleChange(e);
                                                    }}
                                                isInvalid={!!errors.adGroupId && !!touched.adGroupId}
                                            />
                                            {!!touched.folder &&
                                                <Form.Control.Feedback type="invalid">{errors.folder}</Form.Control.Feedback>
                                            }
                                        </Form.Group>
                                        <Form.Group className="col gx-1 col-sm-3">
                                            <Form.Label htmlFor="adId">Ad ID</Form.Label>
                                            <Form.Control
                                                type="number"
                                                name="adId"
                                                id="adId"
                                                value={values.adId}
                                                onBlur={handleBlur}
                                                onChange={(e) => {
                                                    this.setGeneratedLink(values.link, values.campaignId, values.adGroupId, e.target.value);
                                                    handleChange(e);
                                                    }}
                                                isInvalid={!!errors.adId && !!touched.adId}
                                            />
                                            {!!touched.folder &&
                                                <Form.Control.Feedback type="invalid">{errors.folder}</Form.Control.Feedback>
                                            }
                                        </Form.Group> */}
                                                <Form.Group className="col gx-1 col-sm-4">
                                                    <Form.Label htmlFor="ad">Ad</Form.Label>
                                                    <Form.Control
                                                        as="select"
                                                        name="ad"
                                                        id="ad"
                                                        value={values.ad}
                                                        onChange={(e) => {
                                                            this.setGeneratedLink(values.link, JSON.parse(e.target.value).campaignId, JSON.parse(e.target.value).adGroupId, JSON.parse(e.target.value).adId);
                                                            handleChange(e);
                                                        }}
                                                        isInvalid={!!errors.ad && !!touched.ad}
                                                    >
                                                        {this.state.adsSelectOptions}
                                                    </Form.Control>
                                                    {!!touched.folder &&
                                                        <Form.Control.Feedback type="invalid">{errors.folder}</Form.Control.Feedback>
                                                    }
                                                </Form.Group>
                                                <Button className="submit-btn col-12 col-sm-auto align-self-start" type="submit" variant="primary">
                                                    Submit
                                                </Button>
                                            </Row>
                                        </Form>
                                    )}
                                </Formik><br /></>
                        }

                        <h2>QR Codes Table</h2>
                        {this.state.qrCodes.length > 0 &&
                            <BootstrapTable
                                id="qrs_table"
                                headerClasses="sticky"
                                keyField="id"
                                bordered={false}
                                sort={{ dataField: 'updated', order: 'desc' }}
                                striped
                                hover
                                data={this.state.qrCodes ? this.state.qrCodes : []}
                                columns={this.columns}
                                rowClasses={(row) => { return row.isChanged ? "row-pending-changes" : "" }}
                                filter={filterFactory()}
                                cellEdit={cellEditFactory({
                                    mode: 'click',
                                    blurToSave: true,
                                    afterSaveCell: (oldValue, newValue, row, column) =>
                                        this.cellSaved(
                                            oldValue, newValue, row, column,
                                            {
                                                state: { qrs: [...this.state.qrCodes] },
                                                updateRows: this.updateRows,
                                            })
                                })
                                }
                            />}

                    </Card.Body>
                </Card>
            </Container>
        )
    }
}

