import * as types from "../constants";

const initialState = {
    slackChannelsDetailsList: [],
    slackAlertsChannelsEntities: {
        account: [],
        product: []
    }
}

export default function reducer(state = initialState, action) {
    switch (action.type) {
        case types.SET_CHANNEL_DETAILS_LIST:
            return {
                ...state,
                slackChannelsDetailsList: action.payload
            }
        case types.SET_ALERTS_CHANNELS_ENTITIES:
            return {
                ...state,
                slackAlertsChannelsEntities: action.payload
            }
        default:
            return state;
    }
}