import React from "react";

import { genericCachedFetcherFactory, genericFetcherFactory, getCacheKeyForUrl } from "../utils/requestUtils";
import { toastr } from "react-redux-toastr";
import { setFacebookUserData } from "../redux/actions/userContextActions";

import { connect } from 'react-redux';
import { addHours } from "../utils/dateUtils";
import CacheController from "../services/cache/CacheController";

const alreadyLoaded = () => {
    if (window.fbContextLoaded !== true) {
        window.fbContextLoaded = true;
        return false;
    }

    return true;
}
const resetSingletonFlag = () => {
    if (window.fbContextLoaded === true) {
        window.fbContextLoaded = false;
    }
}

const version = 'v19.0';

class FacebookContextComponent extends React.Component {
    fbScriptElementId = "facebook-jssdk";

    constructor() {
        super();

        this.handleFbLogin = this.handleFbLoginResult.bind(this);
        this.handleFbLoginResult = this.handleFbLoginResult.bind(this);
        this.handleFbToken = this.handleFbToken.bind(this);
        this.doFbLogin = this.doFbLogin.bind(this);
        this.doFbLogout = this.doFbLogout.bind(this);
        this.afterFbInit = this.afterFbInit.bind(this);
    }

    componentDidMount() {
        // Check if context was already loaded once in the application. No need to proceed with bootstrapping and context if it has.
        if (alreadyLoaded()) return;

        // Check if Facebook JS is not loaded
        if (!document.getElementById(this.fbScriptElementId)) {
            this.injectFb();
        } else {
            this.afterFbInit();
        }
    }

    componentWillUnmount() {
        resetSingletonFlag();
    }

    injectFb() {
        genericCachedFetcherFactory("/api/facebook-interface/Utilities/getAppId", "FB_APP_ID", "Facebook appId could not be fetched", null)().then(response => {
            if (response.success) {
                let appId = response.data;

                // Define the fbAsyncInit function in the window context so that the Facebook function can call it by name.
                window.fbAsyncInit = () => {
                    window.FB.init({
                        appId: appId,
                        cookie: false,
                        xfbml: false,
                        version: version
                    });

                    this.afterFbInit();
                };

                // Define and run the Facebook bootstrapper. After Facebook is bootstrapped it will call window.fbAsyncInit function.
                (function (d, s, id) {
                    let js, fjs = d.getElementsByTagName(s)[0];
                    if (d.getElementById(id)) { return; }
                    js = d.createElement(s); js.id = id;
                    js.src = "https://connect.facebook.net/en_US/sdk.js";
                    fjs.parentNode.insertBefore(js, fjs);
                }(document, 'script', this.fbScriptElementId));
            } else {
                toastr.error("Facebook API could not be loaded");
            }
        })
    }

    async afterFbInit() {
        // If not already logged in and should login, do login
        if (this.props.facebookUserData && this.props.facebookUserData.isLoggedIn) {
            // Check if the token has a smaller lifetime than 10 minutes and then refresh
            if (this.props.facebookUserData.expiresAt <= (Date.now() - (1000 * 600))) {
                window.FB.getLoginStatus(res => {
                    if (res && res.status == "connected") {
                        this.handleFbToken(res.authResponse.accessToken, res.authResponse.expiresIn);
                    } else {
                        this.doFbLogin();
                    }
                }, true);
            }
        } else {
            if (await this.shouldDoFbLogin() === true) {
                window.FB.getLoginStatus(res => {
                    if (res && res.status == "connected") {
                        this.handleFbToken(res.authResponse.accessToken, res.authResponse.expiresIn);
                    } else {
                        this.doFbLogin();
                    }
                });
            }
        }
    }

    async shouldDoFbLogin() {
        // Call backend and check whether the user is logged in to facebook to perform auto login on load
        return genericCachedFetcherFactory("/isLoggedIntoFb", "FB_DATA", "Error checking FB token with backend", addHours(24 * 30))().then(response => {
            if (response.success) {
                if (response.data === true) {
                    return true;
                }
            }
            return false;
        })
    }

    handleFbLoginResult(response) {
        if (response.authResponse) {
            this.handleFbToken(response.authResponse.accessToken, response.authResponse.expiresIn);
        } else {
            toastr.error("Facebook login cancelled");
        }
    }

    handleFbToken(fbToken, expiresIn) {
        // Send short-lived token to backend to change into long-lived token
        CacheController.deleteObject(getCacheKeyForUrl("/isLoggedIntoFb"));
        genericFetcherFactory("/setFacebookToken/" + fbToken, "FB_TOKEN", "Error processing Facebook token")().then(response => {
            if (response.success === true) {
                window.FB.api('/me', response => {
                    if (this.props.facebookUserData.isLoggedIn !== true) {
                        toastr.success("Logged into Facebook as " + response.name);
                    }
                    this.props.setFacebookUserData({ isLoggedIn: true, facebookName: response.name, accessToken: fbToken, expiresAt: this.calculateExpirationDate(expiresIn) });
                });
            }
        })
    }

    doFbLogin() {
        window.FB.login(this.handleFbLoginResult, {
            scope: 'ads_management,pages_manage_posts,pages_manage_engagement,pages_manage_ads,pages_manage_metadata,pages_read_engagement,pages_read_user_content',
            return_scopes: true
        });
    }

    doFbLogout() {
        CacheController.deleteObject(getCacheKeyForUrl("/isLoggedIntoFb"));
        // This does not really log the user out of FB or remove the authorization given to the QS app in FB.
        // This call just flags the user in the backend as logged out and removes the token from the session.
        genericFetcherFactory("/facebookLogout", "FB_LOGOUT", "Facebook could not log you out.")().then(response => {
            toastr.success("Logged out of Facebook");
            this.props.setFacebookUserData({ isLoggedIn: false, facebookName: "", accessToken: null, expiresAt: null });
        });
    }

    calculateExpirationDate(seconds) {
        return Date.now() + (parseInt(seconds) * 1000);
    }

    render() {
        if (this.props.shouldRender === true) {
            if (this.props.facebookUserData.isLoggedIn !== true) {
                return <div onClick={this.doFbLogin}>Log in to Facebook</div>;
            } else {
                return <div onClick={this.doFbLogout}>Log out from Facebook ({this.props.facebookUserData.facebookName})</div >;
            }
        }
        return "";
    }
}

export default connect(
    (store) => { return { facebookUserData: store.userContext.facebookUserData } },
    { setFacebookUserData })(FacebookContextComponent)