import * as types from "../constants";

const initialState = {
    user: null,
    loggedIn: null,
    allowedModules: [],
    facebookUserData: { isLoggedIn: false },
    personalDashboard: [],
    permalinkList: []
};

export default function reducer(state = initialState, action) {
    switch (action.type) {
        case types.LOAD_USER:
            return {
                ...state,
                user: action.payload
            };
        case types.LOG_IN:
            return {
                ...state,
                loggedIn: true
            };
        case types.LOG_OUT:
            return {
                ...state,
                loggedIn: false
            }
        case types.UPDATE_ALLOWED_MODULES:
            return {
                ...state,
                allowedModules: action.payload
            }
        case types.SET_FACEBOOK_USER_DATA:
            return {
                ...state,
                facebookUserData: action.payload
            }
        case types.IMPERSONATE_USER:
            return {
                ...state,
                impersonatedUser: action.payload
            }
        case types.PERSONAL_DASHBOARD:
            return {
                ...state,
                personalDashboard: action.payload
            }
        case types.PERMALINK_LIST:
            return {
                ...state,
                permalinkList: action.payload
            }
        case types.ACCOUNTS:
            return {
                ...state,
                accounts: action.payload
            }
        case types.PAGES:
            return {
                ...state,
                pages: action.payload
            }
        default:
            return state;
    }
}
