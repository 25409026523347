import { faBell } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import {
    Alert, Badge, Button,
    Card,
    Container,
    Modal
} from "react-bootstrap";
import { connect } from "react-redux";
import { toastr } from "react-redux-toastr";
import MediaQuery from 'react-responsive';
import * as Icon from "react-feather";
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import AccountDropdown from "../../../components/AccountDropdown";
import FacebookContextComponent from "../../../components/FacebookContextComponent";
import { StoplossGHOnlyColumnsToHide } from "../components/adsetDataView/adsetDataConstants";
import GenericErrorAlert from "../../../components/GenericErrorAlert";
import Loader from "../../../components/Loader";
import { formStatus } from "../../../utils/formUtils";
import { genericFetcherFactory } from "../../../utils/requestUtils";
import { AccountIsActive } from "../../prods-and-accounts/account/accountUtils";
import * as adsetUtils from "../../../utils/adsetUtils"
import AdsetDataView from "../components/adsetDataView/AdsetDataView";
import { currencyFormatter, percentFormatter } from "../components/adsetDataView/AdsetViewHelper";
import ConfirmationTableView from "../components/ConfirmationTableView";
const totalsInit = {
    spend: 0, rev: 0, spendLastHour: 0, conversions: 0, roi: 0, roiLastHour: 0, spendRemainingForToday: 0, totalTestSpentToday: 0, testRoi: 0, testSpend: 0, testRev: 0, sales:0 
}
class StoplossTool extends React.PureComponent {
    constructor(props) {
        super(props);
        this.handleAdsetActions = this.handleAdsetActions.bind(this);
        this.saveAdsetActions = this.saveAdsetActions.bind(this);
        this.accountChanged = this.accountChanged.bind(this);
        this.state = {
            tabValue: 0,
            accountId: this.props.match.params.accountId,
            adsets: null,
            userChanges: null,
            initAdsets: null,
            errorMessage:null,
            init: true,
            adsetDataInProcess: false,
            savingAdsets: formStatus.IDLE,
            formConfirmed: null,
            selectedAccount: null,
            lastUpdate: "N/A",
            UpdateroiLastHour: "N/A",
            filterProduct: [],
            totals: {}
        }
    }

    getUiEdittedAdsetValues(adsetList, adsetRow, isDailyAdset){  
        const CENTS_IN_A_DOLLAR = 100;
        let adset = {};
        let adsetStatus = "PAUSED", adsetBudget, adsetBid;
        let maybeAdsetDto = adsetList.find(adset => adset.adsetId === adsetRow.adsetId);

        if(maybeAdsetDto !== undefined){
            adsetStatus = maybeAdsetDto.adsetStatus;
            adsetBid = maybeAdsetDto.bidAmount / CENTS_IN_A_DOLLAR;
            adsetBudget = isDailyAdset ? maybeAdsetDto.dailyBudget : maybeAdsetDto.lifetimeBudget;
        }               

        adset.isActive = adsetUtils.getStatusByEnum(adsetStatus);
        adset.bidAmount = adsetBid ? adsetBid : adsetRow.Bid;
        adset.budget = (adsetBudget ? adsetBudget : (isDailyAdset ? adsetRow.dailyBudget : adsetRow['Adset Budget']))/CENTS_IN_A_DOLLAR;

        return adset;
    }

    handleTabChange = (event, value) => {
        this.setState({ tabValue: value, init: true, errorMessage:null, initAdsets: null, selectedAccount: null, adsets: null, lastUpdate: null, lastUpdateFB: null, totals: totalsInit });
    }

    async accountChanged(account) {

        if (account !== undefined) {
            this.setState({ selectedAccount: account, adsetDataInProcess: true, adsets: null, lastUpdate: null, lastUpdateFB: null, totals: totalsInit});
            let adsetResponse = [];
            let lastUpdateDataFieldString = this.state.tabValue === 1 ? 'BE last Update IL' : 'Last Update IL';
            [adsetResponse] = await Promise.all([this.fetchAdsetsByAccounts(account, this.state.tabValue)]);
            if (adsetResponse.success === true /*&& productsResonse.success === true*/) {
                const CENTS_IN_A_DOLLAR = 100;
                let totals = { ...totalsInit }
                let adsetList = adsetResponse.data.dailyAcceleraedList;
                let lambdaJsonResponse = JSON.parse(adsetResponse.data.lambdaJsonResponse);
                let lastUpdateRow = lambdaJsonResponse.find(row => row[lastUpdateDataFieldString]);
                let lastUpdateFacebookRow = lambdaJsonResponse.find(row => row['FB Last Update IL']);
                let commissionRow = lambdaJsonResponse.find(row => row['commission']);
                let commission = 1;
                if (commissionRow !== undefined) {
                    commission = commissionRow.commission;
                }

                let lastUpdate = "N/A";
                let lastUpdateFB = "N/A";
                if (lastUpdateRow !== undefined) {
                    lastUpdate = lastUpdateRow[lastUpdateDataFieldString];
                }
                if (lastUpdateFacebookRow !== undefined) {
                    lastUpdateFB = lastUpdateFacebookRow['FB Last Update IL'];
                }

                if (lambdaJsonResponse.length > 0) {
                    let adsets = [], adsetIds = [], duplicateAdsetsIndexes = [];

                    lambdaJsonResponse.forEach((adsetRow, index) => {
                        //check if adset is duplicated
                        if(adsetIds.includes(adsetRow.adsetId)){
                            duplicateAdsetsIndexes.push(index);
                        }
                        adsetIds.push(adsetRow.adsetId);
                        let isDailyAdset = adsetUtils.isDailyAdset(adsetRow.adset_schedule_days);
                        let adsetDto = this.getUiEdittedAdsetValues(adsetList, adsetRow, isDailyAdset);

                        adsetDto.adsetId = adsetRow.adsetId;
                        adsetDto.adsetName = adsetRow.adsetName;

                        if (adsetRow['Spend Today'] !== undefined) {
                            totals.spend += Number(adsetRow['Spend Today']);
                            if(adsetUtils.isTestAdset(adsetDto.adsetName)){
                                totals.testSpend += Number(adsetRow['Spend Today']);
                            }
                        }
                        if (adsetRow['Conv Today'] !== undefined) {
                            totals.conversions += Number(adsetRow['Conv Today']);
                        }
                        if (adsetRow['Sales BE Today'] !== undefined) {
                            totals.sales += Number(adsetRow['Sales BE Today']);
                        }
                        if (adsetRow['Revenue BE Today'] !== undefined) {
                            totals.rev += Number(adsetRow['Revenue BE Today']);
                            if(adsetUtils.isTestAdset(adsetDto.adsetName)){
                                totals.testRev += Number(adsetRow['Revenue BE Today']);
                            }
                        }
                        if (adsetRow['Spend -1 hour'] !== undefined) {
                            totals.spendLastHour += Number(adsetRow['Spend -1 hour']);
                            // if (adsetRow['Revenue BE Today'] !== undefined) {
                            //     adsetRow['ROI -1 hour'] = Number(adsetRow['Revenue BE Today']) / (Number(adsetRow['Spend -1 hour']) * commission)
                            // }
                        }
                        if(isDailyAdset){
                            if (adsetRow.dailyBudget !== undefined){
                                adsetDto.dailyBudget = adsetRow.dailyBudget = adsetDto.budget;
                            }
                        }else{
                            if (adsetRow['Adset Budget'] !== undefined){
                                let calculatedDaysLeftToRun = adsetUtils.calculateDaysRemaining(adsetRow.adset_schedule_days, adsetRow.adset_end_time);
                                adsetDto.dailyBudget = adsetRow.dailyBudget = Math.round((Number(adsetRow.budgetRemaing) / calculatedDaysLeftToRun / CENTS_IN_A_DOLLAR + Number.EPSILON) * 100) / 100;
                            }
                        }
                        if(adsetRow.budgetRemaing !== undefined){
                            adsetDto.budgetRemaining = adsetRow.budgetRemaing / CENTS_IN_A_DOLLAR;
                            adsetRow.budgetRemaing = Number(adsetRow.dailyBudget) - Number(adsetRow['Spend Today']);
                            if(adsetDto.isActive && adsetUtils.shouldAdsetRunToday(adsetRow.adset_schedule_days)){
                                totals.spendRemainingForToday += Number(adsetRow.budgetRemaing);
                            }
                        }
                        if(adsetRow['Spend Today'] !== undefined && adsetUtils.isTestAdset(adsetDto.adsetName)){
                            totals.totalTestSpentToday += Number(adsetRow['Spend Today'])
                        }

                        let adset = {
                            acceleratedDto: adsetDto,
                            adsetData: adsetRow
                        }
                        if(adsetRow.adsetId && adsetRow['Spend Today'] > 0){
                            adsets.push(adset);
                        }
                    });
                    //remove duplicate adsets
                    duplicateAdsetsIndexes.forEach(index => {
                        adsets.splice(index, 1);
                    });
                    if (totals.rev > 0 && totals.spend > 0) {
                        totals.roi = totals.rev / (totals.spend * commission)
                    }
                    if (totals.testRev > 0 && totals.testSpend > 0) {
                        totals.testRoi = totals.testRev / (totals.testSpend * commission)
                    }
                    if (totals.rev > 0 && totals.spendLastHour > 0) {
                        totals.roiLastHour = totals.rev / (totals.spendLastHour * commission)
                    }
                    if (adsets.length > 0) {
                        this.setState({ initAdsets: true, init: true, errorMessage:null, adsets: adsets, lastUpdate: lastUpdate, lastUpdateFB: lastUpdateFB, adsetDataInProcess: false, totals: totals });
                    } else {
                        this.setState({ init: false, errorMessage: "Failed to get data for  adsets", adsetDataInProcess: false });

                    }
                } else {
                    this.setState({ init: false, errorMessage: "The response received contains 0 adsets, maybe try again later..", adsetDataInProcess: false });
                }
            } else {
                toastr.error("Failed to Fetch Adsets Data");
            }


        } else {
            toastr.error("An error occured. Could not locate selected account");
        }
    }

    handleAdsetActions(userChanges) {
        if (userChanges.length <= 0) {
            toastr.warn("No pending changes to save");
        } else {

            this.setState({ savingAdsets: formStatus.CONFIRMATION_REQUIRED });
            if (this.state.formConfirmed === null) {
                this.setState({ savingAdsets: formStatus.CONFIRMATION_REQUIRED, userChanges: userChanges });
            } else {
                this.saveAdsetActions(userChanges);
            }
        }
    }

    saveAdsetActions(userChanges) {
        let userChangeInDollar = [];
        let currAcc;
        userChanges.forEach(acc => {
            currAcc = JSON.parse(JSON.stringify(acc));//creating a copy and not using the same reference 

            //add snowflake data to dto
            let index = this.state.adsets.findIndex(adset => adset.acceleratedDto.adsetId == acc.acceleratedAdsetId);
            if (index !== -1) {
                let adset = this.state.adsets[index];
                currAcc.snowflakeData = adset.adsetData;
            }

            let keys = Object.keys(acc);
            keys.forEach(key => {
                if (key == 'dailyBudget' || key == 'bidAmount' || key == 'budget') {
                    let value = acc[key] * 100;
                    if(key == 'dailyBudget'){
                        if(!adsetUtils.isDailyAdset(this.state.adsets[index].adsetData.adset_schedule_days)){
                            delete currAcc.dailyBudget;
                        }else{
                            currAcc.dailyBudget = value;
                        }
                    }else if(key == 'budget'){
                        currAcc.lifetimeBudget = value;
                    }else{
                        currAcc[key] = value;
                    }
                }
            });

            userChangeInDollar.push(currAcc);
        });

        this.setState({ savingAdsets: formStatus.IN_PROGRESS  });
        genericFetcherFactory(`/api/facebook-interface/Adset/updateAllOnFacebook`, "UPDATE_ADSETS", "Failed to save all adsets", "POST",
            {
                method: "POST",
                body: JSON.stringify({
                    accountId: this.state.selectedAccount.accountId,
                    dtoList: userChangeInDollar
                }),
                headers: {
                    "Content-Type": "application/json"
                }
            })().then(result => {
                if (result.success === true) {
                    this.setState({  savingAdsets: formStatus.IDLE,userChanges:[] });
                    toastr.success("Adset have been updated");
                } 
            });
    }

    async fetchAccountProducts(accountProducts) {
        return genericFetcherFactory("/api/product/Products/byProductIdList", "PRODUCT_LIST", "Failed to get productlist", "POST",
            {
                method: "POST",
                body: JSON.stringify(accountProducts),
                headers: { "Content-Type": "application/json" }
            })()
    }

    async fetchAdsetsByAccounts(account, tabValue) {
        let urlsuffix = tabValue === 0 ? "StopLossByAccountGHOnly" : "StopLossByAccount";
        return genericFetcherFactory("/api/facebook-interface/Adsets/" + urlsuffix + "/", "Daily", "Failed toget Adsets", "POST",
            {
                method: "POST",
                body: JSON.stringify({ ...account, accountType: "Facebook" }),
                headers: { "Content-Type": "application/json" }
            })()
    }

    render() {
        return (
            <Container fluid className="p-0" id="StopLossTool">
                <Modal size="xl"
                    show={this.state.savingAdsets !== formStatus.IDLE}
                    centered
                >
                    <Modal.Header tag="h2" toggle={() => this.setState({ savingAdsets: formStatus.IDLE })}>
                        Update Adsets {this.state.savingAdsets}
                    </Modal.Header>
                    <Button color="secondary" size="small"
                        style={{ position: "absolute", top: "10px", right: "10px" }}
                        onClick={e => this.setState({ savingAdsets: formStatus.IDLE })}>
                        Close
                    </Button>
                    <Modal.Body>
                        {this.state.savingAdsets === formStatus.IN_PROGRESS &&
                            <Loader content={["Stopping adests", "Please come again"]} />
                        }
                        {this.state.savingAdsets === formStatus.CONFIRMATION_REQUIRED &&
                            <>
                                <ConfirmationTableView
                                    userActions={this.state.userChanges}
                                    adsets={this.state.adsets}
                                />
                                <Button onClick={() => this.saveAdsetActions(this.state.userChanges)} color="primary">Confirm Changes</Button>
                            </>
                        }
                    </Modal.Body>
                </Modal>


                <Card>
                    <Card.Header className="pb-1">
                        <Alert className="d-sm-none mb-2">
                            <div className="alert-icon">
                                <FontAwesomeIcon icon={faBell} fixedWidth />
                            </div>
                            <div className="alert-message">Please rotate your mobile phone for better view</div>
                        </Alert>
                        <Card.Title tag="h1" className="mb-0">Stop Loss Adset management</Card.Title>
                    </Card.Header>
                    <Card.Body className="pt-0">
                        <div className="pb-2">
                            {this.state.selectedAccount !== null && this.state.tabValue === 1 &&
                                <>
                                    <Badge bg="warning" className="me-2">BE Update: {this.state.lastUpdate}</Badge>
                                    <Badge bg="warning" className="me-2">FB Update: {this.state.lastUpdateFB}</Badge>
                                </>
                            }
                            {this.state.selectedAccount !== null && this.state.tabValue === 0 &&
                                <>
                                    <Badge bg="warning" className="me-2">Update: {this.state.lastUpdate}</Badge>
                                </>
                            }

                            {this.state.totals.spend > 0 &&
                                <Badge bg="success" className="me-2">Overall Spend: {currencyFormatter.format(Number(this.state.totals.spend))}</Badge>
                            }
                            {this.state.totals.conversions > 0 &&
                                <Badge bg="secondary" className="me-2">Total Conversions: {(Number(this.state.totals.conversions))}</Badge>
                            }
                            {this.state.totals.sales > 0 &&
                                <Badge bg="secondary" className="me-2">Total Sales: {(Number(this.state.totals.sales))}</Badge>
                            }
                            {this.state.totals.roi > 0 &&
                                <Badge bg="info" className="me-2">ROI: {percentFormatter.format(Number(this.state.totals.roi))}</Badge>
                            }
                            {this.state.totals.roiLastHour > 0 &&
                                <Badge bg="info" className="me-2">LAST Hour ROI: {percentFormatter.format(Number(this.state.totals.roiLastHour))}</Badge>
                            }
                            {this.state.totals.spendRemainingForToday > 0 &&
                                <Badge bg="info" className="me-2">Max. Spending remaining for today: {currencyFormatter.format(Number(this.state.totals.spendRemainingForToday))}</Badge>
                            }
                            {this.state.totals.totalTestSpentToday > 0 &&
                                <Badge bg="info" className="me-2">Test Spend: {currencyFormatter.format((Number(this.state.totals.totalTestSpentToday)))}</Badge>
                            }
                            {this.state.totals.testRoi > 0 &&
                                <Badge bg="info" className="me-2">Test ROAS: {percentFormatter.format(Number(this.state.totals.testRoi))}</Badge>
                            }

                        </div>
                        {!this.props.facebookUserData.isLoggedIn ?
                            <Button color="info"><FacebookContextComponent shouldRender={true} /></Button>
                            :
                            <>

                                {(this.state.initAdsets === null || this.state.selectedAccount !== null) &&
                                    <>
                                        <div className="mb-4" style={{ display: "flex", justifyContent: "space-between", zIndex: 1, position: 'relative' }}>
                                            <AccountDropdown
                                                widthClass={"col-12 col-sm-4"}
                                                updateStateCallback={(params) => { console.log(params) }}
                                                accountChanged={this.accountChanged}
                                                selectedAccount={this.state.selectedAccount}
                                                platform='FACEBOOK'
                                                itemActive={AccountIsActive.ACTIVE} />
                                        </div>
                                    </>
                                }

                                <Tabs value={this.state.tabValue} onChange={this.handleTabChange}>
                                    <Tab label="GH (New)" />
                                    <Tab label="Non GH (Old)" />
                                </Tabs>
                                <TabPanel value={this.state.tabValue} index={0}>
                                {this.state.adsets !== null &&
                                    <>
                                        <MediaQuery minWidth={900} >
                                            <AdsetDataView
                                                adsets={this.state.adsets}
                                                type="stoploss"
                                                submitFunction={this.handleAdsetActions}
                                                hiddenColumns={StoplossGHOnlyColumnsToHide}
                                                isMobile={false}
                                            />
                                        </MediaQuery>
                                        <MediaQuery maxWidth={899} >
                                            <AdsetDataView
                                                adsets={this.state.adsets}
                                                type="stoploss"
                                                submitFunction={this.handleAdsetActions}
                                                hideColumnsOnMobile={true}
                                                hiddenColumns={StoplossGHOnlyColumnsToHide}
                                                isMobile={true}
                                            />
                                        </MediaQuery>
                                    </>
                                }                                
                                </TabPanel>
                                <TabPanel value={this.state.tabValue} index={1}>
                                {this.state.adsets !== null &&
                                    <>
                                        <MediaQuery minWidth={900} >
                                            <AdsetDataView
                                                adsets={this.state.adsets}
                                                type="stoploss"
                                                submitFunction={this.handleAdsetActions}
                                            />
                                        </MediaQuery>
                                        <MediaQuery maxWidth={899} >
                                            <AdsetDataView
                                                adsets={this.state.adsets}
                                                type="stoploss"
                                                submitFunction={this.handleAdsetActions}
                                                hideColumnsOnMobile={true}
                                            />
                                        </MediaQuery>
                                    </>
                                }
                                </TabPanel>
                                {this.state.init === false &&
                                    <GenericErrorAlert errorMessage={this.state.errorMessage} />
                                }
                                {this.state.adsetDataInProcess &&
                                    <Loader content={["Adsets are coming", "Prepare to stop adsets"]} />
                                }

                            </>
                        }
                    </Card.Body>
                </Card>
            </Container >
        )
    }
}

function TabPanel(props) {
    const { children, value, index } = props;
  
    return (
      <Typography
        component="div"
        role="tabpanel"
        hidden={value !== index}
      >
        {value === index && <div>{children}</div>}
      </Typography>
    );
}

export default connect((store) => {
    return {
        facebookUserData: store.userContext.facebookUserData
    }
})(StoplossTool)
