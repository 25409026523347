import React from "react";
import { Container, Card, Form, Alert } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBell, faCheckCircle } from "@fortawesome/free-regular-svg-icons";
import Loader from '../../../components/Loader';
import { connect } from 'react-redux';
import { setDeploymentSharedComponent, setUserStatus, setCmsData } from "../../../redux/actions/cmsActions"
import { cmsUtils } from '../utils';
import { SharedComponentUtils } from '../SharedComponentUtils';
import { UserStatusUtils } from '../UserStatusUtils';
import { PushNotifications } from "../../../components/PushNotifications";

class TemplatesList extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            error: null,
            templatesToUpdate: null,
            pushStates: []
        };

        this.utils = new cmsUtils();
        this.SharedComponentUtils = new SharedComponentUtils();
        this.userStatusUtils = new UserStatusUtils();
        this.handleChange = this.handleChange.bind(this);
        this.checkVersionToUpload = this.checkVersionToUpload.bind(this);

        this.SCALI_SESSION_ID = this.props.cmsData.deploySelectedComponent;
    }

    componentDidMount() {
        if (this.props.cmsData.sharedComponentsFullData === null || !Object.keys(this.props.cmsData.sharedComponentsFullData).includes(this.props.cmsData.deploySelectedComponent)) {
            this.SharedComponentUtils.getSharedComponentFromGitToS3([this.props.cmsData.deploySelectedComponent], this.SCALI_SESSION_ID);
        } else {
            this.checkVersionToUpload();
        }

    }

    handleChange(event) {
        const { name, value } = event.target;
        this.setState({
            [name]: value
        });
    }

    checkVersionToUpload() {
        let deploySelectedComponent = this.props.cmsData.deploySelectedComponent;
        let latestCompVersion = this.props.cmsData.sharedComponentsFullData[deploySelectedComponent].version;
        let components = this.props.cmsData.fetchedSharedComponentsMapping;
        if (!Object.keys(components).includes(deploySelectedComponent)) {
            this.setState({ 'templatesToUpdate': false });
        } else {
            let versions = Object.keys(components[deploySelectedComponent]);
            versions = versions.map((version) => { return parseFloat(version) });
            versions = versions.filter(version => { return version !== parseFloat(latestCompVersion) });
            let templatesToUpdate = [];
            for (const version of Object.keys(components[deploySelectedComponent])) {
                if (version < latestCompVersion) {
                    templatesToUpdate.push(...components[deploySelectedComponent][version])
                }
            }
            this.setState({ 'templatesToUpdate': templatesToUpdate });
            this.props.setDeploymentSharedComponent({ ...this.props.deploymentSharedComponent, [this.props.cmsData.deploySelectedComponent]: templatesToUpdate })
        }
    }

    addVersion(template) {
        for (const version of Object.keys(this.props.cmsData.fetchedSharedComponentsMapping[this.props.cmsData.deploySelectedComponent])) {
            if (this.props.cmsData.fetchedSharedComponentsMapping[this.props.cmsData.deploySelectedComponent][version].includes(template)) {
                return version
            }
        }
    }

    render() {

        if (this.state.templatesToUpdate !== null && this.state.templatesToUpdate) {
            return (
                <Container fluid className="cms__inner">
                    <h1>Choose a Template to Deploy "{this.props.cmsData.deploySelectedComponent}" [{this.props.cmsData.sharedComponentsFullData[this.props.cmsData.deploySelectedComponent].version}] Component:</h1>
                    {this.state.templatesToUpdate.length > 0 &&

                        <Card>
                            <Card.Body>
                                <Form>
                                    {this.state.templatesToUpdate.map((data, value) => {
                                        return (
                                            <Form.Group key={value}>
                                                <Form.Check
                                                    type="radio"
                                                    value={data}
                                                    id={data}
                                                    key={data}
                                                    onChange={this.utils.handleChange}
                                                    checked={this.props.cmsData.template == data}
                                                    name='template'
                                                    label={data + " [" + this.addVersion(data) + "]"}
                                                />
                                            </Form.Group>
                                        )
                                    })}
                                </Form>
                            </Card.Body>
                        </Card>

                    }
                    {this.state.templatesToUpdate.length === 0 &&
                        <Alert variant='success'>
                            <h3 className="alert-message mb-0">
                                <span className="alert-icon"> <FontAwesomeIcon icon={faCheckCircle} /> </span>
                                All tempaltes are updated with the latest version of <b>{this.props.cmsData.deploySelectedComponent}:</b> {this.props.cmsData.sharedComponentsFullData[this.props.cmsData.deploySelectedComponent].version}</h3>
                        </Alert>
                    }
                </Container>
            );
        } else if (this.state.templatesToUpdate === false) {
            return (
                <Container fluid className="cms__inner">
                    <h1>Choose a Template to Deploy "{this.props.cmsData.deploySelectedComponent}" [{this.props.cmsData.sharedComponentsFullData[this.props.cmsData.deploySelectedComponent].version}] Component:</h1>
                    <Alert variant='danger'>
                        <h3 className="alert-message mb-0">
                            <span className="alert-icon"> <FontAwesomeIcon icon={faBell} /> </span>
                            <b>{this.props.cmsData.deploySelectedComponent}</b> doesnt exist in the <a href="https://dev-projects-data.s3.us-west-2.amazonaws.com/shared-components/shared-components.json" target="_blank">Shared Components Mapping data</a> </h3>
                    </Alert>
                </Container>
            )
        } else {
            return (
                <>
                    <Container fluid className="cms__inner">
                        <h1>Choose a Template to Deploy "{this.props.cmsData.deploySelectedComponent}"  Component:</h1>
                        <Loader content={["Fetching " + "Temlpates data", "Almost done..."]} width="full" />
                    </Container>
                    <PushNotifications
                        topic={"jenkins-" + this.SCALI_SESSION_ID}
                        messageCallBack={(msg) => {
                            this.setState(prevState => ({
                                pushStates: [...prevState.pushStates, msg.step]
                            }))
                            if (msg.step === "CopyComponentsToS3Done") {
                                this.SharedComponentUtils.getComponentDataFromS3([this.props.cmsData.deploySelectedComponent]).then(() => {
                                    this.checkVersionToUpload();
                                })
                            }
                        }}
                    ></PushNotifications>
                </>
            )
        }
    }
}

export default connect(
    (store) => {
        return {
            deploymentSharedComponent: store.cms.deploymentSharedComponent,
            cmsData: store.cms.cmsData,
            userStatus: store.cms.userStatus,
            user: store.userContext.user
        }
    },
    { setCmsData, setDeploymentSharedComponent, setUserStatus })(TemplatesList)