export function addHours(hours) {
    return new Date(Date.now() + 1000 * 60 * 60 * hours);
}

export function formatDateToYYYYMMDD(date, splitter = "-"){
    let y = date.getFullYear();
    let m = date.getMonth() + 1;
    let d = date.getDate();

    let yS = y.toString();
    let mS = m < 10 ? "0" + m.toString() : m.toString();
    let dS = d < 10 ? "0" + d.toString() : d.toString();

    return `${yS}${splitter}${mS}${splitter}${dS}`;
}