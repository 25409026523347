import { GridActionsCellItem, GridColDef } from '@mui/x-data-grid-premium';
import React from "react";
import { Form, OverlayTrigger, Tooltip } from "react-bootstrap";
import 'react-bootstrap-table2-filter/dist/react-bootstrap-table2-filter.min.css';
import * as Icon from "react-feather";

export const currencyFormatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
});

export const percentFormatter = new Intl.NumberFormat('en-US', {
    style: 'percent',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
});

export default class UnderDeliveryAdsetViewHelper {
    constructor() {

    }

    adsetIdcheckboxFormatter(row, { toggleChecked }) {
        return (<Form.Check
            type="checkbox"
            name="isClicked" id="isClicked"
            value={row.isClicked}
            onChange={event => toggleChecked(event.target.checked, row)}
            checked={row.isClicked}
        />)
    }

    checkboxFormatter(row) {
        return (<Form.Check
            type="switch"
            name="isActive" id="isActive"
            value={row.isActive}
            checked={row.isActive}
            readOnly
        />)
    }

    setGridColsFull(revertAdsetChanges, toggleIsClickedRow, adsetNameWidth) {
        const columns = [
            {
                field: "adsetId", headerName: "Adset", sortable: false, editable: false,
                headerClassName: 'adsetIdCol shortView',
                cellClassName: 'adsetIdCol shortView',
                headerAlign: 'center',
                align: 'center',
                width: 55,
                renderCell: (params) => this.adsetIdcheckboxFormatter(params.row, { toggleChecked: toggleIsClickedRow })
            },
            {
                field: "isActive", headerName: "Active", sortable: false, editable: false,
                headerClassName: 'isActiveCol shortView',
                cellClassName: 'isActiveCol shortView',
                headerAlign: 'center',
                align: 'center',
                width: 55,
                renderCell: (params) => this.checkboxFormatter(params.row)
            },
            {
                field: "adsetName", headerName: "Name",
                headerClassName: 'adsetNameCol shortView',
                cellClassName: 'adsetNameCol shortView',
                width: adsetNameWidth,
                resizable: true,
                renderCell: (params) => (
                    <OverlayTrigger
                        placement="top"
                        overlay={
                            <Tooltip>
                                {params.value}
                            </Tooltip>
                        }
                    >
                        <div>{params.value}</div>
                    </OverlayTrigger>
                ),
            },
            {
                field: "startTDate",
                headerName: "Start Date",
                sortable: true,
                headerClassName: 'sfDataHeader',
                cellClassName: 'sfDataCell shortView',
                type: 'dateColumn',
                headerAlign: 'left',
                align: 'left',
                width: 110
            },
            {
                field: "Spend Today",
                headerName: "Spend Today",
                sortable: true,
                headerClassName: 'sfDataHeader',
                cellClassName: 'sfDataCell shortView',
                type: 'number',
                headerAlign: 'center',
                align: 'center',
                valueFormatter: (value) => { return (value !== undefined ? currencyFormatter.format(Number(value)) : "N/A") }

            },
            {
                field: "bidAmount", headerName: "Bid", editable: false,
                headerClassName: 'bidCol shortView',
                cellClassName: 'bidCol shortView',
                type: 'number',
                headerAlign: 'center',
                align: 'center',
                width: 70,
                valueFormatter: (value) => { return (value !== undefined ? currencyFormatter.format(Number(value)) : "N/A") }
            },

            {
                field: "budget",
                headerName: "Budget",                
                headerClassName: 'budgetCol shortView',
                cellClassName: 'budgetCol shortView',
                type: 'number',
                headerAlign: 'center',
                align: 'center',
                resizable: false,
                valueFormatter: (value) => { return (value !== undefined ? currencyFormatter.format(Number(value)) : "N/A") }
            },

            {
                field: "budgetRemaing", headerName: "Budget Remaining", sort: false,
                editable: false,
                headerClassName: 'sfDataHeader shortView',
                cellClassName: 'sfDataCell shortView',
                type: 'number',
                headerAlign: 'center',
                align: 'center',
                valueFormatter: (value) => { return ((value !== undefined || value !== null) ? currencyFormatter.format(Number(value)) : "N/A") }

            },
            {
                field: "dailyBudget", headerName: "Daily Budget", editable: false,
                headerClassName: 'dailyBudgetCol shortView',
                cellClassName: 'dailyBudgetCol shortView',
                type: 'number',
                headerAlign: 'center',
                align: 'center',
                valueFormatter: (value) => { return (value !== undefined ? currencyFormatter.format(Number(value)) : "N/A") }
            },

            {
                field: "lifetimeBudget", headerName: "Lifetime Budget", editable: false,
                headerClassName: 'lifetimeBudgetCol shortView',
                cellClassName: 'lifetimeBudgetCol shortView',
                type: 'number',
                headerAlign: 'center',
                align: 'center',
                valueFormatter: (value) => { return (value !== undefined ? currencyFormatter.format(Number(value)) : "N/A") }
            },

            {
                field: "ROI - Today",
                headerName: "ROAS", sort: false,
                editable: false,
                headerClassName: 'sfDataHeader fullView',
                cellClassName: 'sfDataCell fullView',
                type: 'number',
                headerAlign: 'center',
                align: 'center',
                valueFormatter: (value) => { return ((value !== null && value !== undefined) ? percentFormatter.format(Number(value)) : "N/A") }
            },
            {
                field: "ROI -1 hour",
                headerName: "ROAS (-1h)", sort: false,
                editable: false,
                headerClassName: 'sfDataHeader fullView',
                cellClassName: 'sfDataCell fullView',
                type: 'number',
                headerAlign: 'center',
                align: 'center',
                valueFormatter: (value) => { return ((value !== null && value !== undefined) ? percentFormatter.format(Number(value)) : "N/A") }
            },
            {
                field: "Spend -1 hour",
                headerName: "spend (-1h) BE Sales",
                sort: false,
                editable: false,
                headerClassName: 'sfDataHeader fullView',
                cellClassName: 'sfDataCell fullView',
                type: 'number',
                headerAlign: 'center',
                align: 'center',
                valueFormatter: (value) => { return (value !== undefined ? Number(value) : "N/A") }
            },
            {
                field: "Revenue BE Today",
                headerName: "Revenue BE Today",
                sort: false,
                editable: false,
                headerClassName: 'sfDataHeader shortView',
                cellClassName: 'sfDataCell shortView',
                type: 'number',
                headerAlign: 'center',
                align: 'center',
                valueFormatter: (value) => { return (value !== undefined ? currencyFormatter.format(Number(value)) : "N/A") }

            },
            {
                field: "Spend -1 Day now",
                headerName: "Spend -1 Day now",
                sort: false,
                editable: false,
                headerClassName: 'sfDataHeader',
                cellClassName: 'sfDataCell fullView',
                type: 'number',
                headerAlign: 'center',
                align: 'center',
                valueFormatter: (value) => { return (value !== undefined ? currencyFormatter.format(Number(value)) : "N/A") }

            },
            {
                field: "ROI -1 Day",
                headerName: "ROAS -1 Day",
                sort: false,
                editable: false,
                headerClassName: 'sfDataHeader fullView',
                cellClassName: 'sfDataCell fullView',
                type: 'number',
                headerAlign: 'center',
                align: 'center',
                valueFormatter: (value) => { return ((value !== null && value !== undefined) ? percentFormatter.format(Number(value)) : "N/A") }
            },
            {
                field: "Spend -7 Days now",
                headerName: "Spend -7 Day",
                sort: false,
                editable: false,
                headerClassName: 'sfDataHeader fullView',
                cellClassName: 'sfDataCell fullView',
                type: 'number',
                headerAlign: 'center',
                align: 'center',
                valueFormatter: (value) => { return (value !== undefined ? Number(value) : "N/A") }
            },
            {
                field: "ROI -7 Days",
                headerName: "ROAS -7 Day",
                sort: false,
                editable: false,
                headerClassName: 'sfDataHeader fullView',
                cellClassName: 'sfDataCell fullView',
                type: 'number',
                headerAlign: 'center',
                align: 'center',
                valueFormatter: (value) => { return ((value !== null && value !== undefined) ? percentFormatter.format(Number(value)) : "N/A") }

            },
            {
                field: "revert",
                type: "actions",
                resizable: false,
                headerClassName: 'revertButtonCol shortView',
                cellClassName: 'revertButtonCol shortView',
                width: 35,
                getActions: (params) =>
                    [<GridActionsCellItem
                        icon={<Icon.RotateCcw />}
                        onClick={() => revertAdsetChanges(params.row)}
                        title="Revert"
                    />],
            }
        ]
        return columns;
    }

}