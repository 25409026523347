import React from "react";
import { Table } from "reactstrap";

import { sortableContainer, sortableElement, sortableHandle } from 'react-sortable-hoc';

import * as Icon from "react-feather";

const SortableBody = sortableContainer(({ children }) => { return <tbody>{children}</tbody>; });
const DragHandle = sortableHandle(() => <span style={{ cursor: "row-resize" }}><Icon.Move className="feather align-middle" /></span>)
const SortableRow = sortableElement(({ children }) => { return <tr><td><DragHandle /></td>{children}</tr> });

export default class DraggableTable extends React.PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            sortedTable: [...this.props.data]
        }

        this.tableRef = React.createRef();

        this.onSortEnd = this.onSortEnd.bind(this);
    }

    renderRowColumns(rowData, rowIndex) {
        let columns = [];

        this.props.columns.forEach((column) => {
            let columnValue;
            if (column.isIndex) {
                columnValue = <td>{rowIndex + 1}</td>;
            } else {
                let rawColumnValue;
                if (column.dataField) {
                    rawColumnValue = rowData[column.dataField];
                } else {
                    rawColumnValue = "";
                }

                if (column.formatter instanceof Function) {
                    columnValue = <td>{column.formatter(rawColumnValue, rowData, rowIndex)}</td>;
                } else {
                    columnValue = <td>{rawColumnValue}</td>;
                }
            }
            columns.push(columnValue);
        });

        return columns;
    }

    onSortEnd({ oldIndex, newIndex }) {
        let a = [...this.props.data];

        let [member] = a.splice(oldIndex, 1);
        a.splice(newIndex, 0, member);

        if (this.props.onChange) {
            this.props.onChange(a);
        }
    }

    render() {
        return (
            <Table innerRef={this.tableRef}>
                <thead>
                    <tr>
                        <th style={{ width: "20px" }}></th>
                        {this.props.columns.map(columns => { return <th>{columns.text}</th> })}
                    </tr>
                </thead>
                <SortableBody onSortEnd={this.onSortEnd} useDragHandle lockAxis="y" helperContainer={() => { return this.tableRef.current.children[1] }}>
                    {this.props.data.map((row, index) => { return (<SortableRow key={`dataRow-${index}`} index={index}>{this.renderRowColumns(row, index)}</SortableRow>); })}
                </SortableBody>
            </Table >
        )
    }
}