import React from "react";
import { Modal, Container, Card } from "react-bootstrap";
import BootstrapTable from "react-bootstrap-table-next";
import * as Icon from "react-feather";

import { toastr } from "react-redux-toastr";

import Loader from '../../../components/Loader'
import ContactEditForm, { FormModes } from './ContactEditForm';
import { handleErrorResponse } from "../../../utils/ajaxErrorHandler";
import GenericErrorAlert from "../../../components/GenericErrorAlert";
import { genericFetcherFactory } from "../../../utils/requestUtils";

export default class Contacts extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = { contacts: [], initSuccess: null };
        this.updateContact = this.updateContact.bind(this);
    }

    updateContact(jsonData, contactId) {
        this.setState({ ...this.state, formSaving: true })

        genericFetcherFactory("/api/crm/Contacts/" + contactId, "CONTACTS", "Failed to update Contacts", "PATCH",
            {
                method: "PATCH",
                body: JSON.stringify(jsonData),
                headers: { 'Content-Type': 'application/json' }
            })().then((res) => {
                if (res.success) {
                    let updatedContact = res.data;
                    let newContacts = [...this.state.contacts];
                    let contactIndex = newContacts.findIndex(item => (item.contactId === contactId));
                    newContacts.splice(contactIndex, 1, updatedContact);

                    this.setState({ ...this.state, contacts: newContacts, formSaving: false });
                    toastr.success("Update Success", updatedContact.contactName + " was updated successfully.")

                } else {
                    this.setState({ ...this.state, formSaving: false });
                    handleErrorResponse(res, "Update Failed");
                }
            }).catch(error => {
                console.log(error);
            });

    }

    fetchContacts() {

        genericFetcherFactory("/api/crm/Contacts", "CONTACTS", "Failed to fetch from Contacts API Service")().then(res => {
            if (res.success == true) {
                let result = res.data;
                this.setState({ ...this.state, contacts: result, initSuccess: true });
            } else {
                this.setState({ ...this.state, initSuccess: false })
                handleErrorResponse(res, "Fetch Contacts Failed");
            }
        })

    }

    componentDidMount() {
        this.fetchContacts();
    }

    // Boostrap table configuration 
    // Expand row configuration for editing rows in the Contacts table
    expandRow = {
        renderer: row => {
            console.log(row);
            return (
                <div className="ml-5 mr-5 mt-3 mb-3" key={row.contactId}>
                    <ContactEditForm key={"form"+row.contactId} mode={FormModes.EDIT} contactData={row} submitCallback={this.updateContact} />
                </div>
            )
        },
        expandColumnRenderer: ({ expanded }) => {
            if (expanded) {
                return (
                    <Icon.Edit className="feather align-middle" />
                );
            }
            return (
                <Icon.Edit2 className="feather align-middle" />
            );
        },
        expandHeaderColumnRenderer: ({ isAnyExpands }) => {
            return "";
        },
        showExpandColumn: true,
        expandByColumnOnly: true,
        expandColumnPosition: 'right'
    };

    // Column configuration for the contacts table
    contactsColumns = [
        {
            dataField: "contactId",
            text: "ID",
            headerStyle: { width: "30px" },
        },
        {
            dataField: "contactName",
            text: "Contact Name",
            sort: true
        },
        {
            dataField: "phoneNumber",
            text: "phoneNumber",
        },
        {
            dataField: "email",
            text: "Email",
            sort: true
        },
        {
            dataField: "position",
            text: "Position",
        }

    ]

    render() {
        if (this.state.initSuccess === true) {
            return (
                <Container fluid className="p-0">
                    <Modal show={this.state.formSaving === true} centered size="lg">
                        <Modal.Header>
                            Saving Contact...
                        </Modal.Header>
                        <Modal.Body className="text-center m-3">
                            <Loader width="wide" />
                        </Modal.Body>
                    </Modal>
                    <Card>
                        <Card.Header>
                            <Card.Title tag="h5" className="mb-0">
                                Contacts
                            </Card.Title>
                        </Card.Header>
                        <Card.Body>
                            <BootstrapTable
                                bootstrap4
                                keyField="contactId"
                                bordered={false}
                                striped
                                hover
                                data={this.state.contacts ? this.state.contacts : []}
                                columns={this.contactsColumns}
                                expandRow={this.expandRow}
                            />
                        </Card.Body>
                    </Card>
                </Container>
            )
        } else if (this.state.initSuccess === false) {
            return (
                <GenericErrorAlert />
            );
        } else {
            return (
                <Card>
                    <Card.Header>
                        <Card.Title tag="h5" className="mb-0">
                            Contacts
                        </Card.Title>
                    </Card.Header>
                    <Loader width="wide" />
                </Card>
            );
        }
    }
}