import React from "react";
import { debugProps } from "./debug";

import { Label, Input, Button } from 'reactstrap';

const title = "Switch the original Post with a new Post in all available Adsets";
export default class Step3SwitchPosts extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            postId: this.props.stepData.newPostId ? this.props.stepData.newPostId : ""
        }
    }

    render() {
        return (
            <div className={"wizard-step-container"}>
                <div className={"step-title"}>
                    {title}
                </div>
                <div className={"step-body"}>
                    {this.props.errorDisplay != null &&
                        <div className={"wizard-error-display"}>{this.props.errorDisplay}</div>
                    }
                    <div className={"wizard-step-info"}>
                        <div>Original Post ID: {this.props.stepData.originalPostId}</div>
                    </div>
                    <div className={"wizard-input-and-button"}>
                        <Label for="newPost">New Post ID:</Label>
                        <Input readOnly={this.props.readOnly} type="textbox" name="newPost" id="newPost" value={this.state.postId} placeholder="New Post ID" onChange={(event) => this.setState({ postId: event.target.value })}></Input>
                        <Button disabled={this.props.readOnly || this.state.postId.length < 1} onClick={() => {
                            this.setState({ showSpinner: true });
                            this.props.switchPosts(this.state.postId);
                        }}>Switch With New Post</Button>
                    </div>
                </div>

                {/* {debugProps(this.props)} mb-2 */}
            </div>
        );
    }
}