import React from 'react';
import { toastr } from "react-redux-toastr";
import AccountDropdown from "../../components/AccountDropdown";
import { AccountIsActive } from "../prods-and-accounts/account/accountUtils";
import EntitySlackChannelsView from "../slack/EntitySlackChannelsView";
import SlackAlertChannelEditForm from '../slack/SlackAlertChannelEditForm';
import slackChannelService, { slackModalStyles } from "../slack/slackService";
import * as Yup from "yup";
import { Formik } from "formik";
import { Button, Form, Row, Container, Modal } from "react-bootstrap";

const FormModes = {
    NEW: "new",
    EDIT: "edit"
}
const EntityType = "account";

export { FormModes };

export default class DailyCapEditForm extends React.PureComponent {

    constructor(props) {
        super(props);
        this.sumbitForm = this.sumbitForm.bind(this);
        this.service = slackChannelService;
        this.state = {
            account: [],
            modal: false,
            selectedAccount: null,
        }
        this.accountChanged = this.accountChanged.bind(this);
        this.updateStateCallback = this.updateStateCallback.bind(this);
    }

    componentDidMount() {
        if (this.props.dailyCapData.entityId !== null) {
            this.setState({ selectedAccount: { entityId: parseInt(this.props.dailyCapData.entityId) } });
        }
    }

    sumbitForm(values) {
        // Validate everything else

        // Logic for when adding a new Daily Cap
        if (this.props.mode == FormModes.NEW) {
            // All data will exist
            this.props.submitCallback(
                {
                    entityId: this.state.selectedAccount.accountId.toString(),
                    entityType: values.entityType,
                    cap: parseInt(values.cap),
                    weekendCap: parseInt(values.weekendCap)
                }
            );
        } else if (this.props.mode == FormModes.EDIT) {
            let changeMade = false;
            let dailyCapData = {};

            // Iterate through the form data
            for (let prop in values) {
                // TODO: Change this check to use an object defition rather than relying on the components properties
                if (this.props.dailyCapData[prop] !== undefined) {
                    // Check if the field was edited
                    if (values[prop] != this.props.dailyCapData[prop]) {
                        changeMade = true;
                        dailyCapData[prop] = values[prop];
                    }
                } else {
                    console.warn("field " + prop + " doesn't exist");
                }
            }

            if (dailyCapData.cap) {
                dailyCapData.cap = parseInt(dailyCapData.cap);
                changeMade = true;
            }

            if (dailyCapData.weekendCap) {
                dailyCapData.weekendCap = parseInt(dailyCapData.weekendCap);
                changeMade = true;
            }

            if (dailyCapData.entityId !== undefined && dailyCapData.entityId !== this.state.selectedAccount.accountId.toString()) {
                dailyCapData.entityId = this.state.selectedAccount.accountId.toString();
                changeMade = true;
            }

            if (changeMade === true) {
                // Append the cap Id for completion
                dailyCapData["id"] = this.props.dailyCapData.id;
            }

            // Finally check if there was actually a change made before proceeding to the callback
            if (changeMade === false) {
                toastr.warning("Cannot save daily cap", "No changes were made");
                return;
            } else {
                this.props.submitCallback(dailyCapData, this.props.dailyCapData.id);
            }
        }
    }

    updateStateCallback(key, value) {
        this.setState({ [key]: value });
    }


    accountChanged(account) {
        if (account !== undefined) {
            this.setState({ selectedAccount: account });
        }
    }

    render() {

        return (
            <Formik
                initialValues={this.props.dailyCapData}

                validationSchema={Yup.object().shape({
                    cap: Yup.number().moreThan(0, 'Cap is required').required('Cap is required'),
                    weekendCap: Yup.number().positive( 'weekendCap is a positive number').min(0)
                })}
                onSubmit={(values) => this.sumbitForm(values)}
            >
                {({ handleSubmit, handleChange, handleBlur, errors, touched, values }) => (
                    <Container fluid className="p-0 row">
                        <div className="col-xl-8">
                            <Form onSubmit={handleSubmit}>
                                <Row className="gx-1 mt-3 form__dailycap" >
                                    <Form.Group className="col gx-1">
                                        <Form.Label htmlFor="entityType">Entity Type</Form.Label>
                                        <Form.Control
                                            type="text"
                                            name="entityType"
                                            id="entityType"
                                            value={EntityType}
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            disabled={true}
                                        />
                                    </Form.Group>
                                    <div class="form-group" style={{ width: "300px" }}>
                                        <Form.Group className="col gx-1">
                                            <Form.Label htmlFor="entityId">Entity Id</Form.Label>
                                            <AccountDropdown
                                                updateStateCallback={this.updateStateCallback}
                                                accountChanged={this.accountChanged}
                                                selectedAccount={this.state.selectedAccount}
                                                platform='FACEBOOK'
                                                itemActive={AccountIsActive.ALL}
                                            />
                                        </Form.Group>
                                    </div>
                                    <Form.Group className="col gx-1">
                                        <Form.Label htmlFor="cap">Cap</Form.Label>
                                        <Form.Control
                                            type="number"
                                            name="cap"
                                            id="cap"
                                            value={values.cap}
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            isInvalid={!!errors.cap && !!touched.cap}
                                        />
                                        {!!touched.cap &&
                                            <Form.Control.Feedback type="invalid">cap is required</Form.Control.Feedback>
                                        }
                                    </Form.Group>
                                    <Form.Group className="col gx-1">
                                        <Form.Label htmlFor="weekendCap">Weekend Cap</Form.Label>
                                        <Form.Control
                                            type="number"
                                            name="weekendCap"
                                            id="weekendCap"
                                            value={values.weekendCap}
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            isInvalid={!!errors.weekendCap && !!touched.weekendCap}
                                        />                                  
                                    </Form.Group>
                                    <Button type="submit" className="submit-btn col-12 col-sm-auto align-self-start" variant="primary">Submit</Button>
                                </Row>
                            </Form>
                        </div>

                        {(this.state.selectedAccount !== null && this.state.selectedAccount.accountId > 0) &&
                            <>
                                <div className="col-xl-3">
                                    <div class="mb-2"><b>Accounts'Slack Channel:</b></div>
                                    <EntitySlackChannelsView entityId={this.state.selectedAccount.accountId} entityType="account" class="mb-4" />
                                    <Button id="slackAlertChannels" variant="secondary" onClick={e => this.setState({ modal: true })}>
                                        Update Slack Channel
                                    </Button>
                                </div>
                                <Modal show={this.state.modal === true} size="lg" style={slackModalStyles} centered>
                                    <Button variant="secondary" style={{ position: "absolute", top: "10px", right: "10px" }} onClick={e => this.setState({ modal: false })}>
                                        Close
                                    </Button>
                                    <Modal.Header>Slack Alert Channels</Modal.Header>
                                    <Modal.Body className="text-center m-2">
                                        <Container fluid className="mb-0">
                                            <SlackAlertChannelEditForm
                                                entityId={this.state.selectedAccount.accountId}
                                                entityType={"account"}
                                                specificPlatform={this.state.selectedAccount.platform.platformName}
                                                updateSuccessCallback={() => {
                                                    toastr.success("Slack Channel Add to account")
                                                }}
                                                failedCallback={(err) => { toastr.error(err) }}
                                            />
                                        </Container>
                                    </Modal.Body>
                                </Modal>
                            </>
                        }
                    </Container>
                )}
            </Formik >
        )
    }
}

DailyCapEditForm.defaultProps = {
    dailyCapData: {
        id: null,
        entityId: 0,
        entityType: "",
        cap: 0,
        weekendCap: 0
    },
    mode: FormModes.NEW
}