import React from "react";
import Select from "react-select";
import {
    Label, Spinner
} from "reactstrap";
import { CACHE_EXPIRATION_DEFAULTS } from "../../../../services/qsCache";
import { addHours } from "../../../../utils/dateUtils";
import { genericCachedFetcherFactory } from "../../../../utils/requestUtils";




export default class AccountPixels extends React.PureComponent {
    constructor(props) {
        super(props)
        this.state = {
            accountPixels: null,
            initSuccess: null
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.account.accountPlatformId != this.props.account.accountPlatformId) {
            genericCachedFetcherFactory(`/api/facebook-interface/WebsiteAudiences/GetAccountPixels/${this.props.account.accountPlatformId}`, `GET_ACCOUNT_PIXELS_${this.props.account.accountPlatformId}`, "Failed to fetch pixels",
                addHours(CACHE_EXPIRATION_DEFAULTS.Accounts))().then((result) => {
                    if (result.success === true) {
                        let accountPixels = result.data.map(pixel => {
                            return { label: pixel.name, value: pixel.adPixelId }
                        }
                        );
                        this.setState({ initSuccess: true, accountPixels: accountPixels })
                    } else {
                        this.setState({ initSuccess: false });
                    }
                });
        }

    }

    componentDidMount() {
        genericCachedFetcherFactory(`/api/facebook-interface/WebsiteAudiences/GetAccountPixels/${this.props.account.accountPlatformId}`, `GET_ACCOUNT_PIXELS_${this.props.account.accountPlatformId}`, "Failed to fetch pixels",
            addHours(CACHE_EXPIRATION_DEFAULTS.Accounts))().then((result) => {
                if (result.success === true) {
                    let accountPixels = result.data.map(pixel => {
                        return { label: pixel.name, value: pixel.adPixelId }
                    }
                    );
                    this.setState({ initSuccess: true, accountPixels: accountPixels })
                } else {
                    this.setState({ initSuccess: false });
                }
            });
    }

    render() {
        return (

            <div>
                { this.state.initSuccess === null &&
                    <Spinner color="primary" className="mr-2" />
                }
                { this.state.initSuccess === true &&
                    <>
                        <Label>Pixels:</Label>
                        <Select
                            name="eventName"
                            id="eventName"
                            placeholder="Select pixel"
                            value={this.props.selectedPixel}
                            onChange={this.props.pixelSelecedCallBack}
                            options={this.state.accountPixels}
                        />
                    </>
                }
            </div>

        )
    }
}