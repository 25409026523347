import React from 'react';
import { connect } from 'react-redux';
import { toastr } from "react-redux-toastr";
import { withRouter } from "react-router-dom";
import { Container } from "reactstrap";
import TableauReport from 'tableau-react';
import GenericErrorAlert from '../../../components/GenericErrorAlert';
import Loader from '../../../components/Loader';
import { genericFetcherFactory } from "../../../utils/requestUtils";
import { IframeSettings } from "../constants";
import ReportTabs from '../ReportTabs';

class InternalReports extends React.PureComponent {

    constructor(props) {
        super();
        this.state = {
            currentItem: null,
            url: props.url,
            initComplete: null,
            trustedToken: null,
            tabs: []
        }
    }

    init() {

        genericFetcherFactory("/api/tableaus/TrustedToken/", "TRUSTED_TOKEN", "Failed to get trusted Token")().then((result) => {
            if (result.success == true) {
                let newCurrentItem = this.props.tableauMenus.find(item => item.permalink === this.props.match.params.permalink);
                if (!newCurrentItem) {
                    newCurrentItem = this.props.tableauMenus.find(item => item.tabId === this.props.match.params.permalink);
                }
                this.setState({ currentItem: newCurrentItem, url: newCurrentItem.url, initComplete: true, trustedToken: result.data.token });
            } else {
                this.setState({ initComplete: false });
            }
        });
    }

    componentDidUpdate(prevProps) {
        if (prevProps.match.params.permalink != this.props.match.params.permalink) {
            this.setState({ initComplete: null });
            this.init();
        }
    }

    componentDidMount() {
        this.init();
    }

    setInternalIframeSettings(settings) {
        let internalSettings = { ...settings };
        internalSettings.options.height = "800px";
        return internalSettings;
    }

    additionalQuaryParams() {
        const urlParam = this.props.location.search;
        let param;
        if (urlParam.includes("Account")) {
            param = "&Account=" + urlParam.split("Account=")[1];
            return param;
        } else {
            return false
        }
    }


    render() {
        let settings = this.setInternalIframeSettings(IframeSettings);
        let addedQuary = this.additionalQuaryParams();

        if (this.state.initComplete === true) {
            if (this.state.currentItem) {
                let jsx;
                if (this.state.currentItem.children && this.state.currentItem.children.length > 0) {
                    jsx = (
                        <ReportTabs
                            tabs={this.state.currentItem.children}
                            trustedToken={this.state.trustedToken}
                            iframeSettings={settings}
                        />
                    );
                } else {
                    if (this.state.currentItem.showToolbar === true) {
                        settings.options.hideToolbar = false;
                    }
                    jsx = (
                        <TableauReport
                            url={this.state.url}
                            token={this.state.trustedToken}
                            filters={settings.filters}
                            parameters={settings.parameters}
                            options={settings.options}
                            query={"?:jsdebug=n&:embed=yes&:refresh=yes" + addedQuary}
                        />
                    );
                }

                return (
                    <Container fluid className="p-0">
                        {jsx}
                    </Container>
                )
            } else {
                toastr.error(`Permalink ${this.props.match.params.permalink} not found.`)
                return (<GenericErrorAlert />)
            }
        } else if (this.state.initComplete === false) {
            return (<GenericErrorAlert />)
        } else {
            return (<Loader />)
        }
    }

}
export default withRouter(connect(
    (store) => {
        return {
            tableauMenus: store.tableau.tableauMenus,
            user: store.userContext.user
        }
    }
)(InternalReports));
