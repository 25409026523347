import React from "react";
import {
    Button,
    Card, Container,
    Modal
} from "react-bootstrap";
import { withRouter } from "react-router";
import CacheController from "../../../services/cache/CacheController";
import { handleErrorResponse } from "../../../utils/ajaxErrorHandler";
import { genericFetcherFactory, getCacheKeyForUrl } from "../../../utils/requestUtils";
import AccountEditForm, { FormModes } from "./AccountEditForm";



const linkToFunnels = "/dashboard/funnels/account-funnels";

class NewAccount extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            formSaving: false,
            accountCreated: false
        };

        this.save = this.save.bind(this);
    }

    save(accountObj) {
        this.setState({ ...this.state, formSaving: true, accountCreated: false });
        genericFetcherFactory("/api/accounts/Accounts", "SAVE_ACCOUNT", "Failed to save account", "POST", {
            method: "POST",
            body: JSON.stringify(accountObj),
            headers: { "Content-Type": "application/json" }
        })().then(res => {
            if (res.success) {
                CacheController.deleteObject(getCacheKeyForUrl("/api/accounts/Accounts"));
                this.setState({ ...this.state, formSaving: true, accountCreated: true });
            } else {
                this.setState({ ...this.state, formSaving: false, accountCreated: false });
                handleErrorResponse(res, "Save Failed");
            }
        });
    }

    render() {
        return (
            <>
                <Modal show={this.state.accountCreated === true} centered>
                    <Button className='col-12' variant="secondary" onClick={e => this.setState({ accountCreated: false })}>
                        Close
                    </Button>
                    <Modal.Header>Account Created!</Modal.Header>
                    <Modal.Body className="text-center m-3">
                        <p>You are awesome! You just created an account!
                            <br></br>NEXT STEP: <a href={linkToFunnels} target="_self">Funnels Creation</a>
                            <br></br> it’s in the oven- will be ready in about 10 minutes!</p>
                    </Modal.Body>
                </Modal>

                <Container fluid className="p-0">
                    <h1 className="h3 mb-3">Create new account</h1>
                    <Card>
                        <Card.Body>
                            <AccountEditForm mode={FormModes.NEW} submitCallback={this.save} accountData={{
                                accountId: "",
                                accountName: "",
                                gmtId: "",
                                isActive: false,
                                platformId: 0,
                                accountPlatformId: "",
                                platformName: "",
                                brandId: 0,
                                cmUserId: 0,
                                currencyId: 0,
                                accountProducts: [],
                                selectedChannels: [],
                                deactivateReason: ""
                            }} />
                        </Card.Body>
                    </Card>
                </Container>
            </>
        );
    }
}

export default withRouter(NewAccount);
