import { toastr } from "react-redux-toastr";
import { SetSlackAlertsChannelsEntities } from "../../redux/actions/slackChannelsActions";
import store from "../../redux/store";
import { CACHE_EXPIRATION_DEFAULTS } from "../../services/qsCache";
import { addHours } from "../../utils/dateUtils";
import { getPlatformIcon } from "../../utils/PlatformUtils";
import { genericCachedFetcherFactory, genericFetcherFactory } from "../../utils/requestUtils";

export const slackModalStyles = {
    content: {
        top: '80%',
        left: '80%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-80%',
        transform: 'translate(-80%, -80%)'
    }
}
class SlackChannelService {
    slackChannelsDetailsList = [];
    slackAlertsChannelsEntities = {
        account: [],
        product: []
    };

    constructor() {
        if (!SlackChannelService._instance) {
            SlackChannelService._instance = this;
        }
        return SlackChannelService._instance;
    }

    async build(entityType) {
        let slackChannelsDetailsList = [];
        let slackAlertsChannelsEntities = {
            account: [],
            product: []
        };
        [slackChannelsDetailsList, slackAlertsChannelsEntities] = await Promise.all([this.fetchAllSlackCahnnels(), this.fetchSlackCahnnelsByEntityType(entityType)]);
    }

    async fetchAllSlackCahnnels() {
        return genericFetcherFactory("/api/alerts/GetListOfAllSlackChannels", "SLACK", "Failed to fetch from slack's API Service")().then(res => {
            if (res.success == true) {
                this.slackChannelsDetailsList = res.data.channels;
                return res.data.channels;
            } else {
                return [];
            }
        })
    }

    async fetchSlackCahnnelsByEntityType(entityType) {
        return genericFetcherFactory("/api/alerts/Slacks/ByEntityType/" + entityType, "SLACK", "Failed to fetch from slack's API Service")().then(res => {
            if (res.success == true) {
                this.slackAlertsChannelsEntities[entityType] = res.data
                let unsubscribe = store.subscribe(() => { });
                store.dispatch(SetSlackAlertsChannelsEntities(this.slackAlertsChannelsEntities));
                unsubscribe();
                return res.data;
            } else {
                return [];
            }
        })
    }

    getSelectedDetailedChannelsByEntityIdAndType(entityId, entityType, slackAlertsChannelsEntities) {

        if (slackAlertsChannelsEntities === undefined || slackAlertsChannelsEntities[entityType].length == 0) {
            this.fetchSlackCahnnelsByEntityType(entityType)
            slackAlertsChannelsEntities = this.slackAlertsChannelsEntities;
        }

        let entity = slackAlertsChannelsEntities[entityType].find(item => item.entityId === entityId);
        
        if (entity !== undefined && entity.slackAlertChannelList.length > 0) {
            return entity.slackAlertChannelList.map(slackAlert => {
                let foundSlack = this.slackChannelsDetailsList.find(channelDetails => channelDetails.id === slackAlert.channel);
                if (foundSlack) {
                    return { channel: foundSlack.id, channelName: foundSlack.name, platform: slackAlert.platform, icon: getPlatformIcon(slackAlert.platform) }
                } else {
                    return { channel: null, channelName: null, platform: null, icon: null }
                }
            });
        } else {
            return [];
        }
    }

    getSelectedSlackChannelIdAndType(entityId, entityType,slackAlertsChannelsEntities) {
        let entity = slackAlertsChannelsEntities[entityType].find(item => item.entityId === entityId);
        if (entity !== undefined) {
            return entity.id;
        } else {
            return undefined;
        }
    }

    newSlackChannel(slackData, successCallback, failedCallback) {
        genericFetcherFactory("/api/alerts/SlackAlertChannels", "SLACK", "Failed to save new Slack Channel list", "POST",
            {
                method: "POST",
                body: JSON.stringify(slackData),
                headers: { "Content-Type": "application/json" }
            })().then((res) => {
                if (res.success) {
                    if (successCallback !== undefined) {
                        let unsubscribe = store.subscribe(() => { });
                        let slackAlertsChannelsEntities = { ...store.getState().slackChannel.slackAlertsChannelsEntities };
                        slackAlertsChannelsEntities[res.data.entityType].push(res.data);
                        store.dispatch(SetSlackAlertsChannelsEntities(slackAlertsChannelsEntities));
                        unsubscribe();
                        successCallback(res.data);
                    }

                } else {
                    if (failedCallback !== undefined) {
                        failedCallback(res);
                    }

                }
            }).catch((error) => {
                if (failedCallback !== undefined) {
                    failedCallback(error);
                }
            });
    }

    updateSlackChannel(slackData, successCallback, failedCallback) {
        return genericFetcherFactory("/api/alerts/SlackAlertChannels/" + slackData.id, "SLACK", "Failed to save new Slack Channel list", "PATCH",
            {
                method: "PATCH",
                body: JSON.stringify(slackData),
                headers: { "Content-Type": "application/json" }
            })().then((res) => {
                if (res.success) {
                    if (successCallback !== undefined) {
                        let unsubscribe = store.subscribe(() => { });
                        let slackAlertsChannelsEntities = { ...store.getState().slackChannel.slackAlertsChannelsEntities };
                        
                        let index = slackAlertsChannelsEntities[res.data.entityType].findIndex(item => item.entityId === res.data.entityId);
                        let entityArray = slackAlertsChannelsEntities[res.data.entityType]

                        entityArray.splice(index, 1, res.data);
                        slackAlertsChannelsEntities[res.data.entityType] = entityArray;
                        
                        store.dispatch(SetSlackAlertsChannelsEntities(slackAlertsChannelsEntities));
                        unsubscribe();

                        successCallback(res.data);
                    }

                } else {
                    if (failedCallback !== undefined) {
                        failedCallback(res);
                    }

                }
            }).catch((error) => {
                if (failedCallback !== undefined) {
                    failedCallback(error);
                }
            });
    }
    static getInstance() {
        return this._instance;
    }
}

export default SlackChannelService;