import React from 'react';
import * as Yup from "yup";
import { Formik } from "formik";
import { Button, Form, Row } from "react-bootstrap";
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { toastr } from "react-redux-toastr";
import { handleErrorResponse } from "../../../utils/ajaxErrorHandler";
import { convertToEditor, convertToHtml } from '../../../utils/wysiwygConverter';

import { genericCachedFetcherFactory } from "../../../utils/requestUtils";
import { addHours } from "../../../utils/dateUtils";

const FormModes = {
    NEW: "new",
    EDIT: "edit"
}

export { FormModes };
export default class ClientEditForm extends React.PureComponent {
    constructor(props) {
        super(props);
        this.sumbitForm = this.sumbitForm.bind(this);
        this.onEditorStateChange = this.onEditorStateChange.bind(this);

        this.state = {
            imgValidity: true,
            imgToUpload: null,
            imgDisplay: "",
            clientData: { description: props.clientData.description }
        }


    }

    componentDidMount() {
        // On mount check if client data was passed and update accordingly
        if (this.props.clientData.imgUrl) {
            this.setState({ ...this.state, imgDisplay: this.props.clientData.imgUrl });
        }

        if (this.props.clientData.description) {
            let editorState = convertToEditor(this.props.clientData.description);
            this.setState({ ...this.state, editorState: editorState });
        }

        genericCachedFetcherFactory("/api/crm/Contacts", "CONTACTS", "Failed to fetch CONTACTS data", addHours(1))().then(result => {
            if (result.success === true) {
                let clients = result.data;
                clients.forEach(client => {
                    if (client.contact != null) {
                        client.contactName = client.contact.contactName;
                        client.contactId = client.contact.contactId;
                    }
                });
                this.setState({ ...this.state, contacts: [{ contactId: 0, contactName: "Select Contact" }, ...clients] });
            } else {
                handleErrorResponse(result, "Fetch Contacts Failed");
                return undefined;
            }
        });

    }

    changeFile(file) {
        // File was changed and supplied
        if (file) {
            let reader = new FileReader();
            reader.onload = (el) => {
                this.setState({
                    ...this.state,
                    imgToUpload: file,
                    imgDisplay: el.target ? el.target.result : "",
                    imgValidity: true
                })
            }
            reader.readAsDataURL(file);
        }
        // File was changed and prompt was closed without selecting a file
        else {
            this.setState({
                ...this.state,
                imgToUpload: null,
                imgDisplay: "",
                imgValidity: false
            })
        }
    }

    onEditorStateChange(editorState) {
        this.state.clientData.description = convertToHtml(editorState.getCurrentContent());
        this.setState({ ...this.state, editorState: editorState });
    }

    /**
     * Does various validation, data manipulation and runs the callback provided on component's submitCallback function
     * with a FormData object constructed from the form.
     * @param {*} event 
     * @param {*} errors 
     * @param {*} values 
     */

    sumbitForm(values) {
        // Validate if image exists only in new client creation. Image is not mandatory when editing.
        if (this.props.mode === FormModes.NEW && this.state.imgToUpload == null) {
            this.setState({ ...this.state, imgValidity: false });
            return;
        }

        let formData = new FormData();

        // Logic for when adding a new client
        if (this.props.mode == FormModes.NEW) {
            // All data will exist
            let clientData = {
                clientName: values.clientName,
                legalCompanyName: values.legalCompanyName,
                vatNumber: values.vatNumber,
                nameOnInvoice: values.nameOnInvoice,
                billingAddress: values.billingAddress,
                billingEmail: values.billingEmail,
                contactId: values.contactId,
                description: this.state.clientData.description,
                isActive: values.isActive
            };
            formData.append("logo", this.state.imgToUpload);
            formData.append("clientDetails", JSON.stringify(clientData));

            this.props.submitCallback(formData);
        }
        // Logic for when editing an existing client
        else if (this.props.mode == FormModes.EDIT) {
            let changeMade = false;
            let clientData = {};

            // Iterate through the form data
            for (let prop in values) {
                // TODO: Change this check to use an object defition rather than relying on the components properties
                if (this.props.clientData[prop] !== undefined) {
                    // Check if the field was edited
                    if (values[prop] != this.props.clientData[prop]) {
                        changeMade = true;
                        clientData[prop] = values[prop];
                    }
                } else {
                    console.warn("field " + prop + " doesn't exist");
                }
            }

            if (this.state.clientData.description != this.props.clientData.description) {
                clientData['description'] = this.state.clientData.description;
                changeMade = true;
            }

            // Add clientDetails to the FormData object if it has actually changed
            if (changeMade === true) {
                // Append the clientId for completion
                clientData["clientId"] = this.props.clientData.clientId;
                formData.append("clientDetails", JSON.stringify(clientData));
            }

            // Handle the image if it was changed
            if (this.state.imgToUpload != null) {
                changeMade = true;
                formData.append("logo", this.state.imgToUpload);
            }

            // Finally check if there was actually a change made before proceeding to the callback
            if (changeMade === false) {
                toastr.warning("Cannot save product", "No changes were made");
                return;
            } else {
                this.props.submitCallback(formData, this.props.clientData.clientId);
            }
        }
    }



    render() {
        let contactSelectOptions = "";
        if (this.state.contacts) {
            contactSelectOptions = this.state.contacts.map(item => <option value={item.contactId}>{item.contactName}</option>);
        }

        return (
            <Formik
                initialValues={this.props.clientData}
                validationSchema={Yup.object().shape({
                    clientName: Yup.string().required(),
                    legalCompanyName: Yup.string().required(),
                    vatNumber: Yup.string().required(),
                    nameOnInvoice: Yup.string().required(),
                    billingAddress: Yup.string().required(),
                    billingEmail: Yup.string().email('Please fill in a valid email').required("Email is required"),
                    contactId: Yup.number().moreThan(0,'Contact is required').required('Contact is required')
                })}
                onSubmit={(values) => this.sumbitForm(values)}
            >
                {({ handleSubmit, handleChange, handleBlur, errors, touched, values }) => (
                    <Form onSubmit={handleSubmit}>
                        <Row className="gx-1">
                            <img style={{ maxHeight: "150px", maxWidth: "150px" }} src={this.state.imgDisplay}></img>

                            <Form.Label>Client Logo</Form.Label>
                            <Form.Control type="file" name="clientImg" accept=".jpg,.jpeg,.png,.gif"
                                onBlur={handleBlur}
                                isInvalid={!!errors.clientImg && !!touched.clientImg}
                                onChange={(e) => { this.changeFile(e.target.files["0"]) }}
                            />
                            <Form.Text className={this.state.imgValidity !== false ? "text-muted" : "text-danger"}>Please use a logo of up to 150x150 px. Bigger size will be automatically resized</Form.Text>
                        </Row>

                        <Row className="gx-1 mt-3 form__client" >                           
                            <Form.Group className="col gx-1">
                                <Form.Label htmlFor="clientName">Client name</Form.Label>
                                <Form.Control
                                    type="text"
                                    name="clientName"
                                    id="clientName"
                                    value={values.clientName}
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    isInvalid={!!errors.clientName && !!touched.clientName}
                                />
                                {!!touched.clientName &&
                                    <Form.Control.Feedback type="invalid">Client name is required</Form.Control.Feedback>
                                }
                            </Form.Group>
                            <Form.Group className="col-auto me-2">
                                <Form.Label htmlFor="isActive">Active</Form.Label>
                                <Form.Check
                                    type="switch"
                                    name="isActive"
                                    id="isActive"
                                    value={values.isActive}
                                    checked={values.isActive}                                    
                                    onChange={handleChange}   
                                    className='text-center'                                 
                                />                           
                            </Form.Group>
                            <Form.Group className="col gx-1">
                                <Form.Label htmlFor="legalCompanyName">Legal company name</Form.Label>
                                <Form.Control
                                    type="text"
                                    name="legalCompanyName"
                                    id="legalCompanyName"
                                    value={values.legalCompanyName}
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    isInvalid={!!errors.legalCompanyName && !!touched.legalCompanyName}
                                />
                                {!!touched.legalCompanyName &&
                                    <Form.Control.Feedback type="invalid">Legal company name is required</Form.Control.Feedback>
                                }
                            </Form.Group>
                            <Form.Group className="col gx-1">
                                <Form.Label htmlFor="vatNumber">VAT number</Form.Label>
                                <Form.Control
                                    type="text"
                                    name="vatNumber"
                                    id="vatNumber"
                                    value={values.vatNumber}
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    isInvalid={!!errors.vatNumber && !!touched.vatNumber}
                                />
                                {!!touched.vatNumber &&
                                    <Form.Control.Feedback type="invalid">VAT number is required</Form.Control.Feedback>
                                }
                            </Form.Group>
                            <Form.Group className="col gx-1">
                                <Form.Label htmlFor="nameOnInvoice">Name on invoice</Form.Label>
                                <Form.Control
                                    type="text"
                                    name="nameOnInvoice"
                                    id="nameOnInvoice"
                                    value={values.nameOnInvoice}
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    isInvalid={!!errors.nameOnInvoice && !!touched.nameOnInvoice}
                                />
                                {!!touched.nameOnInvoice &&
                                    <Form.Control.Feedback type="invalid">Name on invoice is required</Form.Control.Feedback>
                                }
                            </Form.Group>
                            <Form.Group className="col gx-1">
                                <Form.Label htmlFor="billingAddress">Billing address</Form.Label>
                                <Form.Control
                                    type="text"
                                    name="billingAddress"
                                    id="billingAddress"
                                    value={values.billingAddress}
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    isInvalid={!!errors.billingAddress && !!touched.billingAddress}
                                />
                                {!!touched.billingAddress &&
                                    <Form.Control.Feedback type="invalid">Billing address is required</Form.Control.Feedback>
                                }
                            </Form.Group>
                            <Form.Group className="col gx-1">
                                <Form.Label htmlFor="billingEmail">Billing email</Form.Label>
                                <Form.Control                                    
                                    name="billingEmail"
                                    id="billingEmail"
                                    value={values.billingEmail}
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    isInvalid={!!errors.billingEmail && !!touched.billingEmail}
                                />
                                {!!touched.billingEmail &&
                                    <Form.Control.Feedback type="invalid"> {errors.billingEmail}</Form.Control.Feedback>
                                }
                            </Form.Group>
                            <Form.Group className="col gx-1">
                                <Form.Label htmlFor="contactId">Contact</Form.Label>
                                <Form.Control
                                    as="select"
                                    name="contactId"
                                    id="contactId"
                                    value={values.contactId}
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    isInvalid={!!errors.contactId && !!touched.contactId}
                                >
                                    {contactSelectOptions}
                                </Form.Control>
                                {!!touched.contactId &&
                                    <Form.Control.Feedback type="invalid">{errors.contactId}</Form.Control.Feedback>
                                }
                            </Form.Group>
                            <Row className="gx-1 mt-3 mb-3 wysiwyg">
                                <Form.Label>
                                    Client Description
                                </Form.Label>
                                <Editor
                                    editorState={this.state.editorState}
                                    onEditorStateChange={this.onEditorStateChange}
                                    wrapperClassName={"wysiwyg-wrapper"}
                                    editorClassName={"wysiwyg-editor"}
                                />
                            </Row>
                            <Button type="submit" className="submit-btn" variant="primary">Submit</Button>
                        </Row>
                    </Form>
                )}
            </Formik>
        )
    }
}

ClientEditForm.defaultProps = {
    clientData: {
        clientId: null,
        clientName: "",
        legalCompanyName: "",
        vatNumber: "",
        nameOnInvoice: "",
        billingAddress: "",
        billingEmail: "",
        imgUrl: "",
        contactId: 0,
        description: "",
        isActive: true
    },
    mode: FormModes.NEW
}