import { toastr } from "react-redux-toastr";
import { genericFetcherFactory, handleErrorResponse } from "./requestUtils";

export function facebookApiErrorHandler(errorObject) {
    //update slack dev group
    let payload = {
        method: "POST",
        body: JSON.stringify(errorObject)
    };
    genericFetcherFactory('/front-errors', "FrontErrors", "Failed Send Error to backend", "POST", payload, false, false)();
    toastr.error("Video Upload Facebook Error : " + errorObject.rawError.error.message);
}

export function facebookApiFetchWrapper(fetchUrl, fetchObjectType, requestPayload = null) {
    return async () => {
        let response = await fetch(fetchUrl, requestPayload);
        if (response.ok) {
            let responseData = await response.json();
            return Promise.resolve({ success: true, data: responseData });
        } else {
            let responseErrorData = await response.json();
            return Promise.resolve({ fetchObject: fetchObjectType, success: false, rawError: responseErrorData });
        }
    };
}

export function getErrorMessageFromErrorAPIObject(errorObject) {
    let jsonErrorObject = JSON.parse(errorObject);
    if (jsonErrorObject.error !== undefined ) {
        return jsonErrorObject.error.message
    } else {
        return errorObject;
    }
}