import React from "react";
import {
    Button,
    Card,
    Container,
    Form, Modal,
    Spinner
} from "react-bootstrap";
import { genericFetcherFactory } from "../../../utils/requestUtils";

import BootstrapTable from "react-bootstrap-table-next";
import filterFactory, { textFilter } from 'react-bootstrap-table2-filter';
import { toastr } from "react-redux-toastr";

export default class AutoStopLoss extends React.PureComponent {
    constructor(props) {
        super(props);

        this.toggleIsAccountInAutoStopLoss = this.toggleIsAccountInAutoStopLoss.bind(this);
        this.saveAllChanges = this.saveAllChanges.bind(this);

        this.state = {
            activeAccounts: [],
            showingSavePrompt: false
        }
    }

    componentDidMount() {
        this.getActiveAccounts();
    }

    getActiveAccounts() {
        genericFetcherFactory(`/api/accounts/Accounts/activeAccountIdListFacebook`,
            "ACTIVE_ACCOUNTS", "Failed to fetch active accounts")().then(result => {
                if (result.success === true) {
                    this.setState({ activeAccounts: result.data });
                    return result.data;
                }
            });
    }

    saveAllChanges() {
        this.setState({ showingSavePrompt: true });
        let accountsListToSave = this.state.activeAccounts.filter(row => row.isChanged === true);

        if (accountsListToSave.length <= 0) {
            toastr.warning("No pending changes to save");
            this.setState({ showingSavePrompt: false });
        } else {
            genericFetcherFactory("/api/accounts/Accounts/updateAutoSLAccountList", "UPDATE_AUTO_SL_ACCOUNT_LIST",
                "Failed to update", "PATCH", {
                method: "PATCH",
                body: JSON.stringify(accountsListToSave),
                headers: { "Content-Type": "application/json" }
            })().then((res) => {
                this.setState({ showingSavePrompt: false });

                if (res.success) {
                    toastr.success("Updated Status Successfully")
                }
            }).catch(error => {
                console.log(error);
            });

        }
    }

    columns = [
        {
            dataField: "accountId",
            text: "Account ID",
            headerStyle: { width: "60px" },
            sort: true,
            editable: false,
        },
        {
            dataField: "accountName",
            text: "Account Name",
            sort: true,
            editable: false,
            filter: textFilter({ placeholder: '', style: { width: "50%" } }),
        },
        {
            dataField: "isAccountInAutoStopLoss",
            text: "Auto Stop Loss Scan Status",
            sort: true,
            headerStyle: { width: "220px" },
            align: "center",
            formatter: (cell, row, rowIndex) => this.checkboxFormatter(cell, row, rowIndex, { toggleChecked: this.toggleIsAccountInAutoStopLoss })
        }
    ];

    checkboxFormatter(cell, row, index, { toggleChecked }) {
        return <Form.Check
            type="switch"
            id={`row-${row.accountId}`}
            name={`row-${row.accountId}`}
            checked={row.isAccountInAutoStopLoss}
            onChange={event => toggleChecked(event.target.checked, row)}
        />
    }

    toggleIsAccountInAutoStopLoss(checked, row) {
        let newActiveAccounts = [...this.state.activeAccounts];
        let userActionrowIndex = newActiveAccounts.findIndex(_row => _row.accountId == row.accountId);
        if (userActionrowIndex != -1) {
            let updated = { ...newActiveAccounts[userActionrowIndex], isAccountInAutoStopLoss: checked, isChanged: checked };
            newActiveAccounts.splice(userActionrowIndex, 1, updated);
            this.setState({ activeAccounts: newActiveAccounts });
        }
    }

    render() {
        return (
            <Container fluid className="p-0">
                <Modal
                    isOpen={this.state.showingSavePrompt === true}
                    centered
                >
                    <Modal.Header>
                        Saving Accounts Selection...
                    </Modal.Header>
                    <Modal.Body className="text-center m-3">
                        <Spinner color="dark" className="mr-2" />
                    </Modal.Body>
                </Modal>

                <Card>
                    <Card.Header>
                        <Card.Title tag="h1" className="mb-0">
                            Auto Stop Loss Account Selection
                        </Card.Title>
                        Disable the automatic stop loss for your account by deselecting the active switch.
                    </Card.Header>
                    <Card.Body>
                        {this.state.showingSavePrompt == true &&
                            <Spinner color="dark" className="mr-2" />
                        }
                        <BootstrapTable
                            bootstrap4
                            keyField="accountId"
                            bordered={true}
                            striped
                            hover
                            data={this.state.activeAccounts ? this.state.activeAccounts : []}
                            columns={this.columns}
                            filter={filterFactory()}
                        />
                        <Button className="submit-btn mt-4" color="primary" onClick={this.saveAllChanges}>Save All</Button>
                    </Card.Body>
                </Card>
            </Container>
        )
    }
}