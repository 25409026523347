import React from "react";
import { Card, Container } from "react-bootstrap";
import BootstrapTable from "react-bootstrap-table-next";
import * as Icon from "react-feather";
import { toastr } from "react-redux-toastr";
import GenericErrorAlert from "../../../components/GenericErrorAlert";
import Loader from '../../../components/Loader';
import CacheController from "../../../services/cache/CacheController";
import { addHours } from "../../../utils/dateUtils";
import { genericCachedFetcherFactory, getCacheKeyForUrl } from "../../../utils/requestUtils";
import ExternalUserPermissionMapping from "./ExternalUserPermissionMapping";

export default class ExternalUsersMgmt extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = { users: [], initSuccess: null };
        this.savedUser = this.savedUser.bind(this);
    }

    componentDidMount() {
        genericCachedFetcherFactory("/temp-api/externalUsers/getExternalUsersForEdit/", "EXTERNAL_USERS", "Could not fetch External Users", addHours(1))().then(result => {
            if (result.success === true) {
                this.setState({
                    users: result.data,
                    initSuccess: true
                });
            } else {
                this.setState({
                    initSuccess: false
                });
            }
        })
    }

    savedUser(userObj) {
        CacheController.deleteObject(getCacheKeyForUrl("/temp-api/externalUsers/getExternalUsersForEdit/"));
        let foundIndex = this.state.users.findIndex(user => user.userId === userObj.userId);
        if (foundIndex != -1) {
            let newUsers = [...this.state.users];
            newUsers.splice(foundIndex, 1, userObj);
            this.setState({ users: newUsers });
        } else {
            toastr.error("Failed to refresh with updates. Please refresh the page manually to avoid data inconsistency");
        }
    }

    expandRow = {
        renderer: row => {
            return (
                <div className="ml-5 mr-5 mt-3 mb-3">
                    <ExternalUserPermissionMapping user={row} savedUser={this.savedUser} />
                </div>
            )
        },
        expandColumnRenderer: ({ expanded }) => {
            if (expanded) {
                return (
                    <Icon.Edit className="feather align-middle" />
                );
            }
            return (
                <Icon.Edit2 className="feather align-middle" />
            );
        },
        expandHeaderColumnRenderer: ({ isAnyExpands }) => {
            return "";
        },
        showExpandColumn: true,
        expandByColumnOnly: true,
        expandColumnPosition: 'right'
    };

    // Column configuration for the Users table
    usersColumns = [
        {
            dataField: "userId",
            text: "ID",
            headerStyle: { width: "30px" },
        },
        {
            dataField: "userLogin",
            text: "User Login",
            sort: true
        },
        {
            dataField: "canViewOverview",
            text: "Can View Client Overview",
            sort: true
        }
    ]

    render() {
        if (this.state.initSuccess === true) {
            return (
                <Container fluid className="p-0">
                    <Card>
                        <Card.Header>
                            <Card.Title tag="h5" className="mb-0">
                                External Users
                            </Card.Title>
                        </Card.Header>
                        <Card.Body>
                            <BootstrapTable
                                bootstrap4
                                keyField="userId"
                                bordered={false}
                                striped
                                hover
                                data={this.state.users ? this.state.users : []}
                                columns={this.usersColumns}
                                expandRow={this.expandRow}
                            />
                        </Card.Body>
                    </Card>
                </Container>
            )
        } else if (this.state.initSuccess === false) {
            return (
                <GenericErrorAlert />
            );
        } else {
            return (
                <Card>
                    <Card.Header>
                        <Card.Title tag="h5" className="mb-0">
                            External Users
                        </Card.Title>
                    </Card.Header>
                    <Loader />
                </Card>
            );
        }
    }
}