import { Form, Field } from 'formik'
import React from 'react'
import DateView from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'

function DatePickerControl(props) {
    const { label, name, ...rest } = props
    return (
        <>
            <Field name={name} >
                {({ form, field }) => {
                    const { setFieldValue } = form
                    const { value } = field
                    let className= "form-control";
                    if (props.isInvalid === true) { 
                        className= "form-control is-invalid"
                    }
                    return (
                        <DateView
                            id={name}
                            name={name}
                            {...field}
                            {...rest}
                            className={className}
                            selected={value}
                            onChange={val => setFieldValue(name, val)}
                        />
                    )
                }}
            </Field>
            {props.isInvalid &&
                <div style={{display:"block"}} className='invalid-feedback'>{props.errors[name]}</div>
            }
        </>
    )
}

export default DatePickerControl