import React from 'react';

import {
    Button,
    Modal,
    ModalBody,
    ModalHeader
} from "reactstrap";

import {
    AvForm,
} from "availity-reactstrap-validation";

import { toastr } from "react-redux-toastr";
import { DEFAULT_FUNNEL_ID, comparePixels, deepCloneFunnel, isPixelBasedFunnelPlatform, kpiTypes } from './funnelUtils';
import FunnelBasicFieldsEditForm, { FormModes } from "./edit-form-components/FunnelBasicFieldsEditForm";
import FunnelPixelsEditForm from './edit-form-components/FunnelPixelsEditForm';
import { UNSELECTED_PIXEL_PLACEHOLDER } from './edit-form-components/PixelControl';
import { genericFetcherFactory } from '../../utils/requestUtils';

export { FormModes };

export default class FunnelEditForm extends React.PureComponent {
    constructor(props) {
        super(props);

        this.submitForm = this.submitForm.bind(this);
        this.updateBasicFunnelData = this.updateBasicFunnelData.bind(this);
        this.updatePixels = this.updatePixels.bind(this);
        this.validateFunnelData = this.validateFunnelData.bind(this);
        this.createFunnelGroupCallback = this.createFunnelGroupCallback.bind(this);
        let pristine = deepCloneFunnel(props.funnelData);
        let funnelData = deepCloneFunnel(props.funnelData);

        this.state = {
            pristineObj: pristine,
            funnelData: funnelData,
            accountPixels: props.prefetchedEntities.pixels,
            propagatedValidationErrors: {}
        }

        // If this is a new funnel instantiate important data if no value was provided
        switch (props.mode) {
            case FormModes.NEW:
                funnelData.productId = funnelData.productId ? funnelData.productId : 0;
                funnelData.funnelGroupId = funnelData.funnelGroupId ? funnelData.funnelGroupId : 0;
                funnelData.funnelId = funnelData.funnelId ? funnelData.funnelId : "";
                funnelData.showInReport = funnelData.showInReport === undefined || funnelData.showInReport === null ? true : funnelData.showInReport;
                funnelData.isActive = funnelData.isActive === undefined || funnelData.isActive === null ? true : funnelData.isActive;
                funnelData.targetCpaFromDefault = funnelData.funnelId === DEFAULT_FUNNEL_ID ? null : funnelData.targetCpaFromDefault;
                funnelData.targetRoiFromDefault = funnelData.funnelId === DEFAULT_FUNNEL_ID ? null : funnelData.targetRoiFromDefault;

                if (isPixelBasedFunnelPlatform(props.platform)) {
                    funnelData.pixels = funnelData.pixels ? funnelData.pixels : {};
                    pristine.pixels = pristine.pixels ? pristine.pixels : {};
                }

                break;
            case FormModes.HISTORY:
                funnelData.funnelGroupId = funnelData.funnelGroupId ? funnelData.funnelGroupId : 0;
                break;
            case FormModes.READONLY:
                funnelData.funnelGroupId = funnelData.funnelGroupId ? funnelData.funnelGroupId : 0;
                break;
        }
    }

    /**
     * Does various validation, data manipulation and runs the callback provided on component's submitCallback function
     * with a FormData object constructed from the form.
     * @param {*} event 
     * @param {*} errors 
     * @param {*} values 
     */
    submitForm(event, errors, values) {
        this.setState({ propagatedValidationErrors: {} });

        // Validate

        if (this.validateFunnelData(this.state.funnelData, this.props.platform) !== true) {
            return;
        }

        if (errors.length != 0) {
            return;
        }

        let funnelDataState = this.state.funnelData;

        let funnelBasicData = {
            accountId: funnelDataState.accountId,
            funnelId: funnelDataState.funnelId,
        };
        let funnelExtendedData = {};
        let changeMade = false;

        switch (this.props.mode) {
            case FormModes.NEW:
                changeMade = true;

                funnelBasicData = {
                    ...funnelBasicData,
                    funnelName: funnelDataState.funnelName,
                    targetRoiFromDefault: funnelDataState.targetRoiFromDefault,
                    targetRoi: funnelDataState.targetRoi,
                    targetCpaFromDefault: funnelDataState.targetCpaFromDefault,
                    targetCpa: funnelDataState.targetCpa,
                    funnelGroupId: funnelDataState.funnelGroupId == 0 ? null : funnelDataState.funnelGroupId,
                    productId: funnelDataState.productId,
                    mainKpi: funnelDataState.mainKpi,
                    showInReport: funnelDataState.showInReport,
                    isActive: funnelDataState.isActive,
                    kpiType: funnelDataState.kpiType

                }

                if (isPixelBasedFunnelPlatform(this.props.platform)) {
                    funnelExtendedData.pixels = funnelDataState.pixels;
                }

                break;
            case FormModes.EDIT:
                if (this.state.pristineObj.funnelName !== funnelDataState.funnelName) {
                    changeMade = true;
                    funnelBasicData.funnelName = funnelDataState.funnelName;
                }

                if (this.state.pristineObj.targetRoiFromDefault !== funnelDataState.targetRoiFromDefault) {
                    changeMade = true;
                    funnelBasicData.targetRoiFromDefault = funnelDataState.targetRoiFromDefault;
                    funnelBasicData.targetRoi = funnelDataState.targetRoi;
                }

                if (this.state.pristineObj.targetRoi !== funnelDataState.targetRoi) {
                    changeMade = true;
                    funnelBasicData.targetRoi = funnelDataState.targetRoi;
                    funnelBasicData.targetRoiFromDefault = funnelDataState.targetRoiFromDefault;
                }

                if (this.state.pristineObj.targetCpaFromDefault !== funnelDataState.targetCpaFromDefault) {
                    changeMade = true;
                    funnelBasicData.targetCpaFromDefault = funnelDataState.targetCpaFromDefault;
                    funnelBasicData.targetCpa = funnelDataState.targetCpa;
                }

                if (this.state.pristineObj.targetCpa !== funnelDataState.targetCpa) {
                    changeMade = true;
                    funnelBasicData.targetCpa = funnelDataState.targetCpa;
                    funnelBasicData.targetCpaFromDefault = funnelDataState.targetCpaFromDefault;
                }

                if (this.state.pristineObj.funnelGroupId !== funnelDataState.funnelGroupId) {
                    changeMade = true;
                    funnelBasicData.funnelGroupId = funnelDataState.funnelGroupId;
                }

                if (this.state.pristineObj.productId !== funnelDataState.productId) {
                    changeMade = true;
                    funnelBasicData.productId = funnelDataState.productId;
                }

                if (this.state.pristineObj.mainKpi !== funnelDataState.mainKpi) {
                    changeMade = true;
                    funnelBasicData.mainKpi = funnelDataState.mainKpi;
                }

                if (this.state.pristineObj.showInReport !== funnelDataState.showInReport) {
                    changeMade = true;
                    funnelBasicData.showInReport = funnelDataState.showInReport;
                }

                if (this.state.pristineObj.isActive !== funnelDataState.isActive) {
                    changeMade = true;
                    funnelBasicData.isActive = funnelDataState.isActive;
                }
                
                if(this.state.pristineObj.kpiType !== funnelDataState.kpiType){
                    changeMade = true;
                    funnelBasicData.kpiType = funnelDataState.kpiType;
                }

                if (isPixelBasedFunnelPlatform(this.props.platform)) {
                    if (comparePixels(this.state.pristineObj.pixels, funnelDataState.pixels) === false) {
                        changeMade = true;
                        funnelExtendedData.pixels = funnelDataState.pixels;
                    }
                }

                break;
            case FormModes.HISTORY:
                changeMade = true;

                funnelBasicData = {
                    ...funnelBasicData,
                    funnelName: funnelDataState.funnelName,
                    targetRoiFromDefault: funnelDataState.targetRoiFromDefault,
                    targetRoi: funnelDataState.targetRoi,
                    targetCpaFromDefault: funnelDataState.targetCpaFromDefault,
                    targetCpa: funnelDataState.targetCpa,
                    funnelGroupId: funnelDataState.funnelGroupId == 0 ? null : funnelDataState.funnelGroupId,
                    productId: funnelDataState.productId,
                    fromDate: funnelDataState.fromDate,
                    mainKpi: funnelDataState.mainKpi,
                    showInReport: funnelDataState.showInReport,
                    isActive: funnelDataState.isActive
                }

                if (isPixelBasedFunnelPlatform(this.props.platform)) {
                    funnelExtendedData.pixels = funnelDataState.pixels;
                }

                break;
        }

        if (changeMade === true) {
            this.props.submitCallback({ ...funnelBasicData, ...funnelExtendedData });
        } else {
            toastr.warning("No changes were made to the funnel");
        }
    }

    validatePixels(pixels) {
        let pixelsValidationErrors = {};
        let isValid = true;

        let pixelTypes = Object.keys(pixels);
        pixelTypes.forEach(pixelType => {
            pixels[pixelType].forEach((pixel, index) => {
                if (pixel === UNSELECTED_PIXEL_PLACEHOLDER || pixel === null) {
                    if (!pixelsValidationErrors) {
                        pixelsValidationErrors = {};
                    }
                    if (!pixelsValidationErrors[pixelType]) {
                        pixelsValidationErrors[pixelType] = [];
                    }

                    pixelsValidationErrors[pixelType][index] = true;
                    isValid = false;
                }
            })
        });

        return [isValid, pixelsValidationErrors];
    }

    validateFunnelData(funnelData, platform) {
        let isValid = true;
        let propagatedValidationErrors = {};

        if (funnelData.mainKpi == null) {
            propagatedValidationErrors.mainKpi = true;
            isValid = false;
        }

        if (isPixelBasedFunnelPlatform(platform)) {
            let pixelsValid;
            [pixelsValid, propagatedValidationErrors.pixels] = this.validatePixels(funnelData.pixels);

            isValid = isValid && pixelsValid;
        }

        if (funnelData.kpiType == null) {
            propagatedValidationErrors.kpiType = true;
            isValid = false;
        }
        
        this.setState({ propagatedValidationErrors: propagatedValidationErrors });

        return isValid;
    }

    mergeBasicData(currentObject, newObject) {
        let funnelData = { ...currentObject };

        funnelData.funnelId = newObject.funnelId;
        funnelData.funnelName = newObject.funnelName;
        funnelData.targetRoiFromDefault = newObject.targetRoiFromDefault;
        funnelData.targetRoi = newObject.targetRoi;
        funnelData.targetCpaFromDefault = newObject.targetCpaFromDefault;
        funnelData.targetCpa = newObject.targetCpa;
        funnelData.funnelGroupId = newObject.funnelGroupId;
        funnelData.productId = newObject.productId;
        funnelData.showInReport = newObject.showInReport;
        funnelData.isActive = newObject.isActive;
        funnelData.targetCpaFromDefault = newObject.targetCpaFromDefault;
        funnelData.targetRoiFromDefault = newObject.targetRoiFromDefault;
        funnelData.fromDate = newObject.fromDate;
        funnelData.fromDateString = newObject.fromDateString;
        funnelData.mainKpi = newObject.mainKpi;
        funnelData.kpiType = newObject.kpiType;
        
        return funnelData;
    }

    mergePixelsData(currentObject, newObject) {
        let funnelData = { ...currentObject };
        funnelData.pixels = newObject.pixels;
        return funnelData;
    }

    createFunnelGroupCallback(funnelGroupName) {
        //save new FG 
        let newFunnelGroupObj = {};
        newFunnelGroupObj.funnelGroupName = funnelGroupName;
        newFunnelGroupObj.accountId = this.state.funnelData.accountId;
        genericFetcherFactory("/api/accounts/FunnelGroups/", "FUNNEL_GROUP", "Failed to save funnel Group", "POST",
            {
                method: "POST",
                body: JSON.stringify(newFunnelGroupObj),
                headers: { "Content-Type": "application/json" }
            })().then((res) => {
                if (res.success) {
                    let funnelData = { ...this.state.funnelData }
                    funnelData.funnelGroupId = res.data.funnelGroupId;
                    funnelData.funnelGroupName = res.data.funnelGroupName;
                    this.setState({ funnelData: funnelData });
                    //update parent with new funnel data
                    this.props.resetFunnelGroupTrigger(res.data);
                }
            }).catch((error) => {
                toastr.error("Save Failed", "Error: " + error)
            });


    }
    updateBasicFunnelData(changes, propagatedValidationKeyReset = null) {
        let newState = {};

        let mergedData = this.mergeBasicData(this.state.funnelData, changes);
        newState.funnelData = mergedData;

        if (propagatedValidationKeyReset !== null) {
            if (this.state.propagatedValidationErrors[propagatedValidationKeyReset] === true) {
                newState.propagatedValidationErrors = { ...this.state.propagatedValidationErrors };
                newState.propagatedValidationErrors[propagatedValidationKeyReset] = false;
            }
        }

        this.setState(newState);
    }

    updatePixels(changes, propagatedValidationKeyReset = null) {
        let newState = {};

        let mergedData = this.mergePixelsData(this.state.funnelData, changes);
        newState.funnelData = mergedData;

        if (propagatedValidationKeyReset !== null) {
            let pixelType = propagatedValidationKeyReset.pixelType;
            let pixelIndex = propagatedValidationKeyReset.pixelIndex;

            if (this.state.propagatedValidationErrors.pixels &&
                this.state.propagatedValidationErrors.pixels[pixelType] &&
                this.state.propagatedValidationErrors.pixels[pixelType][pixelIndex] === true) {
                newState.propagatedValidationErrors = { ...this.state.propagatedValidationErrors };
                newState.propagatedValidationErrors.pixels[pixelType][pixelIndex] = false;
            }
        }

        this.setState(newState);
    }

    render() {
        let additionalFields = "";
        if (this.props.additionalFields === true) {
            let isDefaultFunnel = this.state.funnelData.funnelId === DEFAULT_FUNNEL_ID;
            let defaultFunnelPixels = null;

            if (!isDefaultFunnel) {
                defaultFunnelPixels = this.props.defaultFunnelData.pixels;
            }

            if (isPixelBasedFunnelPlatform(this.props.platform)) {
                additionalFields = (
                    <FunnelPixelsEditForm
                        platform={this.props.platform}
                        isDefaultFunnel={isDefaultFunnel}
                        defaultFunnelPixels={defaultFunnelPixels}
                        accountPixels={this.state.accountPixels}
                        funnelData={this.state.funnelData}
                        onChange={this.updatePixels}
                        mode={this.props.mode}
                        propagatedValidationErrors={this.state.propagatedValidationErrors}
                    />
                )
            }
        }

        return (
            <AvForm onSubmit={this.submitForm} >
                <FunnelBasicFieldsEditForm
                    mode={this.props.mode}
                    funnelData={this.state.funnelData}
                    prefetchedEntities={this.props.prefetchedEntities}
                    onChange={this.updateBasicFunnelData}
                    platform={this.props.platform}
                    createFunnelGroupCallback={this.createFunnelGroupCallback}
                    propagatedValidationErrors={this.state.propagatedValidationErrors}
                />
                {additionalFields}
                {this.props.mode !== FormModes.READONLY &&
                    <Button className="submit-btn" color="primary">Submit</Button>
                }
            </AvForm>
        )
    }
}

FunnelEditForm.defaultProps = {
    additionalFields: false,
    platform: null,
    mode: null,
    funnelData: {
        accountId: null,
        funnelId: "",
        funnelName: "",
        targetRoi: null,
        targetRoiFromDefault: false,
        targetCpa: null,
        targetCpaFromDefault: false,
        mainKpi: null,
        funnelGroupId: 0,
        productId: 0,
        kpiType: null
    },
    prefetchedEntities: {
        products: null,
        funnelGroups: null,
        pixels: null
    },
    submitCallback: null,
    funnelHistory: false,
}