import React from 'react';
import { Loader } from 'react-feather';
import { connect } from 'react-redux';
import { withRouter } from "react-router-dom";
import {
    Button, Container
} from "reactstrap";
import TableauReport from 'tableau-react';
import { IframeSettings } from "../pages/tableaus/constants";
import ReportTabs from '../pages/tableaus/ReportTabs';
import { genericFetcherFactory } from "../utils/requestUtils";
import EditPersonalDashboard from './EditPersonalDashboard';
import GenericErrorAlert from './GenericErrorAlert';

class PersonalDashboardLoader extends React.PureComponent {
    constructor(props) {
        super();
        this.state = {
            currentItem: null,
            url: props.url,
            initSuccess: null,
            trustedToken: null,
            tabs: []
        }
    }

    async componentDidMount() {
        if (this.props.personalDashboard.userId) {
            //user has personal tabs in db 
            this.getTableauToken();
        }
    }

    getTableauToken() {
        if (this.props.tableauMenus !== undefined && this.props.tableauMenus.length > 0) {
            genericFetcherFactory("/api/tableaus/TrustedToken/", "TRUSTED_TOKEN", "Failed to get trusted Token")().then((result) => {
                if (result.success == true) {
                    if (this.props.personalDashboard.permalink.length > 1) {
                        let allTabsWithPermalink = this.props.tableauMenus.filter(data => { if (data.permalink) return data });
                        let personalList = [];
                        this.props.personalDashboard.permalink.forEach((permalink) => {
                            let newCurrentItem = allTabsWithPermalink.find(item => item.permalink === permalink);
                            personalList.push(newCurrentItem)
                        })
                        this.setState({
                            currentItem: personalList, url: personalList[0].url, initSuccess: true, trustedToken: result.data.token
                        });
                    } else {
                        let newCurrentItem = this.props.tableauMenus.find(item => item.permalink === this.props.personalDashboard.permalink[0]);
                        if (newCurrentItem) {
                            this.setState({ currentItem: newCurrentItem, url: newCurrentItem.url, initSuccess: true, trustedToken: result.data.token });
                        }
                    }
                }
            });
        }
    }


    setInternalIframeSettings(settings) {
        let internalSettings = { ...settings };
        internalSettings.options.height = "800px";
        return internalSettings;
    }

    componentDidUpdate(prevProps) {
        if (this.props.personalDashboard !== prevProps.personalDashboard) {
            if (this.props.personalDashboard.permalink.length > 1) {
                let allTabsWithPermalink = this.props.tableauMenus.filter(data => { if (data.permalink) return data });
                let personalList = [];
                this.props.personalDashboard.permalink.forEach((permalink) => {
                    let newCurrentItem = allTabsWithPermalink.find(item => item.permalink === permalink);
                    personalList.push(newCurrentItem)
                })
                this.setState({
                    currentItem: personalList, url: personalList[0].url, initSuccess: true, trustedToken: this.state.trustedToken
                });
            } else {
                let newCurrentItem = this.props.tableauMenus.find(item => item.permalink === this.props.personalDashboard.permalink[0]);
                if (newCurrentItem) {
                    this.setState({
                        currentItem: newCurrentItem, url: newCurrentItem.url, initSuccess: true, trustedToken: this.state.trustedToken
                    });
                }
            }
        }
    }
    
    render() {

        let settings = this.setInternalIframeSettings(IframeSettings);

        if (this.state.initSuccess === true) {
            if (this.state.currentItem) {
                let jsx;
                if (this.state.currentItem.length > 1) {
                    jsx = (
                        <ReportTabs
                            tabs={this.state.currentItem}
                            trustedToken={this.state.trustedToken}
                            iframeSettings={settings}
                        />

                    );
                } else {
                    if (this.state.currentItem.showToolbar === true) {
                        settings.options.hideToolbar = false;
                    }
                    jsx = (
                        <TableauReport
                            url={this.state.url}
                            token={this.state.trustedToken}
                            filters={settings.filters}
                            parameters={settings.parameters}
                            options={settings.options}
                        />
                    );
                }
                return (
                    <Container fluid className="p-0">
                        {jsx}
                    </Container>
                )
            } else {
                return (<GenericErrorAlert />)
            }
        } else if (this.state.initSuccess === false) {
            return (<GenericErrorAlert />)
        } else if (this.props.personalDashboard === false || (this.props.personalDashboard.permalink && this.props.personalDashboard.permalink.length === 0)) {
            return (
                <Container fluid className="p-0">
                    <EditPersonalDashboard />
                </Container>
            )
        } else {
            return (<Loader />)
        }
    }
}
export default withRouter(connect(
    (store) => {
        return {
            tableauMenus: store.tableau.tableauMenus,
            user: store.userContext.user,
            personalDashboard: store.userContext.personalDashboard
        }
    }
)(PersonalDashboardLoader));