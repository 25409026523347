export const ReportTypes = {
    WEEKLY: "WEEKLY_REPORT",
    MONTHLY: "MONTHLY_REPORT"
}

export const ReportPeriods = {
    WEEKLY_REPORT: "WeeklyReport",
    THIS_MONTH: "This Month",
    LAST_30_DAYS: "Last 30 Days",
    THIS_QUARTER: "This Quarter"
}

export const IframeSettings = {
    options: {
        height: "1500px",
        width: "100%",
        hideTabs: false,
        hideToolbar: true,
        frameBorder: 0
    },
    filters: {}
}

export const ExternalReportScopes = {
    PRODUCT: "PRODUCT",
    CLIENT: "CLIENT"
}