import React from 'react';
import { withRouter } from "react-router";

import {
    Card,
    CardBody,
    Container,
    Modal,
    ModalBody,
    ModalHeader,
} from "reactstrap";

import ProductEditForm from './ProductEditForm';
import Loader from '../../../components/Loader'

import { handleErrorResponse } from "../../../utils/ajaxErrorHandler";

class NewProduct extends React.PureComponent {

    constructor(props) {
        super(props);
        this.state = {
            formSaving: false
        }

        this.save = this.save.bind(this);
    }

    save(productObj) {
        this.setState({ ...this.state, formSaving: true })
        fetch("/api/product/Products", { method: "POST", body: JSON.stringify(productObj), headers: { 'Content-Type': 'application/json' } }).then(res => {
            if (res.ok) {
                this.props.history.push('/prods-accounts/products')
            } else {
                this.setState({ ...this.state, formSaving: false });
                handleErrorResponse(res, "Save Failed");
            }
        });
    }

    render() {
        return (
            <>
                <Modal isOpen={this.state.formSaving === true} centered>
                    <ModalHeader>
                        Saving Product...
                    </ModalHeader>
                    <ModalBody className="text-center m-3">
                        <Loader />
                    </ModalBody>
                </Modal>

                <Container fluid className="p-0">
                    <h1 className="h3 mb-3">Create new product</h1>
                    <Card>
                        <CardBody>
                            <ProductEditForm submitCallback={this.save} />
                        </CardBody>
                    </Card>
                </Container>
            </>
        )
    }
}

export default withRouter(NewProduct);