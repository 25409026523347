import React from "react";
import {
    Input,
    Form,
    FormGroup,
    CustomInput,
    Modal,
    ModalBody,
    Button
} from "reactstrap";
import { Spinner } from "react-bootstrap";
import { toastr } from "react-redux-toastr";

import { genericFetcherFactory } from '../../utils/requestUtils';

export default class UploadFile extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            imgDisplay: "",
            imgValidity: false,
            imgPreview: false,
            selectedFile: null,
            pageFilesIsLoaded: null,
            filterText: ''
        };


        this.fetchFilesList = this.fetchFilesList.bind(this)
        this.handleChange = this.handleChange.bind(this)
        this.updateFile = this.updateFile.bind(this)

        this.pagePath = this.props.pageData.global["path"] + "/";
    }

    handleChange(event) {
        const { name, value } = event.target;
        this.setState({
            [name]: value
        });
    }

    updateFile() {
        this.props.updateCallback({ [this.props.dataRefName]: [this.state.selectedFile, this.props.pathObj] });
        this.setState({ imgPreview: false })
    }

    fetchFilesList() {
        this.setState({ pageFilesIsLoaded: false });

        let rest = {
            folder: this.props.pageData.global.path.replace('https://dtjlhiahlv53j.cloudfront.net/assets/', ''),
            type: 'files',
            file_type: this.props.type.includes('video') ? 'video' : 'image'
        }
        genericFetcherFactory(
            "/lambda-proxy/lambdaProxy/lambdas3filelist",
            "FILES-LIST",
            "Failed to fetch files list",
            "POST",
            {
                method: 'POST',
                body: JSON.stringify(rest)
            })().then(res => {
                if (res.success !== true) {
                    this.setState({
                        error: 'Data not found'
                    });
                    toastr.warning('Failed to fetch data');
                } else {
                    let filesList = res.data;
                    this.setState({ pageFilesIsLoaded: true, filesList: filesList, imgPreview: true });
                }
            }
            );

    }


    render() {
        const imgUrl = this.props.pageData.global["path"] + "/" + "upload/" + this.state.imgName;
        return (
            <div className="uploadImage_content">
                <button className="btn btn-secondary"
                    type="button" onClick={() => this.fetchFilesList()}>
                    Files Library
                </button>
                {this.state.pageFilesIsLoaded == false &&
                    <Spinner animation="border" variant="primary" className="me-7" />
                }
                {this.state.imgPreview &&
                    <Modal
                        isOpen={this.state.imgPreview === true}
                        centered
                        className="cms__s3_library"
                    >
                        <div className="modal-header">
                            <h5 className="modal-title">Files Library</h5>
                            <div className="cms__s3_library__filter">
                                <Input
                                    type="text"
                                    className='form-check-input'
                                    value={this.state.filterText}
                                    id='filterText'
                                    onChange={this.handleChange}
                                    name='filterText'
                                    label={this.state.filterText}
                                    placeholder="Search..."
                                />
                            </div>
                            <div>
                                {this.state.selectedFile !== null &&
                                    <Button type="button" color="secondary me-1" onClick={() => this.updateFile()}>
                                        Save & Close
                                    </Button>
                                }
                                <Button type="button" className="close" onClick={() => this.setState({ imgPreview: false })}>
                                    X
                                </Button>
                            </div>
                        </div>
                        <ModalBody className="text-center m-5">

                            <div>
                                <Form>
                                    {this.state.filesList
                                        .filter(data => data.includes(this.state.filterText) && !data.includes('.webp'))
                                        .map((data, value) => {
                                            return (
                                                <FormGroup key={value}>
                                                    <CustomInput
                                                        type="radio"
                                                        className='form-check-input'
                                                        value={data}
                                                        id={data}
                                                        onChange={this.handleChange}
                                                        name='selectedFile'
                                                        label={data}
                                                    />
                                                    <div>
                                                        <a href={this.pagePath + data} target="_blank">Preview</a>
                                                    </div>
                                                    {!data.includes('mp4') &&
                                                        <img src={this.pagePath + data}></img>}
                                                    {data.includes('mp4') &&
                                                        <video controls> <source src={this.pagePath + data} type="video/mp4" /> </video>}
                                                </FormGroup>
                                            )
                                        })}
                                </Form>
                            </div>
                        </ModalBody>
                    </Modal>
                }

            </div>
        )
    }
}