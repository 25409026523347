export const LookalikesSeedTypes = {
    CAMPAIGN: "Campaign",
    ADSET: "Adset",
    CUSTOM_AUDIENCE: "CustomAudience"
}

export const LookalikesLocationTypes = {
    COUNTRIES: "countries",
    SPEC: "spec"
}

export const RatioValues = {
    MAX: 20,
    MIN: 1
}