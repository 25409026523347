
import { Formik } from "formik";
import React from 'react';
import { Button, Card, Container, Form, Modal, Row, Spinner } from "react-bootstrap";
import {
    CheckCircle as SuccessIcon, XCircle as ErrorIcon
} from "react-feather";
import { connect } from "react-redux";
import { toastr } from "react-redux-toastr";
import Select from "react-select";
import { Label, Table } from "reactstrap";
import * as Yup from "yup";
import AccountDropdown from "../../../../components/AccountDropdown";
import { getErrorMessageFromErrorAPIObject } from "../../../../utils/facebookApiUtils";
import { formStatus } from '../../../../utils/formUtils';
import { genericFetcherFactory } from "../../../../utils/requestUtils";
import { AccountIsActive } from "../../../prods-and-accounts/account/accountUtils";
import AccountPixels from "./AccountPixels";


const EVENT_OPTIONS = {
    PV: "PageView",
    ATC: "AddToCart",
    CR: "CompleteRegistration",
    IC: "InitiateCheckout",
    Pur: "Purchase",
    Lead: "Lead",
    VC: "ViewContent",
    API:"AddPaymentInfo"
}

const defualtWebsiteCustomAudienceFormData = {
    eventsList: [],
    url: null,
    retentionDays: null,
    pixelId: null,
    name: null
}

const defualtWebsiteCustomAudience = {
    events: [],
    url: null,
    retentionDays: [],
    adPixelId: null,
    name: null
}

class WebsiteCustomAudienceForm extends React.PureComponent {
    constructor(props) {
        super(props);
        this.updateStateCallback = this.updateStateCallback.bind(this);
        this.accountChanged = this.accountChanged.bind(this);
        this.accountPixelChagedCallBack = this.accountPixelChagedCallBack.bind(this);
        this.sumbitForm = this.sumbitForm.bind(this);

        this.state = {
            selectedAccount: null,
            formStatus: formStatus.IDLE,
            initSuccess: null,
            report: [],
            currentCustomAudience: defualtWebsiteCustomAudienceFormData
        }
    }

    updateStateCallback(key, value) {
        this.setState({ [key]: value });
    }

    accountChanged(account) {
        if (account !== undefined) {
            this.setState({ selectedAccount: account, currentCustomAudience: defualtWebsiteCustomAudienceFormData });
        }
    }

    accountPixelChagedCallBack(pixel) {
        if (pixel !== undefined) {
            let currentCustomAudience = { ...this.state.currentCustomAudience };
            currentCustomAudience.pixelId = pixel.value;
            this.setState({ currentCustomAudience: currentCustomAudience });
        }
    }

    sumbitForm(values) {
        let websiteCustomAudience = defualtWebsiteCustomAudience;
        let currentCustomAudience = values;
        websiteCustomAudience.adPixelId = currentCustomAudience.pixelId;

        websiteCustomAudience.events = currentCustomAudience.eventsList.map(event => { return event.value });
        this.setState({ formStatus: formStatus.IN_PROGRESS })

        if (currentCustomAudience.url !== null) {
            websiteCustomAudience.url = currentCustomAudience.url;
        }else{
            websiteCustomAudience.url = null;
        }

        if (currentCustomAudience.prefix !== null) {
            websiteCustomAudience.prefix = currentCustomAudience.prefix;
        }

        let retentionDays = []
        let retentionDaysCommaArray = currentCustomAudience.retentionDays.split(",");
        retentionDaysCommaArray.forEach(element => {
            if (element.indexOf('-') > -1) {
                let splittedElement = element.split('-');
                for (let i = parseInt(splittedElement[0]); i <= parseInt(splittedElement[1]); i++) {
                    retentionDays.push(i);
                }
            } else {
                retentionDays.push(parseInt(element))
            }
        });
        websiteCustomAudience.retentionDays = retentionDays;

        let payload = {
            method: "POST",
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(websiteCustomAudience)
        }

        genericFetcherFactory(`/api/facebook-interface/WebsiteAudiences/${this.state.selectedAccount.accountPlatformId}`, "CREATE_WCA", "Failed to create audiences", "POST", payload)()
            .then(result => {
                if (result.success) {
                    this.setState({ formStatus: formStatus.SUCCESS, report: result.data });
                } else {
                    this.setState({ formStatus: formStatus.FAILED });
                }
            });
    }


    render() {
        let eventOptions = Object.entries(EVENT_OPTIONS).map(([key, value]) => { return { label: value, value: key } });
        let report = this.state.report;
        return (
            <Container fluid className="p-0 row ">
                <Modal show={this.state.formStatus !== formStatus.IDLE} centered size="lg" >
                    <Modal.Header>
                        Submiting Website Custom Audience {this.state.formStatus}
                    </Modal.Header>
                    <Button color="secondary" size="small"
                        style={{ position: "absolute", top: "10px", right: "10px" }}
                        onClick={e => this.setState({ formStatus: formStatus.IDLE, currentCustomAudience: defualtWebsiteCustomAudienceFormData })}>
                        Close
                    </Button>
                    <Modal.Body className="text-center">
                        {this.state.formStatus === formStatus.IN_PROGRESS &&
                            <Spinner color="primary" className="mr-2" />
                        }

                        {this.state.formStatus === formStatus.SUCCESS &&
                            <>
                                <Table striped className="wizard-table">
                                    <thead>
                                        <tr>
                                            <th></th>
                                            <th>ID</th>
                                            <th>name</th>
                                            <th>event</th>
                                            <th>Retention Days</th>
                                            <th>Notes</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {report.map(row => {
                                            let className = row.status ? "success" : "error";
                                            let errorMessage = row.status ? "" : getErrorMessageFromErrorAPIObject(row.reason);
                                            return (
                                                <tr className={className}>
                                                    <td> {row.status ? <SuccessIcon /> : <ErrorIcon />}</td>
                                                    <td>{row.audienceId}</td>
                                                    <td>{row.newAudienceName}</td>
                                                    <td>{EVENT_OPTIONS[row.event]}</td>
                                                    <td>{row.retentionDays}</td>
                                                    <td>{errorMessage}</td>
                                                </tr>
                                            );
                                        })
                                        }
                                    </tbody>
                                </Table>
                            </>
                        }

                    </Modal.Body>
                </Modal>
                <div className="col-xl-12">
                    <Card>
                        <Card.Header>
                            <Card.Title tag="h5" className="mb-0">Website Custom Audience Tool</Card.Title>
                        </Card.Header>
                        <Card.Body>
                            <div className="from-group-wrap row">
                                <div className="form-group col-lg-4 col-xl-5 col-5">
                                    <AccountDropdown
                                        updateStateCallback={this.updateStateCallback}
                                        accountChanged={this.accountChanged}
                                        selectedAccount={this.state.selectedAccount}
                                        platform='FACEBOOK' itemActive={AccountIsActive.ACTIVE}
                                    />
                                </div>
                            </div>

                            {(this.state.selectedAccount !== null && this.props.facebookUserData.isLoggedIn) &&
                                <>
                                    <AccountPixels
                                        account={this.state.selectedAccount}
                                        pixelSelecedCallBack={this.accountPixelChagedCallBack}
                                    />

                                    {this.state.currentCustomAudience.pixelId !== null &&
                                        <Formik
                                            initialValues={this.state.currentCustomAudience}
                                            validationSchema={Yup.object().shape({
                                                eventsList: Yup.array().min(1).required(),
                                                retentionDays: Yup.string().required()
                                            })}
                                            onSubmit={(values) => this.sumbitForm(values)}
                                        >
                                            {({ handleSubmit, handleChange, handleBlur, errors, touched, values, setFieldValue }) => (
                                                <Form onSubmit={handleSubmit}>
                                                    <Row>
                                                        <Form.Group className="col gx-1">
                                                            <Label>Multi Events Select</Label>
                                                            <Select
                                                                id="eventsList" name="eventsList"
                                                                className="react-select-container"
                                                                classNamePrefix="react-select"
                                                                value={values.eventsList}
                                                                options={eventOptions}
                                                                onChange={(opt, e) => {
                                                                    handleChange("eventsList");
                                                                    setFieldValue("eventsList", opt);
                                                                }}
                                                                isMulti
                                                            />
                                                            {values.eventsList.length == 0 &&
                                                                <Form.Text className={"text-danger"}>Please add at least one event</Form.Text>
                                                            }
                                                        </Form.Group>

                                                        <Form.Group className="col gx-1">
                                                            <Form.Label htmlFor="retentionDays">Retention Days</Form.Label>
                                                            <Form.Control
                                                                type="text"
                                                                name="retentionDays"
                                                                id="retentionDays"
                                                                value={values.retentionDays}
                                                                onBlur={handleBlur}
                                                                onChange={handleChange}
                                                                pattern='[0-9]+(?:[-,]?[0-9]+)*?$'
                                                                isInvalid={!!errors.retentionDays && !!touched.retentionDays}
                                                            />
                                                            {!!touched.retentionDays &&
                                                                <Form.Control.Feedback type="invalid">you can set range with `-` (1-5) and use `,` for specific days</Form.Control.Feedback>
                                                            }
                                                        </Form.Group>
                                                        <Form.Group className="col gx-1">
                                                            <Form.Label htmlFor="url">Refine by URL</Form.Label>
                                                            <Form.Control
                                                                type="url"
                                                                name="url" id="url"
                                                                value={values.url}
                                                                onBlur={handleBlur}
                                                                onChange={handleChange}
                                                                pattern="https?://.+"
                                                            />
                                                        </Form.Group>

                                                        <Form.Group className="col gx-1">
                                                            <Form.Label htmlFor="prefix">Prefix</Form.Label>
                                                            <Form.Control
                                                                type="text"
                                                                name="prefix" id="prefix"
                                                                value={values.prefix}
                                                                onBlur={handleBlur}
                                                                onChange={handleChange}
                                                            />
                                                        </Form.Group>
                                                        <Button type="submit" className="submit-btn col-auto align-self-start gx-1" variant="primary" disable={this.state.formStatus !== formStatus.IN_PROGRESS}>Submit</Button>
                                                    </Row>
                                                </Form>
                                            )}
                                        </Formik>
                                    }
                                </>
                            }
                        </Card.Body>
                    </Card>
                </div>
            </Container>
        )
    }
}
export default connect((store) => {
    return {
        facebookUserData: store.userContext.facebookUserData
    }
})(WebsiteCustomAudienceForm)