import { Formik } from "formik";
import React from 'react';
import { Button, Card, Container, Form, Modal, Row, Spinner } from "react-bootstrap";
import {
    CheckCircle as SuccessIcon, XCircle as ErrorIcon
} from "react-feather";
import { connect } from "react-redux";
import { toastr } from "react-redux-toastr";
import Select from "react-select";
import { Label, Table } from "reactstrap";
import * as Yup from "yup";
import AccountDropdown from "../../../../components/AccountDropdown";
import FacebookContextComponent from "../../../../components/FacebookContextComponent";
import { formStatus } from '../../../../utils/formUtils';
import { genericFetcherFactory } from "../../../../utils/requestUtils";
import { AccountIsActive } from "../../../prods-and-accounts/account/accountUtils";
import PageDropdown from "./PageDropdown";

const EVENT_OPTIONS = {
    PE: "page_engaged",
    PV: "page_visited",
    PL: "page_liked",
    PM: "page_messaged",
    PCC: "page_cta_clicked",
    PS: "page_or_post_save",
    PSI: "page_post_interaction"
}

const defaultPageCustomAudienceFormData = {
    eventsList: [],
    retentionDays: null,
    name: null
}

const defaultPageCustomAudience = {
    events: [],
    retentionDays: [],
    name: null
}

class PageCustomAudienceForm extends React.PureComponent {
    constructor(props) {
        super(props);

        this.updateStateCallback = this.updateStateCallback.bind(this);
        this.pageChanged = this.pageChanged.bind(this);
        this.sumbitForm = this.sumbitForm.bind(this);
        this.accountChanged = this.accountChanged.bind(this);
 
        this.state = {
            selectedPage: null,
            selectedAccount: null,
            formStatus: formStatus.IDLE,
            initSuccess: null,
            currentCustomAudience: defaultPageCustomAudienceFormData,
            report: [],
            showSubmitBtn: false
        }
    }

    pageChanged(page) {
        if (page !== undefined) {
            this.setState({
                selectedPage: page,
                currentCustomAudience: defaultPageCustomAudienceFormData,
                selectedAccount: null,
                showSubmitBtn: false
            });
        }
    }

    accountChanged(account) {
        if (account !== undefined) {
            this.setState({ selectedAccount: account });
            //Once user choose a page id and account, check if the page is related to this account
            if (account.facebookPageId === this.state.selectedPage.id) {
                this.setState({ showSubmitBtn: true });
            } else {
                toastr.error("This page id is not related to this account. Go to accounts and edit facebook page id.");
                this.setState({ showSubmitBtn: false });

            }
        }
    }

    updateStateCallback(key, value) {
        this.setState({ [key]: value });
    }

    getDropdownButtonLabel({ placeholderButtonLabel, value }) {
        if (value && value.some((o) => o.value === "*")) {
            return `${placeholderButtonLabel}: All`;
        } else {
            return `${placeholderButtonLabel}: ${value.length} selected`;
        }
    }

    sumbitForm(values) {
        let pageCustomAudience = defaultPageCustomAudience;
        let currentCustomAudience = values;

        pageCustomAudience.pageId = this.state.selectedPage.id;

        if (currentCustomAudience.name !== null) {
            pageCustomAudience.name = currentCustomAudience.name;
        }
        this.setState({ formStatus: formStatus.IN_PROGRESS })

        //events
        pageCustomAudience.events = currentCustomAudience.eventsList.map(event => { return event.value });

        //retentionDays
        let retentionDays = []
        let retentionDaysCommaArray = currentCustomAudience.retentionDays.split(",");
        retentionDaysCommaArray.forEach(element => {
            if (element.indexOf('-') > -1) {
                let splittedElement = element.split('-');
                for (let i = parseInt(splittedElement[0]); i <= parseInt(splittedElement[1]); i++) {
                    retentionDays.push(i);
                }
            } else {
                retentionDays.push(parseInt(element))
            }
        });

        pageCustomAudience.retentionDays = retentionDays;
        pageCustomAudience.accountPlatformId = this.state.selectedAccount.accountPlatformId

        //Sending custom audience data to backend
        let payload = {
            method: "POST",
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(pageCustomAudience)
        }

        genericFetcherFactory(`/api/facebook-interface/createCustomAudienceByPageId`,
            "CREATE_PCA", "Failed to create page ca", "POST", payload)().then(result => {
                if (result.success) {
                    this.setState({ formStatus: formStatus.SUCCESS, report: result.data });
                } else {
                    this.setState({ formStatus: formStatus.FAILED });
                }
            });
    }

    render() {
        let eventOptions = Object.entries(EVENT_OPTIONS).map(([key, value]) => { return { label: value, value: key } });
        let report = this.state.report;
        return (
            <Container fluid className="p-0 row ">
                <Modal
                    show={this.state.formStatus !== formStatus.IDLE} centered size="lg" >

                    <Modal.Header>Page Custom Audience</Modal.Header>
                    <Button color="secondary" size="small"
                        style={{ position: "absolute", top: "10px", right: "10px" }}
                        onClick={e => this.setState({
                            formStatus: formStatus.IDLE,
                            currentCustomAudience: defaultPageCustomAudienceFormData
                        })}>
                        Close
                    </Button>

                    <Modal.Body className="text-center">
                        {this.state.formStatus === formStatus.IN_PROGRESS &&
                            <Spinner color="primary" className="mr-2" />
                        }

                        {this.state.formStatus === formStatus.SUCCESS &&
                            <>
                                <Table striped className="wizard-table">
                                    <thead>
                                        <tr>
                                            <th></th>
                                            <th>Name</th>
                                            <th>Event</th>
                                            <th>Retention Days</th>
                                            <th>Note</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {report.map(row => {
                                            let className = row.status ? true : false;
                                            let errorMessage = row.status ? "" : row.reason;
                                            return (
                                                <tr className={className}>
                                                    <td> {row.status ? <SuccessIcon /> : <ErrorIcon />}</td>
                                                    <td>{row.newAudienceName}</td>
                                                    <td>{EVENT_OPTIONS[row.event]}</td>
                                                    <td>{row.retentionDays}</td>
                                                    <td>{errorMessage}</td>
                                                </tr>
                                            );
                                        })
                                        }
                                    </tbody>
                                </Table>
                            </>
                        }
                    </Modal.Body>
                </Modal>

                <div className="col-xl-12">
                    <Card>
                        <Card.Header>
                            <Card.Title tag="h5" className="mb-0">Page Custom Audience Tool</Card.Title>
                        </Card.Header>
                        <Card.Body>
                            {/*Facebook Loggin button */}
                            {!this.props.facebookUserData.isLoggedIn &&
                                <Button color="info"><FacebookContextComponent shouldRender={true} /></Button>
                            }

                            {this.props.facebookUserData.isLoggedIn &&
                                <div className="from-group-wrap row">
                                    <div className="form-group col-lg-4 col-xl-5 col-5">
                                        <Label>Page ID: {this.state.selectedPage ? this.state.selectedPage.id : ""}</Label>
                                        <PageDropdown
                                            accessToken={this.props.facebookUserData.accessToken}
                                            updateStateCallback={this.updateStateCallback}
                                            pageChanged={this.pageChanged}
                                            selectedPage={this.state.selectedPage}
                                            type="pageCustomAudience"
                                        />
                                    </div>
                                    {this.state.selectedPage &&
                                        <div className="mb-4" >
                                            <AccountDropdown
                                                widthClass={"col-lg-4 col-xl-5 col-5"}
                                                updateStateCallback={this.updateStateCallback}
                                                accountChanged={this.accountChanged}
                                                selectedAccount={this.state.selectedAccount}
                                                platform='FACEBOOK'
                                                itemActive={AccountIsActive.ACTIVE}
                                            />
                                        </div>
                                    }
                                </div>
                            }

                            {(this.state.selectedPage !== null && this.props.facebookUserData.isLoggedIn && this.state.showSubmitBtn === true) &&
                                <>
                                    {<Formik
                                        initialValues={this.state.currentCustomAudience}
                                        validationSchema={Yup.object().shape({
                                            eventsList: Yup.array().min(1).required(),
                                            retentionDays: Yup.string().required(),
                                            name: Yup.string().required()
                                        })}
                                        onSubmit={(values) => this.sumbitForm(values)}
                                    >
                                        {({ handleSubmit, handleChange, handleBlur, errors, touched, values, setFieldValue }) => (
                                            <Form onSubmit={handleSubmit}>
                                                <Row>
                                                    <Form.Group className="col gx-1">
                                                        <Label>Multi Events Select</Label>
                                                        <Select
                                                            className="react-select-container"
                                                            classNamePrefix="react-select"
                                                            value={values.eventsList}
                                                            options={[{ label: "SELECT All", value: "*" }, ...eventOptions]}
                                                            onChange={(opt, e) => {
                                                                handleChange("eventsList");
                                                                if (e.action === "select-option" && e.option.value === "*") {
                                                                    //select all
                                                                    setFieldValue("eventsList", eventOptions);
                                                                } else {
                                                                    setFieldValue("eventsList", opt);
                                                                }
                                                            }}
                                                            isMulti
                                                            allowSelectAll={true}
                                                            closeMenuOnSelect={false}
                                                        />
                                                        {values.eventsList.length == 0 &&
                                                            <Form.Text className={"text-danger"}>Please add at least one event</Form.Text>
                                                        }
                                                    </Form.Group>

                                                    <Form.Group className="col gx-1">
                                                        <Form.Label htmlFor="retentionDays">Retention Days</Form.Label>
                                                        <Form.Control
                                                            type="text"
                                                            name="retentionDays"
                                                            id="retentionDays"
                                                            value={values.retentionDays}
                                                            onBlur={handleBlur}
                                                            onChange={handleChange}
                                                            pattern='[0-9]+(?:[-,]?[0-9]+)*?$'
                                                            isInvalid={!!errors.retentionDays && !!touched.retentionDays}
                                                        />
                                                        {!!touched.retentionDays &&
                                                            <Form.Control.Feedback type="invalid">you can set range with `-` (1-5) and use `,` for specific days</Form.Control.Feedback>
                                                        }
                                                    </Form.Group>

                                                    <Form.Group className="col gx-1">
                                                        <Form.Label htmlFor="name">Prefix</Form.Label>
                                                        <Form.Control
                                                            type="text"
                                                            name="name" id="name"
                                                            value={values.name}
                                                            onBlur={handleBlur}
                                                            onChange={handleChange}
                                                            isInvalid={!!errors.name && !!touched.name}
                                                        />
                                                        {!!touched.name &&
                                                            <Form.Control.Feedback type="invalid">Prefix is required</Form.Control.Feedback>
                                                        }
                                                    </Form.Group>
                                                    <Button type="submit" className="submit-btn col-auto align-self-start gx-1" variant="primary" disable={this.state.formStatus !== formStatus.IN_PROGRESS}>Submit</Button>
                                                </Row>
                                            </Form>
                                        )}
                                    </Formik>
                                    }
                                </>
                            }
                        </Card.Body>
                    </Card>
                </div>
            </Container >
        )
    }
}
export default connect((store) => {
    return {
        facebookUserData: store.userContext.facebookUserData
    }
})(PageCustomAudienceForm)