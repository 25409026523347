import React from "react";
import {
    Container,
    CustomInput,
    Form,
    FormGroup,
    Card,
    CardBody,
    Input,
    Button
} from "reactstrap";
import { Spinner } from "react-bootstrap";
import Loader from '../../components/Loader';
import { toastr } from "react-redux-toastr";
import { PushNotifications } from "../../components/PushNotifications";
import { genericFetcherFactory } from '../../utils/requestUtils';
import { connect } from 'react-redux';
import { cmsUtils } from './utils';
import { setCmsData, setCMSTemplateData } from "../../redux/actions/cmsActions"

const PushStatesValues = {
    CLONE_STARTED: "clonedPageMergedStarted",
    CLONE_READY: "clonedPageMergedReady",
    CLONE_FAILED: "clonedPageMergedFailed",
    DELETE_STARTED: "deletePageMergedStarted",
    DELETE_READY: "deletePageMergedReady",
    DELETE_FAILED: "deletePageMergedFailed",
    COPY_TO_S3: "CopyFilesToS3Done"
}
class PageList extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            error: null,
            newPageName: null,
            pushStates: [],
            pushNotificationsActive: false,
            cloneMergeAndDeploy_status: null,
            deleteMergeAndDeploy_status: null
        };

        this.utils = new cmsUtils();
        this.cloneMergeAndDeploy = this.cloneMergeAndDeploy.bind(this);
        this.duplicateJsonS3 = this.duplicateJsonS3.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handlePushStates = this.handlePushStates.bind(this);

    }

    componentDidMount() {
        this.utils.fetchPageList().then(() => {
            this.utils.fetchFileFromS3("package.json")
        })
    }

    handleChange(event) {
        let { name, value } = event.target;
        if (name === 'newPageName') {
            value = value.replace(/[^a-zA-Z0-9_-]/g, '');
        }
        this.setState({
            [name]: value
        })
    }

    cloneMergeAndDeploy() {
        this.setState({
            pushNotificationsActive: true
        })

        let rest = {
            MERGE_GIT: "clonePage",
            BRANCH_TO_MERGE: this.props.cmsData.template + "_" + this.state.newPageName,
            FOLDER_NAME: this.props.cmsData.template,
            FILE_NAME: this.props.cmsData.page + ".html",
            NEW_FILE_NAME: this.state.newPageName + ".html",
            scali_session_id: this.props.cmsData.template + '_' + this.state.newPageName
        }
        genericFetcherFactory(
            "/jenkins-api/jobTrigger/CMS-Merge",
            "jenkins - merge",
            "Failed to to run jenkins",
            "POST",
            {
                method: 'POST',
                body: JSON.stringify(rest),
                headers: { 'Content-Type': 'application/json' }
            })().then(
                (response) => {
                    if (response.success === true) {
                        this.setState({ cloneMergeAndDeploy_status: "success" });
                        this.duplicateJsonS3();
                    } else {
                        toastr.warning('Error in creating a page');
                    }
                }
            )
    }

    removeMergeAndDeploy() {
        this.setState({
            pushNotificationsActive: true
        })

        this.props.cmsData.deletePages.forEach((element, index) => {
            this.props.cmsData.deletePagesHTML[index] = element + '.html';
            this.props.cmsData.deletePagesJSON[index] = element + '.html.json';
        });
        let filesToRemoveHTML = this.props.cmsData.deletePagesHTML.toString().replaceAll(",", " ");
        let filesToRemoveJSON = this.props.cmsData.deletePagesJSON.toString().replaceAll(",", " ");

        let rest = {
            MERGE_GIT: "deletePage",
            BRANCH_TO_MERGE: this.props.cmsData.template + "_deletePages",
            FOLDER_NAME: this.props.cmsData.template,
            FILES_TO_REMOVE_HTML: filesToRemoveHTML,
            FILES_TO_REMOVE_JSON: filesToRemoveJSON,
            scali_session_id: this.props.cmsData.template + '_deletePages'
        }
        genericFetcherFactory(
            "/jenkins-api/jobTrigger/CMS-Merge",
            "jenkins - merge",
            "Failed to to run jenkins",
            "POST",
            {
                method: 'POST',
                body: JSON.stringify(rest),
                headers: { 'Content-Type': 'application/json' }
            })().then(
                (response) => {
                    if (response.success === true) {
                        this.setState({ deleteMergeAndDeploy_status: "success" });
                        this.utils.resetDataModel()
                        this.deleteJsonS3();
                    } else {
                        toastr.warning('Error in deleting a page');
                    }
                }
            )
    }

    deleteJsonS3() {
        let filesToRemove = this.props.cmsData.deletePagesJSON;

        for (const file of filesToRemove) {
            let rest = {
                folder: this.props.cmsData.template,
                file: file,
            }
            genericFetcherFactory(
                "/lambda-proxy/lambdaProxy/lambdas3delete",
                "s3-delete-file",
                "Failed to duplicate file",
                "POST",
                {
                    method: 'POST',
                    body: JSON.stringify(rest)
                })().then(res => {
                    if (res.success !== true) {
                        this.setState({
                            error: "Failed to delete file"
                        });

                    }
                }
                );
        }

    }

    duplicateJsonS3() {
        let rest = {
            folder: this.props.cmsData.template,
            file: this.props.cmsData.page + ".html.json",
            newFile: this.state.newPageName + ".html.json",
        }
        genericFetcherFactory(
            "/lambda-proxy/lambdaProxy/lambdas3duplicatefile ",
            "s3-duplicate-file",
            "Failed to duplicate file",
            "POST",
            {
                method: 'POST',
                body: JSON.stringify(rest)
            })().then(res => {
                if (res.success !== true) {
                    this.setState({
                        error: "Failed to duplicate file"
                    });

                }
            }
            );

    }

    handlePushStates(pushState) {
        if (pushState === PushStatesValues.CLONE_READY) {
            toastr.info('Cloned page from:' + this.props.cmsData.page + " to: " + this.state.newPageName);

            this.props.setCmsData(
                this.utils.updateSuccessCallback({
                    'newPageName': [this.state.newPageName, false],
                    'currentPage': [this.state.newPageName, false]
                }));

            this.setState({ newPageName: null });
            this.utils.fetchPageList(true)
                .then(() => {
                    //move to next page     
                    this.props.next()
                }
                );
        } else if (pushState === PushStatesValues.CLONE_FAILED) {
            toastr.warning('Cloned page ' + this.state.newPageName + ' FAILED, Try a different page name');
            this.setState({
                cloneMergeAndDeploy_status: null,
                newPageName: null,
                pushNotificationsActive: false
            });
        } else if (pushState === PushStatesValues.DELETE_READY) {
            this.utils.fetchPageList(true)
            toastr.info('Pages deleted successfully: ' + this.props.cmsData.deletePagesHTML + " [Reloading Page list]");
            this.setState({
                deleteMergeAndDeploy_status: null,
                pushStates: []
            });
            this.props.setCmsData({ ...this.props.cmsData, page: null, deletePages: [], deletePagesHTML: [], deletePagesJSON: [] })
        }
    }


    render() {
        const fetchList = this.props.CMSTemplateData.templatePageLists[this.props.cmsData.template] || [];

        if (this.props.cmsData.pageListIsLoaded === false && this.props.cmsData.pageListError) {
            return (
                <Container fluid className="cms__inner">
                    <h1>Choose a Page:</h1>
                    <div>{this.props.cmsData.pageListError}</div>
                </Container>
            )
        } else if (this.props.cmsData.pageListIsLoaded === true && this.props.cmsData.packageJson_Loaded === true) {
            return (
                <Container fluid className="cms__inner cms__pageList">
                    <h1><b>{this.props.cmsData.template}</b></h1>
                    <h2>Select an Option:</h2>
                    <Card>
                        <CardBody>
                            <Form>
                                <FormGroup className="cms__pageList__editOrCreate">
                                    <div className="custom-controls-stacked">
                                        <CustomInput
                                            type="radio"
                                            id="editPage"
                                            name="editOrCreate"
                                            value="edit"
                                            label="Edit a Page"
                                            className="form-check-input"
                                            onChange={() => { this.props.setCmsData({ ...this.props.cmsData, editOrCreate: "edit" }) }}
                                            defaultChecked={this.props.cmsData.editOrCreate === "edit"}
                                        />
                                        <CustomInput
                                            type="radio"
                                            id="createPage"
                                            name="editOrCreate"
                                            value="create"
                                            label="Create a New Page"
                                            className="form-check-input"
                                            onChange={() => { this.props.setCmsData({ ...this.props.cmsData, editOrCreate: "create"}) }}
                                            defaultChecked={this.props.cmsData.editOrCreate === "create"}
                                        />
                                        <CustomInput
                                            type="radio"
                                            id="deletePage"
                                            name="editOrCreate"
                                            value="delete"
                                            label="Delete a Page"
                                            className="form-check-input"
                                            onChange={() => { this.props.setCmsData({ ...this.props.cmsData, editOrCreate: "delete", deletePages: [] }) }}
                                            defaultChecked={this.props.cmsData.editOrCreate === "delete"}
                                        />
                                    </div>
                                </FormGroup>
                                {this.props.cmsData.editOrCreate === "create" &&
                                    <FormGroup className="cms__pageList__enterPageName col-3">
                                        <Input type="text" name="newPageName" value={this.state.newPageName} placeholder="Enter a new Page Name" onChange={this.handleChange} />
                                    </FormGroup>
                                }
                            </Form>
                        </CardBody>
                    </Card>
                    {this.props.cmsData.editOrCreate === "create" &&
                        <h2>Choose a Page To Duplicate:</h2>
                    }
                    {this.props.cmsData.editOrCreate === "delete" &&
                        <h2>Choose a Page To Delete:</h2>
                    }
                    {this.props.cmsData.editOrCreate === "edit" &&
                        <h2>Choose a Page To Edit:</h2>
                    }
                    <Card>
                        <CardBody>
                            <Form>
                                <FormGroup>
                                    {this.props.cmsData.editOrCreate === "delete" && fetchList.map((data, value) => {
                                        return (
                                            <CustomInput key={value}
                                                type="checkbox"
                                                className='form-check-input'
                                                value={data}
                                                id={data}
                                                onChange={this.utils.handleChange}
                                                name='page'
                                                label={data}
                                            />
                                        );
                                    })}
                                    {this.props.cmsData.editOrCreate !== "delete" && fetchList.map((data, value) => {
                                        return (
                                            <CustomInput key={value}
                                                type="radio"
                                                className='form-check-input'
                                                value={data}
                                                id={data}
                                                onChange={this.utils.handleChange}
                                                name='page'
                                                checked={this.props.cmsData.page === data}
                                                label={data}
                                            />
                                        );
                                    })}
                                </FormGroup>
                            </Form>

                            {this.props.cmsData.editOrCreate === "create" &&
                                <Button className="cms__pageList__button" color="primary" onClick={() => this.cloneMergeAndDeploy()}
                                    disabled={(
                                        this.props.cmsData.viewOnly ||
                                        this.state.newPageName === null ||
                                        this.props.cmsData.page === null ||
                                        this.state.cloneMergeAndDeploy_status === "success") &&
                                        !this.state.pushStates.includes(PushStatesValues.CLONE_FAILED)
                                    }
                                >
                                    Create a new page</Button>
                            }
                            {this.props.cmsData.editOrCreate === "delete" &&
                                <Button className="cms__pageList__button" color="primary" onClick={() => this.removeMergeAndDeploy()}
                                    disabled={(
                                        this.props.cmsData.viewOnly ||
                                        this.props.cmsData.page === null ||
                                        this.state.deleteMergeAndDeploy_status === "success") &&
                                        !this.state.pushStates.includes(PushStatesValues.DELETE_FAILED)
                                    }
                                >
                                    Delete pages</Button>
                            }

                            {this.state.cloneMergeAndDeploy_status === "success" &&
                                !this.state.pushStates.includes(PushStatesValues.CLONE_FAILED) &&
                                <div>
                                    <Spinner animation="border" variant="primary" className="me-2" />
                                </div>
                            }
                            {this.state.deleteMergeAndDeploy_status === "success" &&
                                !this.state.pushStates.includes(PushStatesValues.DELETE_FAILED)
                                &&
                                !this.state.pushStates.includes(PushStatesValues.DELETE_READY)
                                &&
                                <div>
                                    <Spinner animation="border" variant="primary" className="me-2" />
                                </div>
                            }
                        </CardBody>
                    </Card>
                    {this.state.pushNotificationsActive &&
                        <PushNotifications
                            topic={
                                this.props.cmsData.editOrCreate === "delete" ?
                                    "jenkins-" + this.props.cmsData.template + '_deletePages'
                                    :
                                    "jenkins-" + this.props.cmsData.template + '_' + this.state.newPageName
                            }
                            messageCallBack={(msg) => {
                                this.setState(prevState => ({
                                    pushStates: [...prevState.pushStates, msg.step]
                                }))
                                this.handlePushStates(msg.step);                               
                            }}
                        ></PushNotifications>
                    }
                </Container>
            );
        } else {
            return (
                <Container fluid className="cms__inner">
                    <h1>Choose a Page:</h1>
                    <Loader />
                </Container>
            )
        }
    }

}

export default connect(
    (store) => {
        return {
            CMSTemplateData: store.cms.CMSTemplateData,
            cmsData: store.cms.cmsData
        }
    },
    { setCmsData, setCMSTemplateData })(PageList)
