import * as types from "../constants";

export function loadUser(user) {
    return {
        type: types.LOAD_USER,
        payload: user
    };
}

export function logIn() {
    return {
        type: types.LOG_IN
    }
}

export function logOut() {
    return {
        type: types.LOG_OUT
    }
}

export function updateAllowedModules(allowedModules) {
    return {
        type: types.UPDATE_ALLOWED_MODULES,
        payload: allowedModules
    }
}

export function setFacebookUserData(facebookUserData) {
    return {
        type: types.SET_FACEBOOK_USER_DATA,
        payload: facebookUserData
    }
}

export function impersonateUser(userContext) {
    return {
        type: types.IMPERSONATE_USER,
        payload: userContext
    }
}

export function setPersonalDashboard(personalDashboard) {
    return {
        type: types.PERSONAL_DASHBOARD,
        payload: personalDashboard
    }
}

export function setPermalinkList(permalinkList) {
    return {
        type: types.PERMALINK_LIST,
        payload: permalinkList
    }
}

export function setAccountData(accounts) {
    return {
        type: types.ACCOUNTS,
        payload: accounts
    }
}

export function setProductData(products) {
    return {
        type: types.PRODUCTS,
        payload: products
    }
}

export function setUserPages(pages) {
    return {
        type: types.PAGES,
        payload: pages
    }
}

