import React from "react";
import { Button, Card, Container, Modal, OverlayTrigger, Tooltip } from "react-bootstrap";
import BootstrapTable from "react-bootstrap-table-next";
import filterFactory, { numberFilter, textFilter } from 'react-bootstrap-table2-filter';
import paginationFactory from 'react-bootstrap-table2-paginator';
import * as Icon from "react-feather";
import { connect } from "react-redux";
import { toastr } from "react-redux-toastr";
import AccountDropdown from "../../../../components/AccountDropdown";
import FacebookContextComponent from "../../../../components/FacebookContextComponent";
import GenericErrorAlert from "../../../../components/GenericErrorAlert";
import ListToCopy from "../../../../components/ListToCopy";
import Loader from "../../../../components/Loader";
import { genericFetcherFactory } from '../../../../utils/requestUtils';
import { AccountIsActive, AccountTypeMapping } from "../../../prods-and-accounts/account/accountUtils";
import CAEditForm, { FormModes } from "./CAEditForm";
import { Label } from "reactstrap";
import PageDropdown from "../page/PageDropdown";


const paginationOptions = {
    sizePerPage: 15,
    hideSizePerPage: true,
    hidePageListOnlyOnePage: true
};

class CustomAudiences extends React.PureComponent {
    constructor(props) {
        super(props);

        this.saveNewCA = this.saveNewCA.bind(this);
        this.updateCA = this.updateCA.bind(this);
        this.removeCA = this.removeCA.bind(this);
        this.accountChanged = this.accountChanged.bind(this);
        this.getEditFormJsx = this.getEditFormJsx.bind(this);
        this.updateStateCallback = this.updateStateCallback.bind(this);
        this.getVideoListFromSnowflake = this.getVideoListFromSnowflake.bind(this);
        this.prepateCAForListToCopy = this.prepateCAForListToCopy.bind(this);
        this.copyToAnotherAccount = this.copyToAnotherAccount.bind(this);
        this.convertCAtoDto = this.convertCAtoDto.bind(this);
        this.pageChanged = this.pageChanged.bind(this);

        this.state = {
            customAudiences: [],
            videoList: [],
            selectedAccount: null,
            initSuccess: null,
            addingNewCA: false,
            copyAllCustomAudienceBtn: false,
            caMap: [],
            viewVideoListBtn: false,
            selectedPage: null
        };
    }

    componentDidMount() {
    }

    updateStateCallback(key, value) {
        this.setState({ [key]: value });
    }

    fetchCustomAudiences(accountId, accountPlatformId, page) {
        genericFetcherFactory(`/api/facebook-interface/CustomAudience/${accountId}/${accountPlatformId}/${page.id}`,
            "CUSTOM_AUDIENCES", "Failed to fetch custom audiences")().then(res => {
                if (res.success == true) {
                    this.setState({ customAudiences: res.data, formSaving: false, selectedPage: page });
                } else {
                    this.setState({ formSaving: false });
                }
            })
    }

    updateCA(updateObject) {
        this.setState({ formSaving: true });

        updateObject.accountId = this.state.selectedAccount.accountId;
        updateObject.facebookAccount = this.state.selectedAccount;
        updateObject.facebookAccount.accountType = AccountTypeMapping.FACEBOOK;

        genericFetcherFactory(`/api/facebook-interface/CustomAudience/${this.state.selectedAccount.accountId}?accountPlatformId=${this.state.selectedAccount.accountPlatformId}`,
            "SAVE_CA", "Failed to update custom audience", "PATCH", {
            method: "PATCH",
            body: JSON.stringify(updateObject),
            headers: { 'Content-Type': 'application/json' }
        })().then(result => {
            if (result.success == true) {
                let updatedAudience = result.data;
                let newAudiences = [...this.state.customAudiences];
                let audIndex = newAudiences.findIndex(item => (item.customAudienceId === updatedAudience.customAudienceId));
                newAudiences.splice(audIndex, 1, updatedAudience);
                this.setState({ customAudiences: newAudiences, formSaving: false });
            } else {
                this.setState({ formSaving: false });
            }
        });

    }

    saveNewCA(insertObject) {
        this.setState({ formSaving: true });

        insertObject.accountId = this.state.selectedAccount.accountId;
        insertObject.facebookAccount = this.state.selectedAccount;
        insertObject.facebookAccount.accountType = AccountTypeMapping.FACEBOOK;
        insertObject.pageId = this.state.selectedPage.id;

        genericFetcherFactory(`/api/facebook-interface/CustomAudience/${this.state.selectedAccount.accountId}?accountPlatformId=${this.state.selectedAccount.accountPlatformId}`,
            "SAVE_CA", "Failed to save custom audience", "POST", {
            method: "POST",
            body: JSON.stringify(insertObject),
            headers: { 'Content-Type': 'application/json' }
        })().then(result => {
            if (result.success == true) {
                let newAudiences = [...this.state.customAudiences];
                newAudiences.push(result.data);
                this.setState({ customAudiences: newAudiences, formSaving: false, addingNewCA: false });
            } else {
                this.setState({ formSaving: false });
            }
        });
    }

    removeCA(removeObject) {
        let confirmation = window.confirm("Are you sure you want to delete the Custom Audience?");

        if (confirmation == true) {
            this.setState({ formSaving: true });

            genericFetcherFactory(`/api/facebook-interface/CustomAudience/${this.state.selectedAccount.accountId}/${removeObject.filterId}`,
                "DELETE_CA", "Failed to delete custom audience", "DELETE",
                {
                    method: "DELETE"
                })().then(result => {
                    if (result.success == true) {
                        let newAudiences = [...this.state.customAudiences];
                        let audIndex = newAudiences.findIndex(item => (item.customAudienceId === removeObject.customAudienceId));
                        newAudiences.splice(audIndex, 1);
                        this.setState({ customAudiences: newAudiences, formSaving: false });
                    } else {
                        this.setState({ formSaving: false });
                    }
                });
        }
    }

    copyToAnotherAccount(data) {
        this.setState({ formSaving: true });
        let newCADto = this.convertCAtoDto(data);

        genericFetcherFactory(`/api/facebook-interface/CustomAudience/copyAll/${data.accountPlatformId}?pageId=${data.pageId}`,
            "COPY_ALL_CA", "Failed to create all custom audience for different account", "POST", {
            method: "POST",
            body: JSON.stringify(newCADto),
            headers: {
                'Content-Type': 'application/json'
            }
        })().then(result => {
            if (result.success == true) {
                this.setState({ copyAllCustomAudienceBtn: false, formSaving: false })
                toastr.success("Save Custom Audiences Succesfully!");
            } else {
                this.setState({ formSaving: false });
            }
        });
    }

    prepateCAForListToCopy() {
        let caList = this.state.customAudiences;
        let normalizedCA = [];
        caList.forEach(ca => {
            normalizedCA.push(this.normalizedCA(ca));
        });
        this.setState({ caMap: normalizedCA, copyAllCustomAudienceBtn: true });
    }

    normalizedCA(ca) {
        let item = {};
        item.id = ca.customAudienceId;
        item.name = ca.customAudienceName
        return item;
    }

    convertCAtoDto(data) {
        let newCADto = [];
        let orignCA = [...this.state.customAudiences];
        let caToSave = data.filter(ca => ca.isChanged === true);

        //convert custom audience back to dto
        caToSave.forEach(ca => {
            let index = orignCA.findIndex(_ca => _ca.customAudienceId == ca.id);
            if (index != -1) {
                let dto = { ...orignCA[index] };
                dto.accountId = data.accountId;
                dto.filterId = null;
                dto.facebookAccount = this.state.selectedAccount;
                dto.facebookAccount.accountType = AccountTypeMapping.FACEBOOK;
                newCADto.push(dto);
            }
        });
        return newCADto;
    }

    async accountChanged(account) {
        if (account === undefined) {
            toastr.error("Selected account has no data. Please try to refresh the page and try again");
            this.setState({ initSuccess: false });
            return;
        }

        this.setState({
            selectedAccount: account,
            selectedPage: null
        });
    }

    async pageChanged(page) {
        if (page !== undefined) {
            this.setState({
                formSaving: true,
                customAudiences: [],
                addingNewCA: false
            });

            this.fetchCustomAudiences(this.state.selectedAccount.accountId, this.state.selectedAccount.accountPlatformId, page);
        }
    }

    getVideoListFromSnowflake(row) {
        this.setState({ videoList: null, viewVideoListBtn: true });
        row.facebookAccount = this.state.selectedAccount;
        row.facebookAccount.accountType = AccountTypeMapping.FACEBOOK;
        row.pageId = this.state.selectedPage.id;
        genericFetcherFactory(`/api/facebook-interface/CustomAudience/videoList/${this.state.selectedAccount.accountPlatformId}`,
            "CA_VIDEO_LIST", "Failed to get video list from Snowflake", "POST", {
            method: "POST",
            body: JSON.stringify(row),
            headers: { 'Content-Type': 'application/json' }
        })().then(result => {
            if (result.success == true) {
                this.setState({ videoList: result.data });
            } else {
                this.setState({ videoList: [] });
                toastr.warning("No video list found from Snowflake for this custom audience");

            }
        });

    }

    // Column configuration 
    columns = [
        {
            dataField: "videoId",
            text: "Video ID",
            align: "left",
            headerStyle: { width: "130px" },
            sort: true
        },
        {
            dataField: "postName",
            text: "Post Name",
            align: "left",
            filter: textFilter(),
            sort: true
        }
    ]

    render() {
        if (this.state.initSuccess === false) {
            this.setState({ formSaving: false });
            return (
                <GenericErrorAlert />
            );
        }

        return (
            <Container fluid className="p-0">
                <Modal show={this.state.formSaving === true} centered size="lg">
                    <Modal.Header>
                        Video Custom Audience
                    </Modal.Header>
                    <Modal.Body className="text-center m-3">
                        <Loader width="wide" />
                    </Modal.Body>
                </Modal>

                <Card>
                    <Card.Header>
                        Video Custom Audiences
                        <Card.Title tag="h5" className="mb-0"></Card.Title>
                    </Card.Header>
                    {!this.props.facebookUserData.isLoggedIn &&
                        <Card.Body><Button color="info"><FacebookContextComponent shouldRender={true} /></Button></Card.Body>
                    }
                    {this.props.facebookUserData.isLoggedIn &&
                        <Card.Body>
                            <div className="form-group col-lg-4 col-xl-5 col-5" >
                                <AccountDropdown
                                    updateStateCallback={this.updateStateCallback}
                                    accountChanged={this.accountChanged}
                                    selectedAccount={this.state.selectedAccount}
                                    platform='FACEBOOK'
                                    itemActive={AccountIsActive.ACTIVE}
                                />
                            </div>
                            {this.state.selectedAccount &&
                                <div className="form-group col-lg-4 col-xl-5 col-5">
                                    <PageDropdown
                                        accessToken={this.props.facebookUserData.accessToken}
                                        updateStateCallback={this.updateStateCallback}
                                        pageChanged={this.pageChanged}
                                        type="videoCustomAudienceByPage"
                                        selectedPage={this.state.selectedPage}
                                        selectedAccount={this.state.selectedAccount}
                                    />
                                </div>
                            }
                            {this.state.selectedAccount && this.state.selectedPage && <>
                                <Label>Page ID: {this.state.selectedPage ? this.state.selectedPage.id : ""}</Label>

                                <BootstrapTable
                                    bootstrap4
                                    keyField="customAudienceId"
                                    bordered={false}
                                    striped
                                    hover
                                    data={this.state.customAudiences}
                                    columns={this.tableColumns}
                                    expandRow={this.expandRow}
                                    filter={filterFactory()}
                                />

                                {!this.state.addingNewCA &&
                                    <Button variant="secondary" onClick={() => this.setState({ addingNewCA: true })}>Add New</Button>
                                }

                                {this.state.addingNewCA && <div>
                                    <CAEditForm
                                        mode={FormModes.NEW}
                                        onSubmit={this.saveNewCA}
                                    />
                                </div>}

                                <Button disabled={this.state.customAudiences.length == 0}
                                    className="btn btn-success" onClick={this.prepateCAForListToCopy}>
                                    Copy Custom Audience
                                </Button>

                                {this.state.copyAllCustomAudienceBtn &&
                                    <ListToCopy
                                        items={this.state.caMap}
                                        submitCallback={this.copyToAnotherAccount}
                                        selectedAccount={this.state.selectedAccount}
                                        isCopyCABtnClicked={this.state.copyAllCustomAudienceBtn}
                                    />
                                }

                                <Modal size="lg" show={this.state.viewVideoListBtn === true}
                                    toggle={() => this.setState({ viewVideoListBtn: false })}
                                    centered>
                                    <Button variant="secondary" style={{ position: "absolute", top: "10px", right: "10px" }}
                                        onClick={e => this.setState({ viewVideoListBtn: false })}>
                                        Close
                                    </Button>
                                    <Modal.Header toggle={() => this.setState({ viewVideoListBtn: false })}>
                                        Custom Audience Video List For Page: {this.state.selectedPage.id}
                                    </Modal.Header>
                                    <Modal.Body className="text-center m-9" >
                                        {this.state.videoList && this.state.videoList !== null &&
                                            <BootstrapTable
                                                bootstrap4
                                                keyField="videoId"
                                                bordered={true}
                                                striped
                                                hover
                                                data={this.state.videoList}
                                                columns={this.columns}
                                                filter={filterFactory()}
                                                pagination={paginationFactory(paginationOptions)}
                                            />
                                        }
                                        {this.state.videoList === null &&
                                            <Loader />
                                        }
                                    </Modal.Body>
                                </Modal>
                            </>
                            }
                        </Card.Body>
                    }
                </Card>
            </Container>
        )
    }

    // Table display
    termsFormatter(cell, type) {
        let data;
        if (type == "contained") {
            data = <>
                <div>
                    {cell.containedOperand}
                </div>
                <div>
                    {cell.containedMessageTerms.join(",")}
                </div>
            </>
        } else if (type == "not-contained") {
            data = <>
                <div>
                    {cell.notContainedOperand}
                </div>
                <div>
                    {cell.notContainedMessageTerms.join(",")}
                </div>
            </>
        }
        return (data)
    }

    tableColumns = [
        { dataField: "customAudienceId", text: "Audience ID", sort: true, filter: textFilter({ placeholder: "Search...", style: { width: "80%" } }) },
        { dataField: "customAudienceName", text: "Custom Audience Name", sort: true, filter: textFilter({ placeholder: "Search...", style: { width: "80%" } }) },
        { dataField: "eventName", text: "Event Name", sort: true, filter: textFilter({ placeholder: "Search...", style: { width: "80%" } }) },
        { dataField: "dtoCustomAudienceVideoFilterConditions.minVideoLength", text: "Min Video Length", sort: true, filter: numberFilter({ placeholder: " ", withoutEmptyComparatorOption: true }) },
        { dataField: "dtoCustomAudienceVideoFilterConditions.maxVideoLength", text: "Max Video Length", sort: true, filter: numberFilter({ placeholder: " ", withoutEmptyComparatorOption: true }) },
        { dataField: "dtoCustomAudienceVideoFilterConditions", text: "Contains", formatter: (cell) => this.termsFormatter(cell, "contained") },
        { dataField: "dtoCustomAudienceVideoFilterConditions", text: "Not Contains", formatter: (cell) => this.termsFormatter(cell, "not-contained") },
        { dataField: "retentionDays", text: "Watched X Days Ago", sort: true, filter: numberFilter({ placeholder: " ", withoutEmptyComparatorOption: true }) },
        {
            dataField: "status", text: "Scan Status",
            sort: true, headerStyle: { width: "75px" }, editable: false, style: { cursor: "pointer" },
            formatter: (cell, row) => this.statusActionsFormatter(cell, row)
        },
        {
            editable: false, isDummyField: true, headerStyle: { width: "20px", textAlign: "center" }, align: "center",
            formatter: (cell, row) => this.videoListActionsFormatter(cell, row, { viewVideoList: this.getVideoListFromSnowflake }
            )
        },
        {
            editable: false, isDummyField: true, headerStyle: { width: "20px", textAlign: "center" }, align: "center",
            formatter: (cell, row) => this.deleteActionsFormatter(cell, row, { removeCA: this.removeCA }
            )
        }
    ]

    statusActionsFormatter(cell, row) {
        let statusFormatterId = "statusIcon-" + row.customAudienceId;
        if (row != null && row.status == 0) {
            return (
                <>
                    <div tabindex="-1">
                        <OverlayTrigger placement="top" key={statusFormatterId} overlay={
                            <Tooltip id={statusFormatterId}>
                                Video Update Status
                            </Tooltip >
                        }>
                            <Icon.X id={statusFormatterId} style={{ cursor: "pointer" }} className="feather mr-2" />
                        </OverlayTrigger>
                    </div>
                </>
            );
        } else {
            return (
                <>
                    <div tabindex="-1">
                        <OverlayTrigger placement="top" key={statusFormatterId} overlay={
                            <Tooltip id={statusFormatterId}>
                                Video Update Status
                            </Tooltip >
                        }>
                            <Icon.Check id={statusFormatterId} style={{ cursor: "pointer" }} className="feather mr-2" />
                        </OverlayTrigger>
                    </div>
                </>
            )
        }
    }
    deleteActionsFormatter(cell, row, { removeCA }) {
        let removeFormatterId = "deleteIcon-" + row.customAudienceId;
        return (
            <>
                <div tabindex="-1">
                    <OverlayTrigger placement="top" key={removeFormatterId} overlay={
                        <Tooltip id={removeFormatterId}>
                            Delete Custom Audience
                        </Tooltip >
                    }>
                        <Icon.Trash2 id={removeFormatterId} style={{ cursor: "pointer" }} className="feather mr-2"
                            onClick={() => { removeCA(row) }} />
                    </OverlayTrigger>
                </div>
            </>
        )
    }

    videoListActionsFormatter(cell, row, { viewVideoList }) {
        let videoListFormatterId = "videoListlIcon-" + row.customAudienceId;
        return (
            <>
                <div tabindex="-1">
                    <OverlayTrigger placement="top" key={videoListFormatterId} overlay={
                        <Tooltip id={videoListFormatterId}>
                            View Video List
                        </Tooltip >
                    }>
                        <Icon.Video id={videoListFormatterId} style={{ cursor: "pointer" }} className="feather mr-2"
                            onClick={() => viewVideoList(row)} />
                    </OverlayTrigger>
                </div>
            </>
        )
    }
    // Table and render

    getEditFormJsx(customAudience, formMode, callback) {
        return (
            <div className="funnel-id-wrap mt-3 mb-3">
                <CAEditForm
                    customAudience={customAudience}
                    mode={formMode}
                    onSubmit={callback}
                />
            </div>
        );
    }

    expandRow = {
        renderer: (row) => {
            return this.getEditFormJsx(row, FormModes.EDIT, this.updateCA);
        },
        expandColumnRenderer: ({ expanded }) => {
            if (expanded) {
                return (
                    <Icon.Edit className="feather align-middle" />
                );
            }
            return (
                <Icon.Edit2 className="feather align-middle" />
            );
        },
        expandHeaderColumnRenderer: ({ isAnyExpands }) => {
            return "";
        },
        showExpandColumn: true,
        expandByColumnOnly: true,
        expandColumnPosition: 'right'
    };
}

export default connect((store) => {
    return {
        facebookUserData: store.userContext.facebookUserData
    }
})(CustomAudiences)