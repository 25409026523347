import * as types from "../constants";

export function setCMSTemplateData(CMSTemplateData){
    return {
        type: types.SET_CMS_TEMPLATE_DATA,
        payload: CMSTemplateData
    }
}

export function setCmsData(cmsData){
    return {
        type: types.SET_CMS_DATA,
        payload: cmsData
    }
}

export function setDeploymentSharedComponent(deploymentSharedComponent){
    return {
        type: types.SET_DEPLOY_SHARED_COMPONENT,
        payload: deploymentSharedComponent
    }
}

export function setUserStatus(userStatus){
    return {
        type: types.SET_USER_STATUS_DATA,
        payload: userStatus
    }
}