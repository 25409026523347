import React from "react";
import { connect } from 'react-redux';
import Select from "react-select";
import { Platforms } from "../pages/prods-and-accounts/account/accountUtils";
import { setAccountData } from "../redux/actions/userContextActions";
import { CACHE_EXPIRATION_DEFAULTS } from "../services/qsCache";
import { addHours } from "../utils/dateUtils";
import { genericCachedFetcherFactory } from "../utils/requestUtils";
class AccountDropdown extends React.PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            initSuccess: null,
            accounts: [],
            multiAccountsSelection: []
        }

        this.accountChanged = this.accountChanged.bind(this);
        this.multipleAccountChanged = this.multipleAccountChanged.bind(this);
    }

    // Init and fetchers

    componentDidMount() {
        genericCachedFetcherFactory(`/api/accounts/Accounts/byPlatform?platformName=${Platforms[this.props.platform]}`, "ACCOUNTS", "Failed to fetch accounts", addHours(CACHE_EXPIRATION_DEFAULTS.Accounts))().then((result) => {
            if (result.success == true) {
                if (this.props.accounts) {
                    // account list was already loaded 
                    this.setState({ initSuccess: true, accounts: this.props.accounts.data });
                } else {
                    this.setState({ initSuccess: true, accounts: [...result.data] });
                    //set in props
                    this.props.setAccountData({ data: [...result.data] });

                    this.props.updateStateCallback('initSuccess', true);
                    if (this.props.getAccounts) {
                        this.props.updateStateCallback('accounts', [...result.data]);
                    }
                }
            } else { 
                this.setState({ initSuccess: false });
                this.props.updateStateCallback('initSuccess', false);
            }
        });

    }

    accountChanged(accountId) {
        let selectedAccount = this.state.accounts.find(item => item.accountId == accountId);
        this.props.accountChanged(selectedAccount);
    }

    multipleAccountChanged(accounts) {
        this.setState({ multiAccountsSelection: accounts });
        this.props.multipleAccountChanged(accounts);
    }

    render() {
        if (this.state.initSuccess === true) {
            let accountsSelectOptions = this.state.accounts.map(account => { return { value: account.accountId, label: account.accountName, isActive: account.isActive } });
            let selectedAccount = this.props.selectedAccount ? accountsSelectOptions.find(option => option.value == (this.props.selectedAccount.accountId || this.props.selectedAccount.entityId)) : null;
            if (selectedAccount === undefined) {
                selectedAccount = null;
            }

            return (
                <>
                    {this.props.isMulti ?
                        <>
                            <div class="form-group" style={{ width: "600px" }}>
                                <Select
                                    isMulti
                                    options={accountsSelectOptions}
                                    id="multiAccountDropdown"
                                    placeholder="Select Multiple accounts..."
                                    value={this.state.multiAccountsSelection}
                                    onChange={selectedOptions => this.multipleAccountChanged(selectedOptions)}
                                    filterOption={(item, input) => {
                                        if (this.props.itemActive == null) {
                                            return item.data.label.toLowerCase().includes(input.toLowerCase());
                                        } else if (item.data.isActive == this.props.itemActive) {
                                            return item.data.label.toLowerCase().includes(input.toLowerCase());
                                        }
                                    }}
                                />
                            </div>
                        </>
                        :
                        <>
                            <Select
                                className={this.props.widthClass}
                                options={accountsSelectOptions}
                                id="accountDropdown"
                                placeholder="Select an account..."
                                value={selectedAccount}
                                onChange={selectedOption => this.accountChanged(parseInt(selectedOption.value))}
                                filterOption={(item, input) => {
                                    if (this.props.itemActive == null) {
                                        return item.data.label.toLowerCase().includes(input.toLowerCase());
                                    } else if (item.data.isActive == this.props.itemActive) {
                                        return item.data.label.toLowerCase().includes(input.toLowerCase());
                                    }
                                }}
                            />
                        </>

                    }
                </>
            )
        } else {
            return null;
        }
    }
}
export default connect(
    (store) => {
        return {
            accounts: store.userContext.accounts
        }
    },
    { setAccountData })(AccountDropdown) 