import InternalReports from "./tableau-view/InternalReports";
import * as Icon from "react-feather";
import { isJSDocNullableType } from "typescript";

export const ItemTypes = {
    SECTION: "Section",
    MENU_ITEM: "Menu Item",
    TAB: "Tab",
}

export function createTreeDataFromTabData(tabItemsFlat) {

    //update items to fit treeData format 
    let tabItemsArray = tabItemsFlat.map((tabMenuItem) => {
        tabMenuItem.treeId = tabMenuItem.tabId;
        tabMenuItem.title = tabMenuItem.tabName;
        tabMenuItem.expanded = true;
        tabMenuItem.children = [];
        return tabMenuItem;
    });

    let arrMap = new Map(tabItemsArray.map(item => [item.treeId, item]));
    let tree = [];

    for (let i = 0; i < tabItemsArray.length; i++) {
        let item = tabItemsArray[i];
        
        if (item.parentId) {
            let parentItem = arrMap.get(item.parentId);

            if (parentItem) {
                let { children } = parentItem;

                if (children) {
                    parentItem.children.push(item);
                } else {
                    parentItem.children = [item];
                }
            }
        } else {
            tree.push(item);
        }
    }

    tree.forEach(section => {
        section.itemType = ItemTypes.SECTION;
        section.children.forEach(menuItem => {
            menuItem.itemType = ItemTypes.MENU_ITEM;
            menuItem.children.forEach(tab => {
                tab.itemType = ItemTypes.TAB;
            });
        });
    });

    return tree;
}

export function flattenTree(tree) {
    let flatArray = [];
    tree.forEach(section => {
        flatArray.push({ tabName: section.tabName, url: null, authGroupIds: section.authGroupIds, tabId: section.treeId, tabMenuItemType: "Default"});
        section.children.forEach(tabMenuItem => {
            flatArray.push({ tabName: tabMenuItem.tabName, url: tabMenuItem.url, authGroupIds: null, tabId: tabMenuItem.treeId, parentId: section.treeId, showToolbar: tabMenuItem.showToolbar, tabMenuItemType: "Default", permalink: tabMenuItem.permalink});
            tabMenuItem.children.forEach(tab => {
                flatArray.push({ tabName: tab.tabName, url: tab.url, authGroupIds: null, tabId: tab.treeId, parentId: tabMenuItem.treeId, showToolbar: tab.showToolbar, tabMenuItemType: "Default", permalink: tab.permalink});
            });
        });
    });
    return flatArray;
}

export function findItemIndices(tree, item) {
    let sectionIndex = null;
    let menuItemIndex = null;
    let tabIndex = null;

    switch (item.itemType) {
        case ItemTypes.SECTION:
            sectionIndex = item.treeId ? tree.findIndex(section => section.treeId == item.treeId) : -1;
            break;
        case ItemTypes.MENU_ITEM:
            sectionIndex = item.parentId ? tree.findIndex(section => section.treeId == item.parentId) : -1;
            if (sectionIndex != -1) {
                menuItemIndex = item.treeId ? tree[sectionIndex].children.findIndex(menuItem => menuItem.treeId == item.treeId) : -1;
            } else {
                menuItemIndex = -1;
            }
            break;
        case ItemTypes.TAB:
            if (item.parentId) {
                sectionIndex = tree.findIndex(section => {
                    let foundIndex = section.children.findIndex(menuItem => menuItem.treeId == item.parentId);
                    if (foundIndex != -1) {
                        menuItemIndex = foundIndex;
                        tabIndex = item.treeId ? section.children[menuItemIndex].children.findIndex(tabItem => tabItem.treeId == item.treeId) : -1;
                        return true;
                    }
                });
            } else {
                sectionIndex = -1;
            }

            if (sectionIndex == -1) {
                menuItemIndex = -1;
                tabIndex = -1;
            }
            break;
        default:
            console.error(`Unknown itemType: ${item.itemType}`)
            break;
    }
    return { sectionIndex: sectionIndex, menuItemIndex: menuItemIndex, tabIndex: tabIndex };
}

export function addToTree(tree, tabItem) {
    let treeItem = { ...tabItem };
    treeItem.treeId = Date.now().toString();
    treeItem.expanded = true;
    let newTree = [...tree];
    let { sectionIndex, menuItemIndex } = findItemIndices(newTree, treeItem);

    switch (treeItem.itemType) {
        case ItemTypes.SECTION:
            treeItem.children = [];
            newTree.push(treeItem);
            break;
        case ItemTypes.MENU_ITEM:
            if (sectionIndex != -1) {
                treeItem.children = [];
                newTree[sectionIndex].children.push(treeItem);
            } else {
                console.error(`Could not find parent item in tree with treeId ${treeItem.parentId}`);
            }
            break;
        case ItemTypes.TAB:
            if (sectionIndex != -1 && menuItemIndex != -1) {
                newTree[sectionIndex].children[menuItemIndex].children.push(treeItem);
            } else {
                console.error(`Could not find parent item in tree with treeId ${treeItem.parentId}`);
            }
            break;
        default:
            console.error(`Unknown itemType: ${treeItem.itemType}`)
            break;
    }
    return newTree;
}

export function updateInTree(tree, tabItem) {
    let treeItem = { ...tabItem };
    let newTree = [...tree];

    let { sectionIndex, menuItemIndex, tabIndex } = findItemIndices(newTree, treeItem);

    switch (treeItem.itemType) {
        case ItemTypes.SECTION:
            if (sectionIndex != -1) {
                treeItem.children = newTree[sectionIndex].children;
                newTree[sectionIndex] = treeItem;
            } else {
                console.error(`Could not find item in tree with treeId ${treeItem.treeId}`);
            }
            break;
        case ItemTypes.MENU_ITEM:
            if (sectionIndex != -1 && menuItemIndex != -1) {
                treeItem.children = newTree[sectionIndex].children[menuItemIndex].children;
                newTree[sectionIndex].children[menuItemIndex] = treeItem;
            } else {
                console.error(`Could not find item in tree with treeId ${treeItem.treeId}`);
            }
            break;
        case ItemTypes.TAB:
            if (sectionIndex != -1 && menuItemIndex != -1 && tabIndex != -1) {
                newTree[sectionIndex].children[menuItemIndex].children[tabIndex] = treeItem;
            } else {
                console.error(`Could not find item in tree with treeId ${treeItem.treeId}`);
            }
            break;
        default:
            console.error(`Unknown itemType: ${treeItem.itemType}`);
            break;
    }
    return newTree;
}

export function removeItemFromTree(tree, tabItem) {
    let newTree = [...tree];

    let { sectionIndex, menuItemIndex, tabIndex } = findItemIndices(newTree, tabItem);

    switch (tabItem.itemType) {
        case ItemTypes.SECTION:
            if (sectionIndex != -1) {
                newTree.splice(sectionIndex, 1);
            } else {
                console.error(`Could not find item in tree with treeId ${tabItem.tabItem}`);
            }
            break;
        case ItemTypes.MENU_ITEM:
            if (sectionIndex != -1 && menuItemIndex != -1) {
                newTree[sectionIndex].children.splice(menuItemIndex, 1);
            } else {
                console.error(`Could not find item in tree with treeId ${tabItem.tabItem}`);
            }
            break;
        case ItemTypes.TAB:
            if (sectionIndex != -1 && menuItemIndex != -1 && tabIndex != -1) {
                newTree[sectionIndex].children[menuItemIndex].children.splice(tabIndex, 1);
            } else {
                console.error(`Could not find item in tree with treeId ${tabItem.tabItem}`);
            }
            break;
        default:
            console.error(`Unknown itemType: ${tabItem.itemType}`)
            break;
    }

    return newTree;
}

function menuTreeDataFromTabData(tabItemsFlat) {
    let arrMap = new Map(tabItemsFlat.map(item => [item.tabId, item]));
    let tree = [];

    for (let i = 0; i < tabItemsFlat.length; i++) {
        let item = tabItemsFlat[i];

        if (item.parentId) {
            let parentItem = arrMap.get(item.parentId);

            if (parentItem) {
                let { children } = parentItem;

                if (children) {
                    let child = {
                        tabName: item.tabName,
                        parentId: item.parentId,
                        permalink: item.permalink,
                        children: item.children,
                        tabId: item.tabId,
                        url: item.url,
                    }

                    parentItem.children.push(child);
                } else {
                    parentItem.children = [item];
                }
            }
        } else {
            tree.push(item);
        }
    }
    return tree;
}

export function routesFromTabs(tabsArray) {
    
    let tabsTree = menuTreeDataFromTabData(tabsArray);
    let routes = [];
 
    tabsTree.forEach(tab => {
        let categoryNode = {
            appModuleId: "tableaus",
            path: "/tableaus/internal-reports/",
            name: tab.tabName,
            icon: Icon.TrendingUp,
            checkActiveOverride: (currentPath, { children }) => { return children && children.find(child => child.path === currentPath) !== undefined; }
        };

        categoryNode.children = [];
        
        if (tab.children) {
            let categoryChildren = [];
            tab.children.forEach(childNode => {
                let permalink = childNode.permalink === null ? childNode.tabId : childNode.permalink;
                
                categoryChildren.push({
                    tabId: childNode.tabId,
                    appModuleId: "tableaus",
                    path: "/tableaus/internal-reports/tableau-embed/" + permalink,
                    name: childNode.tabName,
                    component: InternalReports,
                    url: childNode.url,
                    permalink: permalink
                });
            })
            categoryNode.children = categoryChildren;
        }

        routes.push(categoryNode);
    });

    return routes;
}