import React from 'react';
import { withRouter } from "react-router";

import {
    Card,
    CardBody,
    Container,
    Modal,
    ModalBody,
    ModalHeader,
} from "reactstrap";

import IndustryEditForm from './IndustryEditForm';
import Loader from '../../../components/Loader'

import { handleErrorResponse } from "../../../utils/ajaxErrorHandler";
import { genericFetcherFactory } from "../../../utils/requestUtils";


class NewIndustry extends React.PureComponent {

    constructor(props) {
        super(props);
        this.state = {
            formSaving: false
        }

        this.save = this.save.bind(this);
    }


    save(industryObj) {
        this.setState({ ...this.state, formSaving: true })
        genericFetcherFactory("/api/product/Industries", "INDUSTRIES", "Failed to fetch from Industries API Service", "POST", {
            method: "POST",
            body:  JSON.stringify(industryObj),
            headers: { 'Content-Type': 'application/json' }
        })().then(res => {
            if (res.success == true) {
                this.props.history.push('/prods-accounts/industries')
            } else {
                this.setState({ ...this.state, formSaving: false });
                handleErrorResponse(res, "Save Industry Failed");
            }
        })

    }

    render() {
        return (
            <>
                <Modal isOpen={this.state.formSaving === true} centered>
                    <ModalHeader>
                        Saving Industry...
                    </ModalHeader>
                    <ModalBody className="text-center m-3">
                        <Loader />
                    </ModalBody>
                </Modal>

                <Container fluid className="p-0">
                    <h1 className="h3 mb-3">Create new industry</h1>
                    <Card>
                        <CardBody>
                            <IndustryEditForm submitCallback={this.save} />
                        </CardBody>
                    </Card>
                </Container>
            </>
        )
    }
}

export default withRouter(NewIndustry);