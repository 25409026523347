import React from "react";
import { connect } from 'react-redux';
import { Spinner } from "reactstrap";
import SlackChannelService from "./slackService";


class EntitySlackChannelsView extends React.PureComponent {

    constructor(props) {
        super(props);
        this.state = {
            entitysChannelsEntities: [],
            init: null
        };
        this.slackService = new SlackChannelService();

    }

    componentDidMount() {
        
        if (this.props.entityId) {
            let entitysChannelsEntities = this.slackService.getSelectedDetailedChannelsByEntityIdAndType(this.props.entityId, this.props.entityType, this.props.slackAlertsChannelsEntities);
            this.setState({ entitysChannelsEntities: entitysChannelsEntities, init: true });
        } else {
            this.setState({ init: false });
        }
    }

    componentDidUpdate(prevProps, prevState) {

        if (this.props.entityId && prevState.init === true) {
            let entitysChannelsEntities = this.slackService.getSelectedDetailedChannelsByEntityIdAndType(this.props.entityId, this.props.entityType, this.props.slackAlertsChannelsEntities);
            let prevChannels = prevState.entitysChannelsEntities;
            if (this.compareByChannelId(prevChannels, entitysChannelsEntities) === false) {
                this.setState({ entitysChannelsEntities: entitysChannelsEntities, init: true });
            }

        }
    }

    compareByChannelId(list11, list2) {
        let equals = true;
        if (list11.length !== list2.length) {
            equals = false;
        } else {
            let channels = list2.map(entity => { return entity.channel });
            list11.forEach(element => {
                if (channels.findIndex(channel => channel === element.channel) < 0) {
                    equals = false;
                }
            });
        }
        return equals;
    }

    render() {
        if (this.state.init === null) {
            return <Spinner color="dark" className="mr-2"></Spinner>
        } else {
            if (this.state.init === true && this.state.entitysChannelsEntities.length > 0 && this.state.entitysChannelsEntities !== undefined) {
                return (
                    this.state.entitysChannelsEntities.map(item => {
                        return (
                            <div className="grid-container mb-2" style={{ display: "grid", gridTemplateColumns: "0fr 1fr", gap: "20px" }}>
                                <div className="grid-child one">{item.icon}</div><div className="grid-child two">{item.channelName}</div>
                            </div>
                        )
                    }));
            } else {
                return (<div className="mb-2"> [No Slack channels]</div>)
            }
        }

    }
}
export default connect(
    (store) => {
        return {
            slackAlertsChannelsEntities: store.slackChannel.slackAlertsChannelsEntities,
        }
    },
    {})(EntitySlackChannelsView)