export const defaultColumnsToHide = {
    'Spend -1 Day now': false,
    'Campaign Budget': false,
    'Campaign Budget remaining': true,
    'AOV Today': false,
    "Conv Today": false,
    "CPA Today": false,
    "Spend -1 hour": false,
    "CPA -1 hour": false,
    "CPA -1 Day Now": false,
    "CPA -1 Day": false,
    "ctr": false,
    "cpm": false,
    "Conv -1 Day": false,
    "Sales BE -1 Day": false,
    "ROI -1 Day": false,
    "AOV -1 Day": false,
    "Revenue BE -1 Day": false,
    "initiate_checkout_today": false
}

export const StopLossColumnsToHide = {
    "bidAmount": false, "dailyBudget": false, ...defaultColumnsToHide
}

export const StoplossGHOnlyColumnsToHide = {
    'Spend -1 hour': false,
    'ROI -1 hour': false,
    'Spend -1 Day -1 hour': false,
    'ROI -1 Day -1 hour': false,
    "Spend -7 Days -1 hour": false,
    "ROI -7 Days -1 hour": false,
    "Conv -1 hour": false,
    "CPA -1 hour": false,
    "CPA -1 Day Now": false,
    "CPA -1 Day": false,
    "lastUpdate": false,
    "initiate_checkout_today": false
}
export const acceleratedAdsetsPageColumnsToHide = {
    'budget': false,
    'budgetRemaing': false,
}

export const acceleratedAdsetsPageColumnsOrder = ["isActive", "adsetName",  "bidAmount", "Spend Today","ROI - Today","dailyBudget", "Spend -1 hour", "ROI -1 hour","ROI -1 Day", "ROI -1 Day Now","Spend -1 Day -1 hour","ROI -1 Day -1 hour", "ROI -7 Days","Spend -7 Days -1 hour","ROI -7 Days -1 hour","Sales BE Today", "Conv Today","Conv -1 hour","cpm","ctr","AOV Today","AOV -1 Day","Revenue BE Today", "Spend -1 Day now", "Sales BE -1 Day", "Revenue BE -1 Day","CPA Today","CPA -1 hour","Conv -1 Day","initiate_checkout_today"]

const ROICalculation = ({values}) => {
  let denominator = 0, numerator = 0;
  values.forEach((value) => {
    if (value) {
      denominator += value.denominator;
      numerator += value.numerator;
    }
  });
  return numerator / (denominator*values[0].commission);
}
export const ROIAggregation = {
  getCellValue: ({ row }) => ({ commission: row.commission, denominator: row["Spend Today"], numerator: row["Revenue BE Today"]}),
  apply: ROICalculation,
  label: 'ROI',
  columnTypes: ['number'],
}

export const ROIMinus1Hour = {
  getCellValue: ({ row }) => ({ commission: row.commission, denominator: row["Spend -1 hour"], numerator: row["Revenue BE Today"] }),
  apply: ROICalculation,
  label: 'ROI -1Hour',
  columnTypes: ['number'],
}

export const ROIMinus1Day = {
  getCellValue: ({ row }) => ({ commission: row.commission, denominator: row["spend_yesterday"], numerator: row["Revenue BE -1 Day"] }),
  apply: ROICalculation,
  label: 'ROI -1Day',
  columnTypes: ['number'],
}

export const ROIMinus1DayNow = {
  getCellValue: ({ row }) => ({ commission: row.commission, denominator: row["Spend -1 Day now"], numerator: row["revenue_be_yesterday_now"] }),
  apply: ROICalculation,
  label: 'ROI -1Day Now',
  columnTypes: ['number'],
}

export const ROIMinus1DayMinus1Hour = {
  getCellValue: ({ row }) => ({ commission: row.commission, denominator: row["Spend -1 Day -1 hour"], numerator: row["revenue_be_yesterday_now"] }),
  apply: ROICalculation,
  label: 'ROI -1Day -1Hour',
  columnTypes: ['number'],
}

export const ROIMinus7Days = {
  getCellValue: ({ row }) => ({ commission: row.commission, denominator: row["spend_7day"], numerator: row["revenue_be_7day"] }),
  apply: ROICalculation,
  label: 'ROI -7Days',
  columnTypes: ['number'],
}

export const ROIMinus7DaysMinus1Hour = {
  getCellValue: ({ row }) => ({ commission: row.commission, denominator: row["Spend -7 Days -1 hour"], numerator: row["revenue_be_7day_now"] }),
  apply: ROICalculation,
  label: 'ROI -7Days -1Hour',
  columnTypes: ['number'],
}

export const CPAToday = {
  getCellValue: ({ row }) => ({ commission: 1, denominator: row["Conv Today"], numerator: row["Spend Today"] }),
  apply: ROICalculation,
  label: 'CPA Today',
  columnTypes: ['number'],
}

export const CPAMinus1Hour = {
  getCellValue: ({ row }) => ({ commission: 1, denominator: row["Conv -1 hour"], numerator: row["Spend -1 hour"] }),
  apply: ROICalculation,
  label: 'CPA -1Hour',
  columnTypes: ['number'],
}

export const CPAMinus1Day = {
  getCellValue: ({ row }) => ({ commission: 1, denominator: row["Conv -1 Day"], numerator: row["spend_yesterday"] }),
  apply: ROICalculation,
  label: 'CPA -1Day',
  columnTypes: ['number'],
}

export const CPAMinus1DayNow = {
  getCellValue: ({ row }) => ({ commission: 1, denominator: row["Conv -1 Day Now"], numerator: row["Spend -1 Day now"] }),
  apply: ROICalculation,
  label: 'CPA -1Day Now',
  columnTypes: ['number'],
}

export const CPABEToday = {
  getCellValue: ({ row }) => ({ commission: 1, denominator: row["Sales BE Today"], numerator: row["Spend Today"] }),
  apply: ROICalculation,
  label: 'CPA BE Today',
  columnTypes: ['number'],
}

export const AOVToday = {
  getCellValue: ({ row }) => ({ commission: 1, denominator: row["Sales BE Today"], numerator: row["Revenue BE Today"] }),
  apply: ROICalculation,
  label: 'AOV Today',
  columnTypes: ['number'],
}

export const AOVMinus1Day = {
  getCellValue: ({ row }) => ({ commission: 1, denominator: row["Sales BE -1 Day"], numerator: row["Revenue BE -1 Day"] }),
  apply: ROICalculation,
  label: 'AOV -1Day',
  columnTypes: ['number'],
}

export const NCRToday = {
  getCellValue: ({ row }) => ({ commission: 1, denominator: row["sales_be_today_for_ncr"], numerator: row["new_customers_today"] }),
  apply: ROICalculation,
  label: 'NCR Today',
  columnTypes: ['number'],
}

export const CPM = {
  getCellValue: ({ row }) => ({ commission: 0.001, denominator: row["impressions_today"], numerator: row["Spend Today"] }),
  apply: ROICalculation,
  label: 'CPM',
  columnTypes: ['number'],
}

export const CTR = {
  getCellValue: ({ row }) => ({ commission: 1, denominator: row["impressions_today"], numerator: row["clicks_today"] }),
  apply: ROICalculation,
  label: 'CTR',
  columnTypes: ['number'],
}