import { Button, Modal } from "react-bootstrap";
import { Platforms } from "../accountUtils";



export function FirstStep(props) {

    return (
        <Modal size={"x1"} show={props.displayDeactivateAccountPopUp} top >
            <Modal.Header>
                <p>The <strong>first</strong> step is to give permission to:</p>
            </Modal.Header>
            <Button color="info" size="small"
                style={{ position: "absolute", top: "10px", right: "10px" }}
                onClick={props.closeTrigger}>Close
            </Button>
            <Modal.Body>
                <div >
                    {!props.platformName &&
                        <p><u>Facebook</u>: Qualityscore BI, appuser, appadmin in two sections: ad accounts and pages in the facebook business manager<br></br>
                            <u>Google/Bing</u><strong>: tech@qualityscore.co</strong> and then inform the BI team about the new client details.<br></br>
                            <u>Taboola</u>: Just need to inform the BI team about the new client details.</p>}
                    {props.platformName === Platforms.FACEBOOK &&
                        <p><u>Facebook</u>: Qualityscore BI, appuser, appadmin in two sections: ad accounts and pages in the facebook business manager</p>
                    }
                    {(props.platformName === Platforms.GOOGLE || props.platformName === Platforms.BING) &&
                        <p><u>Google/Bing</u><strong>: tech@qualityscore.co</strong> and then inform the BI team about the new client details.</p>
                    }
                    {props.platformName === Platforms.TABOOLA &&
                        <p><u>Taboola</u>: Just need to inform the BI team about the new client details.</p>
                    }
                </div>
            </Modal.Body>
        </Modal >
    )
}

export function DeactivateStep(props) {
    return(
        <Modal show={props.displayDeactivateAccountPopUp === true} top >
                <Modal.Header>
                    <p>Before this account leaves us, please let us know:</p>
                </Modal.Header>
                <Button color="info" size="small"
                    style={{ position: "absolute", top: "10px", right: "10px" }}
                    onClick={props.closeTrigger}>
                    Close
                </Button>
                <Modal.Body>
                    <p> 1. Why are you deactivating it?<br></br>
                        2. Please remember to remove permissions for:<br></br>
                        <u>Facebook</u><strong>: bi.qualityscore@gmail.com</strong><br></br>
                        <u>Google/Bing</u><strong>: tech@qualityscore.co</strong><br></br>
                        <u>Taboola</u>: Just need to inform the BI team about the new client details.</p>
                </Modal.Body>
            </Modal >
    );
}

