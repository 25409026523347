import React from 'react';
import CreatableSelect from 'react-select/creatable';

import { OperandTypes } from './OperandTypes';

import {
    Button,
    ButtonGroup
} from "reactstrap";

export default class TermsInput extends React.PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            pendingInput: '',
            values: props.values ? props.values.map(val => this.createOption(val)) : [],
            operandType: props.operandType || OperandTypes.AND
        };

        this.onChange = props.onChange || (() => { });

        this.handleChange = this.handleChange.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleKeyDown = this.handleKeyDown.bind(this);
        this.handleOperandTypeChange = this.handleOperandTypeChange.bind(this);
    }

    createOption(val) {
        return { label: val, value: val };
    };

    convertOptions(opts) {
        return opts ? opts.map(opt => opt.value) : [];
    }

    handleChange(newVal, actionMeta) {
        newVal = newVal || [];
        this.setState({ values: newVal });
        this.onChange({ values: this.convertOptions(newVal), operandType: this.state.operandType });
    };

    handleInputChange(val) {
        this.setState({ errorFlag: false, pendingInput: val });
    };

    handleKeyDown(event) {
        const { pendingInput, values } = this.state;
        if (!pendingInput) return;
        switch (event.key) {
            case 'Enter':
            case ' ':
            case 'Tab':
                if (values.findIndex(item => item.value == pendingInput) != -1) {
                    this.setState({ errorFlag: true });
                } else {
                    let newValues = [...values, this.createOption(pendingInput)];
                    this.setState({
                        pendingInput: '',
                        values: newValues,
                    });

                    this.onChange({ values: this.convertOptions(newValues), operandType: this.state.operandType });
                }

                event.preventDefault();
        }
    };

    handleOperandTypeChange(val) {
        this.setState({ operandType: val });
        this.onChange({ values: this.convertOptions(this.state.values), operandType: val });
    }

    render() {
        return (
            <div style={{ width: "400px" }}>
                <div className="mb-2">
                    {this.props.label}
                </div>
                <ButtonGroup color="primary" className="mb-2">
                    <Button color={"primary"} outline={this.state.operandType != OperandTypes.AND} onClick={() => this.handleOperandTypeChange(OperandTypes.AND)}>And</Button>
                    <Button color={"primary"} outline={this.state.operandType != OperandTypes.OR} onClick={() => this.handleOperandTypeChange(OperandTypes.OR)}>Or</Button>
                </ButtonGroup>
                <CreatableSelect
                    components={{ DropdownIndicator: null }}
                    inputValue={this.state.pendingInput}
                    isClearable
                    isMulti
                    menuIsOpen={false}
                    onChange={this.handleChange}
                    onInputChange={this.handleInputChange}
                    onKeyDown={this.handleKeyDown}
                    placeholder="Add a list of terms..."
                    value={this.state.values}
                />
                {this.state.errorFlag && <span style={{ color: "red" }}>* Term already exists</span>}
            </div>
        );
    }
}
