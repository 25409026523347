import React from "react";
import BootstrapTable from "react-bootstrap-table-next";
import cellEditFactory, { Type } from 'react-bootstrap-table2-editor';
import filterFactory, { textFilter } from 'react-bootstrap-table2-filter';
import 'react-bootstrap-table2-filter/dist/react-bootstrap-table2-filter.min.css';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import * as Icon from "react-feather";
import { toastr } from "react-redux-toastr";
import {
    Button, Card,
    Container, Form, Modal, OverlayTrigger, Popover, Tooltip,Spinner
} from "react-bootstrap";
// import {
//     Button, Card,
//     CardBody,
//     CardHeader,
//     CardTitle,
//     Container, CustomInput, Modal,
//     ModalBody,
//     ModalHeader, PopoverBody, Spinner, UncontrolledPopover, UncontrolledTooltip
// } from "reactstrap";
import { addHours } from "../../../utils/dateUtils";
import { genericCachedFetcherFactory, genericFetcherFactory } from "../../../utils/requestUtils";
import CountryDisplaySelectionHelper from "./countryDisplaySelectionHelper";

export default class CountryDisplaySelections extends React.PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            countryDisplaySelections: [],
            dataLoading: false,
            accountProducts: [],
            newCountDispSelcNumber: 1,
            countryFilterSaving: false
        }
        this.helper = new CountryDisplaySelectionHelper();
        this.processData = this.processData.bind(this);

        this.toggleIsVisible = this.toggleIsVisible.bind(this);
        this.updateRows = this.updateRows.bind(this);

        this.SaveCountryDisplaySelection = this.SaveCountryDisplaySelection.bind(this);
        this.revertRowChanges = this.revertRowChanges.bind(this);
        this.saveAllChanges = this.saveAllChanges.bind(this);

        this.fetchCountries = this.fetchCountries.bind(this);
    }

    componentDidMount() {
        this.fetchCountries()
    }

    // Init and fetchers

    async fetchCountries() {

        this.setState({ dataLoading: true });

        let responseData = await genericFetcherFactory(`/api/facebook-interface/CountrySelection/`, "COUNTRY_SELECTION",
            "Failed to fetch countries")().then(result => {
                if (result.success === true) {
                    return result.data;
                } else {
                    return [];
                }
            });

        let countryDisplaySelections = [];
        let accountProducts = [];
        if (responseData.length > 0) {
            [accountProducts] = await Promise.all([
                this.fetchProducts()
            ]);
            countryDisplaySelections = this.processData(responseData, accountProducts);
        }

        let newCountDispSelcNumber = countryDisplaySelections.length > 0 ? countryDisplaySelections.length + 1 : 1;
        
        this.setState({ countryDisplaySelections: countryDisplaySelections, accountProducts: accountProducts, dataLoading: false, newCountDispSelcNumber: newCountDispSelcNumber });
    }

    async fetchProducts() {
        return genericCachedFetcherFactory(`/api/product/Products`, "PRODUCTS", "Failed to fetch PRODUCTS data", addHours(1))().then(result => {
            if (result.success === true) {
                return result.data;
            } else {
                return [];
            }
        });
    }

    // Entity Functions

    revertRowChanges(rowItem) {
        let newCountryDisplaySelections = [...this.state.countryDisplaySelections];
        let index = newCountryDisplaySelections.findIndex(_test => _test.key == rowItem.key);
        if (index != -1) {
            let pristineCopy = { ...rowItem.pristineState };
            let pristineItem = { ...rowItem.pristineState };
            pristineItem.pristineState = pristineCopy;
            newCountryDisplaySelections.splice(index, 1, pristineItem);
            this.setState({ countryDisplaySelections: newCountryDisplaySelections });
        }
    }

    SaveCountryDisplaySelection(counteyDisplaySelection) {
        this.setState({ countryFilterSaving: true });
        genericFetcherFactory(`/api/facebook-interface/CountrySelection`, "UPDATE_COUNTRY", "Failed to save country", "PATCH", {
            method: "PATCH",
            body: JSON.stringify(counteyDisplaySelection),
            headers: { "Content-Type": "application/json" }
        })().then(result => {
            let state = { countryFilterSaving: false };
            if (result.success === true) {
                let saved = this.processCountryDispRowData(result.data, this.state.accountProducts);
                let countryDisplaySelections = [...this.state.countryDisplaySelections];
                let index = countryDisplaySelections.findIndex(item => item.key == saved.key);
                if (index != -1) {
                    toastr.success("Country Display selection saved successfully");
                    countryDisplaySelections.splice(index, 1, saved);
                    state.countryDisplaySelections = countryDisplaySelections;
                } else {
                    toastr.warn("Country was saved but view cannot be updated due to an error. Please refresh the page");
                }
            }
            this.setState({ ...state });
        });
    }

    saveAllChanges() {

        this.setState({ countryFilterSaving: true });
        let countiesList = this.state.countryDisplaySelections.filter(country => country.isChanged === true);

        if (countiesList.length < 0) {
            toastr.warn("No pending changes to save");
            this.setState({ countryFilterSaving: false });
        } else {

            genericFetcherFactory(`/api/facebook-interface/CountrySelection/bulkUpdate`, "UPDATE_BULK_COUNTRIES", "Failed to save all countries", "PATCH",
                {
                    method: "POST",
                    body: JSON.stringify(countiesList),
                    headers: { "Content-Type": "application/json" }
                })().then(result => {
                    let state = { countryFilterSaving: false };
                    if (result.success === true) {
                        let itemsToUpdate = this.state.countryDisplaySelections.filter(test => test.isChanged === true);
                        let state = { countryFilterSaving: false };
                        let savedItems = itemsToUpdate.map(item => this.processCountryDispRowData(item, this.state.accountProducts));

                        let failedToReplace = [];
                        let newUpdatedRows = [...this.state.countryDisplaySelections];

                        savedItems.forEach(row => {
                            row.isChanged = false;
                            let index = newUpdatedRows.findIndex(item => item.key == row.key);
                            if (index != -1) {
                                newUpdatedRows.splice(index, 1, row);
                            } else {
                                failedToReplace.push(row);
                            }
                        });

                        if (failedToReplace.size > 0) {
                            toastr.warn("The view could not be properly updated. Check the console for details and please refresh the page to avoid inconsistency.");
                            console.log(failedToReplace);
                        }

                        state.countryDisplaySelections = newUpdatedRows;
                        this.setState({ ...state });
                    }
                });
        }
    }

    // Table config

    columns = [
        {
            dataField: "countryId", text: "Country", headerStyle: { width: "125px" }, sort: true, editable: false, filter: textFilter({ placeholder: '', style: { width: "80%" } })
        },
        {
            dataField: "productName", text: "Product", headerStyle: { width: "125px" }, sort: true, editable: false, filter: textFilter({ placeholder: '', style: { width: "80%" } }),
            editor: { type: Type.TEXT }
        },
        {
            dataField: "platform", text: "Platform", headerStyle: { width: "125px" }, sort: true, editable: false, filter: textFilter({ placeholder: '', style: { width: "80%" } })
        },
        {
            dataField: "startDate", text: "Start Date", sort: true, type: "date", style: { cursor: "pointer" }, headerStyle: { width: "150px" },
            editorRenderer: (editorProps, value, row, column, rowIndex, columnIndex) => (
                <DatePicker
                    dateFormat="dd/MM/yyyy"
                    selected={value}
                    onChange={date => editorProps.onUpdate(date)}
                    isInputFocused />),
            formatter: (cell) => cell ? cell.toLocaleDateString('en-GB') : ""
        },
        {
            dataField: "isVisible", text: "Visible", sort: true, headerStyle: { width: "75px" }, editable: false, style: { cursor: "pointer" },
            formatter: (cell, row, rowIndex) => this.checkboxFormatter(cell, row, rowIndex, { toggleChecked: this.toggleIsVisible })
        },
        {
            dataField: "comment", text: "Comment", sort: false, headerStyle: { width: "65px" }, align: "center",
            editorRenderer: (editorProps, value) => {
                let ref = React.createRef();
                // The cancel button returns the original value since the bootstrap table API doesn't provide a "cancel" functionality
                return (
                    <Modal
                        show={true}
                        centered
                    >
                        <Modal.Header>
                            Comment
                        </Modal.Header>
                        <Modal.Body className="text-center m-3">
                            <textarea style={{ width: "100%", minHeight: "100px" }} defaultValue={value || ""} ref={ref} />
                            <div className="mt-4">
                                <Button className="mr-2" onClick={() => editorProps.onUpdate(ref.current.value)}>Set Comment</Button>
                                <Button onClick={() => editorProps.onUpdate(value != null ? value : "")}>Cancel</Button>
                            </div>
                        </Modal.Body>
                    </Modal>
                )
            },
            formatter: (cell, row) => this.commentFormatter(cell, row)
        },
        {
            editable: false, isDummyField: true, headerStyle: { width: "80px", textAlign: "center" }, align: "center",
            formatter: (cell, row) => this.actionsFormatter(
                cell, row, { SaveCountryDisplaySelection: this.SaveCountryDisplaySelection, revertRowChanges: this.revertRowChanges }
            )
        }
    ];

    commentFormatter(cell, row) {
        let commentIconId = "commentIcon-" + row.key;

        const commentPoper = (
            <Popover placement="bottom" target={commentIconId} trigger="hover">
                <Popover.Body>
                    {cell}
                </Popover.Body>
            </Popover >
        )

        if (cell != null && cell.length > 0) {
            return (
                <OverlayTrigger trigger="hover" placement="bottom" overlay={commentPoper}>
                    <Icon.FileText id={commentIconId} style={{ cursor: "pointer" }} className="feather mr-2" />

                </OverlayTrigger>
            );
        } else {
            return (
                <OverlayTrigger placement="top" key={"ot-" + commentIconId} overlay={
                    <Tooltip id={"ot-" + commentIconId}>
                        No comment
                    </Tooltip >
                }>
                    <Icon.File id={commentIconId} style={{ cursor: "pointer" }} color="lightgrey" className="feather mr-2" />

                </OverlayTrigger>
            )
        }
    }

    actionsFormatter(cell, row, { SaveCountryDisplaySelection, revertRowChanges }) {
        let saveFormatterId = "saveIcon-" + row.key;
        let undoFormatterId = "undoIcon-" + row.key;
        return (
            <div tabindex="-1">
                <OverlayTrigger placement="top" key={"ot-" + saveFormatterId} overlay={
                    <Tooltip placement="top" id={"ot-" + saveFormatterId}>
                        {row.isChanged ? "Save" : "No changes made"}
                    </Tooltip>
                }>
                    <Icon.Save id={saveFormatterId} style={{ cursor: "pointer" }} className="feather mr-2" color={row.isChanged ? "black" : "lightgrey"}
                        onClick={row.isChanged ? () => { SaveCountryDisplaySelection(row) } : null} />
                </OverlayTrigger>

                <OverlayTrigger placement="top" key={"ot-" + undoFormatterId} overlay={
                    <Tooltip placement="top" id={"ot-" + undoFormatterId}>
                        {row.isChanged ? "Undo Changes" : "No changes made"}
                    </Tooltip>
                }>
                    <Icon.RotateCcw id={undoFormatterId} style={{ cursor: "pointer" }} className="feather mr-2" color={row.isChanged ? "black" : "lightgrey"}
                        onClick={row.isChanged ? () => { revertRowChanges(row) } : null} />
                </OverlayTrigger>
            </div>
        )
    }

    checkboxFormatter(cell, row, index, { toggleChecked }) {
        return <Form.Check
            type="switch"
            id={`visible-${row.key}`}
            name={`visible-${row.key}`}
            checked={row.isVisible}
            onChange={event => toggleChecked(event.target.checked, row)}
        />
    }

    // Table callbacks

    toggleIsVisible(checked, row) {
        let newCountries = [...this.state.countryDisplaySelections];
        let rowIndex = newCountries.findIndex(test => test.key == row.key);
        if (rowIndex != -1) {
            let updatedAbTest = { ...newCountries[rowIndex], isVisible: checked, isChanged: true };
            newCountries.splice(rowIndex, 1, updatedAbTest);
            this.setState({ countryDisplaySelections: newCountries });
        }
    }

    updateRows(countryDisplaySelections) {
        this.setState({ countryDisplaySelections: countryDisplaySelections });
    }

    cellSaved(oldValue, newValue, row, column, { state, updateRows, getProductName }) {
        if (oldValue != newValue) {
            let countryDisplaySelections = state.countryDisplaySelections;
            let index = countryDisplaySelections.findIndex(test => test.key == row.key);
            if (index !== -1) {
                let country = { ...countryDisplaySelections[index], isChanged: true };
                countryDisplaySelections.splice(index, 1, country);

                updateRows(countryDisplaySelections);
            }
        }
    }

    // Logic

    processData(countryDisplaySelections, accountProducts) {
        let processed = countryDisplaySelections.map(countryDisplaySelection => {
            return this.processCountryDispRowData(countryDisplaySelection, accountProducts);
        });

        return processed;
    }

    processCountryDispRowData(countryDisplaySelection, accountProducts) {
        let key = this.helper.generateKey(countryDisplaySelection);
        let productName = this.helper.getProductName(countryDisplaySelection.productId, accountProducts);
        let parsedStartDate = countryDisplaySelection.startDate ? new Date(countryDisplaySelection.startDate) : null;
        let pristineState = { ...countryDisplaySelection, key: key, startDate: parsedStartDate, productName: productName };
        return {
            ...countryDisplaySelection,
            pristineState: pristineState,
            key: key,
            startDate: parsedStartDate,
            productName: productName,
        }
    }

    // Render
    render() {
        let hasChangedTests = this.state.countryDisplaySelections.find(element => element.isChanged === true) !== undefined;
        return (
            <Container fluid className="p-0">
                <Modal
                    isOpen={this.state.countryFilterSaving === true}
                    centered
                >
                    <Modal.Header>
                        Saving Country Display Selection...
                    </Modal.Header>
                    <Modal.Body className="text-center m-3">
                        <Spinner color="dark" className="mr-2" />
                    </Modal.Body>
                </Modal>

                <Card>
                    <Card.Header>
                        <Card.Title tag="h5" className="mb-0">
                            Country Display Selection
                        </Card.Title>
                    </Card.Header>
                    <Card.Body>
                        <div className="mb-4" style={{ display: "flex", justifyContent: "space-between" }}>
                            <Button disabled={hasChangedTests == false} className="submit-btn" color="primary" onClick={this.saveAllChanges}>Save All</Button>
                        </div>
                        {this.state.dataLoading == true &&
                            <Spinner color="dark" className="mr-2" />
                        }
                        <BootstrapTable
                            headerClasses="sticky"
                            bootstrap4
                            keyField="key"
                            bordered={false}
                            striped
                            hover
                            data={this.state.countryDisplaySelections ? this.state.countryDisplaySelections : []}
                            columns={this.columns}
                            rowClasses={(row) => { return row.isChanged ? "row-pending-changes" : "" }}
                            filter={filterFactory()}
                            cellEdit={cellEditFactory({
                                mode: 'click',
                                blurToSave: true,
                                afterSaveCell: (oldValue, newValue, row, column) =>
                                    this.cellSaved(
                                        oldValue, newValue, row, column,
                                        {
                                            state: { countryDisplaySelections: [...this.state.countryDisplaySelections] },
                                            updateRows: this.updateRows,
                                            getProductName: this.helper.getProductName
                                        })
                            })
                            }
                        />
                        <Button disabled={hasChangedTests == false} className="submit-btn mt-4" color="primary" onClick={this.saveAllChanges}>Save All</Button>
                    </Card.Body>
                </Card>
            </Container>
        )


    }
}