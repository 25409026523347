import * as types from "../constants";

export function SetSlackChannelsDetailsList(slackChannelsDetailsList) {
    return {
        type: types.SET_CHANNEL_DETAILS_LIST,
        payload: slackChannelsDetailsList
    }
}

export function SetSlackAlertsChannelsEntities(slackAlertsChannelsEntities) {
    return {
        type: types.SET_ALERTS_CHANNELS_ENTITIES,
        payload: slackAlertsChannelsEntities
    }
}