import { EditorState, convertToRaw, ContentState } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';

export function convertToEditor(htmlContent){
    let blocksFromHtml = htmlToDraft(htmlContent);
    let { contentBlocks, entityMap } = blocksFromHtml;
    let contentState = ContentState.createFromBlockArray(contentBlocks, entityMap);
    let editorState = EditorState.createWithContent(contentState);

    return editorState;
};

export function convertToHtml(editorContent){
    return draftToHtml(convertToRaw(editorContent));
};