import { toastr } from "react-redux-toastr";
import CacheController from "../services/cache/CacheController";

const fetcherPrefix = "reqUrl_";

export function getCacheKeyForUrl(fetchUrl) {
    return fetcherPrefix + fetchUrl;
}

export function handleErrorResponse(res, titleMessage, popToaster = true) {
    if (!titleMessage || titleMessage.length < 1) {
        titleMessage = "Action Failed";
    }
    let fullMessage = titleMessage;

    res.json().then(errorResponse => {
        let errorMessage = "Error " + res.status + ". " + errorResponse.response;
        if (popToaster) {
            toastr.error(titleMessage, errorMessage);
        }
        fullMessage = titleMessage + ". " + errorMessage;
    }).catch(reason => {
        console.error(reason);
        let errorMessage = "Error " + res.status + ". Unknown error.";
        if (popToaster) {
            toastr.error(titleMessage, errorMessage);
        }
        fullMessage = titleMessage + ". " + errorMessage;
    })

    return fullMessage;
}

export function genericFetcherFactory(fetchUrl, fetchObjectType, errorMessage, httpMethod = "GET", requestPayload = null, doHandleErrorResponse = true, popToaster = true) {
    let envPrefix = "";
        if (process.env.NODE_ENV === "development") {
            envPrefix = "https://localhost:8050";
        }

    return async () => {
        let response;
        let combinedUrl = envPrefix + fetchUrl;
        switch (httpMethod) {
            case "GET":
                response = await fetch(combinedUrl);
                break;
            case "POST":
                if (requestPayload == null) {
                    requestPayload = {
                        method: "POST",
                        body: null
                    }
                }
                response = await fetch(combinedUrl, requestPayload);
                break;
            case "PATCH":
            case "PUT":
                response = await fetch(combinedUrl, requestPayload);
                break;
            case "DELETE":
                response = await fetch(combinedUrl, requestPayload);
                break;
            default:
                console.error("Unsupported httpMethod: " + httpMethod)
                return Promise.error();
        }

        if (response.ok) {
            let responseData = await response.json();
            return Promise.resolve({ fetchObject: fetchObjectType, success: true, data: responseData });
        } else {
            let errorText = errorMessage;
            if (doHandleErrorResponse === true) {
                errorText = handleErrorResponse(response, errorMessage, popToaster);
            }
            return Promise.resolve({ fetchObject: fetchObjectType, success: false, message: errorText, responseObject: response });
        }
    };
}

export function genericCachedFetcherFactory(fetchUrl, fetchObjectType, errorMessage, expiration, httpMethod = "GET", requestPayload = null, doHandleErrorResponse = true, popToaster = true) {
    return async () => {
        let key = getCacheKeyForUrl(fetchUrl);
        if (CacheController.hasKey(key)) {
            return Promise.resolve({ fetchObject: fetchObjectType, success: true, data: CacheController.getObject(key) });
        } else {
            return genericFetcherFactory(fetchUrl, fetchObjectType, errorMessage, httpMethod, requestPayload, doHandleErrorResponse, popToaster)().then((result) => {
                if (result.success === true) {
                    CacheController.putObject(key, result.data, expiration);
                    return Promise.resolve(result);
                } else {
                    return Promise.resolve(result);
                }
            })
        }
    }
}