import React from 'react';
import { Modal, Container, Card } from "react-bootstrap";
import ContactEditForm from './ContactEditForm';

import Loader from '../../../components/Loader'

import { withRouter } from "react-router";

import { handleErrorResponse } from "../../../utils/ajaxErrorHandler";
import { genericFetcherFactory } from "../../../utils/requestUtils";

class NewContact extends React.PureComponent {

    constructor(props) {
        super(props);
        this.state = {
            formSaving: false
        }

        this.upload = this.upload.bind(this);
    }

    upload(contactData) {
        this.setState({ ...this.state, formSaving: true })
        genericFetcherFactory("/api/crm/Contacts", "CONTACTS", "Failed to fetch from Contacts API Service", "POST", {
            method: "POST",
            body:  JSON.stringify(contactData),
            headers: { 'Content-Type': 'application/json'}
        })().then(res => {
            if (res.success == true) {
                this.props.history.push('/crm/contacts')
            } else {
                this.setState({ ...this.state, formSaving: false });
                handleErrorResponse(res, "Save Contact Failed");
            }
        })

    }

    render() {
        return (
            <>
                <Modal show={this.state.formSaving === true} centered size="lg">
                        <Modal.Header>
                        Saving Contacts...
                        </Modal.Header>
                        <Modal.Body className="text-center m-3">
                            <Loader width="wide" />
                        </Modal.Body>
                    </Modal>

                <Container fluid className="p-0">
                    <h1 className="h3 mb-3">Create New Contact</h1>
                    <Card>
                        <Card.Body>
                            <ContactEditForm submitCallback={this.upload} />
                        </Card.Body>
                    </Card>
                </Container>
            </>
        )
    }

}

export default withRouter(NewContact);