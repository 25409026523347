import React from "react";

import { Modal, Container, Card } from "react-bootstrap";
import BootstrapTable from "react-bootstrap-table-next";

import * as Icon from "react-feather";

import Loader from '../../../components/Loader';
import BrandEditForm, { FormModes } from './BrandEditForm';

import { toastr } from "react-redux-toastr";
import { handleErrorResponse } from "../../../utils/ajaxErrorHandler";
import GenericErrorAlert from "../../../components/GenericErrorAlert";
import { genericFetcherFactory } from "../../../utils/requestUtils";

export default class Brands extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = { brands: [], initSuccess: null };
        this.updateBrand = this.updateBrand.bind(this);
    }

    updateBrand(formData, brandId) {
        this.setState({ ...this.state, formSaving: true })

        genericFetcherFactory("/api/crm/Brands/Brand/" + brandId, "BRANDS", "Failed to update Brands", "PATCH",
        {
            method: "PATCH",
            body: formData
        })().then((res) => {
            if (res.success) {

                let updatedBrand =  res.data;
                updatedBrand.logo = updatedBrand.logo + "&refresh=" + Date.now().toString();

                let newBrands = [...this.state.brands];
                let brandIndex = newBrands.findIndex(item => (item.brandId === brandId));
                newBrands.splice(brandIndex, 1, this.normalizeBrand(updatedBrand));

                this.setState({ ...this.state, brands: newBrands, formSaving: false });
                toastr.success("Update Success", updatedBrand.brandName + " was updated successfully.")
                
            } else {
                this.setState({ ...this.state, formSaving: false });
                handleErrorResponse(res, "Failed to Update Brands");
            }
        }).catch(error => {
            console.log(error);
        });

    }

    expandRow = {
        renderer: row => {
            return (
                <div className="ms-3 me-3 mt-3 mb-3">
                    <BrandEditForm mode={FormModes.EDIT} brandData={{ ...row, imgUrl: "/getImage?key=" + row.logo}} submitCallback={this.updateBrand} />
                </div>
            )
        },
        expandColumnRenderer: ({ expanded }) => {
            if (expanded) {
                return (
                    <Icon.Edit className="feather align-middle" />
                );
            }
            return (
                <Icon.Edit2 className="feather align-middle" />
            );
        },
        expandHeaderColumnRenderer: ({ isAnyExpands }) => {
            return "";
        },
        showExpandColumn: true,
        expandByColumnOnly: true,
        expandColumnPosition: 'right'
    };

    imageFormatter(cell, row, rowIndex, formatExtraData) {
        return <img style={{ maxHeight: "45px", maxWidth: "45px" }} src={"/getImage?key=" + cell} />
        // return <img style={{ maxHeight: "45px", maxWidth: "45px" }} src={"https://www.nbn.org.il/jobboard/wp-content/uploads/2019/02/Quality-Score-Logo.png"} />
    }

    brandsColumns = [
        {
            dataField: "brandId",
            text: "ID",
            headerStyle: { width: "30px" },
        },
        {
            dataField: "logo",
            text: "",
            formatter: this.imageFormatter,
            headerStyle: { width: "45px" },
            align: "center"
        },
        {
            dataField: "brandName",
            text: "Brand Name",
            sort: true
        },
        {
            dataField: "legalCompanyName",
            text: "Legal Company Name",
            sort: true
        },
        {
            dataField: "vatNumber",
            text: "VAT Number",
            sort: true
        },
        {
            dataField: "nameOnInvoice",
            text: "Name on Invoice",
            sort: true
        },
        {
            dataField: "billingAddress",
            text: "Billing Address",
            sort: true
        },
        {
            dataField: "billingEmail",
            text: "Billing Email",
            sort: true
        },
        {
            dataField: "startDayOfWeek",
            text: "Start Day Of Week",
            sort: true
        },
        {
            dataField: "clientName",
            text: "Client Name",
            sort: true
        },
        {
            dataField: "userLogin",
            text: "User Login",
            sort: true
        },
        {
            dataField: "contactName",
            text: "Contact Name",
            sort: true,
        }
    ]

    normalizeBrand(brandObj) {
        let brand = {};

        brand.brandId = brandObj.brandId;
        brand.brandName = brandObj.brandName;
        brand.legalCompanyName = brandObj.legalCompanyName;
        brand.vatNumber = brandObj.vatNumber;
        brand.nameOnInvoice = brandObj.nameOnInvoice;
        brand.billingAddress = brandObj.billingAddress;
        brand.billingEmail = brandObj.billingEmail;
        brand.logo = brandObj.logo;
        brand.startDayOfWeek = brandObj.startDayOfWeek;
        brand.clientId = brandObj.client.clientId;
        brand.clientName = brandObj.client.clientName;
        brand.contactId = brandObj.contact.contactId;
        brand.contactName = brandObj.contact.contactName;
        brand.crsUserId = brandObj.crsUserId;
        brand.userLogin = this.state.users.find((user) => user.userId == brandObj.crsUserId).userLogin;

        return brand;
    }

    fetchBrands() {

        genericFetcherFactory("/api/crm/Brands", "BRANDS", "Failed to fetch from Brands API Service")().then(res => {
            if (res.success == true) {
                let brands =  res.data;
                let normalizedBrands = [];

                brands.forEach(brand => {
                    normalizedBrands.push(this.normalizeBrand(brand));
                });
                
                this.setState({ ...this.state, brands: normalizedBrands });
            } else {
                this.setState({ ...this.state, initSuccess: false });
                handleErrorResponse(res, "Failed to fetch Brands");
            }
        })
    }

    componentDidMount() {

        genericFetcherFactory("/api/users/Users", "USERS", "Failed to fetch from Users API Service")().then(res => {
            if (res.success == true) {
                let result =  res.data;
                this.setState({ ...this.state, users: result, initSuccess: true });
                this.fetchBrands();
            } else {
                this.setState({ ...this.state, initSuccess: false })
                handleErrorResponse(res, "Failed to fetch users");
            }
        })

    }

    render() {
        if (this.state.initSuccess === true) {
            return (
                <Container fluid className="p-0">
                    <Modal show={this.state.formSaving === true} centered size="lg">
                        <Modal.Header>
                        Saving Brand...
                        </Modal.Header>
                        <Modal.Body className="text-center m-3">
                            <Loader width="wide" />
                        </Modal.Body>
                    </Modal>
                    <Card>
                        <Card.Header>
                            <Card.Title tag="h5" className="mb-0">
                                Brands
                            </Card.Title>
                        </Card.Header>
                        <Card.Body>
                            <BootstrapTable
                                bootstrap4
                                keyField="brandId"
                                bordered={false}
                                striped
                                hover
                                data={this.state.brands ? this.state.brands : []}
                                columns={this.brandsColumns}
                                expandRow={this.expandRow}
                            />
                        </Card.Body>
                    </Card>
                </Container>
            )
        } else if (this.state.initSuccess === false) {
            return (
                <GenericErrorAlert />
            );
        } else {
            return (
                <Card>
                    <Card.Header>
                        <Card.Title tag="h5" className="mb-0">
                            Brands
                        </Card.Title>
                    </Card.Header>
                    <Loader width="wide" />
                </Card>
            );
        }
        
    }
}