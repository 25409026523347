import React from "react";
import { UncontrolledAlert } from "reactstrap";
import * as Icon from "react-feather";

const GenericErrorAlert = ({ errorMessage }) => {
    let slackUrl = "https://qualityscore.slack.com/archives/CNJ3F477H";

    if (!errorMessage || errorMessage.length < 1) {
        errorMessage = <><strong>An error occured, please try again.</strong> If the error persists please contact <a href={slackUrl} target="_blank">Tech</a>.</>;
    }

    return (
        <UncontrolledAlert color={"danger"}>
            <div className="alert-icon">
                <Icon.AlertOctagon className="feather align-middle" />
            </div>
            <div className="alert-message">
                {errorMessage}
            </div>
        </UncontrolledAlert>
    )
}

export default GenericErrorAlert;