export function calculateDaysRemaining(adsetWeekdays, adsetEndTime){
    const msInDay = 1000 * 60 * 60 * 24;
    const msInWeek = msInDay * 7;
    const FullWeekArrayLength = 7;
    let now = new Date();
    let endDate = new Date(adsetEndTime);
    let adsetActiveDaysLeftCounter = 0;
    let adsetWeekdaysArray = getWeekdaysSchedulingArray(adsetWeekdays);
    if(adsetWeekdaysArray.length == 7){//no need to make any special calculation
        adsetActiveDaysLeftCounter = Math.ceil(Math.abs(endDate - now) / msInDay);
    }else{
        let fullWeeksBetweenDates = Math.floor(Math.abs(endDate - now) / msInWeek);//we count the number of full weeks within that period,
        let activeDaysForFullWeeks = adsetWeekdaysArray.length * fullWeeksBetweenDates;// and we can multiply that by the number of days in a week the adset is active on
        adsetActiveDaysLeftCounter += activeDaysForFullWeeks;
        endDate.setDate(endDate.getDate() - (FullWeekArrayLength * fullWeeksBetweenDates));//now we can just calculate for the remaining days(up to 6)

        for (let d = now; d <= endDate; d.setDate(d.getDate() + 1)) {
            if(adsetWeekdaysArray.includes(d.getDay())){
                adsetActiveDaysLeftCounter++;
            }
        }
    }

    return adsetActiveDaysLeftCounter;
}

export function isTestAdset(adsetName){
    return adsetName.includes("-test_");
}

export function isDailyAdset(adsetWeekdays){
    return adsetWeekdays ? JSON.parse(adsetWeekdays) == null : true;
}

export function shouldAdsetRunToday(adsetWeekdays){
        
    let adsetWeekdaysArray = getWeekdaysSchedulingArray(adsetWeekdays);
    return adsetWeekdaysArray.includes(new Date().getDay());
}

export function getStatusByEnum(adsetStatus) {
    if (adsetStatus !== 'PAUSED') {
        return true;
    } else {
        return false;
    }
}

function getWeekdaysSchedulingArray(adsetWeekdays){
    const weeklyArray = [0, 1, 2, 3, 4, 5, 6];
    return JSON.parse(adsetWeekdays) == null ? weeklyArray : JSON.parse(adsetWeekdays);
}
