import React from 'react';
import * as Yup from "yup";
import { Formik } from "formik";
import { toastr } from "react-redux-toastr";
import { Button, Container, Form, Row } from "react-bootstrap";
import { handleErrorResponse } from '../../../utils/ajaxErrorHandler';
import { genericFetcherFactory } from '../../../utils/requestUtils';

const FormModes = {
    NEW: "new",
    EDIT: "edit"
}

export { FormModes };

export default class ProductEditForm extends React.PureComponent {
    constructor(props) {
        super(props);
        this.sumbitForm = this.sumbitForm.bind(this);
        this.state = { brands: [{ brandId: 0, brandName: "Select Brand" }] }
    }

    componentDidMount() {
        (async () => {

            await genericFetcherFactory("/api/crm/Brands", "BRANDS", "Failed to fetch from Brands API Service")().then(res => {
                if (res.success == true) {
                    let result = res.data;
                    this.setState({ ...this.state, brands: [{ brandId: 0, brandName: "Select Brand" }, ...result] });
                } else {
                    this.setState({ ...this.state, initSuccess: false })
                    handleErrorResponse(res, "Failed to fetch Brands");
                }
            })

            await genericFetcherFactory("/api/product/Industries", "INDEUSTRIES", "Failed to fetch from Industries API Service")().then(res => {
                if (res.success == true) {
                    let result = res.data;
                    this.setState({ ...this.state, industries: [{ industryId: -1, industryName: "Select Industry" }, { industryId: 0, industryName: "No Industry" }, ...result] });
                } else {
                    this.setState({ ...this.state, initSuccess: false })
                    handleErrorResponse(res, "Failed to fetch Industries");
                }
            })

        })();

    }

    /**
     * Does various validation, data manipulation and runs the callback provided on component's submitCallback function
     * with a FormData object constructed from the form.
     * @param {*} event 
     * @param {*} errors 
     * @param {*} values 
     */
    sumbitForm(values) {
        // Logic for when adding a new product
        if (this.props.mode == FormModes.NEW) {
            // All data will exist
            this.props.submitCallback(
                {
                    productName: values.productName,
                    productSummary: values.productSummary,
                    brandId: parseInt(values.brandId),
                    industryId: parseInt(values.industryId)
                }
            );
        }
        // Logic for when editing an existing product
        else if (this.props.mode == FormModes.EDIT) {
            let changeMade = false;

            let productData = {};

            // Iterate through the form data
            for (let prop in values) {
                // TODO: Change this check to use an object defition rather than relying on the components properties
                if (this.props.productData[prop] !== undefined) {
                    // Check if the field was edited
                    if (values[prop] != this.props.productData[prop]) {
                        changeMade = true;
                        productData[prop] = values[prop];
                    }
                } else {
                    console.warn("field " + prop + " doesn't exist");
                }
            }

            if (productData.brandId) {
                productData.brandId = parseInt(productData.brandId);
            }

            if (productData.industryId) {
                productData.industryId = parseInt(productData.industryId);
            }

            if (changeMade === true) {
                // Append the productId for completion
                productData["productId"] = this.props.productData.productId;
            }

            // Finally check if there was actually a change made before proceeding to the callback
            if (changeMade === false) {
                toastr.warning("Cannot save product", "No changes were made");
                return;
            } else {
                this.props.submitCallback(productData, this.props.productData.productId);
            }
        }
    }

    render() {
        let brandsSelectOptions = "";
        if (this.state.brands) {
            brandsSelectOptions = this.state.brands.map(item => <option value={item.brandId}>{item.brandName}</option>);
        }

        let industrySelectOptions = "";
        if (this.state.industries) {
            industrySelectOptions = this.state.industries.map(item => <option value={item.industryId}>{item.industryName}</option>);
        }

        return (
            <div>
                <Formik
                    initialValues={this.props.productData}
                    validationSchema={Yup.object().shape({
                        productName: Yup.string().required(),
                        brandId: Yup.number().moreThan(0, 'Brand is required').required('Brand is required'),
                        industryId: Yup.number().moreThan(0, 'Industry is required').required('Industry is required'),
                    })}
                    onSubmit={(values) => this.sumbitForm(values)}
                >
                    {({ handleSubmit, handleChange, handleBlur, errors, touched, values }) => (
                        <Form onSubmit={handleSubmit}>
                            <Row className="gx-1 mt-3 mb-3">
                                <Form.Group className="col gx-1">
                                    <Form.Label htmlFor="productName">Product name</Form.Label>
                                    <Form.Control name="productName" id="productName"
                                        value={values.productName}
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        isInvalid={!!errors.productName && !!touched.productName}
                                    />
                                    {!!touched.productName &&
                                        <Form.Control.Feedback type="invalid">Product name is required</Form.Control.Feedback>
                                    }
                                </Form.Group>
                                <Form.Group className="col gx-1">
                                    <Form.Label htmlFor="productSummary">Product Summary</Form.Label>
                                    <Form.Control name="productSummary" id="productSummary" value={values.productSummary} onChange={handleChange}/>
                                </Form.Group>
                                <Form.Group className="col gx-1">
                                    <Form.Label htmlFor="brandId">Brand</Form.Label>
                                    <Form.Control as="select" name="brandId" id="brandId" value={values.brandId}
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        isInvalid={!!errors.brandId && !!touched.brandId}
                                    >
                                        {brandsSelectOptions}
                                    </Form.Control>
                                    {!!touched.brandId &&
                                        <Form.Control.Feedback type="invalid">{errors.brandId}</Form.Control.Feedback>
                                    }
                                </Form.Group>
                                <Form.Group className="col gx-1">
                                    <Form.Label htmlFor="industryId">Industry</Form.Label>
                                    <Form.Control as="select" name="industryId" id="industryId"
                                        value={values.industryId}
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        isInvalid={!!errors.industryId && !!touched.industryId}
                                    >
                                        {industrySelectOptions}
                                    </Form.Control>
                                    {!!touched.industryId &&
                                        <Form.Control.Feedback type="invalid">{errors.industryId}</Form.Control.Feedback>
                                    }
                                </Form.Group>
                                <Button type="submit" className="submit-btn col-auto align-self-start gx-1" color="primary">Submit</Button>
                            </Row>
                        </Form>
                    )}
                </Formik>
            </div >
        )
    }
}

ProductEditForm.defaultProps = {
    productData: {
        productId: null,
        productName: "",
        productSummary: "",
        brandId: 0,
        industryId: -1
    },
    mode: FormModes.NEW
}