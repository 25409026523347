import React from 'react';
import {
  Card,
  CardBody,
} from "reactstrap";
import { DragDropContainer, DropTarget } from 'react-drag-drop-container';

/*
    BoxItem - a thing that appears in a box, after you drag something into the box
*/
export default class BoxItem extends React.Component {
  constructor(props) {
    super(props);
  }

  handleDrop = (e) => {
    e.stopPropagation();
    this.props.swap(e.dragData.index, this.props.index, e.dragData, e);
    e.containerElem.style.visibility="hidden";
  };

  removeItem = () => {
    this.props.removeItem(this.props.uid, this.props.index);
  }

  render() {
    /*
      Notice how these are wrapped in a DragDropContainer (so you can drag them) AND
      in a DropTarget (enabling you to rearrange items in the box by dragging them on
      top of each other)
    */

    return (
      <div className="box_item_component">
        <DragDropContainer
          targetKey="boxItem"
          dragData={{ label: this.props.children, index: this.props.index }}
          onDrop={this.removeItem}
          disappearDraggedElement={true}
          dragHandleClassName="grabber"
        >
          <DropTarget
            onHit={this.handleDrop}
            targetKey="boxItem"
          >
            <div className="outer">
              <div className="item">
                <Card className="mb-1 border bg-light">
                  <CardBody className="box_itemWrapper">
                    <span className="grabber">{this.props.children}</span>
                    {this.props.removeBTN === 'true' &&
                      <span className="box_removeItem" onClick={this.removeItem}>X</span>
                    }
                  </CardBody>
                </Card>
              </div>
            </div>
          </DropTarget>
        </DragDropContainer>
      </div>
    );
  }
}