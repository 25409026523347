export default class CacheObject {
    private key: string;
    private data: any;
    private expiration: Date | null;

    constructor(key: string, object: any, expiration: Date | null) {
        this.key = key;
        this.data = object;
        this.expiration = expiration;
    }

    getKey(): string {
        return this.key;
    }

    setKey(key: string) {
        this.key = key;
    }

    getData(): any {
        return this.data;
    }

    setData(data: any) {
        this.data = data;
    }

    getExpiration(): Date | null {
        return this.expiration;
    }

    setExpiration(expiration: Date | null) {
        this.expiration = expiration;
    }
}