import React from "react";
import merge from 'lodash/merge';
import cloneDeep from 'lodash/cloneDeep';
import { genericFetcherFactory } from '../../utils/requestUtils';
import { setCmsData, setCMSTemplateData } from "../../redux/actions/cmsActions";
import store from '../../redux/store/index';
import { toastr } from "react-redux-toastr";

const sharedComponents = 'shared-components';
export class cmsUtils {
    constructor() {
        this.handleChange = this.handleChange.bind(this);
        this.updateCurrentPage = this.updateCurrentPage.bind(this);
        this.templatePage = this.templatePage.bind(this);
        this.resetDataModel = this.resetDataModel.bind(this);
        this.resetTemplatePageDataModel = this.resetTemplatePageDataModel.bind(this);
        this.fetchPageList = this.fetchPageList.bind(this);
        this.fetchFileFromS3 = this.fetchFileFromS3.bind(this);

    }

    handleChange(event) {
        let unsubscribe = store.subscribe(() => { });
        const { name, value, checked } = event.target;
        let state = { ...store.getState().cms.cmsData };
        state[name] = value;

        //clear user selection
        let update = {};
        if (name === 'template') {
            update = { component: null, page: null, currentPage: "" }
        } else if (name === 'page') {
            update = { component: null }
        }
        for (const [key, thisValue] of Object.entries(update)) {
            state[[key]] = thisValue;
        };

        if (name === 'page' || name === 'editOrCreate' || name === 'delete') {
            state = this.updateCurrentPage(state, checked);
        }
        store.dispatch(setCmsData({ ...state }));
        unsubscribe();
        return state;
    }

    updateCurrentPage(state, checked) {
        if (state.editOrCreate === 'create') {
            state.currentPage = state.newPageName;
        } else if (state.editOrCreate === 'delete') {
            if (!state.deletePages.includes(state.page) && checked) {
                state.deletePages.push(state.page)
            } else if (!checked) {
                state.deletePages = state.deletePages.filter(function (value, index, arr) {
                    return value !== state.page;
                });
            }

        } else {
            state.currentPage = state.page;
        }
        return state;
    }

    //update the state
    updateSuccessCallback(data) {
        let unsubscribe = store.subscribe(() => { });
        let state,
            CMSTemplateData_state = { ...store.getState().cms.CMSTemplateData },
            cmsData_state = { ...store.getState().cms.cmsData };
        for (const [key, value] of Object.entries(data)) {
            if (value[1] === true) {
                state = CMSTemplateData_state;
                let mergedData;
                if (value[2] === 'pristineDataModel') {
                    //update other pages with updated component version
                    let pristineDataModel = CMSTemplateData_state.pristineDataModel;
                    mergedData = merge(cloneDeep(pristineDataModel[[key]]), value[0]);
                    pristineDataModel[[key]] = mergedData;
                    state['pristineDataModel'] = pristineDataModel;
                } else {
                    mergedData = merge(cloneDeep(state.dataModel), value[0]);
                    state[[key]] = mergedData;
                }
            } else if (value[1] === "compOnlyMerge") {
                state = CMSTemplateData_state;
                let mergedData = cloneDeep(state.dataModel);
                mergedData[key] = value[0];
                state.dataModel = mergedData;
            } else {
                state = cmsData_state;
                state[[key]] = value[0]
            }
        }
        unsubscribe();
        return state;

    }

    async resetDataModel(type) {
        let unsubscribe = store.subscribe(() => { });
        let state = { ...store.getState().cms.cmsData },
            data = { ...store.getState().cms.CMSTemplateData };

        let singlePage;
        let folder = state.template ? state.template : state.lastEditedTemplate;
        if (type !== 'allPages') {
            data.dataIsLoaded = null;
            store.dispatch(setCMSTemplateData({ ...data }));
            singlePage = (state.page !== null ? state.page : data['templatePageLists'][folder][0]);
        }
        let files = type === 'allPages'
            ? data.templatePageLists[state.template]
            : [singlePage];

        let fetchLoop = [];
        let pristineDataModelTemplate, pristineDataModel;
        for (const file of files) {
            let rest = {
                folder: folder,
                file: file + ".html.json",
            }
            if (data.pristineDataModel[this.templatePage(rest.file)] != null && type !== 'allPages') {
                data.dataIsLoaded = true;
                data.dataModel = cloneDeep(data.pristineDataModel[this.templatePage(state.page)]);
                store.dispatch(setCMSTemplateData({ ...data }));
            } else {
                fetchLoop.push(
                    genericFetcherFactory(
                        "/lambda-proxy/lambdaProxy/lambdas3download",
                        "TEMPLATE data",
                        "Failed to fetch template data",
                        "POST",
                        {
                            method: 'POST',
                            body: JSON.stringify(rest)
                        })().then(res => {
                            if (res.success !== true) {
                                state = { ...store.getState().cms.cmsData };
                                data = { ...store.getState().cms.CMSTemplateData };
                                state.error = 'Data not found';
                                data.dataIsLoaded = false;
                                store.dispatch(setCmsData({ ...state }));
                                store.dispatch(setCMSTemplateData({ ...data }));
                            } else {
                                res.page = rest.file;
                                return res;
                            }
                        }
                        )
                );
            }
        }
        //handle data        
        let responses = [];
        responses = await Promise.all(fetchLoop);
        state = { ...store.getState().cms.cmsData };
        data = { ...store.getState().cms.CMSTemplateData };
        responses.forEach(res => {
            let file = res.page.replace('.html.json', '');

            data.dataIsLoaded = true;
            data.error = null;

            //pristineDataModel
            pristineDataModelTemplate = JSON.parse(res.data);
            pristineDataModel = { ...data.pristineDataModel };
            pristineDataModel[this.templatePage(file)] = pristineDataModelTemplate;
            data.pristineDataModel = pristineDataModel;

            if (state.page === file) {
                if (type !== 'allPages') {
                    data.dataModel = cloneDeep(pristineDataModel[this.templatePage(file)]);
                }
                state.templateIsSharedCompCompatible = Object.keys(data.packages[state.template]).includes("sharedComponents");
            }
            if (type === 'allPages') {
                state.templateIsSharedCompCompatible = true;
            }

            //add sharedComponents per page
            let arrSharedComp = [];
            for (const comp of state.sharedComponentsAvailable) {
                if (Object.keys(pristineDataModel[this.templatePage(file)]).includes(comp)) {
                    arrSharedComp.push(comp)
                }
            }
            if (state.templateIsSharedCompCompatible === true) {
                pristineDataModel[this.templatePage(file)].sharedComponents = arrSharedComp;
            }

            if (state.page === file) {
                if (state.templateIsSharedCompCompatible === true) {
                    data.dataModel.sharedComponents = arrSharedComp;
                }
            }

            let pagesDataLoaded = { ...state.pagesDataLoaded };
            pagesDataLoaded[state.template] = true;
            state.pagesDataLoaded = pagesDataLoaded;

        })
        store.dispatch(setCMSTemplateData({ ...data }));
        store.dispatch(setCmsData({ ...state }));

        unsubscribe();
        return true;
    }

    templatePage(pageName = store.getState().cms.cmsData.page, templateName = store.getState().cms.cmsData.template) {
        return templateName + "_" + pageName.replace('.html.json', '');
    }

    fetchPageList(clearList = false) {
        let unsubscribe = store.subscribe(() => { });
        let cmsData = { ...store.getState().cms.cmsData },
            CMSTemplateData = { ...store.getState().cms.CMSTemplateData };

        return new Promise((resolve, reject) => {
            store.dispatch(setCmsData({ ...cmsData, 'pageListIsLoaded': false }));

            if (!clearList && CMSTemplateData.templatePageLists[cmsData.template] != null) {
                store.dispatch(setCmsData({ ...cmsData, 'pageListIsLoaded': true }));
            } else {
                let rest = {
                    folder: cmsData.template,
                    type: 'data'
                }
                genericFetcherFactory(
                    "/lambda-proxy/lambdaProxy/lambdas3filelist",
                    "PAGE-LIST",
                    "Failed to fetch page list",
                    "POST",
                    {
                        method: 'POST',
                        body: JSON.stringify(rest)
                    })().then(res => {
                        if (res.success !== true) {
                            store.dispatch(setCmsData({ ...cmsData, 'pageListIsLoaded': false, error: 'Data not found' }));
                        } else {
                            cmsData = { ...store.getState().cms.cmsData };
                            CMSTemplateData = { ...store.getState().cms.CMSTemplateData };
                            let templatePageLists, templatePageListsTemplate;
                            templatePageListsTemplate = res.data;
                            templatePageLists = { ...cmsData.templatePageLists };
                            templatePageLists[cmsData.template] = templatePageListsTemplate;

                            store.dispatch(setCMSTemplateData({ ...CMSTemplateData, 'templatePageLists': templatePageLists }));
                            store.dispatch(setCmsData({ ...cmsData, 'pageListIsLoaded': true }));

                            resolve();
                        }
                    }
                    );
            }
            unsubscribe();
        })

    }

    resetTemplatePageDataModel = () => {
        let unsubscribe = store.subscribe(() => { });

        let state = { ...store.getState().cms.CMSTemplateData };
        let pristineDataModel = state.pristineDataModel;
        pristineDataModel[this.templatePage()] = null;
        state.dataModel = cloneDeep(pristineDataModel[this.templatePage()]);
        state.pristineDataModel = pristineDataModel;

        store.dispatch(setCMSTemplateData({ ...state }));
        unsubscribe();
    }

    genericToasterConfirm(message, disableCancel) {
        const toastrConfirmOptions = {
            okText: "OK",
            id: 'edit_comp_confirm_btn',
            disableCancel: disableCancel,
            component: () => (
                <>
                    <div className="confirm_message">
                        {message}
                    </div>
                </>
            )
        };

        toastr.confirm(null, toastrConfirmOptions);
    }

    fetchFileFromS3(file) {
        let unsubscribe = store.subscribe(() => { });
        let cmsData = { ...store.getState().cms.cmsData },
            CMSTemplateData = { ...store.getState().cms.CMSTemplateData };
        return new Promise((resolve, reject) => {
            store.dispatch(setCmsData({ ...cmsData, 'packageJson_Loaded': false }));

            let rest = {
                folder: cmsData.template,
                file: file
            }
            genericFetcherFactory(
                "/lambda-proxy/lambdaProxy/lambdas3download",
                file,
                "Failed to fetch " + file,
                "POST",
                {
                    method: 'POST',
                    body: JSON.stringify(rest)
                })().then(res => {
                    if (res.success !== true) {
                        store.dispatch(setCmsData({ ...cmsData, 'packageJson_Loaded': false, error: 'packageJson not found' }));
                    } else {
                        let fetchedData = JSON.parse(res.data);
                        if (file === 'package.json') {
                            CMSTemplateData = { ...store.getState().cms.CMSTemplateData };
                            CMSTemplateData['packages'] = { ...CMSTemplateData.packages }
                            CMSTemplateData['packages'][cmsData.template] = fetchedData
                        }
                        store.dispatch(setCMSTemplateData({ ...CMSTemplateData }));
                        store.dispatch(setCmsData({ ...cmsData, 'packageJson_Loaded': true }));

                        resolve();
                    }
                }
                );

            unsubscribe();
        })
    }

}