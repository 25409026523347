import { Formik } from "formik";
import React from "react";
import { Button, Form, Row } from "react-bootstrap";
import { toastr } from "react-redux-toastr";
import * as Yup from "yup";
import { ReportTypes } from "../constants";

const reportTypes = [{ value: ReportTypes.MONTHLY, label: "Monthly Report" }, { value: ReportTypes.WEEKLY, label: "Weekly Report" }];

export default class CreateNewTabForm extends React.PureComponent {
    constructor(props) {
        super(props);

        let selectedProject = null;
        let selectedWorkbook = null;
        let selectedViewId = null;

        if (props.projects == undefined || props.projects == null || props.projects.length < 1) {
            toastr.warning("Projects unavailable");
        } else if (props.projects[0].workbooks == undefined || props.projects[0].workbooks == null || props.projects[0].workbooks.length < 1) {
            toastr.warning("Workbooks unavailable for " + props.projects[0].name);
        } else if (props.projects[0].workbooks[0].views == undefined || props.projects[0].workbooks[0].views == null || props.projects[0].workbooks[0].views.length < 1) {
            toastr.warning("views unavailable for " + props.projects[0].workbooks[0].name);
        } else {
            selectedProject = props.projects[0];
            selectedWorkbook = props.projects[0].workbooks[0];
            selectedViewId = props.projects[0].workbooks[0].views[0].tableauId;
        }

        this.state = {
            tabName: "",
            selectedProject: selectedProject,
            selectedWorkbook: selectedWorkbook,
            selectedViewId: selectedViewId,
        };

        this.submitForm = this.submitForm.bind(this);
    }

    projectChanged(newProjectId) {
        if (newProjectId != this.state.selectedProject.tableauId) {
            let selectedProject = this.props.projects.find(project => project.tableauId == newProjectId);

            let selectedWorkbook = null;
            let selectedViewId = null;

            if (selectedProject.workbooks != undefined && selectedProject.workbooks != null && selectedProject.workbooks.length > 0) {
                selectedWorkbook = selectedProject.workbooks[0];
            }

            if (selectedWorkbook != null) {
                if (selectedWorkbook.views != undefined && selectedWorkbook.views != null && selectedWorkbook.views.length > 0) {
                    selectedViewId = selectedWorkbook.views[0].tableauId;
                }
            }

            this.setState({
                selectedProject: selectedProject,
                selectedWorkbook: selectedWorkbook,
                selectedViewId: selectedViewId,
            })
            return [
                selectedWorkbook.tableauId,
                selectedViewId
            ]
        }
    }

    workbookChanged(newWorkbookId) {
        if (!this.state.selectedWorkbook || newWorkbookId != this.state.selectedWorkbook.tableauId) {
            let selectedWorkbook = this.state.selectedProject.workbooks.find(workbook => workbook.tableauId == newWorkbookId)
            let selectedViewId = null;

            if (selectedWorkbook.views != undefined && selectedWorkbook.views != null && selectedWorkbook.views.length > 0) {
                selectedViewId = selectedWorkbook.views[0].tableauId;
            }

            this.setState({
                selectedWorkbook: selectedWorkbook,
                selectedViewId: selectedViewId,
            })
            return selectedViewId;
        } else {
            return ""
        }
    }

    submitForm(values) {

        let selectedViewObject = this.state.selectedWorkbook.views.find(view => view.tableauId == values.view)

        this.props.callback({
            tabName: values.tabName,
            project: this.state.selectedProject.name,
            workbook: this.state.selectedWorkbook.name,
            view: selectedViewObject.name,
            tableauViewId: values.view,
            url: selectedViewObject.url,
        });
    }

    render() {
        let selectedProjectWorkbooks = [];

        if (this.state.selectedProject) {
            selectedProjectWorkbooks = this.state.selectedProject.workbooks;
        }

        let selectedWorkbookId = null;
        let selectedWorkbookViews = [];
        if (this.state.selectedWorkbook) {
            selectedWorkbookId = this.state.selectedWorkbook.tableauId;
            selectedWorkbookViews = this.state.selectedWorkbook.views;
        }

        return (
            <Formik
                initialValues={{
                    tabName: "",
                    projectId: this.props.projects[0].tableauId,
                    workbookId: this.props.projects[0].workbooks[0].tableauId,
                    view: this.props.projects[0].workbooks[0].views[0].tableauId
                }}
                validationSchema={Yup.object().shape({
                    tabName: Yup.string().required(),// consider tabName uniqe
                    view: Yup.string().required(),
                })}
                onSubmit={(values) => this.submitForm(values)}
            >
                {({ handleSubmit, handleChange, handleBlur, errors, touched, values, setFieldValue }) => (
                    <Form onSubmit={handleSubmit}>
                        <Row className="gx-1">
                            <Form.Group className="col gx-1">
                                <Form.Label htmlFor="tabName">Tab Name</Form.Label>
                                <Form.Control
                                    type="text"
                                    name="tabName"
                                    id="tabName"
                                    value={values.tabName}
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    isInvalid={!!errors.tabName && !!touched.tabName}
                                />
                                {!!touched.tabName &&
                                    <Form.Control.Feedback type="invalid">{errors.tabName}</Form.Control.Feedback>
                                }
                            </Form.Group>
                            <Form.Group className="col gx-1">
                                <Form.Label htmlFor="projectId">Project</Form.Label>
                                <Form.Control
                                    value={values.projectId}
                                    as="select"
                                    name="projectId"
                                    id="projectId"
                                    onChange={(event) => {
                                        setFieldValue("projectId", event.target.value);
                                        let [workbookId, viewID] = this.projectChanged(event.target.value);
                                        console.log(this.projectChanged(event.target.value));
                                        setFieldValue('workbookId', workbookId);
                                        setFieldValue('view', viewID);
                                    }}>
                                    {this.props.projects.map(project => { return <option value={project.tableauId}>{project.name}</option> })}
                                </Form.Control>
                            </Form.Group>
                            <Form.Group className="col gx-1">
                                <Form.Label htmlFor="workbookId">Workbook</Form.Label>
                                <Form.Control as="select"
                                    value={selectedWorkbookId}
                                    name="workbookId"
                                    id="workbookId" onChange={(event) => {
                                        setFieldValue('workbookId', event.target.value)
                                        setFieldValue('view', this.workbookChanged(event.target.value));
                                    }}>
                                    {selectedProjectWorkbooks.map(workbook => { return <option value={workbook.tableauId}>{workbook.name}</option> })}
                                </Form.Control>
                            </Form.Group>
                            <Form.Group className="col gx-1">
                                <Form.Label htmlFor="view">View</Form.Label>
                                <Form.Control
                                    as="select"
                                    value={values.view}
                                    name="view"
                                    id="view"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                >
                                    {selectedWorkbookViews.map(view => { return <option value={view.tableauId}>{view.name}</option> })}
                                </Form.Control>
                            </Form.Group>
                            <Form.Group className="col gx-1">
                                <Form.Label htmlFor="reportType">Report Type</Form.Label>
                                <Form.Control as="select" disabled value={this.props.selectedReportType} type="select" name="reportType">
                                    {reportTypes.map(type => { return <option value={type.value}>{type.label}</option> })}
                                </Form.Control>
                            </Form.Group>
                            <Button className="submit-btn col-auto align-self-start gx-1" type="submit" variant="primary">Add Tab</Button>
                        </Row>
                    </Form >
                )}
            </Formik>
        )
    }
}