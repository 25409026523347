import React from "react";
import { Redirect } from "react-router-dom";
import { Container } from "reactstrap";
import { connect } from 'react-redux';
import welcome from '../../assets/img/welcome.gif';
import PersonalDashboardLoader from "../../components/PersonalDashboardLoader";

// ...later
const Landing = (props) => {
    if (props.user.userData.isExternalUser) {
        return (<Redirect to="/client-reports" />);
    }
    return (
        <Container fluid className="p-0 welcome">
            <PersonalDashboardLoader />
        </Container>

    );
};

// export default Landing;

export default connect((store) => {
    return {
        user: store.userContext.user
    }
})(Landing)