import parse from 'html-react-parser';
import React from "react";
import { Button, Card, Container, Modal, Spinner } from "react-bootstrap";
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { Copy, ThumbsUp } from "react-feather";
import { connect } from "react-redux";
import { toastr } from "react-redux-toastr";
import AccountDropdown from "../../../components/AccountDropdown";
import FacebookContextComponent from '../../../components/FacebookContextComponent';
import Loader from '../../../components/Loader';
import { formStatus } from '../../../utils/formUtils';
import { genericFetcherFactory } from "../../../utils/requestUtils";
import { AccountIsActive } from "../../prods-and-accounts/account/accountUtils";
import FacebookPostToolForm from "./FacebookPostToolForm";

export const PostTypes = {
    VIDEO: 'video',
    LINK: 'link'
}

export const DefaultPostData = {
    postType: null,
    callToAction: null,
    callToActionLink: "",
    caption: "",
    title: "",
    description: "",
    message: "",
    imageUrl: "",
    videoId: "",
    videoUrl: "",

}

class FacebookPostTool extends React.PureComponent {
    constructor(props) {
        super(props);
        this.createPreview = this.createPreview.bind(this);
        this.submitPost = this.submitPost.bind(this);
        this.loadPostData = this.loadPostData.bind(this);
        this.updateStateCallback = this.updateStateCallback.bind(this);
        this.accountChanged = this.accountChanged.bind(this);

        this.state = {
            selectedAccount: null,
            postData: { ...DefaultPostData },
            initSuccess: null,
            submitFormStatus: formStatus.IDLE,
            previewFormStatus: formStatus.IDLE,
            loadPostStatus: formStatus.IDLE,
            postId: null,
            createdPostId: null,
            previewHtml: null
        }

    }

    updateStateCallback(key, value) {
        this.setState({ [key]: value });
    }

    loadPostData() {
        //validate
        this.setState({ loadPostStatus: formStatus.IN_PROGRESS });
        if (this.state.postId === null) {
            toastr.warning("Please set post id ");
            return;
        }
        genericFetcherFactory(`/api/facebook-interface/PostTool/getPagePostById?postId=${this.state.postId}&pageId=${this.state.selectedAccount.facebookPageId}`, "LOAD_POST", "Fail to load post", "GET", null, false)()
            .then(result => {
                if (result.success) {
                    this.setState({ postData: result.data, loadPostStatus: formStatus.SUCCESS });
                } else {
                    toastr.error("Failed to load post");
                    this.setState({ postData: DefaultPostData, loadPostStatus: formStatus.SUCCESS });

                }
            });


    }

    submitPost(submittedPostData) {
        this.setState({ submitFormStatus: formStatus.IN_PROGRESS, postData: submittedPostData });
        let payload = {
            method: "POST",
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(submittedPostData)
        }
        genericFetcherFactory(`/api/facebook-interface/PostTool/createFbPost?pageId=${this.state.selectedAccount.facebookPageId}&accountId=${this.state.selectedAccount.accountPlatformId}&preivew=1`, "PREVIEW_POST", "Failed to create post", "POST", payload)()
            .then(result => {
                if (result.success && result.data.postId !== null) {
                    let splitted = result.data.postId.split("_");
                    let linkUrl = "https://business.facebook.com/" + splitted[0] + "/posts/" + splitted[1]; // "http://www.facebook.com/" + iframeUrl;
                    this.setState({ createdPostId: linkUrl, submitFormStatus: formStatus.SUCCESS, postData: submittedPostData });
                } else {
                    this.setState({ submitFormStatus: formStatus.FAILED, postData: submittedPostData });
                }
            });
    }


    createPreview(currentPreviewPost) {
        this.setState({ previewFormStatus: formStatus.IN_PROGRESS, postData: currentPreviewPost });
        let payload = {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(currentPreviewPost)
        }

        genericFetcherFactory(`/api/facebook-interface/PostTool/generatePreview?pageId=${this.state.selectedAccount.facebookPageId}&accountId=${this.state.selectedAccount.accountPlatformId}&preivew=1`, "PREVIEW_POST", "Failed to create preview", "POST", payload)()
            .then(result => {
                if (result.success) {
                    this.setState({ previewHtml: parse(result.data.source), previewFormStatus: formStatus.SUCCESS });
                } else {
                    this.setState({ previewMode: null, previewFormStatus: formStatus.FAILED });
                }
            });
    }

    accountChanged(account) {
        if (account !== undefined) {
            this.setState({ selectedAccount: account });
        }

    }

    render() {

        return (
            <Container fluid className="p-0 row">
                <Modal
                    show={this.state.submitFormStatus !== formStatus.IDLE}
                    centered
                >
                    <Modal.Header>
                        Creating post {this.state.submitFormStatus}
                    </Modal.Header>
                    {this.state.submitFormStatus === formStatus.SUCCESS &&

                        <Button color="secondary" size="small"
                            style={{ position: "absolute", top: "10px", right: "10px" }}
                            onClick={e => this.setState({ submitFormStatus: formStatus.IDLE })}>
                            Close
                        </Button>
                    }
                    <Modal.Body className="text-center postTool__submit">
                        {this.state.submitFormStatus === formStatus.IN_PROGRESS &&
                            <div class="mt-4 mb-4"><Spinner animation="border" variant="primary" /></div>
                        }

                        {this.state.submitFormStatus === formStatus.SUCCESS &&
                            <>
                                <div className="postTool__submit-icon">
                                    <ThumbsUp size={38} color={"green"} />
                                </div>
                                <div className="postTool__submit-editBtn">
                                    <Button color="success" size="large" target="_blank" href={this.state.createdPostId}>Edit on facebook</Button>
                                </div>
                                <div className="postTool__submit-text-url row">
                                    <span class="col-11">{this.state.createdPostId}</span>
                                    <CopyToClipboard text={this.state.createdPostId}
                                        onCopy={() => { toastr.info("Post Edit URL copied"); }}>
                                        <button style={{ border: "none", background: "none" }}><Copy inline size={24} color={"green"} /></button>
                                    </CopyToClipboard>
                                </div>

                            </>
                        }

                    </Modal.Body>
                </Modal>
                <div className={this.state.previewHtml !== null ? "col-xl-7" : "col-xl-12"}>
                    <Card>
                        <Card.Header>
                            <Card.Title tag="h5" className="mb-0">Post Tool</Card.Title>
                        </Card.Header>
                        <Card.Body className="postTool">
                            <div className="from-group-wrap postTool__top row">
                                <div className="form-group col-lg-4 col-xl-5 col-5">
                                    <AccountDropdown updateStateCallback={this.updateStateCallback} accountChanged={this.accountChanged} selectedAccount={this.state.selectedAccount} platform='FACEBOOK' itemActive={AccountIsActive.ALL} />
                                </div>
                                {(this.state.selectedAccount !== null && this.props.facebookUserData.isLoggedIn) &&
                                    <div className="form-group col-lg-4 col-xl-7 col-5">
                                        <label style={{ display: "block" }}>Post Id</label>
                                        <input id="postId" name="postId" style={{ width: "calc( 100% - 100px )" }} onChange={(event) => this.setState({ postId: event.target.value })} />
                                        <Button inline className="submit-bt ml-4" variant="secondary" onClick={this.loadPostData} disabled={this.state.postId === null}>Load</Button>
                                    </div>
                                }
                            </div>

                            {(this.state.selectedAccount !== null && this.props.facebookUserData.isLoggedIn) &&
                                <>
                                    <hr></hr>

                                    <div className="mb-4 postTool__bottom">
                                        {this.state.loadPostStatus === formStatus.IN_PROGRESS ? (
                                            <Loader />
                                        ) : (
                                            <FacebookPostToolForm
                                                account={this.state.selectedAccount}
                                                postData={this.state.postData}
                                                previewHtml={this.state.previewHtml}
                                                accessToken={this.props.facebookUserData.accessToken}
                                                previewCallback={this.createPreview}
                                                submitPostCallback={this.submitPost}
                                            />
                                        )
                                        }
                                    </div>

                                </>
                            }
                            {!this.props.facebookUserData.isLoggedIn &&
                                <Button variant="info"><FacebookContextComponent shouldRender={true} /></Button>
                            }
                        </Card.Body>
                    </Card>
                </div>
                {this.state.previewFormStatus !== formStatus.IDLE &&
                    <div className="col-xl-5 postTool__previewPost">
                        <Card >
                            {this.state.previewFormStatus === formStatus.IN_PROGRESS &&
                                <Spinner animation='border' variant="dark" className="mt-2" />
                            }

                            {this.state.previewFormStatus === formStatus.SUCCESS &&
                                <>
                                    <div className="postTool__previewPost-title">Preview</div>
                                    {this.state.previewHtml}
                                </>
                            }
                        </Card>
                    </div>
                }
            </Container>
        )

    }
}
export default connect((store) => {
    return {
        facebookUserData: store.userContext.facebookUserData
    }
})(FacebookPostTool)