import React from "react";
import { Modal, Container, Card, Tooltip } from "react-bootstrap";

import BootstrapTable from "react-bootstrap-table-next";

import * as Icon from "react-feather";
import Loader from '../../components/Loader';
import { toastr } from "react-redux-toastr";
import { handleErrorResponse, genericCachedFetcherFactory, genericFetcherFactory } from "../../utils/requestUtils";
import { CACHE_EXPIRATION_DEFAULTS } from "../../services/qsCache";
import { addHours } from "../../utils/dateUtils"
import GenericErrorAlert from "../../components/GenericErrorAlert";
import DailyCapEditForm, { FormModes } from './DailyCapEditForm';

const Platform = "Facebook";

export default class DailyCap extends React.PureComponent {
    constructor(props) {
        super(props)
        this.state = { dailycaps: [], accounts: [], initSuccess: null };
        this.updateDailyCap = this.updateDailyCap.bind(this);
        this.removeDailyCap = this.removeDailyCap.bind(this);
    }

    updateDailyCap(capObj, capId) {
        this.setState({ ...this.state, formSaving: true })

        genericFetcherFactory("/api/facebook-interface/DailyCaps/DailyCap/" + capId, "DAILYCAP", "Failed to update daily cap", "PATCH",
            {
                method: "PATCH",
                body: JSON.stringify(capObj),
                headers: {
                    "Content-Type": "application/json"
                }
            })().then((res) => {
                if (res.success) {
                    let updatedDailyCap = res.data;
                    let newDailycaps = [...this.state.dailycaps];
                    let dailyCapIndex = newDailycaps.findIndex(item => (item.id === capId));
                    let dailycapAccount = this.state.accounts.find((item) => { return item.accountId === parseInt(updatedDailyCap.entityId) });
                    updatedDailyCap.entityName = dailycapAccount !== undefined ? dailycapAccount.accountName : "[Not found]";
                    newDailycaps.splice(dailyCapIndex, 1, updatedDailyCap);
                    this.setState({ ...this.state, dailycaps: newDailycaps, formSaving: false });
                    toastr.success("Update Success", updatedDailyCap.entityName + "Daily cap was updated successfully.")
                } else {
                    this.setState({ ...this.state, formSaving: false });
                    handleErrorResponse(res, "Update Daily Cap Failed");
                }
            }).catch(error => {
                console.log(error);
            });
    }

    async componentDidMount() {
        let fetchResult = await this.fetchActiveFacebookAccounts()
        if (fetchResult == true)
            await this.fetchDailyCaps();
    }

    async fetchDailyCaps() {
        genericFetcherFactory("/api/facebook-interface/DailyCaps", "DAILYCAP", "Failed to fetch Daily Caps")().then(res => {
            if (res.success == true) {
                let result = res.data;
                result.map(((dailycap) => {
                    let dailycapAccount = this.state.accounts.find((item) => { return item.accountId === parseInt(dailycap.entityId) });
                    dailycap.entityName = dailycapAccount !== undefined ? dailycapAccount.accountName : "[Not found]";
                    return dailycap;
                }));
                this.setState({ ...this.state, dailycaps: result, initSuccess: true });
            } else {
                this.setState({ ...this.state, initSuccess: false })
                handleErrorResponse(res, "Fetch Daily Cap Failed");
            }
        })
    }

    async fetchActiveFacebookAccounts() {
        return genericCachedFetcherFactory(`/api/accounts/Accounts/byPlatform?platformName=` + Platform, "ACCOUNTS", "Failed to fetch accounts", addHours(CACHE_EXPIRATION_DEFAULTS.Accounts))().then((result) => {
            if (result.success == true) {
                this.setState({ accounts: [...result.data] });
                return Promise.resolve(true);
            } else {
                handleErrorResponse(result, "Fetch Active Facebook accounts Failed");
                return Promise.resolve(false);
            }
        });
    }

    // Boostrap table configuration 
    // Expand row configuration for editing rows in the Daily Cap table
    expandRow = {
        renderer: row => {
            return (
                <div className="ml-5 mr-5 mt-3 mb-3">
                    <DailyCapEditForm mode={FormModes.EDIT} dailyCapData={row} submitCallback={this.updateDailyCap} />
                </div>
            )
        },
        expandColumnRenderer: ({ expanded }) => {
            if (expanded) {
                return (
                    <Icon.Edit className="feather align-middle" />
                );
            }
            return (
                <Icon.Edit2 className="feather align-middle" />
            );
        },
        expandHeaderColumnRenderer: ({ isAnyExpands }) => {
            return "";
        },
        showExpandColumn: true,
        expandByColumnOnly: true,
        expandColumnPosition: 'right'
    };

    // Column configuration for the Daily cap table
    dailyCapsColumns = [
        {
            dataField: "id",
            text: "ID",
            headerStyle: { width: "30px" },

        },
        {
            dataField: "entityName",
            text: "Entity Name",
        },
        {
            dataField: "entityType",
            text: "Entity Type",
            sort: true
        },
        {
            dataField: "cap",
            text: "Cap",
            sort: true
        },
        {
            dataField: "weekendCap",
            text: "Weekend Cap",
            sort: true
        },
        {
            editable: false, isDummyField: true, headerStyle: { width: "40px", textAlign: "center" }, align: "center",
            formatter: (cell, row) => this.actionsFormatter(
                cell, row, { removeDailyCap: this.removeDailyCap }
            )
        }
    ];

    removeDailyCap(row) {
        let confirmation = window.confirm("Are you sure you want to delete the cap?");
        if (confirmation == true) {
            this.setState({ formSaving: true });
            genericFetcherFactory(`/api/facebook-interface/DailyCaps`, "DELETE_TEST", "Failed to delete cap", "DELETE",
                {
                    method: "DELETE",
                    body: JSON.stringify(row),
                    headers: { "Content-Type": "application/json" }
                })().then(result => {
                    let state = { formSaving: false };
                    if (result.success === true) {
                        toastr.success("Daily cap deleted successfully");
                        let newDailycaps = [...this.state.dailycaps];
                        let index = newDailycaps.findIndex(_dailycap => _dailycap.id == row.id);
                        if (index != -1) {
                            newDailycaps.splice(index, 1);
                            state.dailycaps = newDailycaps;
                        }
                    }
                    this.setState({ ...state });
                });
        }
    }

    actionsFormatter(cell, row, { removeDailyCap, saveTest, revertTestChanges }) {
        let removeFormatterId = "deleteIcon-" + row.key;

        return (
            <div tabindex="-1">
                <Icon.Trash2 id={removeFormatterId} style={{ cursor: "pointer" }} className="feather mr-2"
                    onClick={() => { removeDailyCap(row) }} />
                <Tooltip placement="top" target={removeFormatterId}>
                    Delete Test
                </Tooltip>
            </div>
        )
    }

    render() {
        if (this.state.initSuccess === true) {
            return (
                <Container fluid className="p-0">
                    <Modal show={this.state.formSaving === true} centered>
                        <Modal.Header>
                            Saving Daily Cap...
                        </Modal.Header>
                        <Modal.Body className="text-center m-3">
                            <Loader />
                        </Modal.Body>
                    </Modal>
                    <Card>
                        <Card.Header>
                            <Card.Title tag="h5" className="mb-0">
                                Daily Cap
                            </Card.Title>
                        </Card.Header>
                        <Card.Body>
                            <BootstrapTable
                                bootstrap4
                                keyField="id"
                                bordered={false}
                                striped
                                hover
                                data={this.state.dailycaps ? this.state.dailycaps : []}
                                columns={this.dailyCapsColumns}
                                expandRow={this.expandRow}
                            />
                        </Card.Body>
                    </Card>
                </Container>
            )
        } else if (this.state.initSuccess === false) {
            return (
                <GenericErrorAlert />
            );
        } else {
            return (
                <Card>
                    <Card.Header>
                        <Card.Title tag="h5" className="mb-0">
                            Daily Cap
                        </Card.Title>
                    </Card.Header>
                    <Loader />
                </Card>
            );
        }
    }

}