import { genericFetcherFactory } from "../../../utils/requestUtils";

const FetchObjectTypes = {
    ROLES: "userRole",
    DEPARTMENTS: "department",
    USERS: "user"
}

export const RelatedEntityFetchers = {
    FetchObjectTypes: FetchObjectTypes,
    fetchUserDepartments: genericFetcherFactory("/api/users/Departments", FetchObjectTypes.DEPARTMENTS, "Fetch Departments Failed"),
    fetchUserRoles: genericFetcherFactory("/api/users/UserRoles", FetchObjectTypes.ROLES, "Fetch User Roles Failed"),
    fetchUsers: genericFetcherFactory("/api/users/Users", FetchObjectTypes.USERS, "Fetch Users Failed")
}