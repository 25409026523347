import React from "react";

import {
    UncontrolledPopover, PopoverHeader, PopoverBody,
    Table
} from "reactstrap";
import { Modal, Container, Card, Form, FormGroup, } from "react-bootstrap";
import BootstrapTable from "react-bootstrap-table-next";

import * as Icon from "react-feather";

import Loader from '../../../components/Loader'
import ClientEditForm, { FormModes } from './ClientEditForm';

import { toastr } from "react-redux-toastr";
import { handleErrorResponse } from "../../../utils/ajaxErrorHandler";
import GenericErrorAlert from "../../../components/GenericErrorAlert";
import { genericCachedFetcherFactory, genericFetcherFactory } from "../../../utils/requestUtils";
import { addHours } from "../../../utils/dateUtils";

export default class Clients extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = { clients: null, showActiveClients: false, initSuccess: null };
        this.updateClient = this.updateClient.bind(this);
        this.contactFormatter = this.contactFormatter.bind(this);
    }

    updateClient(formData, clientId) {
        this.setState({ ...this.state, formSaving: true })

        genericFetcherFactory("/api/crm/Clients/Client/" + clientId, "CLIENTS", "Failed to update Clients", "PATCH",
            {
                method: "PATCH",
                body: formData
            })().then((res) => {
                if (res.success) {
                    let updatedClient = res.data;
                    updatedClient.logo = updatedClient.logo + "&refresh=" + Date.now().toString();

                    let newClients = [...this.state.clients];
                    let clientIndex = newClients.findIndex(item => (item.clientId === clientId));

                    updatedClient.contactName = updatedClient.contact.contactName;
                    updatedClient.contactId = updatedClient.contact.contactId;
                    newClients.splice(clientIndex, 1, updatedClient);

                    this.setState({ ...this.state, clients: newClients, formSaving: false });
                    toastr.success("Update Success", updatedClient.clientName + " was updated successfully.")

                } else {
                    this.setState({ ...this.state, formSaving: false });
                    res.json().then(errorResponse => {
                        toastr.error("Update Failed", "Error: " + errorResponse.response)
                    });
                }
            }).catch(error => {
                console.log(error);
            });
    }

    updateActiveAcoounts() {

        return (event) => {
            if (event.target.checked === true) {
                let activeAccounts = this.state.clients.filter(client => client.isActive === true);
                this.setState({ ...this.state, 'activeAccounts': activeAccounts, showActiveClients: event.target.checked });
            } else {
                this.setState({ ...this.state, showActiveClients: event.target.checked });
            }
        }
    }


    expandRow = {
        renderer: row => {
            return (
                <div className="ms-5 me-5 mt-3 mb-3">
                    <ClientEditForm mode={FormModes.EDIT} clientData={{ ...row, imgUrl: "/getImage?key=" + row.logo }} submitCallback={this.updateClient} />
                </div>
            )
        },
        expandColumnRenderer: ({ expanded }) => {
            if (expanded) {
                return (
                    <Icon.Edit className="feather align-middle" />
                );
            }
            return (
                <Icon.Edit2 className="feather align-middle" />
            );
        },
        expandHeaderColumnRenderer: ({ isAnyExpands }) => {
            return "";
        },
        showExpandColumn: true,
        expandByColumnOnly: true,
        expandColumnPosition: 'right'
    };

    imageFormatter(cell, row, rowIndex, formatExtraData) {
        return <img style={{ maxHeight: "45px", maxWidth: "45px" }} src={"/getImage?key=" + cell} />
        // return <img style={{ maxHeight: "45px", maxWidth: "45px" }} src={"https://www.nbn.org.il/jobboard/wp-content/uploads/2019/02/Quality-Score-Logo.png"} />
    }

    contactFormatter(cell, row, rowIndex, formatExtraData) {
        return (
            <>
                <div tabindex="-1" id={"client_" + row.clientId + "_contact_" + cell.contactId} style={{ cursor: "pointer" }}>
                    <Icon.FileText className="feather mr-2" />{cell.contactName}
                </div>
                <UncontrolledPopover placement="bottom" target={"client_" + row.clientId + "_contact_" + cell.contactId} trigger="focus">
                    <PopoverHeader><a href={"/api/crm/Contacts/Contact/" + cell.contactId}><Icon.ExternalLink className="feather mr-2" /></a>{cell.contactName}</PopoverHeader>
                    <PopoverBody>
                        <Table size="sm">
                            <tbody>
                                <tr>
                                    <td>Contact ID</td>
                                    <td>{cell.contactId}</td>
                                </tr>
                                <tr>
                                    <td>Position</td>
                                    <td>{cell.position}</td>
                                </tr>
                                <tr>
                                    <td>Email</td>
                                    <td>{cell.email}</td>
                                </tr>
                                <tr>
                                    <td>Phone Number</td>
                                    <td>{cell.phoneNumber}</td>
                                </tr>
                            </tbody>
                        </Table>
                    </PopoverBody>
                </UncontrolledPopover >
            </>
        )
        // return cell;
    }

    clientsColumns = [
        {
            dataField: "clientId",
            text: "ID",
            headerStyle: { width: "30px" },
        },
        {
            dataField: "logo",
            text: "",
            formatter: this.imageFormatter,
            headerStyle: { width: "45px" },
            align: "center"
        },
        {
            dataField: "clientName",
            text: "Client Name",
            sort: true
        },
        {
            dataField: "legalCompanyName",
            text: "Legal Company Name",
            sort: true
        },
        {
            dataField: "vatNumber",
            text: "VAT Number",
            sort: true
        },
        {
            dataField: "nameOnInvoice",
            text: "Name on Invoice",
            sort: true
        },
        {
            dataField: "billingAddress",
            text: "Billing Address",
            sort: true
        },
        {
            dataField: "billingEmail",
            text: "Billing Email",
            sort: true
        },
        {
            dataField: "contact",
            text: "Contact Name",
            formatter: this.contactFormatter,
        }
    ]

    fetchClients() {

        genericFetcherFactory("/api/crm/Clients", "CLIENTS", "Failed to fetch from Clients API Service")().then(res => {
            if (res.success == true) {
                let clients = res.data;
                clients.forEach(client => {
                    if (client.contact != null) {
                        client.contactName = client.contact.contactName;
                        client.contactId = client.contact.contactId;
                    }
                });
                this.setState({ ...this.state, clients: clients, initSuccess: true });
            } else {
                this.setState({ ...this.state, initSuccess: false });
                handleErrorResponse(res, "Fetch Clients Failed");
            }
        })
    }

    getData() {
        if (this.state.clients && !this.state.showActiveClients) {
            return this.state.clients
        } else if (this.state.showActiveClients && this.state.activeAccounts) {
           return  this.state.activeAccounts
        } else { return [] }
    }

    componentDidMount() {
        this.fetchClients();
    }

    render() {
        if (this.state.initSuccess === true) {
            return (
                <Container fluid className="p-0">
                    <Modal show={this.state.formSaving === true} centered size="lg">
                        <Modal.Header>
                            Saving Client...
                        </Modal.Header>
                        <Modal.Body className="text-center m-3">
                            <Loader width="wide" />
                        </Modal.Body>
                    </Modal>
                    <Card>
                        <Card.Header>
                            <Card.Title tag="h5" className="mb-0">
                                Clients
                            </Card.Title>
                        </Card.Header>
                        <Card.Body>
                            <FormGroup className="d-flex">
                                <Form.Label className="me-1 mb-0" htmlFor="showActiveClients">Show only active clients</Form.Label>
                                <Form.Check
                                    type="switch"
                                    name="showActiveClients"
                                    id="showActiveClients"
                                    checked={this.state.showActiveClients}
                                    onChange={this.updateActiveAcoounts()}
                                />
                            </FormGroup>
                            <BootstrapTable
                                bootstrap4
                                keyField="clientId"
                                bordered={false}
                                striped
                                hover
                                data={this.getData()}
                                columns={this.clientsColumns}
                                expandRow={this.expandRow}
                            />
                        </Card.Body>
                    </Card>
                </Container>
            )
        } else if (this.state.initSuccess === false) {
            return (
                <GenericErrorAlert />
            );
        } else {
            return (
                <Card>
                    <Card.Header>
                        <Card.Title tag="h5" className="mb-0">
                            Clients
                        </Card.Title>
                    </Card.Header>
                    <Loader width="wide" />
                </Card>
            );
        }
    }
}