import React from "react";
import { connect } from 'react-redux';
import { toastr } from "react-redux-toastr";
import SortableTree from 'react-sortable-tree';
import FileExplorerTheme from 'react-sortable-tree-theme-minimal';
import 'react-sortable-tree/style.css'; // This only needs to be imported once in your app
import {
    Alert, Button,
    Card,
    CardBody,
    CardHeader,
    CardTitle,
    Container,
    Modal,
    ModalBody,
    ModalHeader
} from "reactstrap";
import GenericErrorAlert from "../../../../components/GenericErrorAlert";
import Loader from '../../../../components/Loader';
import { saveRoutes } from "../../../../redux/actions/sidebarActions";
import { setTableauMenus } from '../../../../redux/actions/tableauActions';
import routes from "../../../../routes/index";
import { genericFetcherFactory } from "../../../../utils/requestUtils";
import { addToTree, createTreeDataFromTabData, flattenTree, ItemTypes, removeItemFromTree, routesFromTabs, updateInTree } from '../../tabMenuUtils';
import TabMenuItemEditForm, { FormModes } from './TabMenuItemEditForm';

const customStyles = {
    content: {
        top: '20%',
        left: '20%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-20%',
        transform: 'translate(-20%, -20%)',
        maxWidth: '1120px'
    }
};

const defaultMenuItemFormData = {
    tabId: null,
    permalink: null,
    tabName: "",
    url: "",
    parentId: null,
    authGroupIds: null,
    showToolbar: null,
}

class TabMenuItems extends React.PureComponent {
    constructor(props) {
        super(props);
        this.setButtons = this.setButtons.bind(this);
        this.updateTreeWithChange = this.updateTreeWithChange.bind(this);
        this.removeItemFromTree = this.removeItemFromTree.bind(this);
        this.saveTree = this.saveTree.bind(this);

        this.state = {
            formModalOpen: false,
            initSuccess: null,
            treeData: [],
            pendingChanges: false,
            currentTabMenuItemData: defaultMenuItemFormData,
            currentMenuItemType: ItemTypes.SECTION,
            formMode: FormModes.NEW
        };
    }

    componentDidMount() {
        genericFetcherFactory("/api/tableaus/TabMenuItems/Internal", "GET_INTERNAL_TABMENUITEMS", "Fail to get Menu Items")().then(res => {
            if (res.success) {
                let tree = createTreeDataFromTabData(res.data);
                this.setState({ treeData: tree, initSuccess: true });
            } else {
                this.setState({ initSuccess: false });
            }
        })
    }

    /**
     * Set Relevant form type per button
     */
    setButtons(item, level) {
        const buttons = [];
        let newChildItemType = null;
        let currentItemType = ItemTypes.TAB;

        // set item type per level 
        if (level.length == 1) {
            newChildItemType = ItemTypes.MENU_ITEM;
            currentItemType = ItemTypes.SECTION;
        }

        if (level.length == 2) {
            newChildItemType = ItemTypes.TAB;
            currentItemType = ItemTypes.MENU_ITEM;
        }

        item.itemType = currentItemType;

        if (newChildItemType) {
            let addButton = (
                <Button size="medium" color="outline-secondary" onClick={() => { this.newMenuItemTypeForm(item.treeId, newChildItemType) }} >
                    Create {newChildItemType}
                </Button>
            )
            buttons.push(addButton);
        }

        let removeButton = (
            <Button size="small" color="outline-danger" onClick={() => { this.removeItemFromTree(item) }} >
                X
            </Button>
        )

        let editButton = (
            <Button size="small" color="outline-primary" onClick={() => { this.editMenuItemTypeForm(item, currentItemType) }}>
                edit
            </Button>
        )
        buttons.push(editButton);
        buttons.push(removeButton);
        return buttons;
    }

    // Events 
    newMenuItemTypeForm(parentItemId, currentItemType) {
        // Update "hidden" fields
        let newItem = { ...defaultMenuItemFormData };
        newItem.parentId = parentItemId;
        newItem.itemType = currentItemType;

        this.setState({
            currentTabMenuItemData: newItem,
            currentMenuItemType: currentItemType,
            formMode: FormModes.NEW,
            formModalOpen: true,
        })
    }

    editMenuItemTypeForm(item, currentItemType) {
        this.setState({
            currentTabMenuItemData: item,
            currentMenuItemType: currentItemType,
            formMode: FormModes.EDIT,
            formModalOpen: true,
        })
    }

    updateTreeWithChange(item) {
        let newTree = [];
        item.title = item.tabName;
        if (this.state.formMode === FormModes.NEW) {
            // This will exist when adding anything other than a new Section
            if (this.state.currentTabMenuItemData) {
                item.parentId = this.state.currentTabMenuItemData.parentId;
            }
            newTree = addToTree(this.state.treeData, item);
        }
        if (this.state.formMode === FormModes.EDIT) {
            newTree = updateInTree(this.state.treeData, item);
        }
        toastr.warning("Please Save your changes");
        this.setState({
            formModalOpen: false,
            pendingChanges: true,
            treeData: newTree
        })
    }

    removeItemFromTree(item) {
        this.setState({
            pendingChanges: true,
            treeData: removeItemFromTree(this.state.treeData, item)
        })
        toastr.warning("Please Save your changes");

    }

    saveTree() {
        this.setState({ formSaving: true });
        
        genericFetcherFactory("/api/tableaus/TabMenuItems/Internal/SaveTree", "SAVE_TABS_TREE", "Failed to save Tableau items", "POST",
            {
                method: "POST",
                body: JSON.stringify(flattenTree(this.state.treeData)),
                headers: { 'Content-Type': 'application/json' }
            })()
            .then(res => {
                if (res.success == true) {
                    let tabsArray = res.data;
                    let combinedRoutes = [...routes];

                    combinedRoutes = combinedRoutes.concat(routesFromTabs(tabsArray));
                    
                    this.props.setTableauMenus(tabsArray);
                    this.props.saveRoutes(combinedRoutes);
                    toastr.success("Menu Items Saved successfully");

                    this.setState({ treeData: createTreeDataFromTabData(tabsArray), formSaving: false, pendingChanges: false });
                } else {

                    this.setState({ formSaving: false });
                }
            }).catch((error) => {
                toastr.error("Save Failed", "Error: " + error);
                this.setState({ pendingChanges: true, formSaving: false });
                return;
            });
    }

    canDrop({ node, nextParent }) {
        if (node.parentId === null && nextParent === null) { // Section Order
            return true;
        }
        if (nextParent !== null && node.parentId !== 0 && node.parentId === nextParent.treeId) { // children ordering of the same parent 
            return true;
        }
        return false;
    };

    render() {
        if (this.state.initSuccess === true) {
            return (
                <Container fluid className="p-0">
                    {this.state.pendingChanges === true &&
                        <Alert color={"warning"}>
                            <div className="alert-message">
                                Pending Changes
                            </div>
                        </Alert>
                    }
                    <Modal isOpen={this.state.formSaving === true} centered >
                        <ModalHeader>
                            Saving Tab Menu Items...
                        </ModalHeader>
                        <ModalBody className="text-center m-3">
                            <Loader />
                        </ModalBody>
                    </Modal>
                    <Card>
                        <CardHeader>
                            <CardTitle tag="h5" className="mb-0">
                                Tab Menu Items
                            </CardTitle>
                        </CardHeader>
                        <CardBody>
                            <div className="TopButtons">
                                <Button size="large" color="secondary" onClick={e => this.newMenuItemTypeForm(null, ItemTypes.SECTION)}>
                                    Create New Section
                                </Button>
                                <Button size="large" color="primary" onClick={e => this.saveTree()}>
                                    Save Tree
                                </Button>
                            </div>
                            <div style={{ height: 1200 }}>
                                <SortableTree
                                    treeData={this.state.treeData}
                                    canDrop={this.canDrop}
                                    onChange={treeData => {
                                        this.setState({ treeData: [...treeData], pendingChanges: true });
                                    }}
                                    theme={FileExplorerTheme}
                                    generateNodeProps={({ node, path }) => ({
                                        buttons: this.setButtons(node, path)
                                    })}
                                    isVirtualized={false}


                                />
                            </div>
                            <div className="BottomButtons">
                                <Button size="large" color="primary" onClick={e => this.saveTree()}>
                                    Save Tree
                                </Button>
                            </div>
                        </CardBody>
                    </Card>

                    <Modal isOpen={this.state.formModalOpen === true} style={{maxWidth: '1120px'}} centered size="large">
                        <Button color="secondary" onClick={e => this.setState({ formModalOpen: false })}>
                            Close
                        </Button>
                        <ModalHeader>New {this.state.currentMenuItemType}</ModalHeader>
                        <ModalBody className="text-center m-3">
                            <Container fluid className="mb-0">
                                <TabMenuItemEditForm
                                    mode={this.state.formMode}
                                    tabMenuItemData={this.state.currentTabMenuItemData}
                                    sectionData={this.state.treeData}
                                    updateCallback={this.updateTreeWithChange} />
                            </Container>
                        </ModalBody>
                    </Modal>

                </Container>
            )
        } else if (this.state.initSuccess === false) {
            return (
                <GenericErrorAlert />
            );
        } else {
            return (
                <Loader />
            );
        }
    }
}

export default connect(null, { setTableauMenus, saveRoutes })(TabMenuItems)