import React from "react";
import {
    Input,
    Progress,
    Modal,
    ModalBody,
    ModalHeader,
    ModalFooter,
    Button
} from "reactstrap";
import { Spinner } from "react-bootstrap";
import { toastr } from "react-redux-toastr";
import {
    Video as VideoIcon
} from "react-feather";
import AWS from 'aws-sdk';
import classNames from "classnames";

const cloundFront = 'https://dtjlhiahlv53j.cloudfront.net/';
export default class UploadFile extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            imgToUpload: null,
            imgDisplay: "",
            fileType: "",
            imgValidity: false,
            progress: null,
            imgPreview: false
        };


        this.clearField = this.clearField.bind(this);

        // Initialize the Amazon Cognito credentials provider
        AWS.config.region = 'us-east-1'; // Region
        AWS.config.credentials = new AWS.CognitoIdentityCredentials({
            IdentityPoolId: 'us-east-1:276420ee-1216-4750-8371-fecc87d8c1e1',
        });
        const customBackoff = (retryCount) => {
            console.log(`retry count: ${retryCount}, waiting: 1000ms`)
            return 10000
        }
        this.myBucket = new AWS.S3({
            params: { Bucket: 'qs-goldenhippo', Region: 'us-west-2' },
            maxRetries: 2,
            retryDelayOptions: { customBackoff },
            httpOptions: {
                connectTimeout: 2 * 10000, // time succeed in starting the call
                timeout: 240000, // time to wait for a response
                // the aws-sdk defaults to automatically retrying
                // if one of these limits are met.
            },
        })
    }

    /**
     * 
     * @param {*} file - image sfile
     * create bytes array from file. in UTF8 bytes array 
     * 
     */
    getFileData(file) {
        this.setState({ progress: "started" });
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = (el) => {
                this.setState({
                    imgToUpload: file,
                    imgDisplay: el.target ? el.target.result : "",
                    fileType: file.type,
                    imgValidity: true,
                    dataRefName: this.props.dataRefName
                })
                resolve(reader.result);
            };
        });
    }

    changeFile(file) {
        // File was changed and supplied
        if (file) {
            this.getFileData(file)
                .then(fileBytes => {
                    this.setState({ fileBytes: fileBytes, imgName: Date.now() + "_" + file.name.replace(/[^a-zA-Z0-9_@.-]/g, '') },
                        () => {
                            this.uploadImg(file);
                        });
                });
        }
        // File was changed and prompt was closed without selecting a file
        else {
            this.setState({
                imgToUpload: null,
                imgDisplay: "",
                imgValidity: false
            });
        }
    }

    uploadImg = (file) => {
        const params = {
            ACL: 'public-read',
            Key: this.props.pageData.global["path"].replace(cloundFront, "") + '/upload' + '/' + this.state.imgName,
            ContentType: file.type,
            Body: file,
        }
        this.myBucket.upload(params)
            .on('httpUploadProgress', (evt) => {
                // that's how you can keep track of your upload progress
                this.setState({
                    progress: Math.round((evt.loaded / evt.total) * 100),
                })
            })
            .send((err) => {
                if (err) {
                    // handle the error here
                    console.log('failed to upload')
                    toastr.warning('Failed to upload file (' + this.state.imgName + ') -Please try again');
                    this.setState({ progress: null, inputKey: Date.now() });
                } else {
                    this.setState({ progress: "done" });
                    if (this.props.type.includes('video') && this.props.inputValue.includes(cloundFront)) {
                        this.props.updateCallback({
                            [this.state.dataRefName]: [this.props.pageData.global["path"] + "/upload/" + this.state.imgName, this.props.pathObj]
                        });
                    } else {
                        this.props.updateCallback({ [this.state.dataRefName]: ["upload/" + this.state.imgName, this.props.pathObj] });
                    }
                }
            })
    }

    clearField() {
        this.setState({ inputKey: Date.now() });
        this.props.updateCallback({ [this.props.dataRefName]: ["", this.props.pathObj] });
    }

    render() {
        const imgUrl = this.props.pageData.global["path"] + "/" + "upload/" + this.state.imgName;
        return (
            <>
                <div className="uploadImage_content">
                    <a className="btn btn-secondary" onClick={() => this.clearField()} key={this.state.inputKey}>Clear Field</a>
                </div>
                <div className="uploadImage_content">
                    <label className="btn btn-secondary">
                        Upload file
                        <Input type="file" name={this.inputId} id="fileUploader" style={{ display: 'none' }} required accept={this.props.type.includes('video') ? "video/*" : "image/*"}
                            onChange={(e) => { this.changeFile(e.target.files["0"]); }} key={this.state.inputKey}
                        />
                    </label>

                    {this.state.fileType.includes('image') && this.state.progress == "done" &&
                        <a classNames={this.state.progress == "done" ? "" : "hidden"} onClick={() => this.setState({ imgPreview: true })}>
                            <img style={{ maxWidth: "150px" }} src={this.state.imgDisplay}></img>
                        </a>
                    }
                    {this.state.fileType.includes('video') && this.state.progress == "done" &&
                        <a className={classNames("videoSnapshot", this.state.progress == "done" ? "" : "hidden")} onClick={() => this.setState({ imgPreview: true })}>
                            <span className="icon"> <VideoIcon /> </span> Click for video preview
                        </a>
                    }
                    <Modal
                        isOpen={this.state.imgPreview === true}
                        centered
                    >
                        <ModalHeader> Preview </ModalHeader>
                        <ModalBody className="text-center m-5">
                            <a href={imgUrl} target="_blank">{this.state.imgName}</a>
                            {this.state.fileType.includes('image') &&
                                <img style={{ maxWidth: "500px" }} src={this.state.imgDisplay}></img>
                            }
                            {this.state.fileType.includes('video') &&
                                <video style={{ maxWidth: "500px" }} controls> <source src={this.state.imgDisplay} type="video/mp4" /> </video>
                            }
                        </ModalBody>
                        <ModalFooter>
                            <Button color="secondary" onClick={() => this.setState({ imgPreview: false })}>
                                Close
                            </Button>{" "}
                        </ModalFooter>
                    </Modal>
                    {[null, "started", "done"].every((currentValue) => this.state.progress !== currentValue) &&
                        <Progress striped value={this.state.progress} className="mb-3">
                            {this.state.progress}%
                        </Progress>
                    }
                    {this.state.progress == "started" &&
                        <Spinner animation="border" variant="primary" className="me-7" />
                    }
                </div>
            </>
        )
    }
}