export const formStatus = {
    IDLE: 'idle',
    IN_PROGRESS: 'In Progress',
    CONFIRMATION_REQUIRED: 'Confirmation Required',
    SUCCESS: 'Success',
    FAILED: 'Failed'
}

export const validUrlWithProtocolRegex = /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_.~#()?&//=]*)/g;

export function removeAllSpecialCharsAndCleanName(item){
    let format = /[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
    
    if(format.test(item)){
        let SpecialChars = item.replace(/[^\w\s]/gi, '');
        let removeExtraSpaces = SpecialChars.replace(/\s+/g,' ');
        let CleanSpecialChars = removeExtraSpaces.replace(/\s/g,"-").trim();
        return CleanSpecialChars;
    } else {
        let cleanItem =  item.replace(/\s/g,"-").trim();
        return cleanItem;
    }
       
}
