export function flattenUser(userObj){
    let user = {};

    user.userId = userObj.userId;
    user.userLogin = userObj.userLogin;
    user.userRole = userObj.userRole.userRoleId;
    user.userRoleDisplayName = userObj.userRole.displayName;
    if (userObj.userParent != null) {
        user.userParent = userObj.userParent.userId;
        user.parentLogin = userObj.userParent.userLogin;
    } else {
        user.userParent = null;
    }
    if (userObj.department != null) {
        user.departmentId = userObj.department.id;
        user.departmentName = userObj.department.name;
    } else {
        user.departmentId = null;
    }

    return user;                
}