import React from "react";
import { toastr } from "react-redux-toastr";
import Select from "react-select";
import { connect } from 'react-redux';
import { setUserPages } from "../../../../redux/actions/userContextActions";


const videoCustomAudienceByPage = "videoCustomAudienceByPage";
const pageCustomAudience = "pageCustomAudience";
const access_token = "EAAHHvkZBb9L0BAIXNNObNYLKw32cI4drHwhvGxD1YNAMnkT2nS8TFbaMgGxHiUOCFcfpZAZA5vqYGjJEUud6gDujNZATXoHZAY5FN4lCSavojAj8QzjotPnGhvNr5BTpbZCZCLH4YBwbDMZBde2X97CldxoKGaPz0My3ZBVJoFY4ZB1NUr19tTBiF8";

class PageDropdown extends React.PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            initSuccess: null,
            pages: []
        }

        this.pageChanged = this.pageChanged.bind(this);
    }

    componentDidMount() {
        const type = this.props.type;
        if (type === videoCustomAudienceByPage) {
            this.getPagesByAccount();
        } else if (type === pageCustomAudience) {
            this.getPagesByRole();
        }
    }

    componentDidUpdate(prevProps) {
        if (this.props.type === videoCustomAudienceByPage && prevProps.selectedAccount.accountId !== this.props.selectedAccount.accountId) {
            this.getPagesByAccount();
        }
    }

    pageChanged(id) {
        let selectedPage = this.state.pages.find(item => item.id == id);
        this.props.pageChanged(selectedPage);
    }

    //Pages the User has a role on.
    getPagesByRole = () => {
        window.FB.api('/me/accounts', 'GET', {
            fields: "id,name",
            limit: "1000",
            access_token: access_token
        },
            response => this.handleApiResponse(response));
    };

    //All pages that have been promoted by under the ad account.
    getPagesByAccount = () => {
        window.FB.api(`/act_${this.props.selectedAccount.accountPlatformId}/promote_pages`, 'GET', {
            fields: "id,name",
            access_token: access_token
        }, response => this.handleApiResponse(response));
    };

    handleApiResponse = (response, successCallback) => {
        if (response && !response.error) {
            const { data } = response;
            this.props.setUserPages({ list: [...data] });
            this.setState({ initSuccess: true, pages: data });
            this.props.updateStateCallback('initSuccess', true);
            if (this.props.getPages) {
                this.props.updateStateCallback('pages', [...data]);
            }
            successCallback && successCallback(data);
        } else {
            toastr.error("Error FacebookAPI: " + response.error.message);
            this.setState({ initSuccess: false });
            this.props.updateStateCallback('initSuccess', false);
        }
    };

    render() {
        if (this.state.initSuccess === true) {

            let pageSelectOptions = this.state.pages.map(page => { return { value: page.id, label: page.name } });
            let selectedPage = this.props.selectedPage ? pageSelectOptions.find(option => option.value == this.props.selectedPage.id) : null;

            if (selectedPage === undefined) {
                selectedPage = null;
            }

            return (
                <Select
                    options={pageSelectOptions}
                    id="pageDropdown"
                    placeholder="Select a page"
                    value={selectedPage}
                    onChange={selectedOption => this.pageChanged(parseInt(selectedOption.value))}
                    filterOption={(item, input) => {
                        if (input) {
                            return item.data.label.toLowerCase().includes(input.toLowerCase());
                        }
                        return true;
                    }
                    } />)
        } else {
            return null;
        }
    }
}
export default connect(
    (store) => {
        return {
            pages: store.userContext.pages
        }
    },
    { setUserPages })(PageDropdown)