import { genericCachedFetcherFactory } from "../utils/requestUtils";
import { addHours } from "../utils/dateUtils";

export const CACHE_EXPIRATION_DEFAULTS = {
    Accounts: 24 * 7,
    Clients: 24 * 7,
    AuthModules: 24,
    SlackChannels: 24 * 7
}

export function prefetchEntities(authMods) {
    if (authMods.find(authMod => authMod.moduleProxy === "accounts")) {
        genericCachedFetcherFactory("/api/accounts/Accounts", "ACCOUNTS", "Could not prefetch accounts", addHours(CACHE_EXPIRATION_DEFAULTS.Accounts), "GET", null, true, false)().then(result => {
            if (result.success !== true) {
                console.warn(result.message);
            }
        });
    }

    if (authMods.find(authMod => authMod.moduleProxy === "crm")) {
        genericCachedFetcherFactory("/api/crm/Clients", "CLIENTS", "Could not prefetch clients", addHours(CACHE_EXPIRATION_DEFAULTS.Clients), "GET", null, true, false)().then(result => {
            if (result.success !== true) {
                console.warn(result.message);
            }
        });
    }
}