import React from "react";
import { connect } from 'react-redux';
import { setUserStatus, setCmsData, setDeploymentSharedComponent } from "../../../redux/actions/cmsActions"
import { UserStatusUtils } from '../UserStatusUtils';
import { SharedComponentUtils } from '../SharedComponentUtils';
import { cmsUtils } from '../utils';
import SharedComponentList from './SharedComponentList';
import TemplatesList from './TemplatesList';
import DataPrep from './DataPrep';
import Deployment from '../Deployment';

import {
    Alert,
} from "react-bootstrap";

class DeploymentSharedComponent extends React.PureComponent {
    constructor(props) {
        super(props)
        this.state = {
            currentStep: 1,
            error: null
        }

        this.utils = new cmsUtils();
        this.userStatusUtils = new UserStatusUtils();
        this.SharedComponentUtils = new SharedComponentUtils();
        this._next = this._next.bind(this)
        this._prev = this._prev.bind(this)

        this.reset = this.reset.bind(this)

    }

    componentDidMount() {
        this.userStatusUtils.userInitStatus();

    }

    //next & prev btn's
    _next(type) {
        let currentStep = this.state.currentStep;
        currentStep = this.updateStep(currentStep, type, 'next');
    }

    updateStep(currentStep) {
        // If the current step is 1 - 4, then add one on "next" button click
        currentStep = currentStep >= 4 ? 1 : currentStep + 1;

        //**After change page**
        if (currentStep === 1) {
            this.reset();
        }
        //set active page
        this.setState({
            currentStep: currentStep
        })

    }

    reset() {
        this.props.setCmsData({
            ...this.props.cmsData,
            deploySelectedComponent: null,
            template: null
        });
    }

    _prev() {
        let currentStep = this.state.currentStep;

        if (currentStep === 3) {
            currentStep = 1
        } else {
            currentStep = currentStep <= 1 ? 1 : currentStep - 1
        }

        this.reset();
        //**After change page**
        //set active page
        this.setState({
            currentStep: currentStep
        })
    }

    get previousButton() {
        let currentStep = this.state.currentStep;
        // If the current step is not 1, then render the "previous" button
        if (currentStep !== 1) {
            return (
                <button
                    className="prevBtn btn btn-secondary sticky"
                    type="button" onClick={this._prev}>
                    Previous
                </button>
            )
        }
        return null;
    }

    isNextStepAvailable() {
        let checkResult;

        switch (this.state.currentStep) {
            case 1:
                checkResult = this.props.cmsData.sharedComponentsListIsLoaded && this.props.cmsData.deploySelectedComponent;
                break;
            case 2:
                checkResult = this.props.cmsData.template;
                break;
            case 4:
                checkResult = this.props.deploymentSharedComponent[this.props.cmsData.deploySelectedComponent].length > 0;
                break;
            default:
                checkResult = false;
                break;
        }

        return checkResult;
    }

    get nextButton() {
        let buttonText = this.state.currentStep < 4 ? "Next" : "Update the next template for " + this.props.cmsData.deploySelectedComponent;
        return (
            <button
                disabled={!this.isNextStepAvailable()}
                className="nextBtn btn btn-secondary float-right sticky"
                type="button" onClick={this._next}>
                {buttonText}
            </button>
        )
    }


    render() {

        return (
            <>
                {this.props.deploymentSharedComponent.viewOnly ? <Alert varian="info" className="viewOnly__alert"><div className="alert-message">You are currently in <b>View only</b> mode</div></Alert> : ''}
                <p>Step <b>{this.state.currentStep}</b> out of 4 </p>
                <div className="cms deploy_shared_component">
                    {this.state.currentStep === 1 &&
                        <SharedComponentList />
                    }

                    {this.state.currentStep === 2 && this.props.cmsData.fetchedSharedComponentsMapping !== null &&
                        <TemplatesList />
                    }

                    {this.state.currentStep === 2 && this.props.cmsData.fetchedSharedComponentsMapping === null &&
                        <div> {this.props.cmsData.fetchedSharedComponentsMapping_error} </div>
                    }

                    {this.state.currentStep === 3 &&
                        <DataPrep
                            next={this._next}
                        />
                    }

                    {this.state.currentStep === 4 &&
                        <Deployment
                            deployType='componentUpdate'
                        />
                    }

                    {this.state.currentStep !== 4 && this.previousButton}
                    {this.nextButton}

                </div>

            </>
        )
    }
}

export default connect(
    (store) => {
        return {
            deploymentSharedComponent: store.cms.deploymentSharedComponent,
            cmsData: store.cms.cmsData,
            userStatus: store.cms.userStatus,
            user: store.userContext.user
        }
    },
    { setCmsData, setDeploymentSharedComponent, setUserStatus })(DeploymentSharedComponent)