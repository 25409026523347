
import React from 'react';

import { Button, Form, Row } from "react-bootstrap";
import { toastr } from "react-redux-toastr";
import * as Yup from "yup";
import { Formik } from "formik";
import { hasOwnProp } from 'fullcalendar';
import { RelatedEntityFetchers } from './userUtils';
import GenericErrorAlert from "../../../components/GenericErrorAlert";
import Loader from '../../../components/Loader';


const FormModes = {
    NEW: "new",
    EDIT: "edit"
}

export { FormModes };

export default class UserEditForm extends React.PureComponent {
    constructor(props) {
        super(props);
        this.sumbitForm = this.sumbitForm.bind(this);
        this.state = {
            initSuccess: null,
            userRoles: [{ userRoleId: 0, displayName: "Select Role" }], users: [{ userId: 0, userLogin: "None" }],
            departments: [{ departmentId: 0, departmentName: "Select Department" }]
        }
    }

    async componentDidMount() {
        let newState = {};

        let entitiesToFetch = [];

        if (this.props.preloadedUsers != null) {
            this.setState({ ...this.state, users: [{ userId: 0, userLogin: "None" }, ...this.props.preloadedUsers] });
        } else {
            entitiesToFetch.push(RelatedEntityFetchers.fetchUsers());
        }
        entitiesToFetch.push(RelatedEntityFetchers.fetchUserRoles());
        entitiesToFetch.push(RelatedEntityFetchers.fetchUserDepartments());

        if (entitiesToFetch.length > 0) {
            let relatedEntityFetchResults = await Promise.all(entitiesToFetch);
            if (relatedEntityFetchResults.findIndex(result => result.success === false) === -1) {
                let fetchUsers = relatedEntityFetchResults.find(result => result.fetchObject === RelatedEntityFetchers.FetchObjectTypes.USERS);
                if (fetchUsers !== undefined) {
                    let newUsers = [...this.state.users];
                    newUsers.push(...fetchUsers.data);
                    newState.users = newUsers;
                }

                let fetchUserRoles = relatedEntityFetchResults.find(result => result.fetchObject === RelatedEntityFetchers.FetchObjectTypes.ROLES);
                if (fetchUserRoles !== undefined) {
                    let newUserRoles = [...this.state.userRoles];
                    newUserRoles.push(...fetchUserRoles.data);
                    newState.userRoles = newUserRoles;
                }

                let fetchUserDepartments = relatedEntityFetchResults.find(result => result.fetchObject === RelatedEntityFetchers.FetchObjectTypes.DEPARTMENTS);
                if (fetchUserDepartments !== undefined) {
                    let newUserDepartments = [...this.state.departments];
                    newUserDepartments.push(...fetchUserDepartments.data);
                    newState.departments = newUserDepartments;
                }

                newState.initSuccess = true;
                this.setState(newState);
            } else {
                console.error("Failed to fetch user entities");
            }
        } else {
            console.error("Failed to fetch entities for existing user");
            newState.initSuccess = false;
            this.setState(newState);
        }

    }

    /**
     * Does various validation, data manipulation and runs the callback provided on component's submitCallback function
     * with a FormData object constructed from the form.
     * @param {*} event 
     * @param {*} errors 
     * @param {*} values 
     */
    sumbitForm(values) {
        // Logic for when adding a new user
        if (this.props.mode === FormModes.NEW) {
            // All data will exist
            this.props.submitCallback(
                {
                    userLogin: values.userLogin,
                    userRole: parseInt(values.userRole),
                    userParent: values.userParent === 0 ? 0 : parseInt(values.userParent),
                    departmentId: values.departmentId === 0 ? 0 : parseInt(values.departmentId),
                }
            );
        }
        // Logic for when editing an existing user
        else if (this.props.mode === FormModes.EDIT) {
            let changeMade = false;

            let userData = {};

            // Iterate through the form data
            for (let prop in values) {
                // TODO: Change this check to use an object defition rather than relying on the components properties
                if (this.props.userData[prop] !== undefined) {
                    // Check if the field was edited
                    if (values[prop] !== this.props.userData[prop]) {
                        changeMade = true;
                        userData[prop] = values[prop];
                    }
                } else {
                    console.warn("field " + prop + " doesn't exist");
                }
            }

            if (hasOwnProp(userData, "userRole")) {
                userData.userRoleId = parseInt(userData.userRoleId);
            }

            if (hasOwnProp(userData, "userParent")) {
                userData.userParent = parseInt(userData.userParent);
            }

            if (hasOwnProp(userData, "departmentId")) {
                userData.departmentId = parseInt(userData.departmentId);
            }

            if (changeMade === true) {
                // Append the productId for completion
                userData["userId"] = this.props.userData.userId;
            }

            // Finally check if there was actually a change made before proceeding to the callback
            if (changeMade === false) {
                toastr.warning("Cannot save user", "No changes were made");
                return;
            } else {
                this.props.submitCallback(userData, this.props.userData.userId);
            }
        }
    }

    render() {
        let roleSelectOptions = "";
        if (this.state.userRoles) {
            roleSelectOptions = this.state.userRoles.map(item => <option value={item.userRoleId}>{item.displayName}</option>);
        }

        let userSelectOptions = "";
        if (this.state.users) {
            userSelectOptions = this.state.users.map(item => <option value={item.userId}>{item.userLogin}</option>);
        }

        let departmentSelectOptions = "";
        if (this.state.departments) {
            departmentSelectOptions = this.state.departments.map(item => <option value={item.id}>{item.name}</option>);
        }

        if (this.state.initSuccess === true) {
            return (
                <Formik
                    initialValues={this.props.userData}
                    validationSchema={Yup.object().shape({
                        userLogin: Yup.string().email("Invalid email format").required("Email is required"),
                        userRole: Yup.number().min(1,"Role is required").required("Role is required")
                    })}
                    onSubmit={(values) => this.sumbitForm(values)}
                >
                    {({ handleSubmit, handleChange, handleBlur, errors, touched, values }) => (
                        <Form onSubmit={handleSubmit}>
                            <Row className="gx-1 mt-3 form__user"  >
                                <Form.Group className="col gx-1">
                                    <Form.Label htmlFor="userLogin">User Login</Form.Label>
                                    <Form.Control
                                        name="userLogin"
                                        id="userLogin"
                                        value={values.userLogin}
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        isInvalid={!!errors.userLogin}
                                    />
                                    {!!touched.userLogin &&
                                        <Form.Control.Feedback type="invalid">{errors.userLogin}</Form.Control.Feedback>
                                    }
                                </Form.Group>
                                <Form.Group className="col gx-1">
                                    <Form.Label htmlFor="userRole">userRole</Form.Label>
                                    <Form.Control
                                        as="select"
                                        name="userRole"
                                        id="userRole"
                                        value={values.userRole}
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        isInvalid={!!errors.userRole && touched.userRole}
                                    >
                                        {roleSelectOptions}
                                    </Form.Control>
                                    {!!touched.userRole &&
                                        <Form.Control.Feedback type="invalid">{errors.userRole}</Form.Control.Feedback>
                                    }
                                </Form.Group>
                                <Form.Group className="col gx-1">
                                    <Form.Label htmlFor="userParent">User Parent</Form.Label>
                                    <Form.Control
                                        as="select"
                                        name="userParent"
                                        id="userParent"
                                        value={values.userParent}
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                    >
                                        {userSelectOptions}
                                    </Form.Control>
                                </Form.Group>
                                <Form.Group className="col gx-1">
                                    <Form.Label htmlFor="departmentId">User Department</Form.Label>
                                    <Form.Control
                                        as="select"
                                        name="departmentId"
                                        id="departmentId"
                                        value={values.departmentId}
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                    >
                                        {departmentSelectOptions}
                                    </Form.Control>
                                </Form.Group>
                                <Button type="submit" className="submit-btn col-auto gx-1 align-self-start" variant="primary">Submit</Button>
                            </Row>
                        </Form>
                    )}
                </Formik>
            )
        } else if (this.state.initSuccess === false) {
            return (
                <GenericErrorAlert />
            );
        } else {
            return (
                <Loader></Loader>
            )
        }
    }
}

UserEditForm.defaultProps = {
    userData: {
        userId: null,
        userLogin: "",
        userRole: 0,
        userParent: 0,
        departmentId: 0,
    },
    preloadedUsers: null,
    mode: FormModes.NEW
}