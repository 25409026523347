import React from "react";
import {
    Button,
    Card,
    Container,
    Modal
} from "react-bootstrap";
import BootstrapTable from "react-bootstrap-table-next";
import filterFactory, { selectFilter, textFilter } from 'react-bootstrap-table2-filter';
import * as Icon from "react-feather";
import { toastr } from "react-redux-toastr";
import GenericErrorAlert from "../../../components/GenericErrorAlert";
import Loader from '../../../components/Loader';
import CacheController from "../../../services/cache/CacheController";
import { CACHE_EXPIRATION_DEFAULTS } from "../../../services/qsCache";
import { addHours } from "../../../utils/dateUtils";
import { genericCachedFetcherFactory, genericFetcherFactory, getCacheKeyForUrl, handleErrorResponse } from "../../../utils/requestUtils";
import { createTimezonesArrayFromMap } from "../../../utils/timeZonesUtils";
import EntitySlackChannelsView from "../../slack/EntitySlackChannelsView";
import SlackAlertChannelEditForm from "../../slack/SlackAlertChannelEditForm";
import SlackChannelService, { slackModalStyles } from "../../slack/slackService";
import AccountEditForm, { FormModes } from './AccountEditForm';
import { AttributionWindows, Platforms, RelatedEntityFetchers } from "./accountUtils";





export default class Accounts extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            accounts: [],
            initSuccess: null,
            brands: [],
            users: [],
            currencies: [],
            timeZones: [],
            capsules:[],
            modal: false,
        };

        this.slackService = new SlackChannelService();
        this.updateSlackChannelsSuccessCallback = this.updateSlackChannelsSuccessCallback.bind(this);
        this.updateAccount = this.updateAccount.bind(this);

    }

    componentDidMount() {
        (async () => {
            let fetchSuccessful = false;

            // Fetch all related entities and check that all of the fetches succeeded
            // Check if there is a 'false' result in one of the promise resolutions. If the index found is anything except -1 then one of the fetches failed.
            let [fetchBrandsResult, fetchUsersResult, fetchCurrenciesResult, fetchTimeZonesResult,fetchCapsulesResult] = await Promise.all([RelatedEntityFetchers.fetchBrands(), RelatedEntityFetchers.fetchUsers(), RelatedEntityFetchers.fetchCurrencies(), RelatedEntityFetchers.fetchTimeZones(),RelatedEntityFetchers.fetchCapsules]);

            if ([fetchBrandsResult, fetchUsersResult, fetchCurrenciesResult, fetchTimeZonesResult,fetchCapsulesResult].findIndex(result => result.success === false) === -1) {
                this.setState({
                    ...this.state,
                    brands: fetchBrandsResult.data,
                    users: fetchUsersResult.data,
                    currencies: fetchCurrenciesResult.data,
                    timeZones: createTimezonesArrayFromMap(fetchTimeZonesResult.data),
                    capsules: fetchCapsulesResult
                });

                await this.slackService.build("account");

                // Fetch the accounts and check that the fetch succeeded
                if (await this.fetchAccounts() === true) {
                    // Fetch products and check that the fetch succeeded
                    if (await this.fetchProducts() === true) {
                        fetchSuccessful = true;
                    }
                }
            }

            if (fetchSuccessful !== false) {
                this.setState({ ...this.state, initSuccess: true });
            } else {
                this.setState({ ...this.state, initSuccess: false });
            }
        })();
    }

    updateAccount(accountObj, accountId) {
        this.setState({ ...this.state, formSaving: true })
        genericFetcherFactory("/api/accounts/Accounts/Account/" + accountId, "ACCOUNT_UPDATE", "Failed to save account", "PATCH",
            {
                method: "PATCH",
                body: JSON.stringify(accountObj),
                headers: { 'Content-Type': 'application/json', 'X-QS-UserIdentifier': accountObj.userLogin }
            })().then(res => {
                if (res.success) {
                    // Clear cache and refetch
                    CacheController.deleteObject(getCacheKeyForUrl("/api/accounts/Accounts"));
                    genericCachedFetcherFactory("/api/accounts/Accounts", "ACCOUNTS", "Fetch accounts failed", addHours(CACHE_EXPIRATION_DEFAULTS.Accounts), "GET", null, true, false)();
                    let updateAccount = res.data;
                    let newAccounts = [...this.state.accounts];
                    let accountIndex = newAccounts.findIndex(item => (item.accountId === accountId));
                    newAccounts.splice(accountIndex, 1, this.normalizeAccount(updateAccount));
                    this.setState({ ...this.state, accounts: newAccounts, formSaving: false });
                    toastr.success("Update Success", updateAccount.accountName + " was updated successfully.")

                } else {
                    this.setState({ ...this.state, formSaving: false });
                    handleErrorResponse(res, "Update Failed");
                }
            });
    }

    normalizeAccount(accountEntity) {
        let account = {};

        // Account Properties
        account.accountId = accountEntity.accountId;
        account.accountName = accountEntity.accountName;
        account.accountPlatformId = accountEntity.accountPlatformId;
        account.isActive = accountEntity.isActive;
        account.insertDate = accountEntity.insertDate;
        account.email = accountEntity.email;
        account.accountProducts = accountEntity.accountProducts;
        account.deactivateReason = accountEntity.deactivateReason;
        account.deactivateReasonFromUser = accountEntity.deactivateReasonFromUser;

        // Account Platform Properties
        account.platformId = accountEntity.platform.platformId;
        account.platformName = accountEntity.platform.platformName;

        // Account Related Entities
        account.currencyAbbr = accountEntity.currency.currencyAbbr;
        account.currencyId = accountEntity.currency.currencyId;
        account.capsuleId = (accountEntity.capsule !== null ? accountEntity.capsule.capsuleId : 0);
        account.brandId = accountEntity.brandId;
        let accountBrand = this.state.brands.find((brand) => { return brand.brandId == accountEntity.brandId });
        account.brandName = accountBrand !== undefined ? accountBrand.brandName : "[Not found]";

        account.cmUserId = accountEntity.cmUserId;
        let accountUser = this.state.users.find((user) => { return user.userId == accountEntity.cmUserId });
        account.userLogin = accountUser !== undefined ? accountUser.userLogin : "[Not found]";

        account.gmtId = accountEntity.gmtId;
        let timeZone = this.state.timeZones.find((item) => { return item.gmtId == accountEntity.gmtId });
        account.gmtName = timeZone !== undefined ? timeZone.tzDisplay : "[Not Found]";

        account.accountProducts = accountEntity.accountProducts;

        // Platform Specific Properties
        switch (account.platformName) {
            case Platforms.FACEBOOK:
                account.attributionWindowId = accountEntity.attributionWindow.attributionWindowId;
                let accountAttributionWindowName = AttributionWindows.find((item) => { return item.attrWindowId == account.attributionWindowId });
                account.attributionWindowName = accountAttributionWindowName !== undefined ? accountAttributionWindowName.attrWindowName : "[Not Found]";
                account.facebookPageId = accountEntity.facebookPageId;
                account.isUnique = accountEntity.isUnique;
                break;
            case Platforms.GOOGLE:
                account.accountGoogleChannels = accountEntity.accountGoogleChannels;
                break;
        }

        account = this.handleAccountSlacks(account)

        return account;
    }

    async fetchProducts() {
        let brandIds = this.state.accounts.map(account => { return account.brandId });

        return genericFetcherFactory("/api/product/Products/byBrandIdList", "BRANDS", "Failed to fetch from slack's API Service", "POST",
            {
                method: "POST",
                body: JSON.stringify(brandIds),
                headers: { 'Content-Type': 'application/json' }
            })().then(res => {
                if (res.success == true) {
                    let responseData = res.data;
                    this.setState({ ...this.state, products: responseData });
                    return Promise.resolve(true);
                } else {
                    handleErrorResponse(res, "Fetch Products by Brand ID's Failed");
                    return Promise.resolve(false);
                }
            })

    }

    async fetchAccounts() {
        let response = await genericCachedFetcherFactory("/api/accounts/Accounts", "ACCOUNTS", "Fetch accounts failed", addHours(CACHE_EXPIRATION_DEFAULTS.Accounts))();

        if (response.success === true) {
            let responseData = response.data;
            let normalizedAccounts = [];
            responseData.forEach(account => {
                normalizedAccounts.push(this.normalizeAccount(account));
            });
            this.setState({ ...this.state, accounts: normalizedAccounts });
            return Promise.resolve(true);
        } else {
            return Promise.resolve(false);
        }
    }

    updateSlackChannelsSuccessCallback(data) {
        let newAccounts = [...this.state.accounts];
        let updatedAccount = { ...newAccounts.find(account => account.accountId === data.entityId) };
        updatedAccount = this.handleAccountSlacks(updatedAccount);
        let index = newAccounts.findIndex(account => account.accountId === updatedAccount.accountId);
        if (index != -1) {
            newAccounts.splice(index, 1, updatedAccount);
            this.setState({ modal: false, initSuccess: true, accounts: newAccounts, formSaving: false });
            toastr.success("Slack Channel added to account successfully.");
        } else {
            console.error("index not found");

            this.setState({ formSaving: false, modal: false });
        }


    }

    handleAccountSlacks(account) {
        account.slackChannelsNameList = <EntitySlackChannelsView entityType="account" entityId={account.accountId} />;
        return account;
    }

    // Boostrap table configuration 
    // Expand row configuration for editing rows in the Accounts table
    expandRow = {
        renderer: row => {
            return (
                <>
                    <div className="ml-5 mr-5 mt-3 mb-3">
                        <AccountEditForm
                            mode={FormModes.EDIT}
                            accountData={row}
                            submitCallback={this.updateAccount}
                            prefetchedData={{ brands: this.state.brands, users: this.state.users, currencies: this.state.currencies, timeZones: this.state.timeZones,capsules:this.state.capsules }} />
                        <Button id="slackAlertChannels" variant="secondary" onClick={e => this.setState({ modal: true })}>
                            Add Slack Alert Channels
                        </Button>
                    </div>
                    <Modal show={this.state.modal === true} size="xl" style={slackModalStyles} centered>
                        <Button variant="secondary"
                            style={{ position: "absolute", top: "10px", right: "10px" }}
                            onClick={e => this.setState({ modal: false })}>
                            Close
                        </Button>
                        <Modal.Header>Slack Alert Channels</Modal.Header>
                        <Modal.Body className="text-center m-2">
                            <Container fluid className="mb-0">
                                <SlackAlertChannelEditForm
                                    entityId={row.accountId}
                                    entityType={"account"}
                                    updateSuccessCallback={this.updateSlackChannelsSuccessCallback}
                                    failedCallback={(err) => { toastr.error(err) }}
                                />
                            </Container>
                        </Modal.Body>
                    </Modal>
                </>
            )
        },
        expandColumnRenderer: ({ expanded }) => {
            if (expanded) {
                return (
                    <Icon.Edit className="feather align-middle" />
                );
            }
            return (
                <Icon.Edit2 className="feather align-middle" />
            );
        },
        expandHeaderColumnRenderer: ({ isAnyExpands }) => {
            return "";
        },
        showExpandColumn: true,
        expandByColumnOnly: true,
        expandColumnPosition: 'right'
    };

    accountsColumns = [
        {
            dataField: "accountId",
            text: "ID",
            headerStyle: { width: "40px" },
            sort: true,
            filter: textFilter()
        },
        {
            dataField: "accountName",
            text: "Account Name",
            sort: true,
            filter: textFilter()
        },
        {
            dataField: "gmtName",
            text: "Timezone",
            sort: true,
            filter: textFilter()
        },
        {
            dataField: "isActive",
            text: "Active",
            sort: true,
            headerStyle: { width: "70px" },
            filter: selectFilter({ options: { true: 'Active', false: 'Inactive' } })
        },
        {
            dataField: "currencyAbbr",
            text: "Currency",
            sort: true,
            headerStyle: { width: "85px" },
            filter: textFilter()
        },
        {
            dataField: "platformName",
            text: "Platform",
            sort: true,
            headerStyle: { width: "90px" },
            filter: selectFilter({ options: { [Platforms.FACEBOOK]: 'Facebook', [Platforms.GOOGLE]: 'Google', [Platforms.BING]: 'Bing', [Platforms.TABOOLA]: 'Taboola', [Platforms.TIKTOK]: 'TikTok', [Platforms.TWITTER]: 'Twitter', [Platforms.DV360]: 'DV', [Platforms.TWITTER]: 'Twitter', [Platforms.SNAPCHAT]: 'Snapchat'} })
        },
        {
            dataField: "accountPlatformId",
            text: "Account Platform Id",
            sort: true,
            filter: textFilter()
        },
        {
            dataField: "brandName",
            text: "Brand",
            sort: true,
            filter: textFilter()
        },
        {
            dataField: "slackChannelsNameList",
            text: "Slack Alert Channel list",
            sort: true
        }
    ]

    render() {
        if (this.state.initSuccess === true) {
            return (
                <Container fluid className="p-0">
                    <Modal isOpen={this.state.formSaving === true} centered>
                        <Modal.Header>
                            Saving Account...
                        </Modal.Header>
                        <Modal.Body className="text-center m-3">
                            <Loader />
                        </Modal.Body>
                    </Modal>
                    <Card>
                        <Card.Header>
                            <Card.Title tag="h5" className="mb-0">
                                Accounts
                            </Card.Title>
                        </Card.Header>
                        <Card.Body>
                            <BootstrapTable
                                bootstrap4
                                keyField="accountId"
                                bordered={false}
                                striped
                                hover
                                data={this.state.accounts ? this.state.accounts : []}
                                columns={this.accountsColumns}
                                expandRow={this.expandRow}
                                filter={filterFactory()}
                            />
                        </Card.Body>
                    </Card>
                </Container>
            )
        } else if (this.state.initSuccess === false) {
            return (
                <GenericErrorAlert />
            );
        } else {
            return (
                <Card>
                    <Card.Header>
                        <Card.Title tag="h5" className="mb-0">
                            Account
                        </Card.Title>
                    </Card.Header>
                    <Loader />
                </Card>
            );
        }
    }
}