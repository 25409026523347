import * as types from "../constants";

const initialState = {
    tableauMenus: [],
    tableauTabs: []
}

export default function reducer(state = initialState, action) {
    switch (action.type) {
        case types.SET_TABLEAU_MENUS:
            return {
                ...state,
                tableauMenus: action.payload
            }
        default:
            return state;
    }
}