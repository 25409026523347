import React from "react";
import { Form, ProgressBar, Spinner } from "react-bootstrap";
import { Upload } from "react-feather";
import { toastr } from "react-redux-toastr";
import { facebookApiErrorHandler, facebookApiFetchWrapper } from "../../../utils/facebookApiUtils";
import { formStatus } from "../../../utils/formUtils";

export class UploadFacebookVideoForm extends React.Component {
    constructor(props) {
        super(props);
        this.uploadVideoFile = this.uploadVideoFile.bind(this);
        this.faceBookGraphUrl = `https://graph-video.facebook.com/v19.0/${props.acc}/advideos`;
        this.state = {
            progress: null,
            videoSource: null,
            vidToUpload: null,
            vidDisplay: "",
            vidFormStatus: formStatus.IDLE,
            vidFacebookId: this.props.videoId,
            vidValidity: false
        };
    }

    getEmbededVideo() {
        let payload = {
            method: "GET"
        }
        facebookApiFetchWrapper(`https://graph-video.facebook.com/v19.0/${this.state.vidFacebookId}?fields=source&access_token=${this.props.accessToken}`, "Get Embeded IFrare", payload)()
            .then(result => {
                if (result.success) {
                    this.setState({ videoSource: result.data.source });
                } else {
                    console.log(result);
                }
            });
    }

    createFBchunkFormDataObject(upload_phase, session_id = null) {
        let formData = new FormData();
        formData.append("access_token", this.props.accessToken);
        formData.append("upload_phase", upload_phase);
        if (session_id !== null) {
            formData.append("upload_session_id", session_id);
        }
        return formData;
    }

    uploadVideo() {
        const formData = this.createFBchunkFormDataObject("start");
        formData.append("start_offset", 0);
        formData.append("file_size", this.state.vidToUpload.size);

        let payload = {
            method: "POST",
            body: formData
        };

        facebookApiFetchWrapper(this.faceBookGraphUrl, "VIDEO_UPLOAD_START", payload)()
            .then(result => {
                console.log(result);

                if (result.success) {

                    this.props.videoIdCallback(result.data.video_id);
                    this.setState({
                        vidFacebookId: result.data.video_id
                    });
                    return new Promise(resolve => {
                        resolve(this.transferVideo(
                            result.data.upload_session_id,
                            result.data.start_offset,
                            result.data.end_offset,
                            1
                        ));
                    })
                } else {
                    this.setState({ progress: null });
                    facebookApiErrorHandler(result);
                }
            }).catch(err => {
                this.setState({ progress: null });
                console.log(err);
            });
    }

    async transferVideo(sessionId, start, end, number) {
        let file = this.state.vidToUpload;
        if (start === end) {
            return new Promise(resolve => {
                resolve(this.postVideo(sessionId, file.name));
            })
        }
        const chunk = this.state.blob.slice(start, end, file.type)
        if (parseInt(start) > 0) {
            let percentage = Math.floor((parseInt(start) / file.size) * 100);
            this.setState({ progress: percentage });
        }
        let formData = this.createFBchunkFormDataObject('transfer', sessionId);
        formData.append('video_file_chunk', chunk);
        formData.append("start_offset", start);

        return new Promise(resolve => {
            resolve(this.uploadVideoChunk(
                formData
            ));
        })
            .then(res => {

                return new Promise(resolve => {
                    resolve(this.transferVideo(
                        sessionId,
                        res.start_offset,
                        res.end_offset,
                        number++
                    ));
                });
            })
            .catch(err => {
                console.log(err)
                let errorObject = {
                    fetchObject: "Tranfer Video Failure",
                    success: false,
                    rawError: err
                };
                this.setState({ progress: null });
                facebookApiErrorHandler(errorObject);
            })
    }

    async uploadVideoChunk(formData) {
        let payload = {
            method: "POST",
            body: formData
        };

        return facebookApiFetchWrapper(this.faceBookGraphUrl, "VIDEO_UPLOAD_TRANSFERING", payload)()
            .then(result => {
                if (result.success) {
                    return result.data;
                } else {
                    this.setState({ progress: null });
                    facebookApiErrorHandler(result);
                }
            });

    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.videoId !== this.props.videoId) {
            this.setState({
                vidFacebookId: nextProps.videoId
            });
            this.getEmbededVideo();
        }
    }

    componentDidMount() {
        if (this.state.vidFacebookId) {
            this.getEmbededVideo();
        }
    }

    postVideo(upload_session_id, title) {
        this.setState({ progress: 100 });

        let formData = this.createFBchunkFormDataObject('finish', upload_session_id);
        formData.append('title', title);
        let payload = {
            method: "POST",
            body: formData
        };

        facebookApiFetchWrapper(this.faceBookGraphUrl, "VIDEO_UPLOAD_FINISH", payload)()
            .then(result => {
                if (result.success) {
                    this.setState({ progress: null });
                    this.props.successCallback(this.state.vidFacebookId);

                } else {
                    this.setState({ progress: null });
                    facebookApiErrorHandler(result);
                }
            });
    }

    changeFile(file) {

        if (file) {
            this.setState({ vidFormStatus: formStatus.IN_PROGRESS });
            let reader = new FileReader();
            reader.onload = (el) => {
                this.setState({
                    vidToUpload: file,
                    vidDisplay: el.target ? el.target.result : "",
                    vidValidity: true,
                    vidFormStatus: formStatus.SUCCESS

                })
                this.uploadVideoFile();
            };
            reader.readAsDataURL(file);
        }
        // File was changed and prompt was closed without selecting a file
        else {
            this.setState({
                vidToUpload: null,
                vidDisplay: "",
                vidValidity: false
            });
        }
    }

    uploadVideoFile() {
        if (this.state.vidToUpload == null) {
            toastr.error("Please select a video to upload");
            this.setState({ ...this.state, vidValidity: false });
            return;
        } else {
            let file = this.state.vidToUpload;
            const blob = new Blob([file], {
                type: file.type
            });

            this.setState({ progress: 0, blob: blob });
        }
        this.uploadVideo();
    }

    uploadVideoIconStatusDisplay() {
        if (this.state.vidFormStatus === formStatus.IN_PROGRESS && this.state.progress === null) {
            return (
                <Spinner color="info" size="sm"></Spinner>
            )
        }

        if (this.state.vidFormStatus === formStatus.SUCCESS && this.state.progress < 100) {
            return (
                <Upload size="24" color="info" />
            )
        }
    }

    render() {
        return (
            <div class="row postTool__uploadVideo">
                <div class="col-4 form-group postTool__uploadVideo-upload">
                    <Form.Label>Video File </Form.Label>
                    <Form.Control type="file" accept="video/*"
                        onChange={(e) => { this.changeFile(e.target.files["0"]) }}
                    />
                    {this.uploadVideoIconStatusDisplay()}

                </div>

                <div class="col-8 postTool__uploadVideo-video">
                    {this.state.progress !== null &&
                        <div class="proggress-wrapper">
                            <ProgressBar animated
                                variant={this.state.progress < 100 ? "primary" : "success"}
                                now={this.state.progress}
                                label={this.state.progress + "%"}
                            />
                        </div>
                    }

                    {(this.state.videoSource !== null && this.state.progress === null) &&
                        <video controls style={{ maxWidth: "300px" }} >
                            <source src={this.state.videoSource} type="video/mp4" />
                        </video>
                    }
                </div>
            </div>
        )
    }

}