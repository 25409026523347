import { default as React } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import BootstrapTable from "react-bootstrap-table-next";
import filterFactory, { textFilter } from 'react-bootstrap-table2-filter';
import { Loader } from "react-feather";
import { connect } from "react-redux";
import PageDropdown from "../pages/facebook-tools/custom-audiences/page/PageDropdown";
import { AccountIsActive } from "../pages/prods-and-accounts/account/accountUtils";
import AccountDropdown from "./AccountDropdown";
import GenericErrorAlert from "./GenericErrorAlert";


class ListToCopy extends React.PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            initSuccess: null,
            selectedAccount: null,
            selectedPage: null,
            openModal: false,
            saveAllBtn: false,
            items: []
        }

        this.accountChanged = this.accountChanged.bind(this);
        this.updateStateCallback = this.updateStateCallback.bind(this);
        this.toggleIsCopyCA = this.toggleIsCopyCA.bind(this);
        this.pageChanged = this.pageChanged.bind(this);
    }


    componentDidMount() {
        if (this.props.isCopyCABtnClicked) {
            this.setState({ initSuccess: true, openModal: true, items: this.props.items });
        } else {
            this.setState({ initSuccess: false });
        }
    }

    accountChanged(account) {
        if (account !== undefined) {
            this.setState({ selectedAccount: account, selectedPage: null });
        }
    }

    pageChanged(page) {
        if (page !== undefined) {
            this.setState({ selectedPage: page });
        }
    }

    updateStateCallback(key, value) {
        this.setState({ [key]: value });
    }

    saveAll() {
        let listToSave = this.state.items;
        if (this.state.selectedAccount != null) {
            listToSave.accountId = this.state.selectedAccount.accountId;
            listToSave.accountPlatformId = this.state.selectedAccount.accountPlatformId;
            listToSave.pageId = this.state.selectedPage.id;
        }

        this.props.submitCallback(listToSave);
    }

    // Columns   
    columns = [
        {
            dataField: "isCopy",
            text: "Copy",
            headerStyle: { width: "55px" },
            editable: false,
            style: { cursor: "pointer" },
            formatter: (cell, row, rowIndex) => this.checkboxFormatter(cell, row, rowIndex, { toggleChecked: this.toggleIsCopyCA })
        },
        {
            dataField: "id",
            text: "Custom Audience Id",
            align: "left",
            headerStyle: { width: "170px" },
            sort: true
        },
        {
            dataField: "name",
            text: "Name",
            align: "left",
            filter: textFilter({ placeholder: '', style: { width: "80%" } }),
            sort: true
        }
    ]

    toggleIsCopyCA(checked, row) {
        let newCA = [...this.state.items];
        let rowIndex = newCA.findIndex(test => test.id == row.id);
        if (rowIndex != -1) {
            let updatedCAs = { ...newCA[rowIndex], isCopy: checked, isChanged: checked };
            newCA.splice(rowIndex, 1, updatedCAs);
            this.setState({ items: newCA });
        }
    }

    checkboxFormatter(cell, row, index, { toggleChecked }) {
        return <Form.Check
            type="switch"
            id={`copy-${row.id}`}
            name={`copy-${row.id}`}
            checked={row.isCopy}
            onChange={event => toggleChecked(event.target.checked, row)}
        />
    }

    render() {
        let hasChangedTests = this.state.items.find(element => element.isChanged === true) !== undefined;

        if (this.state.initSuccess === true) {
            return (

                <Modal size="lg" show={this.state.openModal === true}>
                    <Button color="secondary" size="small"
                        style={{ position: "absolute", top: "10px", right: "10px" }}
                        onClick={e => this.setState({ openModal: false })}>
                        Close
                    </Button>
                    <Modal.Header>
                        <div>
                            Copy Custom Audience From Account: <b>{this.props.selectedAccount.accountName}</b> to
                        </div>
                    </Modal.Header>
                    <Modal.Body className="text-left m-1">
                        <div className="form-group-wrap">
                            <div class="form-group">
                                <Form>
                                    <div class="form-group" style={{ width: "300px" }}>
                                        {this.props.isCopyCABtnClicked &&
                                            <AccountDropdown
                                                updateStateCallback={this.updateStateCallback}
                                                accountChanged={this.accountChanged}
                                                selectedAccount={this.state.selectedAccount}
                                                platform='FACEBOOK'
                                                itemActive={AccountIsActive.ACTIVE}
                                            />
                                        }
                                        {this.state.selectedAccount &&
                                            <PageDropdown
                                                accessToken={this.props.facebookUserData.accessToken}
                                                updateStateCallback={this.updateStateCallback}
                                                pageChanged={this.pageChanged}
                                                type="videoCustomAudienceByPage"
                                                selectedPage={this.state.selectedPage}
                                                selectedAccount={this.state.selectedAccount}
                                            />
                                        }
                                    </div>
                                    <div class="form-group">
                                        {this.state.selectedAccount !== null && this.state.selectedPage !== null &&
                                            <BootstrapTable
                                                bootstrap4
                                                keyField="id"
                                                bordered={true}
                                                striped
                                                hover
                                                data={this.props.items}
                                                columns={this.columns}
                                                filter={filterFactory()}
                                            />
                                        }
                                    </div>
                                </Form>
                            </div>
                        </div>
                    </Modal.Body>
                    <Modal.Footer className="justifyCenter">
                        <Button disabled={hasChangedTests == false} color="secondary" onClick={() => this.saveAll()}>Save All</Button>
                    </Modal.Footer>
                </Modal>
            )
        } else if (this.state.initSuccess === false) {
            return (
                <GenericErrorAlert />
            );
        } else {
            return (
                <Loader />
            );
        }
    }

}
export default connect((store) => {
    return {
        facebookUserData: store.userContext.facebookUserData
    }
})(ListToCopy)