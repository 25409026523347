import React from "react";
import {
    Button, Card,
    Container, Form, Modal, OverlayTrigger, Popover, Tooltip,Spinner
} from "react-bootstrap";
import BootstrapTable from "react-bootstrap-table-next";
import cellEditFactory, { Type } from 'react-bootstrap-table2-editor';
import filterFactory, { textFilter } from 'react-bootstrap-table2-filter';
import 'react-bootstrap-table2-filter/dist/react-bootstrap-table2-filter.min.css';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import * as Icon from "react-feather";
import { toastr } from "react-redux-toastr";
import AccountDropdown from "../../components/AccountDropdown";
import { addHours } from "../../utils/dateUtils";
import { genericCachedFetcherFactory, genericFetcherFactory } from "../../utils/requestUtils";
import { AccountIsActive } from "../prods-and-accounts/account/accountUtils";
import InsertAbTestForm from "./AbTests/InsertAbTestForm";

export default class FacebookABTests extends React.PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            initSuccess: null,
            dataLoading: false,
            accountFunnels: [],
            accountProducts: [],
            abTests: [],
            selectedAccount: null,
            newTestNumber: 1,
            testSaving: false,
            createTest: false,
            pendingInsert: {}
        }

        this.processData = this.processData.bind(this);

        this.toggleIsVisible = this.toggleIsVisible.bind(this);
        this.updateRows = this.updateRows.bind(this);
        this.prepareForInsert = this.prepareForInsert.bind(this);
        this.insertSubmitted = this.insertSubmitted.bind(this);

        this.saveTest = this.saveTest.bind(this);
        this.removeTest = this.removeTest.bind(this);
        this.revertTestChanges = this.revertTestChanges.bind(this);
        this.saveChangedTests = this.saveChangedTests.bind(this);

        this.accountChanged = this.accountChanged.bind(this);
        this.updateStateCallback = this.updateStateCallback.bind(this);
    }

    // Init and fetchers

    updateStateCallback(key, value) {
        this.setState({ [key]: value });
    }

    async accountChanged(account) {

        if (account !== undefined) {
            this.setState({ selectedAccount: account, dataLoading: true });

            let responseData = await genericFetcherFactory(`/api/facebook-interface/ABTests/Account/${account.accountId}`, "ABTESTS", "Failed to fetch AB Tests")().then(result => {
                if (result.success === true) {
                    return result.data;
                } else {
                    return [];
                }
            });

            let abTests = [];
            let accountFunnels = [];
            let accountProducts = [];
            if (responseData.length > 0) {
                [accountFunnels, accountProducts] = await Promise.all([this.fetchFunnels(account.accountId), this.fetchProducts(account.brandId)]);
                abTests = this.processData(responseData, accountFunnels, accountProducts);
            }

            let newTestNumber = abTests.length > 0 ? Math.max(...abTests.map(abTest => abTest.testId)) + 1 : 1;

            this.setState({ abTests: abTests, accountFunnels: accountFunnels, accountProducts: accountProducts, dataLoading: false, newTestNumber: newTestNumber });
        } else {
            toastr.error("An error occured. Could not locate selected account");
        }
    }

    async fetchFunnels(accountId) {
        return genericCachedFetcherFactory(`/api/accounts/Funnels/AccountFunnels/${accountId}`, "FUNNELS", "Failed to fetch funnel data", addHours(1))().then(result => {
            if (result.success === true) {
                return result.data;
            } else {
                return [];
            }
        });
    } 

    async fetchProducts(brandId) {
        return genericCachedFetcherFactory(`/api/product/Products/Brand/${brandId}`, "PRODUCTS", "Failed to fetch funnel data", addHours(24))().then(result => {
            if (result.success === true) {
                return result.data;
            } else {
                return [];
            }
        });
    }

    // Entity Functions

    revertTestChanges(test) {
        let newAbTests = [...this.state.abTests];
        let index = newAbTests.findIndex(_test => _test.key === test.key);
        if (index !== -1) {
            let pristineCopy = { ...test.pristineState };
            let pristineTest = { ...test.pristineState };
            pristineTest.pristineState = pristineCopy;
            newAbTests.splice(index, 1, pristineTest);
            this.setState({ abTests: newAbTests });
        }
    }

    removeTest(test) {
        let confirmation = window.confirm("Are you sure you want to delete the test?");
        if (confirmation === true) {
            this.setState({ testSaving: true });
            genericFetcherFactory(`/api/facebook-interface/ABTests`, "DELETE_TEST", "Failed to delete test", "DELETE",
                {
                    method: "DELETE",
                    body: JSON.stringify(test),
                    headers: { "Content-Type": "application/json" }
                })().then(result => {
                    let state = { testSaving: false };
                    if (result.success === true) {
                        toastr.success("Test deleted successfully");
                        let newAbTests = [...this.state.abTests];
                        let index = newAbTests.findIndex(_test => _test.key === test.key);
                        if (index !== -1) {
                            newAbTests.splice(index, 1);
                            state.abTests = newAbTests;
                        }
                    }
                    this.setState({ ...state });
                });
        }
    }

    saveTest(test) {
        this.setState({ testSaving: true });
        genericFetcherFactory(`/api/facebook-interface/ABTests`, "UPDATE_TEST", "Failed to save test", "PATCH",
            {
                method: "PATCH",
                body: JSON.stringify(test),
                headers: { "Content-Type": "application/json" }
            })().then(result => {
                let state = { testSaving: false };
                if (result.success === true) {
                    let savedTest = this.processTest(result.data, this.state.accountFunnels, this.state.accountProducts);
                    let newAbTests = [...this.state.abTests];
                    let index = newAbTests.findIndex(_test => _test.key === test.key);
                    if (index !== -1) {
                        toastr.success("Test saved successfully");
                        newAbTests.splice(index, 1, savedTest);
                        state.abTests = newAbTests;
                    } else {
                        toastr.warn("Test was saved but view cannot be updated due to an error. Please refresh the page");
                    }
                }
                this.setState({ ...state });
            });
    }

    createTest(test) {
        this.setState({ testSaving: true });
        genericFetcherFactory(`/api/facebook-interface/ABTests`, "INSERT_TEST", "Failed to create test", "POST",
            {
                method: "POST",
                body: JSON.stringify(test),
                headers: { "Content-Type": "application/json" }
            })().then(result => {
                let state = { testSaving: false };
                if (result.success === true) {
                    toastr.success("Test created successfully");
                    state.createTest = false;

                    let savedTest = this.processTest(result.data, this.state.accountFunnels, this.state.accountProducts);
                    let newAbTests = [...this.state.abTests];
                    newAbTests.push(savedTest)
                    state.abTests = newAbTests;
                }
                this.setState({ ...state });
            });
    }

    saveChangedTests() {
        this.setState({ testSaving: true });
        let testsList = this.state.abTests.filter(test => test.isChanged === true);
        if (testsList.length < 0) {
            toastr.warn("No pending changes to save");
            this.setState({ testSaving: false });
        } else {
            genericFetcherFactory(`/api/facebook-interface/ABTests/bulkUpdate`, "UPDATE_BULK_TESTS", "Failed to save test", "PATCH",
                {
                    method: "POST",
                    body: JSON.stringify(testsList),
                    headers: { "Content-Type": "application/json" }
                })().then(result => {
                    let state = { testSaving: false };
                    if (result.success === true) {
                        let savedTests = result.data.map(test => this.processTest(test, this.state.accountFunnels, this.state.accountProducts));
                        let newAbTests = [...this.state.abTests];

                        let failedToReplace = [];

                        savedTests.forEach(test => {
                            let index = newAbTests.findIndex(_test => _test.key === test.key);
                            if (index !== -1) {
                                newAbTests.splice(index, 1, test);
                            } else {
                                failedToReplace.push(test);
                            }
                        });

                        if (failedToReplace.size > 0) {
                            toastr.warn("The view could not be properly updated. Check the console for details and please refresh the page to avoid inconsistency.");
                            console.log(failedToReplace);
                        }

                        state.abTests = newAbTests;
                    }

                    this.setState({ ...state });
                });
        }
    }

    // Table config

    columns = [
        {
            dataField: "testId", text: "Test ID", headerStyle: { width: "75px" }, sort: true, editable: false, filter: textFilter({ placeholder: '', style: { width: "80%" } })
        },
        {
            dataField: "adsetId", text: "Adset ID", sort: true, editable: false, filter: textFilter({ placeholder: '', style: { width: "80%" } }),
            editor: { type: Type.TEXT }
        },
        {
            dataField: "element", text: "Element", sort: true, style: { cursor: "pointer" }, filter: textFilter({ placeholder: '', style: { width: "80%" } }),
            editor: { type: Type.TEXT }
        },
        {
            dataField: "funnelId", text: "Funnel ID", sort: true, style: { cursor: "pointer" }, headerStyle: { width: "90px" },
            filter: textFilter({ placeholder: '', style: { width: "80%" } }),
            editor: { type: Type.TEXT }
        },
        {
            dataField: "productName", text: "Product", sort: true, editable: false, filter: textFilter({ placeholder: '', style: { width: "80%" } })
        },
        {
            dataField: "startDate", text: "Start Date", sort: true, type: "date", style: { cursor: "pointer" }, headerStyle: { width: "150px" },
            editorRenderer: (editorProps, value, row, column, rowIndex, columnIndex) => (
                <DatePicker
                    dateFormat="dd/MM/yyyy"
                    selected={value}
                    onChange={date => editorProps.onUpdate(date)}
                    isInputFocused />),
            formatter: (cell) => cell ? cell.toLocaleDateString('en-GB') : ""
        },
        {
            dataField: "endDate", text: "End Date", sort: true, type: "nullableDate", style: { cursor: "pointer" }, headerStyle: { width: "150px" },
            editorRenderer: (editorProps, value, row, column, rowIndex, columnIndex) => (
                <DatePicker
                    dateFormat="dd/MM/yyyy"
                    selected={value}
                    onChange={date => editorProps.onUpdate(date ? date : null)}
                    isClearable
                    isInputFocused />),
            formatter: (cell) => cell ? cell.toLocaleDateString('en-GB') : ""
        },
        {
            dataField: "isVisible", text: "Visible", sort: true, headerStyle: { width: "75px" }, editable: false, style: { cursor: "pointer" },
            formatter: (cell, row, rowIndex) => this.checkboxFormatter(cell, row, rowIndex, { toggleChecked: this.toggleIsVisible })
        },
        {
            dataField: "comment", text: "Comment", sort: false, headerStyle: { width: "65px" }, align: "center",
            editorRenderer: (editorProps, value) => {
                let ref = React.createRef();
                // The cancel button returns the original value since the bootstrap table API doesn't provide a "cancel" functionality
                return (
                    <Modal
                        show={true}
                        centered
                    >
                        <Modal.Header>
                            Comment
                        </Modal.Header>
                        <Modal.Body className="text-center m-3">
                            <textarea style={{ width: "100%", minHeight: "100px" }} defaultValue={value || ""} ref={ref} />
                            <div className="mt-4">
                                <Button className="mr-2" onClick={() => editorProps.onUpdate(ref.current.value)}>Set Comment</Button>
                                <Button onClick={() => editorProps.onUpdate(value !== null ? value : "")}>Cancel</Button>
                            </div>
                        </Modal.Body>
                    </Modal>
                )
            },
            formatter: (cell, row) => this.commentFormatter(cell, row)
        },
        {
            editable: false, isDummyField: true, headerStyle: { width: "80px", textAlign: "center" }, align: "center",
            headerFormatter: () => this.actionsHeaderFormatter({ enabled: this.state.selectedAccount !== null, addTest: this.prepareForInsert }),
            formatter: (cell, row) => this.actionsFormatter(
                cell, row, { removeTest: this.removeTest, saveTest: this.saveTest, revertTestChanges: this.revertTestChanges }
            )
        }
    ];

    commentFormatter(cell, row) {
        let commentIconId = "commentIcon-" + row.key;
        const commentPoper = (
            <Popover placement="bottom" target={commentIconId} trigger="hover">
                <Popover.Body>
                    {cell}
                </Popover.Body>
            </Popover >
        )

        const noCommentPoper = (
            <Popover placement="bottom" target={commentIconId} trigger="hover">
                <Popover.Body>
                    {cell}
                </Popover.Body>
            </Popover >
        )
        if (cell !== null && cell.length > 0) {
            return (
                <OverlayTrigger trigger="hover" placement="bottom" overlay={commentPoper}>
                    <Icon.FileText id={commentIconId} style={{ cursor: "pointer" }} className="feather mr-2" />

                </OverlayTrigger>
            );
        } else {
            return (
                <OverlayTrigger placement="top" key={"ot-" + commentIconId} overlay={
                    <Tooltip id={"ot-" + commentIconId}>
                        No comment
                    </Tooltip >
                }>
                    <Icon.File id={commentIconId} style={{ cursor: "pointer" }} color="lightgrey" className="feather mr-2" />

                </OverlayTrigger>
            )
        }
    }

    actionsFormatter(cell, row, { removeTest, saveTest, revertTestChanges }) {
        let removeFormatterId = "deleteIcon-" + row.key;
        let saveFormatterId = "saveIcon-" + row.key;
        let undoFormatterId = "undoIcon-" + row.key;
        return (
            <div tabindex="-1">
                <OverlayTrigger placement="top" key={"ot-" + removeFormatterId} overlay={
                    <Tooltip placement="top" id={"ot-" + removeFormatterId}>
                        Delete Test
                    </Tooltip>
                }>
                    <Icon.Trash2 id={removeFormatterId} style={{ cursor: "pointer" }} className="feather mr-2"
                        onClick={() => { removeTest(row) }} />

                </OverlayTrigger>
                <OverlayTrigger placement="top" key={"ot-" + saveFormatterId} overlay={
                    <Tooltip placement="top" id={"ot-" + saveFormatterId}>
                        {row.isChanged ? "Save Test" : "No changes made"}
                    </Tooltip>
                }>
                    <Icon.Save id={saveFormatterId} style={{ cursor: "pointer" }} className="feather mr-2" color={row.isChanged ? "black" : "lightgrey"}
                        onClick={row.isChanged ? () => { saveTest(row) } : null} />
                </OverlayTrigger>

                <OverlayTrigger placement="top" key={"ot-" + undoFormatterId} overlay={
                    <Tooltip placement="top" id={"ot-" + undoFormatterId}>
                        {row.isChanged ? "Undo Changes" : "No changes made"}
                    </Tooltip>
                }>
                    <Icon.RotateCcw id={undoFormatterId} style={{ cursor: "pointer" }} className="feather mr-2" color={row.isChanged ? "black" : "lightgrey"}
                        onClick={row.isChanged ? () => { revertTestChanges(row) } : null} />
                </OverlayTrigger>
            </div>
        )
    }

    actionsHeaderFormatter({ enabled, addTest }) {
        return (
            <OverlayTrigger placement="top" key={"ot-add-test-button"} overlay={
                <Tooltip placement="top" id={"ot-add-test-button"}>
                    {enabled ? "Add Test" : "You must select an account"}
                </Tooltip>
            }>
                <Icon.PlusSquare id="add-test-button" style={{ cursor: "pointer" }} className="feather-lg mr-2"
                    color={enabled ? "green" : "lightgrey"}
                    onClick={enabled ? () => { addTest() } : null} />

            </OverlayTrigger>
        );
    }

    checkboxFormatter(cell, row, index, { toggleChecked }) {
        return <Form.Check
            type="switch"
            id={`visible-${row.key}`}
            name={`visible-${row.key}`}
            checked={row.isVisible}
            onChange={event => toggleChecked(event.target.checked, row)}
        />
    }

    // Table callbacks

    toggleIsVisible(checked, row) {
        let newAbTests = [...this.state.abTests];
        let rowIndex = newAbTests.findIndex(test => test.key === row.key);
        if (rowIndex !== -1) {
            let updatedAbTest = { ...newAbTests[rowIndex], isVisible: checked, isChanged: true };
            newAbTests.splice(rowIndex, 1, updatedAbTest);
            this.setState({ abTests: newAbTests });
        }
    }

    updateRows(abTests) {
        this.setState({ abTests: abTests });
    }

    cellSaved(oldValue, newValue, row, column, { state, updateRows, getProductName }) {
        if (oldValue !== newValue) {
            let abTests = state.abTests;
            let index = abTests.findIndex(test => test.key === row.key);
            if (index !== -1) {
                let test = { ...abTests[index], isChanged: true }
                if (column.dataField === "funnelId") {
                    test.productName = getProductName(newValue, state.accountFunnels, state.accountProducts);
                }
                abTests.splice(index, 1, test);

                updateRows(abTests);
            }
        }
    }

    prepareForInsert() {
        this.setState({ pendingInsert: this.addNewTestTemplate(this.state.selectedAccount.accountId, this.state.newTestNumber), createTest: true });
    }

    // Logic

    updatePendingInsert(propertyName, value) {
        this.setState({ pendingInsert: { ...this.state.pendingInsert, [propertyName]: value } })
    }

    addNewTestTemplate(accountId, testId) {
        let templateAbTest = {
            accountId: accountId,
            testId: testId,
            adsetId: null,
            element: null,
            funnelId: null,
            productName: null,
            startDate: null,
            endDate: null,
            isVisible: false,
        }
        templateAbTest.key = this.generateKey(templateAbTest);

        return templateAbTest;
    }

    generateKey(abTest) {
        return `${abTest.testId}-${abTest.adsetId}`.replace(/\s+/g, "_");
    }

    processData(abTests, accountFunnels, accountProducts) {
        let processed = abTests.map(abTest => {
            return this.processTest(abTest, accountFunnels, accountProducts);
        });

        return processed;
    }

    processTest(abTest, accountFunnels, accountProducts) {
        let key = this.generateKey(abTest);
        let productName = this.getProductName(abTest.funnelId, accountFunnels, accountProducts);
        let parsedStartDate = abTest.startDate ? new Date(abTest.startDate) : null;
        let parsedEndDate = abTest.endDate ? new Date(abTest.endDate) : null;
        let pristineState = { ...abTest, key: key, startDate: parsedStartDate, endDate: parsedEndDate, productName: productName };
        return {
            ...abTest,
            pristineState: pristineState,
            key: key,
            startDate: parsedStartDate,
            endDate: parsedEndDate,
            productName: productName,
        }
    }

    getProductName(funnelId, accountFunnels, accountProducts) {
        let productName = "N/A";

        let funnel = accountFunnels.find(funnel => funnelId === funnel.funnelId) || accountFunnels.find(funnel => funnel.funnelId === "DEFAULT");
        if (funnel !== undefined) {
            let product = accountProducts.find(product => product.productId === funnel.productId);
            if (product !== undefined) {
                productName = product.productName;
            }
        }

        return productName;
    }

    insertSubmitted(values) {
        values['accountId'] = this.state.selectedAccount.accountId;
        this.createTest(values);
    }

    // Render

    render() {
        let hasChangedTests = this.state.abTests.find(test => test.isChanged === true) !== undefined;
        return (
            <Container fluid className="p-0">
                <Modal
                    show={this.state.testSaving === true}
                    centered
                >
                    <Modal.Header>
                        Saving AB Test...
                    </Modal.Header>
                    <Modal.Body className="text-center m-3">
                        <Spinner color="dark" className="mr-2" />
                    </Modal.Body>
                </Modal>
                <Card>
                    <Card.Header>
                        <Card.Title tag="h5" className="mb-0">
                            AB Tests
                        </Card.Title>
                    </Card.Header>
                    <Card.Body>
                        <div className="mb-4" style={{ display: "flex", justifyContent: "space-between" }}>
                            <AccountDropdown  widthClass={"col-12 col-sm-4"} updateStateCallback={this.updateStateCallback} accountChanged={this.accountChanged} selectedAccount={this.state.selectedAccount} platform='FACEBOOK' itemActive={AccountIsActive.ALL} />

                            <Button disabled={hasChangedTests === false} className="submit-btn" color="primary" onClick={this.saveChangedTests}>Save All</Button>
                        </div>
                        <div className="mb-4 general-message">Displaying tests that started in the past 90 days</div>
                        {this.state.dataLoading === true &&
                            <Spinner color="dark" className="mr-2" />
                        }
                        <BootstrapTable
                            id="abtests_table"
                            headerClasses="sticky"
                            keyField="key"
                            bordered={false}
                            striped
                            hover
                            data={this.state.abTests ? this.state.abTests : []}
                            columns={this.columns}
                            rowClasses={(row) => { return row.isChanged ? "row-pending-changes" : "" }}
                            filter={filterFactory()}
                            cellEdit={cellEditFactory({
                                mode: 'click',
                                blurToSave: true,
                                afterSaveCell: (oldValue, newValue, row, column) =>
                                    this.cellSaved(
                                        oldValue, newValue, row, column,
                                        {
                                            state: { abTests: [...this.state.abTests], accountFunnels: [...this.state.accountFunnels], accountProducts: [...this.state.accountProducts] },
                                            updateRows: this.updateRows,
                                            getProductName: this.getProductName
                                        })
                            })
                            }
                        />
                        {this.state.createTest &&
                            <InsertAbTestForm
                                submitCallback={this.insertSubmitted}
                            />
                        }

                        <Button disabled={hasChangedTests === false} className="submit-btn mt-4" color="primary" onClick={this.saveChangedTests}>Save All</Button>

                    </Card.Body>
                </Card>
            </Container>
        )


    }
}