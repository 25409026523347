import * as types from "../constants";

const initialState = {
    CMSTemplateData: {
        error: null,
        dataTemplates: [],
        dataIsLoaded: null,
        pristineDataModel: [],
        templatePageLists: [],
        dataModel: null,
        sharedComponents: {},
        concatSharedComps: []
    },
    cmsData: {
        templatesIsLoaded: null,
        template: null,
        page: '',
        newPageName: null,
        pageListIsLoaded: null,
        currentPage: '',
        sharedComponentsAdded: [],
        sharedComponentsAvailable: null,
        sharedComponentsFullData: null,
        sharedComponentsUpdated: [],
        component: null,
        deploydPages: [],
        editOrCreate: 'edit',
        deletePages: [],
        deletePagesHTML: [],
        deletePagesJSON: [],
        unsavedData: [],
        pagesCheckedVersions: [],
        viewOnly: false,
        deploySelectedComponent: null
    },
    userStatus: {
        fetchedUserData: [],
        inEditMode: [],
        processedUserDataReady: false
    },
    deploymentSharedComponent: {
    }
}

export default function reducer(state = initialState, action) {
    switch (action.type) {
        case types.SET_CMS_TEMPLATE_DATA:
            return {
                ...state,
                CMSTemplateData: action.payload
            }
        case types.SET_CMS_DATA:
            return {
                ...state,
                cmsData: action.payload
            }
        case types.SET_DEPLOY_SHARED_COMPONENT:
            return {
                ...state,
                deploymentSharedComponent: action.payload
            }

        case types.SET_USER_STATUS_DATA:
            return {
                ...state,
                userStatus: action.payload
            }
        default:
            return state;
    }
}