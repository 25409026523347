import React from "react";
import {
    Card,
    CardBody,
} from "reactstrap";
import cloneDeep from 'lodash/cloneDeep';
import { DropTarget } from 'react-drag-drop-container';
import BoxItem from './BoxItem';
import { cmsUtils } from '../utils';
import { connect } from 'react-redux';
import { setCMSTemplateData } from "../../../redux/actions/cmsActions";

class Box extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            pageData: {},
            items: this.props.items || []
        };
        this.utils = new cmsUtils();
    }

    removeItem = (fromIndex, itemIndex) => {
        let items = this.state.items.slice();
        items.splice(itemIndex, 1);
        this.updateModel(items);
    }

    handleDrop = (e) => {
        let items = this.state.items.slice();
        const item = { label: e.dragData.label, uid: e.dragData.index };
        items.push(item.label);
        e.containerElem.style.visibility = "hidden";
        this.updateModel(items);
    };

    swap = (fromIndex, toIndex, dragData, e) => {
        let items = this.state.items.slice();
        const item = { label: dragData.label, uid: e.dragData.index  };
        items.splice(toIndex, 0, item.label);
        this.updateModel(items);
    }
    

    updateModel = (value) => {
        this.setState({ pageData: this.props.dataModel }, () => {
            let fullData = cloneDeep(this.state.pageData);
            fullData[this.props.compValue] = value;
            this.setState({ items: value, pageData: fullData }, () => {
                this.props.setCMSTemplateData(this.utils.updateSuccessCallback({ [this.props.compValue]: [value, 'compOnlyMerge'] }));
            });
        });
    }

    render() {
        return (
            <>
                <Card className="component_box border">
                    <h3>{this.props.title} </h3>
                    <CardBody>
                        <DropTarget
                            onHit={this.handleDrop}
                            targetKey={this.props.targetKey}
                            dropData={{ name: this.props.name }}
                        >
                            <DropTarget
                                onHit={this.handleDrop}
                                targetKey="boxItem"
                                dropData={{ name: this.props.name }}
                            >
                                <div className="box">
                                    {this.state.items !== [] && this.state.items.map((item, index) => {
                                        return (                                            
                                            <BoxItem key={item} uid={item} swap={this.swap} update={this.update} removeBTN={this.props.removeBTN} removeItem={this.removeItem} index={index} >
                                                {item.label || item}
                                            </BoxItem>
                                        )
                                    })}
                                </div>
                            </DropTarget>
                        </DropTarget>
                    </CardBody>
                </Card>
            </>
        );
    }
}

export default connect(
    (store) => {
        return {
            CMSTemplateData: store.cms.CMSTemplateData
        }
    },
    { setCMSTemplateData })(Box)