import React from "react";
import merge from 'lodash/merge';
import cloneDeep from 'lodash/cloneDeep';
import {
    Container,
    Label,
    CustomInput,
    Card,
    CardBody,
    Input,
    Button
} from "reactstrap";
import {
    AvForm,
    AvGroup,
    AvInput
} from "availity-reactstrap-validation";
import { toastr } from "react-redux-toastr";
import UploadFile from './UploadFile';
import S3Files from './S3Files';
import ColorPicker from './ColorPicker';
import { connect } from 'react-redux';
import { cmsUtils } from './utils';
import { setCmsData, setCMSTemplateData } from "../../redux/actions/cmsActions";

class EditComponent extends React.PureComponent {
    constructor(props) {
        super(props);
        let jsondata = this.props.CMSTemplateData.dataModel;
        let currentComponent = [this.props.component];
        let compDataGeneral = jsondata[currentComponent];
        let compDataTrackers = {} || jsondata['cart']['trackers'] || jsondata['cartSingleProduct']['trackers'] || jsondata['cartMultipleProductsSlider']['trackers'];

        let dataRoot = currentComponent.toString();
        let compData = {};
        compData[dataRoot] = this.props.component === 'trackers' ? compDataTrackers : compDataGeneral;

        this.state = {
            pageData: jsondata,
            dataModel: compData,
            saveButtonIsDisabled: true
        };

        this.utils = new cmsUtils();
        this.updateItem = this.updateItem.bind(this);
    }

    //notification on save data
    showToastr(type) {
        const options = {
            timeOut: 3000,
            showCloseButton: true,
            progressBar: true,
            position: "top-center"
        };
        let toastrInstance;
        switch (type) {
            case "info":
                toastrInstance = toastr.info;
                break;
            case "warning":
                toastrInstance = toastr.warning;
                break;
            case "error":
                toastrInstance = toastr.error;
                break;
            default:
                toastrInstance = toastr.success;
                break;
        }

        toastrInstance(
            "Hey",
            "Data was updated for: " + this.upperCaseArray(this.props.component.toString()) + ". Please proceed to the next step to upload it",
            options
        );
    }

    handleSubmit = (event, updateItems = null, compName) => {
        if (event) {
            event.preventDefault();
            this.props.setCMSTemplateData(this.utils.updateSuccessCallback({ 'dataModel': [this.state.dataModel, true] }));
        } else {
            if (updateItems !== null) {
                this.props.setCMSTemplateData(this.utils.updateSuccessCallback({ [compName]: [this.state.dataModel[compName], 'compOnlyMerge'] }));
            } else {
                this.props.setCMSTemplateData(this.utils.updateSuccessCallback({ 'dataModel': [this.state.dataModel, true] }));
            }
        }
        this.showToastr()
        this.setState({ saveButtonIsDisabled: true });
        this.props.updateCallback('unsavedData', this.props.component, false, 'remove');
    }

    //update the state
    updateCallback = (data) => {
        for (const [key, value] of Object.entries(data)) {
            this.setState({ [key]: value[0] });
            let path = value[1];
            this.updateModel(path, value[0]);
        }
    }

    //form 2 object
    createObject(path, value) {
        // Read the part from the end to the start to nest the object
        let pathParts = path.split(".").reverse();
        // console.log('pathParts', pathParts);
        let objectStructure = null;
        pathParts.forEach(part => {
            if (part.includes("[")) {
                let regex =  /([a-zA-Z_]*)\[(\d[0-9]{0,2})]/;
                let [original, key, index] = part.match(regex);
                // Last point of the object is an array
                if (objectStructure === null) {
                    objectStructure = [];
                    objectStructure[index] = value;
                } else {
                    let newStructure = {};
                    newStructure[key] = [];
                    newStructure[key][index] = objectStructure;
                    objectStructure = newStructure;
                }
            }
            else {
                // Last point of the object is a string value
                if (objectStructure === null) {
                    objectStructure = {};
                    objectStructure[part] = value;

                } else {
                    // Nest the structure in itself
                    let newStructure = {};
                    newStructure[part] = objectStructure;

                    objectStructure = newStructure;
                }
            }
        });

        return objectStructure;
    }

    getParts(path) {
        let [component, section, innerSection] = path.split(".");
        let regex = /([a-zA-Z_]*)\[(\d+)\]/;        
        let sectionParts = innerSection !== undefined ? innerSection.match(regex) : section.match(regex);
        let [original, key, index] = sectionParts;
        return [original, key, index, component, section, innerSection];
    }


    removeItemByPath(path) {
        let items = cloneDeep(this.state.dataModel);
        let [original, key, index, component, section, innerSection] = this.getParts(path);
        let array = innerSection !== undefined ? items[component][section][key] : items[component][key];
        array.splice(parseInt(index), 1);
        return [array, key, component, section, innerSection !== undefined ? true : false];
    }

    addItemByPath(path) {
        let items = cloneDeep(this.state.dataModel);
        let [original, key, index, component, section, innerSection] = this.getParts(path);
        let array = innerSection !== undefined ? items[component][section][key] : items[component][key];
        let itemToCopy = innerSection !== undefined ?  items[component][section][key][index] : items[component][key][index];
        array.splice(parseInt(index) + 1, 0, itemToCopy);
        return [array, key, component, section, innerSection !== undefined ? true : false];
    }

    updateModel(path, value, updateItem) {
        let changes, result;
        if (updateItem === 'remove' || updateItem === 'add') {
            this.confirmUpdateItem(updateItem).then(() => {
                if (updateItem === 'remove') {
                    changes = this.removeItemByPath(path);
                } else if (updateItem === 'add') {
                    changes = this.addItemByPath(path);
                }
                let origin = cloneDeep(this.state.dataModel);
                let key = changes[1];
                let value = changes[0];
                if (changes[4] === true){ 
                    //third level
                    origin[changes[2]][changes[3]][key] = value;
                }else{
                    //second level
                    origin[changes[2]][key] = value;
                }
                result = origin;

                this.setState({ saveButtonIsDisabled: false, dataModel: result });
                if (updateItem === 'remove') {
                    this.handleSubmit(false, true, changes[2]);
                } else {
                    this.handleSubmit(false);
                }
            })
        } else {
            changes = this.createObject(path, value);
            let origin = cloneDeep(this.state.dataModel);
            result = merge(origin, changes); // Lodash merge mutates the origin object
            this.setState({ saveButtonIsDisabled: false, dataModel: result });
            this.props.updateCallback('unsavedData', this.props.component, false, 'add');
        }
    }

    confirmUpdateItem(updateItem) {
        return new Promise((resolve, reject) => {
            const toastrConfirmOptions = {
                onOk: () => resolve(),
                onCancel: () => { },
                okText: "Yes, save my changes & " + (updateItem === 'add' ? "add a new item" : "remove this item"),
                cancelText: 'I dont want to save',
                id: 'edit_comp_confirm_btn',
                component: () => (
                    <>
                        <div className="confirm_message">
                            {updateItem === 'add' ? "In order to create a new item you need to save your changes first." : "In order to remove this item you need to save your changes first."}
                            Do you approve?
                        </div>
                    </>
                )
            };
            toastr.confirm(null, toastrConfirmOptions)
        });
    }

    //parse json into form
    generateTextbox(groupId, inputId, inputDisplay, inputValue, type, objName, objIndex, index, path) {
        let disabled = this.props.component === 'trackers' && path.includes('cart') && objName !== 'trackers' ? true : false;
        let readOnlyOptions = this.state.pageData.global.image_fields != null ? this.state.pageData.global.image_fields.concat(["video_src", "videoSrc"]) : ["img", "image", "image_desktop", "image_tablet", "image_mobile", "video_src", "videoSrc"];
        return (
            <AvGroup key={groupId} className={`${inputValue.length > 100 ? 'textarea' : ''} ${inputDisplay === 'color' ? 'colorWrap' : ''}`}>
                <Label for={inputId} key={"lbl-" + inputId + index} className="title">{this.separateWordsArray(inputDisplay)}:</Label>
                {inputDisplay.includes('color') &&
                    <ColorPicker
                        pathObj={path} pageData={this.state.pageData} dataValue={inputValue} dataRefName={inputId} updateCallback={this.updateCallback} />
                }
                <AvInput
                    type={inputValue.length > 100 ? 'textarea' : 'input'}
                    data-name={inputDisplay}
                    name={inputId}
                    value={(this.state[inputId] || this.state[inputId] === "") ? this.state[inputId] : inputValue}
                    data-type={type}
                    data-objname={objName}
                    data-objindex={objIndex}
                    data-index={index + 1}
                    onChange={(e) => { this.updateModel(path, e.target.value) }}
                    readonly={(readOnlyOptions.includes(inputDisplay) || inputDisplay === 'color') ? "readonly" : false}
                    disabled={disabled}
                />
                {readOnlyOptions.includes(inputDisplay) &&
                    <UploadFile
                        pathObj={path} pageData={this.state.pageData} inputValue={inputValue} dataRefName={inputId} type={inputDisplay} updateCallback={this.updateCallback} />
                }
                {readOnlyOptions.includes(inputDisplay) &&
                    <S3Files
                        pathObj={path} template={this.props.cmsData.template} pageData={this.state.pageData} dataRefName={inputId} type={inputDisplay} updateCallback={this.updateCallback} />
                }


            </AvGroup>
        );
    }
    //parse json into form
    generateRadio(groupId, inputId, inputDisplay, inputValue, type, objName, objIndex, index, path) {
        return (
            <AvGroup key={groupId}>
                <span className="radioTitle">{this.separateWordsArray(inputDisplay)}:</span>
                <CustomInput
                    type="radio"
                    id={inputId + '_true'}
                    name={inputId}
                    data-objname={objName}
                    data-objindex={objIndex}
                    value="true"
                    label="true"
                    className="form-check-input"
                    onChange={(e) => { this.updateModel(path, e.target.value) }}
                    defaultChecked={inputValue === "true"}
                />
                <CustomInput
                    type="radio"
                    id={inputId + '_false'}
                    name={inputId}
                    data-objname={objName}
                    data-objindex={objIndex}
                    value="false"
                    label="false"
                    className="form-check-input"
                    onChange={(e) => { this.updateModel(path, e.target.value) }}
                    defaultChecked={inputValue === "false"}
                />
            </AvGroup>
        );
    }

    generateSelect(groupId, inputId, inputDisplay, inputValue, type, objName, objIndex, index, path) {
        let selectedValue = inputValue.selected;
        return (
            <AvGroup key={groupId}>
                <Label for={inputId} key={"lbl-" + inputId + index} className="title">{this.separateWordsArray(inputDisplay)}:</Label>
                <Input
                    type='select'
                    data-name={inputDisplay}
                    name={inputId}
                    data-type={type}
                    data-objname={objName}
                    data-objindex={objIndex}
                    data-index={index + 1}
                    onChange={(e) => { this.updateModel(path + '.selected', e.target.value) }}
                >
                    {
                        inputValue.options.map((data) => {
                            return <option key={data} selected={data === selectedValue ? true : false}>{data}</option>
                        })
                    }
                </Input>
            </AvGroup>
        );
    }

    isLeafType(object) {
        return /string|boolean|number/.test(typeof object);
    }
    isEmptyObject(object) {
        return Object.keys(object).length < 1;
    }
    upperCaseArray(input) {
        return (input.charAt(0).toUpperCase() + input.toLowerCase().slice(1)).match(/[A-Z][a-z]+|[0-9]+/g).join(" ").replace("_", " ");
    }
    separateWordsArray(input) {
        if (input.split(/_\s*/).length !== 1) {
            return (input.split(/_\s*/).join(" "));
        } else if (input.match(/[A-Z][a-z]+|[0-9]+/g) !== null) {
            return (input.charAt(0).toUpperCase() + input.slice(1)).match(/[A-Z][a-z]+|[0-9]+/g).join(" ");
        } else {
            return input
        }
    }



    updateItem(currentPath, status) {
        this.updateModel(currentPath, '', status);
    }
    generateObjectInputs(key, data, checkObj, objName, objIndex, path) {
        if (typeof data === "object") {
            let groupId = "grp-" + key;
            let isArray = checkObj;
            let objGroupName = objName;
            if (Array.isArray(data)) {
                if (data.length < 1) {
                    console.error("current node is an empty array");
                } else {
                    return data.map((item, index) => {
                        let currentPath = path + `[${index}]`;
                        // console.log('currentPath array', path, currentPath);
                        return [<h4 className="title" key={index}>{this.separateWordsArray(objName)}-{index + 1}</h4>, <div className="addRemoveItem"> <a type="button" className="btn btn-secondary" title="Remove this item" onClick={() => this.updateItem(currentPath, 'remove')}>Remove Item</a><a type="button" className="btn btn-secondary" title="Add a new item after this one" onClick={() => this.updateItem(currentPath, 'add')}>Add Item</a></div>, ...this.generateObjectInputs(key + "_" + (index + 1).toString(), item, 'array', key, index, currentPath)];
                    });
                }
            } else if (data != null) {
                if (this.isEmptyObject(data)) {
                    console.error("current node is an empty object");
                } else {
                    return Object.entries(data).map(([entryKey, entryValue], index) => {
                        let currentPath = path + `.${entryKey}`;
                        if (this.isLeafType(entryValue) || entryKey.includes('options')) {
                            if (entryKey.includes('options')) {
                                // creates a select tag
                                return this.generateSelect(groupId + index, groupId + "-" + entryKey, entryKey, entryValue, isArray, objGroupName, (objGroupName + objIndex), index, currentPath);
                            } else if (entryValue === "true" || entryValue === "false") {
                                return this.generateRadio(groupId + index, groupId + "-" + entryKey, entryKey, entryValue, isArray, objGroupName, (objGroupName + objIndex), index, currentPath);
                            } else if (entryKey === 'h' || entryKey === 's' || entryKey === 'l') {
                                return null;
                            } else {
                                if (entryKey === 'field_description') {
                                    return <i>{entryValue}</i>
                                } else if (entryKey === 'version') {
                                    return <i>{entryKey}: {entryValue}</i>
                                } else if (entryKey === 'release_notes') {
                                    return null;
                                } else {
                                    return this.generateTextbox(groupId + index, groupId + "-" + entryKey, entryKey, entryValue, isArray, objGroupName, (objGroupName + objIndex), index, currentPath);
                                }
                            }
                        } else if (entryKey === 'hsl' || entryKey === 'image_fields') {
                            return null;
                        } else {
                            return [
                                <hr></hr>,
                                <h3>Content for: <b className="title"> {this.separateWordsArray(entryKey)}</b> </h3>,
                                <hr></hr>,
                                ...this.generateObjectInputs(entryKey, entryValue, 'object', entryKey, null, currentPath),
                                <hr></hr>
                            ];
                        }
                    });
                }
            } else if (data === null) {
                console.error("current node is null");
            } else {
                console.error("unexpected type" + typeof data);
            }

        } else {
            console.error("current node is not an object");
        }

        return;

    }

    render() {

        let currentComponent = this.props.component === 'trackers' ? 'cart' : [this.props.component];
        let compDataGeneral = this.props.CMSTemplateData.dataModel[currentComponent];
        let compDataTrackers = this.state.pageData['cart'] || this.state.pageData['cartSingleProduct'] || this.state.pageData['cartMultipleProductsSlider'];
        let compData = this.props.component === 'trackers' ? compDataTrackers : compDataGeneral;

        let generated = this.generateObjectInputs(currentComponent.toString(), compData, null, null, null, currentComponent.toString());

        return (
            <Container fluid className="cms__inner component">
                {this.props.title !== "false" &&
                    <>
                        <h1><b>{this.props.cmsData.template}: </b>{this.props.cmsData.currentPage} Page</h1>
                        <h2>Edit data for <b>{this.separateWordsArray(currentComponent.toString())}</b> component:</h2>
                    </>
                }
                {
                    <Card>
                        <CardBody>
                            <AvForm className="cms__editData">
                                {generated}
                            </AvForm>
                            <div className="col-12">
                                <Button color="primary" type="submit" onClick={() => this.handleSubmit()} disabled={this.state.saveButtonIsDisabled || this.props.cmsData.viewOnly} >Save Data</Button>
                            </div>
                        </CardBody>
                    </Card>
                }
            </Container>
        );
    }
}

export default connect(
    (store) => {
        return {
            CMSTemplateData: store.cms.CMSTemplateData,
            cmsData: store.cms.cmsData
        }
    },
    { setCmsData, setCMSTemplateData })(EditComponent)