import React from "react";

import { connect } from 'react-redux';

import Select from "react-select";

import { Card, Form, ButtonGroup, Button, Spinner } from 'react-bootstrap'
import DatePicker from 'react-datepicker';

import { genericCachedFetcherFactory, genericFetcherFactory } from "../../../utils/requestUtils";

import GenericErrorAlert from "../../../components/GenericErrorAlert";
import { addHours, formatDateToYYYYMMDD } from "../../../utils/dateUtils";

import ReportTabs from '../ReportTabs';
import { ReportTypes, ReportPeriods, ExternalReportScopes } from '../constants';
import classNames from "classnames";

class ClientReportsInternalUsersView extends React.PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            initSuccess: null,
            trustedToken: null,
            tokenInit: false,
            products: [],
            clients: [],

            selectedBaseDate: new Date(),

            ...this.getDefaultStateParameters()
        };

        this.changedReportScope = this.changedReportScope.bind(this);
        this.changedProduct = this.changedProduct.bind(this);
        this.changedClient = this.changedClient.bind(this);
        this.changedReportType = this.changedReportType.bind(this);
    }

    getDefaultStateParameters() {
        return {
            tabs: [],
            availableReportTypes: [],
            filteredLabels: [],
            filteredTabs: [],

            selectedLabel: null
        }
    }

    filterLabels(tabs, reportType) {
        return [...new Set(this.filterTabs(tabs, reportType).map(tabItem => tabItem.tag))];
    }

    filterTabs(tabs, reportType) {
        return (tabs && tabs.length > 0 && reportType) ? tabs.filter(tabItem => tabItem.reportType === reportType) : [];
    }

    async componentDidMount() {
        let [getClientResult, getTokenResult] = await Promise.all([this.getClients(), this.getToken()]);

        if (getClientResult.success === true && getTokenResult.success === true) {
            this.setState({
                initSuccess: true,
                clients: getClientResult.data,
                trustedToken: getTokenResult.data.token
            });
        } else {
            this.setState({ initSuccess: false });
        }
    }

    async fetchAndUpdateTabs(reportScope, entityId) {
        let tabItemsResponse = await this.getTabItems(reportScope, entityId);

        if (tabItemsResponse.success === true) {
            let tabItems = tabItemsResponse.data;
            tabItems.forEach(tabItem => { if (!tabItem.tag) { tabItem.tag = "Unlabeled" } });

            let selectedReportType = null;
            let selectedMonthlyPeriod = null;
            let availableReportTypes = tabItems.map(tabItem => tabItem.reportType).filter((value, index, array) => { return value != null && array.indexOf(value) === index });
            let availableReportTypesLength = availableReportTypes.length;
            if (availableReportTypesLength > 0) {
                // Check if previuosly selected value available
                if (this.state.selectedReportType && availableReportTypes.includes(this.state.selectedReportType)) {
                    selectedReportType = this.state.selectedReportType;
                    selectedMonthlyPeriod = this.state.selectedMonthlyPeriod;
                }
                // Set defaults
                else {
                    // Both Monthly and Weekly are available
                    if (availableReportTypesLength > 1 && availableReportTypes.includes(ReportTypes.WEEKLY)) {
                        selectedReportType = ReportTypes.WEEKLY;
                    }
                    // Only one report type is available
                    else {
                        selectedReportType = availableReportTypes[0];
                    }

                    switch (selectedReportType) {
                        case ReportTypes.MONTHLY:
                            selectedMonthlyPeriod = ReportPeriods.THIS_MONTH
                            break;
                        case ReportTypes.WEEKLY:
                            selectedMonthlyPeriod = ReportPeriods.WEEKLY_REPORT
                            break;
                        default:
                            console.error("Unknown report type");
                            break;

                    }
                }
            }

            let filteredLabels = [];
            if (selectedReportType != null) {
                filteredLabels = this.filterLabels(tabItems, selectedReportType);
            }

            this.setState({
                selectedProductId: reportScope == ExternalReportScopes.PRODUCT ? entityId : null,
                filteredLabels: filteredLabels,
                selectedLabel: filteredLabels.length > 0 ? filteredLabels[0] : null,
                tabs: tabItems,
                availableReportTypes: availableReportTypes,
                selectedReportType: selectedReportType,
                selectedMonthlyPeriod: selectedMonthlyPeriod
            })
        } else {
            this.setState({
                ...this.getDefaultStateParameters()
            });
        }
    }

    async getProducts(clientId) {
        return genericCachedFetcherFactory(`/aggregate-api/getProductsByClientId/${clientId}`, "CLIENT_PRODUCTS", "Failed to fetch product list", addHours(1))();
    }

    async getClients() {
        return genericCachedFetcherFactory("/api/crm/Clients/", "CLIENTS", "Failed to fetch clients list", addHours(1))();
    }

    async getTabItems(reportScope, entityId) {
        return genericCachedFetcherFactory(`/api/tableaus/TabMenuItems/External/${reportScope}/${entityId}`, "TAB_ITEMS", "Failed to fetch reports", addHours(1))();
    }

    async getToken() {
        return genericFetcherFactory("/api/tableaus/TrustedToken/", "TRUSTED_TOKEN", "Failed to get trusted Token")();
    }

    changedProduct(productId) {
        if (productId != null) {
            // update the  reports tabs in order to work correct with the token . 
            this.setState({
                tokenInit: true,
                ...this.getDefaultStateParameters(),
            });
            this.fetchAndUpdateTabs(ExternalReportScopes.PRODUCT, productId);
        } else {
            this.setState({ ...this.getDefaultStateParameters() });
        }
    }

    changedClient(clientId) {
        if (clientId != null) {
            this.getProducts(clientId).then(result => {
                if (result.success === true) {
                    this.setState({
                        ...this.getDefaultStateParameters(),
                        products: result.data,
                        selectedClientId: clientId,
                        selectedProduct: null,
                        reportScope: ExternalReportScopes.CLIENT
                    });
                } else {
                    this.setState({ ...this.getDefaultStateParameters() });
                }
                this.fetchAndUpdateTabs(ExternalReportScopes.CLIENT, clientId);
            });
        } else {
            this.setState({ ...this.getDefaultStateParameters() });
        }
    }

    changedReportScope(newReportScope, selectedEntityId) {
        switch (newReportScope) {
            case ExternalReportScopes.CLIENT:
                this.setState({
                    ...this.getDefaultStateParameters(),
                    reportScope: ExternalReportScopes.CLIENT
                });

                this.fetchAndUpdateTabs(ExternalReportScopes.CLIENT, selectedEntityId);
                break;
            case ExternalReportScopes.PRODUCT:
                this.setState({
                    ...this.getDefaultStateParameters(),
                    reportScope: ExternalReportScopes.PRODUCT
                });

                this.fetchAndUpdateTabs(ExternalReportScopes.PRODUCT, selectedEntityId);
                break;
            default:
                console.error("Unsupported scope selected");
                break;
        }
    }

    changedReportType(newReportType) {
        let selectedMonthlyPeriod;

        switch (newReportType) {
            case ReportTypes.MONTHLY:
                selectedMonthlyPeriod = ReportPeriods.THIS_MONTH;
                break;
            case ReportTypes.WEEKLY:
                selectedMonthlyPeriod = ReportPeriods.WEEKLY_REPORT;
                break;
            default:
                newReportType = ReportTypes.MONTHLY;
                selectedMonthlyPeriod = ReportPeriods.THIS_MONTH;
                console.error("Unsuppored report type");
                break;
        }

        let filteredLabels = this.filterLabels(this.state.tabs, newReportType);

        this.setState({
            selectedReportType: newReportType,
            selectedMonthlyPeriod: selectedMonthlyPeriod,
            filteredLabels: filteredLabels,
            selectedLabel: filteredLabels.length > 0 ? filteredLabels[0] : null
        });
    }

    generateLabelsJsx() {
        let labels = this.state.filteredLabels;

        if (labels != null && labels.length > 0) {
            let navItems = labels.map(label => {
                return (
                    <Button
                        variant="outline-primary"
                        outline
                        style={{ zIndex: "0" }}
                        className={classNames("ml-2", { active: this.state.selectedLabel === label })}
                        onClick={() => { this.setState({ selectedLabel: label }) }}
                    >
                        {label}
                    </Button>
                )
            })

            return (
                <ButtonGroup>
                    {navItems}
                </ButtonGroup>
            );
        }
        return "";
    }

    generateReportTypeJsx() {
        if (this.state.availableReportTypes.length > 0) {
            return (
                <>
                    <Form.Group className="form-group col-auto px-3 border-end flex-column">
                        {this.state.availableReportTypes.includes(ReportTypes.WEEKLY) &&
                            <Form.Check
                                type="radio"
                                name="reportType"
                                value="Weekly"
                                label="Weekly"
                                id="Weekly"
                                className="me-2"
                                checked={this.state.selectedReportType === ReportTypes.WEEKLY}
                                onChange={() => this.changedReportType(ReportTypes.WEEKLY)}
                            />
                        }
                        {this.state.availableReportTypes.includes(ReportTypes.MONTHLY) &&
                            <Form.Check
                                type="radio"
                                name="reportType"
                                label="Monthly"
                                value="Monthly"
                                id="Monthly"
                                checked={this.state.selectedReportType === ReportTypes.MONTHLY}
                                onChange={() => this.changedReportType(ReportTypes.MONTHLY)}
                            />
                        }
                    </Form.Group>
                    {this.state.selectedReportType === ReportTypes.MONTHLY &&
                        <Form.Group className="col-auto px-3 border-right align-self-center">
                            <Form.Check type="radio" name="monthlyPeriod" label="This Month" id="This Month" value="This Month" className="ml-4" checked={this.state.selectedMonthlyPeriod === ReportPeriods.THIS_MONTH} onChange={() => this.setState({ selectedMonthlyPeriod: ReportPeriods.THIS_MONTH })} />

                            <Form.Check type="radio" name="monthlyPeriod" label="Last 30 Days" id="Last 30 Days" value="Last 30 Days" className="ml-4" checked={this.state.selectedMonthlyPeriod === ReportPeriods.LAST_30_DAYS} onChange={() => this.setState({ selectedMonthlyPeriod: ReportPeriods.LAST_30_DAYS })} />

                            <Form.Check type="radio" name="monthlyPeriod" label="This Quarter" id="This Quarter" value="This Quarter" className="ml-4" checked={this.state.selectedMonthlyPeriod === ReportPeriods.THIS_QUARTER} onChange={() => this.setState({ selectedMonthlyPeriod: ReportPeriods.THIS_QUARTER })} />
                        </Form.Group>
                    }
                </>
            )
        } else {
            return "";
        }
    }

    inferParams() {
        let params = {};

        params.userLogin = this.props.user.userLogin;

        switch (this.state.reportScope) {
            case ExternalReportScopes.CLIENT:
                params.ClientIdParam = `_client_${this.state.selectedClientId}`;
                break;
            case ExternalReportScopes.PRODUCT:
                if (this.state.selectedProductId != null) {
                    params.ProductIdParam = `_product_${this.state.selectedProductId}`;
                }
                break;
            default:
                break;
        }

        if (this.state.selectedBaseDate != null) {
            params.BaseDate = formatDateToYYYYMMDD(this.state.selectedBaseDate);
        }

        if (this.state.selectedReportType) {
            params.MonthlyPeriod = this.state.selectedMonthlyPeriod;
        }

        return params;
    }

    render() {
        if (this.state.initSuccess === true) {
            let clientOptions = this.state.clients
                .filter(client => client.isActive == true)
                .map(client => { return { value: client.clientId, label: client.clientName } });
            let selectedClient = this.state.selectedClientId != null ? clientOptions.find(option => option.value == this.state.selectedClientId) : null;
            if (selectedClient === undefined) {
                selectedClient = null;
            }

            let productOptions = this.state.products.map(product => { return { value: product.productId, label: product.productName }; });
            if (productOptions.length < 1) {
                productOptions = null;
            }
            let selectedProduct = this.state.selectedProductId != null ? productOptions.find(option => option.value == this.state.selectedProductId) : null;
            if (selectedProduct === undefined) {
                selectedProduct = null;
            };

            let labelsJsx = this.generateLabelsJsx();
            let reportTypesRadioJsx = this.generateReportTypeJsx();

            let additionalParams = this.inferParams();

            // only on the first Tableau report render the token is set. 

            let tabsToShow = this.filterTabs(this.state.tabs, this.state.selectedReportType);

            return (
                <>
                    <Card className="tableauReportsMenu">
                        <Card.Body>
                            <Select
                                placeholder="Select a client..."
                                options={clientOptions}
                                id="clientsDropdown"
                                className="react-select-container mb-2"
                                classNamePrefix="react-select"
                                value={selectedClient}
                                onChange={selectedOption => this.changedClient(selectedOption == null ? null : parseInt(selectedOption.value))}
                                filterOption={(item, input) => {
                                    if (input) {
                                        return item.data.label.toLowerCase().includes(input.toLowerCase());
                                    }
                                    return true;
                                }}
                                theme={theme => ({
                                    ...theme,
                                    borderRadius: 0,
                                    colors: {
                                        ...theme.colors,
                                        primary: '#FFA30F',
                                        primary25: 'rgba(255, 163, 15,0.7);',
                                    },
                                })}

                            />
                            {selectedClient !== null &&
                                <div className="form-group col-12 pb-3 mb-3 justify-content-start  ">
                                    <div className="align-self-center">
                                        <Form.Group className="form-group col-auto px-3 type">
                                            <Form.Check inline type="radio" id="Overview" name="reportScope" label="Overview" className="me-4" checked={this.state.reportScope === ExternalReportScopes.CLIENT} onChange={() => {
                                                this.setState({ tokenInit: true });
                                                this.changedReportScope(ExternalReportScopes.CLIENT, this.state.selectedClientId)
                                            }} />
                                            <Form.Check inline type="radio" id="Product Reports" name="reportScope" label="Product Reports" className="me-4" checked={this.state.reportScope === ExternalReportScopes.PRODUCT} onChange={() => {
                                                this.setState({ tokenInit: true });
                                                this.changedReportScope(ExternalReportScopes.PRODUCT, (this.state.products && this.state.products.length > 0 ? this.state.products[0].productId : null));
                                            }} />
                                        </Form.Group>
                                        {this.state.reportScope == ExternalReportScopes.PRODUCT &&
                                            <div className="productsDropdown form-group col-auto mt-1" >
                                                <Select
                                                    placeholder="Select a product..."
                                                    options={productOptions}
                                                    id="productsDropdown"
                                                    className="react-select-container w-100"
                                                    classNamePrefix="react-select"
                                                    value={selectedProduct}
                                                    onChange={selectedOption => this.changedProduct(selectedOption == null ? null : parseInt(selectedOption.value))}
                                                    filterOption={(item, input) => {
                                                        if (input) {
                                                            return item.data.label.toLowerCase().includes(input.toLowerCase());
                                                        }
                                                        return true;
                                                    }}
                                                    theme={theme => ({
                                                        ...theme,
                                                        borderRadius: 0,
                                                        colors: {
                                                            ...theme.colors,
                                                            primary: '#FFA30F',
                                                            primary25: 'rgba(255, 163, 15,0.7);',
                                                        },
                                                    })}

                                                />
                                            </div>
                                        }
                                    </div>
                                    <Form.Group className="datePickerWrapper form-group flex-column col-auto px-3 border-start border-end " >
                                        <Form.Label>Base Date</Form.Label>
                                        <DatePicker
                                            isClearable
                                            dateFormat="dd/MM/yyyy"
                                            className="border"
                                            // minDate={(new Date()).setDate((new Date()).getDate() - 30)}
                                            maxDate={new Date()}
                                            selected={this.state.selectedBaseDate}
                                            onChange={date => this.setState({ selectedBaseDate: date })} />
                                    </Form.Group>
                                    {reportTypesRadioJsx}
                                    <div className="form-group col-auto px-3 justify-content-start">
                                        {labelsJsx}
                                    </div>
                                </div>
                            }
                        </Card.Body>
                    </Card>
                    {(tabsToShow != null && tabsToShow.length > 0) &&
                        <ReportTabs
                            selectedLabel={this.state.selectedLabel}
                            tabs={tabsToShow}
                            additionalParams={additionalParams}
                            trustedToken={this.state.tokenInit === false ? this.state.trustedToken : null} />
                    }
                    {(selectedClient != null && (tabsToShow == null || tabsToShow.length < 1)) &&
                        <div className="mb-4 general-message">No reports available</div>
                    }
                </>
            )
        } else if (this.state.initSuccess === false) {
            return (
                <GenericErrorAlert errorMessage={<><strong>An error occured, please try again.</strong> If the error persists please contact us.</>} />
            )
        } else {
            return <Spinner />
        }
    };

}

export default connect((store) => {
    return {
        user: store.userContext.user
    }
})(ClientReportsInternalUsersView)