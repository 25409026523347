import { faBell } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Alert, Button, Card, Container, Modal, Badge } from "react-bootstrap";
import * as Icon from "react-feather";
import { connect } from "react-redux";
import { toastr } from "react-redux-toastr";
import MediaQuery from 'react-responsive';
import AccountDropdown from "../../../components/AccountDropdown";
import FacebookContextComponent from "../../../components/FacebookContextComponent";
import GenericErrorAlert from "../../../components/GenericErrorAlert";
import Loader from "../../../components/Loader";
import { formStatus } from "../../../utils/formUtils";
import { genericFetcherFactory } from "../../../utils/requestUtils";
import { AccountIsActive } from "../../prods-and-accounts/account/accountUtils";
import { StopLossColumnsToHide } from "../components/adsetDataView/adsetDataConstants";
import AdsetDataView from "../components/adsetDataView/AdsetDataView";
import ConfirmationTableView from "../components/ConfirmationTableView";
import * as adsetUtils from "../../../utils/adsetUtils"

const totalsInit = { spend: 0, rev: 0, spendLastHour: 0 }
const CENTS_IN_A_DOLLAR = 100;

class UnderDelivery extends React.PureComponent {
    constructor(props) {
        super(props);
        this.saveBidChange = this.saveBidChange.bind(this);
        this.accountChanged = this.accountChanged.bind(this);
        this.increaseBidAction = this.increaseBidAction.bind(this);
        this.state = {
            accountId: this.props.match.params.accountId,
            adsets: null,
            userChanges: null,
            initAdsets: null,
            savingAdsets: formStatus.IDLE,
            formConfirmed: null,
            selectedAccount: null,
            totals: {},
            bidIncreasedPercentage: null
        }
    }

    setStatusByEnum(adsetStatus) {
        if (adsetStatus !== 'PAUSED') {
            return true;
        } else {
            return false;
        }
    }

    async accountChanged(account) {
        if (account !== undefined) {
            this.setState({ initAdsets: null, selectedAccount: account, adsetDataInProcess: true, adsets: null });
            let adsetResponse = [];
            [adsetResponse] = await Promise.all([this.getUnderDeliveryAdsetsAndLambdaResponse(account)])
            if (adsetResponse.success === true) {
                this.buildUnderDeliveryTable(adsetResponse);
            }
        } else {
            toastr.error("An error occured. Could not locate selected account");
        }
    }

    async getUnderDeliveryAdsetsAndLambdaResponse(account) {
        return genericFetcherFactory("/api/facebook-interface/UnderDelivery/getTableColumsFromSF/", "UNDERDELIVERY",
            "Failed to fetch adsets for under delivery tool", "POST",
            {
                method: "POST",
                body: JSON.stringify({ ...account, accountType: "Facebook" }),
                headers: { "Content-Type": "application/json" }
            })()
    }

    buildUnderDeliveryTable(adsetResponse) {
        let totals = { ...totalsInit }
        let adestsWithIncreasedBid = adsetResponse.data.increasedBidAdsetsDto;
        let adsetList = adsetResponse.data.underDeliveryList;
        let lambdaJsonResponse = JSON.parse(adsetResponse.data.lambdaJsonResponse);

        if (lambdaJsonResponse.length > 0) {
            let adsets = [];

            console.log(lambdaJsonResponse, adsetList);
            lambdaJsonResponse.forEach(adsetRowLambda => {
                let maybeAdsetDto = adsetList.find(adset => adset.adsetId === adsetRowLambda.adsetId);
                let adsetDto = maybeAdsetDto === undefined ? {} : maybeAdsetDto;
                let isDailyAdset = adsetUtils.isDailyAdset(adsetRowLambda.adset_schedule_days);

                if (maybeAdsetDto === undefined) {
                    // console.log("Missing adsetId in SF or FB", adsetRowLambda, adsetDto);
                    return;
                }

                //find adests with increased bid
                let maybeActionAdsetDto = adestsWithIncreasedBid.find(adset => adset.adsetId === adsetRowLambda.adsetId);
                let actionAdsetDto = maybeActionAdsetDto === undefined ? {} : maybeActionAdsetDto;

                adsetDto.adsetId = adsetRowLambda.adsetId;
                adsetDto.isActive = this.setStatusByEnum((maybeAdsetDto !== undefined) ? adsetDto.adsetStatus : adsetRowLambda.adsetStatus);
                adsetDto.bidAmount = adsetRowLambda.Bid;

                let adsetBudget;
                if (maybeAdsetDto !== undefined) { 
                    adsetBudget = isDailyAdset ? maybeAdsetDto.dailyBudget: undefined;
                }
                adsetDto.budget = (adsetBudget ? adsetBudget : (isDailyAdset ? adsetRowLambda.dailyBudget : adsetRowLambda['Adset Budget'])) / CENTS_IN_A_DOLLAR;
                adsetDto.adsetName = adsetRowLambda.adsetName;
                adsetDto.startDate = maybeAdsetDto === undefined ? "" : maybeAdsetDto.startDate;
                adsetDto.increasedPercentage = actionAdsetDto.bidIncreasedPercentage;

                if (adsetRowLambda['Spend Today'] !== undefined) {
                    totals.spend += Number(adsetRowLambda['Spend Today']);
                }
                if (adsetRowLambda['Revenue BE Today'] !== undefined) {
                    totals.rev += Number(adsetRowLambda['Revenue BE Today']);
                }
                if (adsetRowLambda['Spend -1 hour'] !== undefined) {
                    totals.spendLastHour += Number(adsetRowLambda['Spend -1 hour']);
                    if (adsetRowLambda['Revenue BE Today'] !== undefined) {
                        adsetRowLambda['ROI -1 hour'] = Number(adsetRowLambda['Revenue BE Today']) / (Number(adsetRowLambda['Spend -1 hour']) * 1.08)
                    }
                }

                if (isDailyAdset) {
                    if (adsetRowLambda.dailyBudget !== undefined) {
                        adsetDto.dailyBudget = adsetRowLambda.dailyBudget = adsetDto.budget;
                    }
                } else {
                    //lifetime budget use dailyBudget from db
                    adsetRowLambda.dailyBudget = adsetDto.dailyBudget;
                    adsetDto.lifetimeBudget /= CENTS_IN_A_DOLLAR;                    
                }         
               
                adsetRowLambda.budgetRemaing = Number(adsetRowLambda.dailyBudget) - Number(adsetRowLambda['Spend Today']);              

                let adset = {
                    acceleratedDto: adsetDto,
                    adsetData: adsetRowLambda
                }
                adsets.push(adset);
            });
            if (totals.rev > 0 && totals.spend > 0) {
                totals.roi = totals.rev / (totals.spend * 1.08)
            }
            if (totals.rev > 0 && totals.spendLastHour > 0) {
                totals.roiLastHour = totals.rev / (totals.spendLastHour * 1.08)
            }
            if (adsets.length > 0) {
                this.setState({ initAdsets: true, adsets: adsets, adsetDataInProcess: false, totals: totals });
            } else {
                this.setState({ initAdsets: false, errorMessage: "Didn't find adsets in DB for this account", adsetDataInProcess: false });
            }
        } else {
            this.setState({ initAdsets: false, errorMessage: "No data from snowflake for this account", adsetDataInProcess: false });
        }
    }

    increaseBidAction(userChanges) {
        if (userChanges == null || userChanges.length <= 0) {
            toastr.warning("No pending changes to save");
        } else {
            this.setState({ savingAdsets: formStatus.CONFIRMATION_REQUIRED });
            if (this.state.formConfirmed === null) {
                this.setState({ savingAdsets: formStatus.CONFIRMATION_REQUIRED, userChanges: userChanges });
            } else {
                this.saveBidChange(userChanges);
            }
        }
    }

    saveBidChange(userChanges) {
        let userChangeInDollar = [];
        userChanges.forEach(acc => {
            //add snowflake data to dto
            let index = this.state.adsets.findIndex(adset => adset.acceleratedDto.adsetId == acc.acceleratedAdsetId);
            if (index !== -1) {
                let adset = this.state.adsets[index];
                acc.snowflakeData = adset.adsetData;
            }
            userChangeInDollar.push(acc);
        });

        this.setState({ savingAdsets: formStatus.IN_PROGRESS });
        this.updateBidOnFacebook(userChangeInDollar);
    }

    async updateBidOnFacebook(userChangeInDollar) {
        return genericFetcherFactory(`/api/facebook-interface/UnderDelivery/updateBidOnFacebook`, "UPDATE_BID", "Failed to update bid", "POST",
            {
                method: "POST",
                body: JSON.stringify({
                    accountId: this.state.selectedAccount.accountId,
                    dtoList: userChangeInDollar
                }),
                headers: {
                    "Content-Type": "application/json"
                }
            })().then(result => {
                if (result.success === true) {
                    this.setState({ savingAdsets: formStatus.IDLE, userChanges: [] });
                    toastr.success("Bids have been updated! You will see the new bid in the next 10 minutes (14:30-17:00)");
                }
            });
    }

    render() {
        return (
            <Container fluid className="p-0" id="StopLossTool">
                <Modal size="xl"
                    show={this.state.savingAdsets !== formStatus.IDLE} centered>
                    <Modal.Header tag="h2" toggle={() => this.setState({ savingAdsets: formStatus.IDLE })}>
                        Update Adsets {this.state.savingAdsets}
                    </Modal.Header>
                    <Button color="secondary" size="small"
                        style={{ position: "absolute", top: "10px", right: "10px" }}
                        onClick={e => this.setState({ savingAdsets: formStatus.IDLE })}>
                        Close
                    </Button>
                    <Modal.Body>
                        {this.state.savingAdsets === formStatus.IN_PROGRESS &&
                            <Loader content={["Increasing bids", "Please come again"]} />
                        }
                        {this.state.savingAdsets === formStatus.CONFIRMATION_REQUIRED &&
                            <>
                                <ConfirmationTableView
                                    userActions={this.state.userChanges}
                                    adsets={this.state.adsets}
                                />
                                <Button onClick={() => this.saveBidChange(this.state.userChanges)} color="primary">Confirm Changes</Button>
                            </>
                        }
                    </Modal.Body>
                </Modal>

                <Card>
                    <Card.Header className="pb-1">
                        <Alert className="d-sm-none mb-2">
                            <div className="alert-icon">
                                <FontAwesomeIcon icon={faBell} fixedWidth />
                            </div>
                            <div className="alert-message">Please rotate your mobile phone for better view</div>
                        </Alert>
                        <Card.Title tag="h1" className="mb-0">Under Delivery Adsets Management</Card.Title>
                    </Card.Header>
                    <Card.Body className="pt-0">
                        {!this.props.facebookUserData.isLoggedIn ?
                            <Button color="info"><FacebookContextComponent shouldRender={true} /></Button>
                            :
                            <>
                                {(this.state.initAdsets === null || this.state.selectedAccount !== null) &&
                                    <>
                                        <div className="mb-4" style={{ display: "flex", justifyContent: "space-between", zIndex: 1, position: 'relative' }}>
                                            <AccountDropdown
                                                widthClass={"col-12 col-sm-4"}
                                                accountChanged={this.accountChanged}
                                                selectedAccount={this.state.selectedAccount}
                                                platform='FACEBOOK'
                                                itemActive={AccountIsActive.ACTIVE} />
                                        </div>
                                    </>
                                }

                                {this.state.adsets !== null &&
                                    <>
                                        <h5><Icon.Info className="feather mr-2"></Icon.Info>
                                            You can choose multiple adsets and update their bid. <br></br>
                                            Bid Update Times 14:30-17:00
                                        </h5>
                                        <Badge bg="secondary" className="me-2">5% </Badge>
                                        <Badge bg="success" className="me-2">10%</Badge>
                                        <Badge bg="info" className="me-2">manual</Badge>

                                        <MediaQuery minWidth={900} >
                                            <AdsetDataView
                                                adsets={this.state.adsets}
                                                type="underdelivery"
                                                submitFunction={this.increaseBidAction}
                                            />
                                        </MediaQuery>
                                        <MediaQuery maxWidth={899} >
                                            <AdsetDataView
                                                adsets={this.state.adsets}
                                                type="underdelivery"
                                                submitFunction={this.increaseBidAction}
                                                hideColumnsOnMobile={StopLossColumnsToHide}
                                            />
                                        </MediaQuery>
                                    </>
                                }
                                {this.state.initAdsets === false &&
                                    <GenericErrorAlert errorMessage={this.state.errorMessage} />
                                }
                                {this.state.adsetDataInProcess &&
                                    <Loader content={["Loading Under Delivery Adsets", "Prepare to edit bid"]} />
                                }
                            </>
                        }
                    </Card.Body>
                </Card>
            </Container >
        )
    }
}


export default connect((store) => {
    return {
        facebookUserData: store.userContext.facebookUserData
    }
})(UnderDelivery)
