import React from "react";
import { Container } from "react-bootstrap";
import { connect } from 'react-redux';
import { setDeploymentSharedComponent, setUserStatus, setCmsData, setCMSTemplateData } from "../../../redux/actions/cmsActions"
import { SharedComponentUtils } from '../SharedComponentUtils';
import { cmsUtils } from '../utils';
import { UserStatusUtils } from '../UserStatusUtils';
import Loader from '../../../components/Loader';


class DataPrep extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            error: null,
            pushStates: [],
            componentFetched: null
        };

        this.utils = new cmsUtils();
        this.userStatusUtils = new UserStatusUtils();
        this.SharedComponentUtils = new SharedComponentUtils();

        this.SCALI_SESSION_ID = this.props.cmsData.template + "_" + this.props.cmsData.deploySelectedComponent
    }

    componentDidMount() {
        this.utils.fetchPageList()
            .then(() => {
                this.utils.resetDataModel('allPages').then(() => {
                    this.SharedComponentUtils.updateAllPagesComponents(
                        { [this.props.cmsData.deploySelectedComponent]: this.props.cmsData.sharedComponentsFullData[this.props.cmsData.deploySelectedComponent] },
                        [this.props.cmsData.deploySelectedComponent]
                    ).then(() => {
                        this.utils.fetchFileFromS3("package.json").then(() => {
                            this.props.next()
                        })
                    })
                });
            });
    }


    render() {
        return (
            <>
                <Container fluid className="cms__inner">
                    <h1>Data preparation for <b>{this.props.cmsData.template}:</b> "{this.props.cmsData.deploySelectedComponent}"</h1>
                    <Loader content={["Comparing components data", "Almost done..."]} width="full" />
                </Container>
            </>
        )
    }
}

export default connect(
    (store) => {
        return {
            deploymentSharedComponent: store.cms.deploymentSharedComponent,
            CMSTemplateData: store.cms.CMSTemplateData,
            cmsData: store.cms.cmsData,
            userStatus: store.cms.userStatus,
            user: store.userContext.user
        }
    },
    { setCmsData, setCMSTemplateData, setDeploymentSharedComponent, setUserStatus })(DataPrep)