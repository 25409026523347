import React from "react";
import {
    Container,
    Card,
    CardBody,
    Collapse,
    CardHeader,
    Button
} from "reactstrap";
import { Modal, Form } from "react-bootstrap";
import cloneDeep from 'lodash/cloneDeep';
import {
    PlusCircle as PlusCirclekIcon,
    MinusCircle as MinusCirclekIcon
} from "react-feather";
import Loader from '../../components/Loader';
import merge from 'lodash/merge';
import { genericFetcherFactory } from '../../utils/requestUtils';
import { PushNotifications } from "../../components/PushNotifications";
import Box from './DragAndDrop/Box';
import classNames from "classnames";
import EditComponent from './EditComponent';
import { connect } from 'react-redux';
import { cmsUtils } from './utils';
import { SharedComponentUtils } from './SharedComponentUtils';
import { setCmsData, setCMSTemplateData } from "../../redux/actions/cmsActions";
import { toastr } from "react-redux-toastr";

const sharedComponents = 'shared-components';
class ComponentsFull extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            changeOrder: false,
            pageData: {},
            items: [],
            unsavedData: [],
            chooseNewCompModalIsOpen: false,
            selectedNewComponent: null,
            refrash: true,
            pushStates: [],
            pushNotificationsActive: true,
            checkComponentsVersions: null,
            versions: [],
            updateComps: []
        };

        this.utils = new cmsUtils();
        this.SharedComponentUtils = new SharedComponentUtils();
        this.handleChange = this.handleChange.bind(this);
        this.updateModel = this.updateModel.bind(this);
        this.getComponentDataFromS3 = this.getComponentDataFromS3.bind(this);
        this.refrashView = this.refrashView.bind(this);
        this.checkComponentsVersions = this.checkComponentsVersions.bind(this);
        this.updateComponentsAfterFetch = this.updateComponentsAfterFetch.bind(this);
        this.conflictComponent = this.conflictComponent.bind(this);
        this.updatePageCheckedVersions = this.updatePageCheckedVersions.bind(this);

        this.SCALI_SESSION_ID = this.props.cmsData.template + '_' + this.props.cmsData.currentPage;
        this.TemplateIsSharedCompCompatible = Object.keys(this.props.CMSTemplateData.packages[this.props.cmsData.template]).includes("sharedComponents");
        this.TemplateIsSharedCompCompatibleEmpty = this.TemplateIsSharedCompCompatible && this.props.CMSTemplateData.dataModel.sharedComponents.length === 0 ? true : false;

    }

    componentDidMount() {
        this.props.setCmsData({
            ...this.props.cmsData,
            'templateIsSharedCompCompatible': this.TemplateIsSharedCompCompatible
        });
        if (this.TemplateIsSharedCompCompatible && this.TemplateIsSharedCompCompatibleEmpty === false && this.props.cmsData.clickedStep === 'next') {
            let compToUpdate = this.props.CMSTemplateData.dataModel.sharedComponents.filter(comp => { return !this.props.cmsData.sharedComponentsUpdated.includes(comp) })
            if (this.props.cmsData.sharedComponentsFullData !== null) {
                compToUpdate = compToUpdate.filter(comp => { return !Object.keys(this.props.cmsData.sharedComponentsFullData).includes(comp) })
            }
            if (this.state.selectedNewComponent !== null || compToUpdate.length > 0) {
                this.setState({ checkComponentsVersions: 'start' });
                this.SharedComponentUtils.getSharedComponentFromGitToS3(
                    this.state.selectedNewComponent !== null ? [this.state.selectedNewComponent] : compToUpdate || null,
                    this.SCALI_SESSION_ID
                );
            } else {
                this.updatePageCheckedVersions();
            }
        }
    }


    getComponentDataFromS3() {
        let files = this.state.selectedNewComponent !== null ? [this.state.selectedNewComponent] : this.props.CMSTemplateData.dataModel.sharedComponents;
        this.SharedComponentUtils.getComponentDataFromS3(files).then(() => {
            //if new component added
            if (this.state.checkComponentsVersions !== 'start') {
                this.updateComponentsAfterFetch({ [this.state.selectedNewComponent]: this.props.cmsData.sharedComponentsFullData[this.state.selectedNewComponent] });
            }
            //updating components
            if (this.state.checkComponentsVersions === 'start' && Object.keys(this.props.cmsData.sharedComponentsFullData).length === files.length) {
                this.checkComponentsVersions();
            }
        })
    }

    updateComponentsAfterFetch(data = this.props.cmsData.sharedComponentsFullData) {
        this.props.setCMSTemplateData(
            this.utils.updateSuccessCallback({
                'dataModel': [data, true]
            })
        );

        //update other pages component 
        if (this.props.cmsData.pagesDataLoaded[this.props.cmsData.template]) {
            let compsToMerge = this.state.selectedNewComponent !== null ? [this.state.selectedNewComponent] : this.state.updateComps;
            this.SharedComponentUtils.updateAllPagesComponents(data, compsToMerge)
        }

        //which components is updated (version)
        let propSharedComponentsUpdated = cloneDeep(this.props.cmsData.sharedComponentsUpdated);
        let sharedComponentsUpdated = this.state.updateComps.filter(comp => { return !propSharedComponentsUpdated.includes(comp) })
        sharedComponentsUpdated = propSharedComponentsUpdated.concat(sharedComponentsUpdated);

        //Added components
        let sharedComponentsAdded = cloneDeep(this.props.cmsData.sharedComponentsAdded) || [];
        if (this.state.selectedNewComponent !== null) {
            sharedComponentsAdded.push(this.state.selectedNewComponent);
        }

        this.props.setCmsData({
            ...this.props.cmsData,
            'sharedComponentsUpdated': sharedComponentsUpdated,
            'sharedComponentsAdded': sharedComponentsAdded
        });

        this.refrashView();
    }

    checkComponentsVersions() {
        const dataModal = this.props.CMSTemplateData.dataModel,
            sharedComponents = dataModal.sharedComponents,
            updateComps = [];

        for (const comp of sharedComponents) {
            if (dataModal[comp].version !== this.props.cmsData.sharedComponentsFullData[comp].version) {
                //if fetched component has higher version then copy it
                updateComps.push(comp)
            }
        }

        this.setState({
            updateComps: updateComps,
            checkComponentsVersions: 'done'
        }, () => {
            if (updateComps.length > 0) {
                this.utils.resetDataModel('allPages').then(() => {
                    this.conflictComponent();
                    this.updatePageCheckedVersions();
                }
                );
                const toastrConfirmOptions = {
                    okText: "OK",
                    id: 'edit_comp_confirm_btn',
                    disableCancel: true,
                    component: () => (
                        <>
                            <div className="confirm_message">
                                This page have an older version of <b>{updateComps.toString().replaceAll(",", " ")}</b>. <br />
                                Release Notes:
                                <ul>
                                    {updateComps.map(data => {
                                        return (
                                            <li key={data}><b className="text-capitalize">{data}:</b> {this.props.cmsData.sharedComponentsFullData[[data]].version} - {this.props.cmsData.sharedComponentsFullData[[data]].release_notes} </li>
                                        )
                                    })}
                                </ul>
                                When deploying - the components would update to the newest version on <b>all pages</b>.<br />
                                <b><i>**Please go over the component data</i></b>
                            </div>
                        </>
                    )
                };

                toastr.confirm(null, toastrConfirmOptions);
            } else {
                this.utils.resetDataModel('allPages').then(() => {
                    this.updatePageCheckedVersions();
                }
                );
            }
        });

    }

    updatePageCheckedVersions = () => {
        let pagesCheckedVersions = { ...this.props.cmsData.pagesCheckedVersions };
        pagesCheckedVersions[this.props.cmsData.template] = { ...this.props.cmsData.pagesCheckedVersions[this.props.cmsData.template] };
        pagesCheckedVersions[this.props.cmsData.template][this.props.cmsData.page] = true;

        this.props.setCmsData({
            ...this.props.cmsData,
            'pagesCheckedVersions': pagesCheckedVersions
        });
    }

    conflictComponent() {
        let mergedData = this.SharedComponentUtils.mergeCompData(this.props.CMSTemplateData.dataModel, this.state.updateComps);
        this.updateComponentsAfterFetch(mergedData);
    }

    handleChange(event) {
        const { name, value } = event.target;
        this.setState({
            [name]: value
        });

    }

    updateCallback = (key, value, isData, isArray) => {
        if (isArray !== "add" && isArray !== "remove") {
            this.props.setCMSTemplateData(this.utils.updateSuccessCallback({ [key]: [value, isData] }));
        }
        if (isArray === 'add') {
            if (!this.state.unsavedData.includes(value)) {
                this.setState(prevState => ({
                    unsavedData: [...prevState.unsavedData, value]
                }),
                    () => {
                        this.props.setCmsData({ ...this.props.cmsData, 'unsavedData': this.state.unsavedData });
                    })
            }
        } else if (isArray === 'remove') {
            this.setState(function (prevState) {
                return {
                    unsavedData: prevState.unsavedData.filter(function (val, i) {
                        return val !== value;
                    })
                };
            },
                () => {
                    this.props.setCmsData({ ...this.props.cmsData, 'unsavedData': this.state.unsavedData });
                });
        }
    }

    get changeOrderBTN() {
        let buttonText = !this.state.changeOrder ? 'Enable components reorder' : 'Done components reorder';
        return (
            <button disabled={this.props.cmsData.viewOnly} onClick={() => this.changeOrderState()}
                className="btn btn-primary big  me-2" type="button">
                {buttonText}
            </button>
        )
    }

    toggleAllComponents(componentsState) {
        this.state.componentsState ? componentsState = false : componentsState = true;
        this.props.CMSTemplateData.dataModel.componentsToEdit.map((data) => {
            this.setState({ [data]: componentsState });
        })
        this.setState({ componentsState: componentsState });
    }

    toggleState = (key) => {
        this.state[key] !== true ? this.setState({ [key]: true }) : this.setState({ [key]: false })
    }

    stateIcon(key, showText) {
        if (this.state[key]) {
            return <><span className="icon"> <MinusCirclekIcon /> </span> {showText ? 'Minimize' : ''}  </>
        } else {
            return <><span className="icon"> <PlusCirclekIcon /> </span> {showText ? 'Expand' : ''}</>
        }
    }

    changeOrderState() {
        this.setState({ changeOrder: !this.state.changeOrder });
    }

    updateModel = (value) => {
        this.setState({ pageData: this.props.CMSTemplateData.dataModel }, () => {
            let fullData = cloneDeep(this.props.CMSTemplateData.dataModel);
            fullData['componentsSaved'] = fullData['componentsSaved'] ? fullData['componentsSaved'] : [];
            fullData['componentsSaved'].splice(0, 0, value);
            fullData['sharedComponents'] = fullData['sharedComponents'] ? fullData['sharedComponents'] : [];
            fullData['sharedComponents'].splice(0, 0, value);
            if (!fullData['componentsToEdit'].includes(value)) {
                fullData['componentsToEdit'].splice(1, 0, value);
            }
            this.setState({ pageData: fullData }, () => {
                this.props.setCMSTemplateData(
                    this.utils.updateSuccessCallback({
                        'dataModel': [fullData, true]
                    })
                );
                this.props.setCmsData({ ...this.props.cmsData, selectedNewComponent: this.state.selectedNewComponent });
                this.getComponentDataFromS3();
            });
        });
    }

    addNewComponent() {
        if (this.state.unsavedData.length === 0) {
            this.setState({ chooseNewCompModalIsOpen: false });
            this.refrashView('off');
            this.SharedComponentUtils.getSharedComponentFromGitToS3(
                [this.state.selectedNewComponent],
                this.SCALI_SESSION_ID
            );
        } else {
            this.setState({ chooseNewCompModalIsOpen: false, selectedNewComponent: null });
            this.props.unsaveDataToasterTrigger('Please save your data before adding a new component', true);
        }
    }

    refrashView(refrash) {
        if (this.state.unsavedData.length === 0) {
            if (refrash === 'off') {
                this.setState({ refrash: false });
            } else {
                this.setState({ refrash: false, refrash: true });
            }
        } else {
            this.props.unsaveDataToasterTrigger('Please save your data before adding a new component', false);
        }
    }


    render() {
        if (this.props.CMSTemplateData.error !== null) {
            return (
                <Container fluid className="cms__inner">
                    <h1><b>{this.props.cmsData.template}:</b> {this.props.cmsData.currentPage} Page </h1>
                    <div>{this.props.CMSTemplateData.error}</div>
                </Container>
            )
        } else if (
            (this.TemplateIsSharedCompCompatible &&
                Object.keys(this.props.cmsData.pagesCheckedVersions).length > 0 &&
                this.props.cmsData.pagesCheckedVersions[this.props.cmsData.template][this.props.cmsData.page] === true)
            ||
            this.TemplateIsSharedCompCompatibleEmpty
            ||
            !this.TemplateIsSharedCompCompatible
        ) {

            const fetchList = this.props.CMSTemplateData.dataModel.componentsToEdit || this.props.CMSTemplateData.dataModel.components || [];
            this.setState({ items: this.props.CMSTemplateData.dataModel.components, pageData: this.props.CMSTemplateData.dataModel });

            return (
                <Container fluid className="cms__inner cms_edit_components_full row">
                    <h1 className="col-lg-12"><b>{this.props.cmsData.template}:</b> {this.props.cmsData.currentPage} Page </h1>
                    <div className="col-lg-7">
                        <h2>Edit components:</h2>
                        <button className="btn btn-primary mb-3" type="button" onClick={() => this.toggleAllComponents()}>
                            {this.stateIcon('componentsState', true)} all components
                        </button>

                        <div className="componentsWrap">
                            {this.state.refrash && fetchList.map((data, value) => {
                                return (
                                    <Card key={data}>
                                        <a className={`toggleButton ${this.props.cmsData.sharedComponentsUpdated.includes(data) ? 'updated' : ''} ${this.props.cmsData.sharedComponentsAdded.includes(data) ? 'new' : ''}
                                        `} onClick={() => this.toggleState(data)}>
                                            <CardHeader>
                                                <h3>
                                                    {this.stateIcon(data)} {data}
                                                    {this.props.cmsData.sharedComponentsUpdated.includes(data) ? <span className="updateBadge">updated</span> : null}
                                                    {this.props.cmsData.sharedComponentsAdded.includes(data) ? <span className="updateBadge">new</span> : null}
                                                </h3>
                                            </CardHeader>
                                        </a>
                                        <Collapse isOpen={this.state[data]}>
                                            <EditComponent
                                                title="false"
                                                updateCallback={this.updateCallback}
                                                component={data}
                                            />
                                        </Collapse>
                                    </Card>
                                );
                            })}
                        </div>

                        {!this.state.refrash &&
                            <div>
                                <Loader content={["Fetching " + this.state.selectedNewComponent, "Almost done..."]} width="full" />
                            </div>
                        }
                    </div>


                    <div className="col-lg-5">
                        <Card>
                            <CardBody>
                                {this.changeOrderBTN}

                                {this.TemplateIsSharedCompCompatible &&
                                    <>
                                        <button className="btn btn-primary big ml-2" type="button" onClick={() => this.setState({ chooseNewCompModalIsOpen: true })}>
                                            + Add a new component
                                        </button>

                                        <Modal
                                            show={this.state.chooseNewCompModalIsOpen === true}
                                            centered
                                        >
                                            <div class="modal-header">
                                                <h5 class="modal-title"> Choose a New Component</h5>
                                                <div>
                                                    <Button type="button" class="close" onClick={() => this.setState({ chooseNewCompModalIsOpen: false, selectedNewComponent: null })}> X </Button>
                                                </div>
                                            </div>
                                            <Modal.Body className="text-left m-5">
                                                <Form>
                                                    {this.props.cmsData.sharedComponentsAvailable != null && this.props.cmsData.sharedComponentsAvailable.map((data, value) => {
                                                        return (
                                                            <Form.Group key={value}>
                                                                <Form.Check
                                                                    type="radio"
                                                                    value={data}
                                                                    id={data}
                                                                    key={data}
                                                                    onChange={this.handleChange}
                                                                    name='selectedNewComponent'
                                                                    label={data}
                                                                    disabled={this.props.CMSTemplateData.dataModel.sharedComponents.includes(data) ? true : false}
                                                                />
                                                            </Form.Group>
                                                        )
                                                    })}
                                                    {this.state.sharedComponentsDataIsLoaded === false &&
                                                        'No Available Components yet...'
                                                    }
                                                </Form>
                                            </Modal.Body>
                                            <Modal.Footer className="justifyCenter">
                                                <Button disabled={this.state.selectedNewComponent === null} color="secondary" onClick={() => this.addNewComponent()}>
                                                    Save & Close
                                                </Button>
                                            </Modal.Footer>
                                        </Modal>
                                    </>
                                }
                                {this.state.refrash &&
                                    <div className={classNames("boxes", { disabled: !this.state.changeOrder })}>
                                        <Box title="Components List" items={this.props.CMSTemplateData.dataModel.components} compValue="components" updateCallback={this.updateCallback} removeBTN="false" dataModel={this.props.CMSTemplateData.dataModel} targetKey="box" />
                                        <Box title="Archived Components" items={this.props.CMSTemplateData.dataModel.componentsSaved} compValue="componentsSaved" removeBTN="true" updateCallback={this.updateCallback} dataModel={this.props.CMSTemplateData.dataModel} targetKey="box" />

                                    </div>
                                }

                                {this.state.pushNotificationsActive &&
                                    <PushNotifications
                                        topic={"jenkins-" + this.SCALI_SESSION_ID}
                                        messageCallBack={(msg) => {
                                            this.setState(prevState => ({
                                                pushStates: [...prevState.pushStates, msg.step]
                                            }))
                                            if (msg.step === "CopyComponentsToS3Done") {
                                                if (this.state.checkComponentsVersions === "start") {
                                                    this.getComponentDataFromS3();
                                                } else {
                                                    this.utils.resetDataModel('allPages').then(() => {
                                                        this.updateModel(this.state.selectedNewComponent);
                                                    }
                                                    );
                                                }
                                            }
                                        }}
                                    ></PushNotifications>
                                }
                            </CardBody>
                        </Card>
                    </div>

                </Container>
            );
        } else {
            return (
                <Container fluid className="cms__inner">
                    <h1><b>{this.props.cmsData.template}: </b>{this.props.cmsData.currentPage} Page </h1>
                    <h2>Edit components:</h2>
                    {/* <Loader /> */}
                    <Loader content={["Updating Components", "Almost done..."]} width="full" />
                    {this.state.pushNotificationsActive &&
                        <PushNotifications
                            topic={"jenkins-" + this.SCALI_SESSION_ID}
                            messageCallBack={(msg) => {
                                this.setState(prevState => ({
                                    pushStates: [...prevState.pushStates, msg.step]
                                }))
                                if (msg.step === "CopyComponentsToS3Done") {
                                    if (this.state.checkComponentsVersions === "start") {
                                        this.getComponentDataFromS3();
                                    } else {
                                        this.utils.resetDataModel('allPages').then(() => {
                                            this.updateModel(this.state.selectedNewComponent);
                                        }
                                        );
                                    }
                                }
                            }}
                        ></PushNotifications>
                    }
                </Container>
            )
        }
    }
}

export default connect(
    (store) => {
        return {
            CMSTemplateData: store.cms.CMSTemplateData,
            cmsData: store.cms.cmsData
        }
    },
    { setCmsData, setCMSTemplateData })(ComponentsFull)