import React from "react";
import {
    Check as CheckIcon
} from "react-feather";
import { toastr } from "react-redux-toastr";
import {
    Button,
    Container,
    Card,
    CardBody
} from "reactstrap";
import { Spinner } from "react-bootstrap";
import cloneDeep from 'lodash/cloneDeep';
import { PushNotifications } from "../../components/PushNotifications";
import Timeline from "../../components/Timeline";
import TimelineItem from "../../components/TimelineItem";
import { genericFetcherFactory } from '../../utils/requestUtils';
import { cmsUtils } from './utils';
import { connect } from 'react-redux';
import { setCmsData, setCMSTemplateData } from "../../redux/actions/cmsActions"
import { SharedComponentUtils } from './SharedComponentUtils';
import Loader from '../../components/Loader';


const DeploymentEnvironments = {
    DEV: "dev",
    STG: "stg",
    PROD: "prod",
}
const stgEnvLink = "http://dev.qualityscore.co";
const prodEnvLink = "http://ec2-44-224-108-15.us-west-2.compute.amazonaws.com";
const prodLinksDoc = "https://docs.google.com/spreadsheets/d/1EkGIBmZM4s640kOdlVyaE80iuYYna5rMtQcmHdVl7KI/edit#gid=1648885167";
const PushStatesValues = {
    STG_STARTED: "stgStarted",
    STG_READY: "stgReady",
    PROD_STARTED: "prodStarted",
    PROD_READY: "prodReady",
    ROLLBACK_STARTED: "rollbackStarted",
    ROLLBACK_READY: "rollbackReady",
    COPY_FILES_TO_S3: "CopyFilesToS3Done"
}
const jsons = {
    packageFile: "package.json",
    sharedComponentsFile: "shared-components.json"
}
const sharedComponents = 'shared-components';

class Deployment extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            dataUploaded: false,
            pushStates: [],
            pushNotificationsActive: true,
            packageIsLoaded: false,
            fetchedPackage: [],
            EnvLinks: [],
            linksAvailable: false,
            mergeJsons: null,
            updatePackageOnGit: null,
            readyToStartSTG: false,
            clickedUploadData: false,
            sharedComponentsUpdated: this.props.cmsData.sharedComponentsUpdated,
            sharedComponentsAdded: this.props.cmsData.sharedComponentsAdded
        };

        this.SharedComponentUtils = new SharedComponentUtils();
        this.uploadDataToS3 = this.uploadDataToS3.bind(this);
        this.copyAndMergeComponent = this.copyAndMergeComponent.bind(this);
        this.deployToJenkins = this.deployToJenkins.bind(this);
        this.rollback = this.rollback.bind(this);
        this.mergeSharedCompToPackage = this.mergeSharedCompToPackage.bind(this);
        this.mergeFileToGit = this.mergeFileToGit.bind(this);
        this.deploySummary = this.deploySummary.bind(this);
        this.uploadAllPagesData = this.uploadAllPagesData.bind(this);
        this.uploadFinalJsonsToGitFromS3 = this.uploadFinalJsonsToGitFromS3.bind(this);

        this.SCALI_SESSION_ID = this.props.cmsData.template + '_' + (this.props.deployType === 'page' ? this.props.cmsData.currentPage : this.props.cmsData.deploySelectedComponent);
        this.DEPLOYD_PAGES = this.props.cmsData.deploydPages;
        this.CURRENT_PAGE = this.props.cmsData.template + '_' + this.props.cmsData.currentPage;
        this.COMP_CHANGED_ADDED = this.state.sharedComponentsUpdated.length > 0 || this.state.sharedComponentsAdded.length > 0 || this.props.cmsData.deploySelectedComponent !== null;

        this.utils = new cmsUtils();

    }

    componentDidMount() {

        this.fetchFileFromS3(jsons.sharedComponentsFile);
        this.setEnvLinks();
        if (this.props.cmsData.templateIsSharedCompCompatible === true) {
            this.SharedComponentUtils.mergeAllCompsBeforeDeploy()
        } else {
            this.props.setCMSTemplateData({ ...this.props.CMSTemplateData, pristineDataModelForDeploy: this.props.CMSTemplateData.pristineDataModel });
        }
    }

    //Upload package.json from git to S3
    // uploadPackageToS3FromGit() {
    //     this.setState({
    //         pushNotificationsActive: true
    //     })

    //     let rest = {
    //         MERGE_GIT: "copyFiles",
    //         FOLDER_NAME: this.props.cmsData.template,
    //         scali_session_id: this.SCALI_SESSION_ID
    //     }
    //     genericFetcherFactory(
    //         "/jenkins-api/jobTrigger/CMS-Merge",
    //         "jenkins - merge",
    //         "Failed to to run jenkins",
    //         "POST",
    //         {
    //             method: 'POST',
    //             body: JSON.stringify(rest),
    //             headers: { 'Content-Type': 'application/json' }
    //         })().then(
    //             (response) => {
    //                 if (response.success === true) {
    //                     console.log('success copy package.json from git to s3');
    //                 }
    //             }
    //         )
    // }

    //Merge git => copy shared Components folders to template folder 
    copyAndMergeComponent() {
        this.setState({
            pushNotificationsActive: true
        })
        let rest = {
            MERGE_GIT: "copyMergedComponent",
            BRANCH_TO_MERGE: this.props.cmsData.template + "_" + this.props.cmsData.page,
            COMPONENTS: this.props.CMSTemplateData.dataModel !== null ? (this.state.sharedComponentsAdded.concat(this.state.sharedComponentsUpdated)).toString().replaceAll(",", " ") : this.props.cmsData.deploySelectedComponent,
            FOLDER_NAME: this.props.cmsData.template,
            scali_session_id: this.SCALI_SESSION_ID
        }
        genericFetcherFactory(
            "/jenkins-api/jobTrigger/CMS-Merge",
            "jenkins - copy component",
            "Failed to to run jenkins",
            "POST",
            {
                method: 'POST',
                body: JSON.stringify(rest),
                headers: { 'Content-Type': 'application/json' }
            })().then(
                (response) => {
                    if (response.success) {
                        this.setState({ copyAndMergeComponent_status: "success" });
                        this.showToastr('Merged Component into project');
                        this.props.setCmsData({
                            ...this.props.cmsData,
                            sharedComponentsAdded: [],
                            sharedComponentsUpdated: []
                        })
                    } else {
                        toastr.warning('Error in copy components');
                    }
                }
            )
    }

    mergeFileToGit(fileName = jsons.packageFile) {
        this.setState({
            pushNotificationsActive: true
        })
        let filesName = Array.isArray(fileName) ? fileName.toString().replaceAll(',', ' ') : fileName;
        let rest = {
            MERGE_GIT: "mergeFileToGit",
            BRANCH_TO_MERGE: this.SCALI_SESSION_ID,
            FOLDER_NAME: this.props.cmsData.template,
            END_FOLDER_NAME: filesName.includes('html') ? 'build/data/' + this.props.cmsData.template : "",
            FILE_NAME: filesName,
            scali_session_id: this.SCALI_SESSION_ID
        }
        genericFetcherFactory(
            "/jenkins-api/jobTrigger/CMS-Merge",
            "jenkins - copy package.json",
            "Failed to run jenkins",
            "POST",
            {
                method: 'POST',
                body: JSON.stringify(rest),
                headers: { 'Content-Type': 'application/json' }
            })().then(
                (response) => {
                    if (response.success) {
                        this.showToastr('Merging ' + fileName + ' into git');
                    } else {
                        toastr.warning('Error in merging ' + fileName);
                    }
                }
            )
    }

    fetchFileFromS3(file) {
        let rest = {
            folder: file === jsons.packageFile ? this.props.cmsData.template : sharedComponents,
            file: file
        }
        genericFetcherFactory(
            "/lambda-proxy/lambdaProxy/lambdas3download",
            file,
            "Failed to fetch " + file,
            "POST",
            {
                method: 'POST',
                body: JSON.stringify(rest)
            })().then(res => {
                if (res.success !== true) {
                    this.setState({
                        [[file.split('.')[0]] + "IsLoaded"]: false,
                        error: 'Data not found'
                    });
                } else {
                    let fetchedData = JSON.parse(res.data);
                    let fetchedFile = file === jsons.packageFile ? 'fetchedPackage' : 'fetchedSharedComponentsMapping';
                    this.setState({
                        [[file.split('.')[0]] + "IsLoaded"]: true,
                        [fetchedFile]: fetchedData
                    });
                    if (file === jsons.packageFile) {
                        this.setEnvLinks();
                    }
                }
            }
            );
    }

    setEnvLinks() {

        const fetchPackage = this.props.CMSTemplateData.packages[this.props.cmsData.template] || [];
        let isDomains = fetchPackage.domains ? true : false;
        let setEnvLinks;
        let currentPage = this.props.cmsData.currentPage === '' ? 'index' : this.props.cmsData.currentPage;
        const domainsLinks = {
            facebook: fetchPackage.domains.facebook || '',
            google: fetchPackage.domains.google || ''
        };
        const stgLinks = stgEnvLink + "/" + fetchPackage['pipeline'].stg[0].folder + "/build";
        if (isDomains) {
            setEnvLinks = {
                PROD_LINK_FACEBOOK: domainsLinks.facebook !== '' ? domainsLinks.facebook + "/" + currentPage + ".html" : '',
                PROD_LINK_GOOGLE: domainsLinks.google !== '' ? domainsLinks.google + "/" + currentPage + ".html" : '',
                STG_LINK: stgLinks + "/" + currentPage + ".html"
            }
        } else {
            setEnvLinks = {
                PROD_LINK: prodEnvLink + "/" + fetchPackage['pipeline'].prod[0].folder + "/" + currentPage + ".html",
                STG_LINK: stgLinks + "/" + currentPage + ".html"
            }
        }
        this.setState({
            EnvLinks: setEnvLinks,
            domainsLinks: domainsLinks,
            stgLinks: stgLinks,
            linksAvailable: true
        })
    }

    // update package.json with all shared components
    mergeSharedCompToPackage() {
        let dataModel = this.props.CMSTemplateData.dataModel;
        if (dataModel !== null && this.props.CMSTemplateData.concatSharedComps.length > 0 || this.props.cmsData.deploySelectedComponent !== null) {
            let fetchPackage = cloneDeep(this.props.CMSTemplateData.packages[this.props.cmsData.template]);
            let sharedComponents = dataModel !== null ? Object.keys(this.props.cmsData.sharedComponentsFullData) : [this.props.cmsData.deploySelectedComponent];
            let fetchedSharedComponentsMapping = this.state.fetchedSharedComponentsMapping;
            fetchPackage['sharedComponents'] = fetchPackage['sharedComponents'] ? fetchPackage['sharedComponents'] : [];
            sharedComponents.map(comp => {
                if (!fetchPackage['sharedComponents'].includes(comp)) {
                    fetchPackage['sharedComponents'].splice(0, 0, comp);
                    this.setState({ 'updatePackageOnGit': true })
                }
                let compVersion = this.props.cmsData.sharedComponentsFullData[comp]['version'];
                //if comp exist
                if (Object.keys(fetchedSharedComponentsMapping).includes(comp)) {
                    let compVersions = Object.keys(fetchedSharedComponentsMapping[comp]);
                    for (const thisVersion of compVersions) {
                        if (fetchedSharedComponentsMapping[comp][thisVersion].includes(this.props.cmsData.template)) {
                            let index = fetchedSharedComponentsMapping[comp][thisVersion].indexOf(this.props.cmsData.template);
                            fetchedSharedComponentsMapping[comp][thisVersion].splice(index, 1)
                        }
                    }
                    //if comp version exist
                    if (compVersions.includes(compVersion)) {
                        //if comp version include this template
                        if (!fetchedSharedComponentsMapping[comp][compVersion].includes(this.props.cmsData.template)) {
                            fetchedSharedComponentsMapping[comp][compVersion].push(this.props.cmsData.template);
                        }
                    } else {
                        fetchedSharedComponentsMapping[comp][compVersion] = [this.props.cmsData.template];
                    }
                } else {
                    fetchedSharedComponentsMapping[comp] = {};
                    fetchedSharedComponentsMapping[comp] = { [compVersion]: [this.props.cmsData.template] };
                }
            }
            )

            this.setState({ fetchedPackage: fetchPackage, fetchedSharedComponentsMapping: fetchedSharedComponentsMapping }, () => {
                if (this.state.updatePackageOnGit === true) {
                    this.uploadDataToS3({
                        [jsons.packageFile]: fetchPackage,
                        [jsons.sharedComponentsFile]: this.state.fetchedSharedComponentsMapping
                    });
                    let packages = cloneDeep(this.props.CMSTemplateData.packages);
                    packages[this.props.cmsData.template] = fetchPackage;
                    this.props.setCMSTemplateData({
                        ...this.props.CMSTemplateData,
                        'packages': packages
                    });
                } else {
                    this.uploadDataToS3({
                        [jsons.sharedComponentsFile]: this.state.fetchedSharedComponentsMapping
                    });
                }
            });
        }
    }

    uploadDataToS3(data) {
        for (const [fileName, fileContent] of Object.entries(data)) {
            let rest = {
                folder: fileName === jsons.sharedComponentsFile ? jsons.sharedComponentsFile.split('.')[0] : this.props.cmsData.template,
                fileName: fileName,
                fileContent: fileContent,
                scali_session_id: this.SCALI_SESSION_ID
            }
            genericFetcherFactory(
                "/lambda-proxy/lambdaProxy/lambdas3upload",
                "UPLOAD DATA",
                "Failed to upload file",
                "POST",
                {
                    method: 'POST',
                    body: JSON.stringify(rest)
                })().then(res => {
                    if (res.success === true) {
                        if (fileName !== jsons.packageFile) {
                            this.showToastr("Data was uploaded successfully for: " + this.props.cmsData.template + ": " + fileName + " Page");
                            if (this.props.cmsData.deploySelectedComponent === null) {
                                this.utils.resetTemplatePageDataModel();
                                this.utils.resetDataModel();
                            }
                            if (this.props.cmsData.templateIsSharedCompCompatible !== true) {
                                this.setState({
                                    readyToStartSTG: true
                                })
                            }
                        }
                        //create deploy summary after all data is retreived                     
                        this.deploySummary();

                        this.uploadFinalJsonsToGitFromS3();
                    }
                }
                );
        }
    }

    uploadFinalJsonsToGitFromS3() {
        if (this.state.mergeJsons !== true) {
            this.setState({ 'mergeJsons': true })
            let files = [];
            if (this.props.cmsData.templateIsSharedCompCompatible) {
                let pages = this.COMP_CHANGED_ADDED ? this.props.CMSTemplateData.templatePageLists[this.props.cmsData.template] : [this.props.cmsData.currentPage];

                for (const page of pages) {
                    let fullPage = page + ".html.json";
                    files.push(fullPage)
                }
            } else {
                files.push(this.props.cmsData.currentPage + ".html.json");
            }
            this.mergeFileToGit(files)
        }
    }

    deploySummary() {
        let deploydPages = cloneDeep(this.DEPLOYD_PAGES);
        if (!deploydPages.includes(this.props.cmsData.currentPage)) {
            deploydPages.splice(0, 0, this.props.cmsData.currentPage);
        }
        this.props.setCmsData(this.utils.updateSuccessCallback({ 'deploydPages': [deploydPages, false] }));
    }

    deployToJenkins(env) {

        this.setState({
            pushNotificationsActive: true
        })

        let rest = {
            BUILD_FOLDER_NAME: this.props.cmsData.template,
            BUILD_ENV: env,
            ADD_COMPONENTS: this.props.CMSTemplateData.packages[this.props.cmsData.template]['sharedComponents'] || [],
            scali_session_id: this.SCALI_SESSION_ID
        }
        genericFetcherFactory(
            "/jenkins-api/jobTrigger/Dev-projects-CMS-ci",
            "Dev-projects-CMS-ci",
            "Failed to to run jenkins",
            "POST",
            {
                method: "POST",
                body: JSON.stringify(rest),
                headers: { 'Content-Type': 'application/json' }
            })().then(response => {
                if (response.success === true) {
                    if (env === DeploymentEnvironments.STG) {
                        this.showToastr('Deploying to staging');
                        this.setState({ deploying: "stg-success" });
                    } else if (env === DeploymentEnvironments.PROD) {
                        this.showToastr('Deploying to production');
                        this.setState({ deploying: "prod-success" });
                    }
                }
            }
            )
    }

    rollback() {
        let rest = {
            BUILD_FOLDER_NAME: this.props.cmsData.template,
            scali_session_id: this.SCALI_SESSION_ID
        }
        genericFetcherFactory(
            "/jenkins-api/jobTrigger/Dev-projects-rollback",
            "jenkins - rollback",
            "Failed to to run jenkins",
            "POST",
            {
                method: 'POST',
                body: JSON.stringify(rest),
                headers: { 'Content-Type': 'application/json' }
            })().then(
                (response) => {
                    if (response.success === true) {
                        this.showToastr('Rollback for:' + this.props.cmsData.template);
                    }

                }
            )
    }

    //notification on save data
    showToastr(message, type) {
        const options = {
            timeOut: 30000,
            showCloseButton: true,
            progressBar: true,
            position: "top-center"
        };
        let toastrInstance;
        switch (type) {
            case "info":
                toastrInstance = toastr.info;
                break;
            case "warning":
                toastrInstance = toastr.warning;
                break;
            case "error":
                toastrInstance = toastr.error;
                break;
            default:
                toastrInstance = toastr.success;
                break;
        }

        toastrInstance(
            'Hey',
            message,
            options
        );
    }

    uploadAllPagesData() {       
        let pages = this.COMP_CHANGED_ADDED ? this.props.CMSTemplateData.templatePageLists[this.props.cmsData.template] : [this.props.cmsData.currentPage];
        let objForUpdate = {};
        for (const page of pages) {
            let name = this.props.cmsData.template + '_' + page;
            objForUpdate[page + ".html.json"] = this.props.CMSTemplateData.pristineDataModelForDeploy[name]
        }
        this.uploadDataToS3(objForUpdate);
    }

    updateStatus() {
        let data = '';
        if (this.state.pushStates.slice(-1)[0] === 'fileMergedToGitStarted') {
            data = 'Pages data is now merging to project...'
        } else if (this.state.pushStates.slice(-1)[0] === 'copyFolderAndMergeGitStarted') {
            data = 'Components data are now merging to project...'
        }

        return <div className="mb-2">{data}</div>;
    }

    checkStatus() {
        if (this.state.clickedUploadData === true && this.state.readyToStartSTG === false) {
            return true;
        } else if (this.state.pushStates.length > 0 && this.state.pushStates.slice(-1)[0].includes('Started')) {
            return true;
        } else { return false }
    }

    render() {
        if (this.props.cmsData.templateIsSharedCompCompatible && this.props.CMSTemplateData.dataReadyForDeploy || !this.props.cmsData.templateIsSharedCompCompatible) {
            return (
                <Container fluid className="cms__inner row">
                    <h1>Save & Deploy edited data for <b>{this.props.cmsData.template}:</b>  {this.props.deployType === 'page' && this.props.cmsData.currentPage ? this.props.cmsData.currentPage + ' page' : '"' + this.props.cmsData.deploySelectedComponent + '" component'}  </h1>
                    <Card className="col-12 col-md-6">
                        <CardBody>
                            <Timeline>
                                <TimelineItem className={`${this.state.readyToStartSTG ? "disabled" : ""} ${this.props.cmsData.deployment === 'prod' ? 'hidden' : ''}`}>
                                    <div className="icon"> <CheckIcon /> </div>
                                    <div className="wrap">
                                        <h3>Click to upload data:</h3>
                                        <Button disabled={this.state.clickedUploadData} color="primary"
                                            onClick={() => {
                                                this.setState({ 'clickedUploadData': true })
                                                if (this.props.cmsData.templateIsSharedCompCompatible
                                                    && (this.state.sharedComponentsUpdated !== null || this.state.sharedComponentsAdded.length > 0)
                                                    || this.props.cmsData.deploySelectedComponent !== null
                                                ) {
                                                    this.uploadAllPagesData();
                                                    this.mergeSharedCompToPackage()
                                                } else {
                                                    this.uploadDataToS3({ [this.props.cmsData.currentPage + ".html.json"]: this.props.CMSTemplateData.dataModel });;
                                                }
                                            }}>Upload Data</Button>

                                    </div>
                                </TimelineItem>
                                <TimelineItem className={`${this.state.readyToStartSTG ? "" : "hidden"} ${this.state.pushStates.includes(PushStatesValues.STG_STARTED) ? "disabled" : ""}`}>
                                    <div className="icon"> <CheckIcon /> </div>
                                    <div className="wrap">
                                        <h2>Staging</h2>
                                        <h3>To deploy to <b>staging</b> environment click:</h3>
                                        <Button disabled={this.state.pushStates.includes(PushStatesValues.STG_STARTED) || this.state.deploying === 'stg-success'} color="primary" onClick={() => {
                                            this.deployToJenkins(DeploymentEnvironments.STG);
                                            this.setState(prevState => ({
                                                pushStates: [...prevState.pushStates, "loaderStarted"]
                                            }))
                                        }}>Deploy to Staging</Button>
                                    </div>
                                </TimelineItem>
                                <TimelineItem className={`${this.state.pushStates.includes(PushStatesValues.STG_READY) ? "" : "hidden"} ${this.state.pushStates.includes(PushStatesValues.PROD_STARTED) ? "disabled" : ""}`}>
                                    <div className="icon"> <CheckIcon /> </div>
                                    <div className="wrap">
                                        <h3>To view the page in staging: <a href={this.state.EnvLinks.STG_LINK} target="_blank">Staging Link</a> </h3>
                                    </div>
                                </TimelineItem>
                                <TimelineItem className={`${this.state.pushStates.includes(PushStatesValues.STG_READY) || this.props.cmsData.deployment === DeploymentEnvironments.PROD ? "" : "hidden"} ${this.state.pushStates.includes(PushStatesValues.PROD_STARTED) ? "disabled" : ""}`}>
                                    <div className="icon"> <CheckIcon /> </div>
                                    <div className="wrap">
                                        <h2>Production</h2>
                                        <h3>To deploy to <b>production</b> environment click:</h3>
                                        <Button disabled={this.state.pushStates.includes(PushStatesValues.PROD_STARTED) || this.state.deploying === 'prod-success'} color="primary" onClick={() => this.deployToJenkins(DeploymentEnvironments.PROD)}>Deploy to Production</Button>
                                    </div>
                                </TimelineItem>
                                <TimelineItem className={`${this.state.pushStates.includes(PushStatesValues.PROD_READY) ? "" : "hidden"} ${!this.state.pushNotificationsActive || this.state.pushStates.includes(PushStatesValues.ROLLBACK_STARTED) ? "disabled" : ""}`}>
                                    <div className="icon"> <CheckIcon /> </div>
                                    <div className="wrap">
                                        <h2>Do you apporve the new version?</h2>
                                        <h3>To view the page in production:
                                            {this.state.EnvLinks.PROD_LINK_FACEBOOK !== '' &&
                                                <a href={this.state.EnvLinks.PROD_LINK_FACEBOOK} target="_blank"> Facebook Domain </a>
                                            }
                                            {this.state.EnvLinks.PROD_LINK_GOOGLE !== '' &&
                                                <a href={this.state.EnvLinks.PROD_LINK_GOOGLE} target="_blank"> Google Domain </a>
                                            }
                                            {!this.props.CMSTemplateData.packages[this.props.cmsData.template].domains &&
                                                <a href={this.state.EnvLinks.PROD_LINK} target="_blank"> Production temporary Link </a>
                                            }
                                        </h3>
                                        {!this.props.CMSTemplateData.packages[this.props.cmsData.template].domains &&
                                            <p>To see the pretty link for production please see <a href={prodLinksDoc} target="_blank">Production links doc</a></p>
                                        }

                                        <Button color="primary" className="me-2" disabled={!this.state.pushNotificationsActive || this.state.pushStates.includes(PushStatesValues.ROLLBACK_STARTED)} onClick={() => this.setState({ pushNotificationsActive: false })}>Yes</Button>
                                        <Button color="danger" onClick={() => this.rollback()} disabled={!this.state.pushNotificationsActive || this.state.pushStates.includes(PushStatesValues.ROLLBACK_STARTED)}>No, Rollback to last working version</Button>
                                    </div>
                                </TimelineItem>

                                {
                                    this.checkStatus()
                                    &&
                                    <>
                                        {this.updateStatus()}
                                        <Spinner animation="border" variant="primary" className="me-7" />
                                    </>
                                }

                            </Timeline>
                        </CardBody>
                    </Card>
                    <div className="col-12 col-md-5 ml-4 deploySummary">
                        <h2>Deployment Summary</h2>
                        {this.props.cmsData.deploydPages === null &&
                            <div>No pages data were deployed yet</div>
                        }
                        {this.props.cmsData.deploydPages !== null && this.state.linksAvailable &&
                            <Card >
                                <h3>Staging links</h3>
                                <div>*Will be ready with changes after staging deployment</div>
                                {this.props.cmsData.deploydPages.map(data => {
                                    return (
                                        <div>
                                            <a key={data} href={this.state.stgLinks + "/" + data + ".html"} target="_blank"> {data}</a>
                                        </div>
                                    )
                                })}
                                {this.props.cmsData.templateIsSharedCompCompatible && this.state.sharedComponentsUpdated !== null &&
                                    <div className="mt-2">
                                        {this.state.sharedComponentsUpdated.length > 0 &&
                                            <>
                                                <h4>Updated shared components:</h4>
                                                <div>
                                                    {this.state.sharedComponentsUpdated.map(data => {
                                                        return (
                                                            <span> {data}</span>
                                                        )
                                                    })}
                                                </div>
                                            </>
                                        }
                                        {this.state.sharedComponentsAdded.length > 0 &&
                                            <>
                                                <h4>Added shared components:</h4>
                                                <div>
                                                    {this.state.sharedComponentsAdded.map(data => {
                                                        return (
                                                            <span> {data}</span>
                                                        )
                                                    })}
                                                </div>
                                            </>
                                        }
                                        {Object.keys(this.props.CMSTemplateData.templatePageLists).includes(this.props.cmsData.template) &&
                                            this.COMP_CHANGED_ADDED &&
                                            <>
                                                <h4>Pages that will also be updated with the latest shared components version:</h4>
                                                <div>
                                                    {this.props.CMSTemplateData.templatePageLists[this.props.cmsData.template].sort().map(data => {
                                                        return (
                                                            <a key={data} href={this.state.stgLinks + "/" + data + ".html"} target="_blank"> {data}</a>
                                                        )
                                                    })}
                                                </div>
                                            </>
                                        }
                                    </div>
                                }
                            </Card>
                        }
                        {!this.state.linksAvailable && <Spinner animation="border" variant="primary" className="me-7" />}
                        {this.props.cmsData.deploydPages !== null && this.state.linksAvailable &&
                            <Card>
                                <h3>Production links</h3>
                                <div>*Will be ready with changes after production deployment</div>
                                <div>
                                    {this.props.cmsData.deploydPages.map(data => {
                                        return (
                                            <>
                                                {this.state.EnvLinks.PROD_LINK_FACEBOOK !== '' &&
                                                    <a key={data + 'fb'} href={this.state.domainsLinks.facebook + "/" + data + ".html"} target="_blank"> {data} (Facebook)</a>
                                                }
                                                {this.state.EnvLinks.PROD_LINK_GOOGLE !== '' &&
                                                    <a key={data + 'yt'} href={this.state.domainsLinks.google + "/" + data + ".html"} target="_blank"> {data} (Google)</a>
                                                }
                                            </>
                                        )
                                    })}
                                </div>
                            </Card>
                        }
                    </div>
                    {this.state.pushNotificationsActive &&
                        //current step -> msg.step
                        //current build -> msg.buildNumber
                        //current scali_session_id -> msg.scali_session_id
                        <PushNotifications
                            topic={"jenkins-" + this.SCALI_SESSION_ID}
                            messageCallBack={(msg) => {
                                this.setState(prevState => ({
                                    pushStates: [...prevState.pushStates, msg.step]
                                }))

                                if (msg.step === 'fileMergedToGitReady' && (this.state.updatePackageOnGit === true || this.COMP_CHANGED_ADDED)) {
                                    //git - copy shared Components folders to template folder (if there is a change in shared components - version or added)
                                    this.copyAndMergeComponent();
                                } else if (msg.step === 'fileMergedToGitReady' && this.state.updatePackageOnGit === null) {
                                    this.setState({ "readyToStartSTG": true })
                                }

                                if (msg.step === 'copyFolderAndMergeGitReady' && this.state.updatePackageOnGit === true) {
                                    //git - merge package.json to git (if there is a change in shared components - version or added)
                                    this.mergeFileToGit(jsons.packageFile);
                                    this.setState({ "updatePackageOnGit": false, "readyToStartSTG": true })
                                } else if (msg.step === 'copyFolderAndMergeGitReady' && this.state.updatePackageOnGit === null) {
                                    this.setState({ "readyToStartSTG": true })
                                }
                            }}
                        ></PushNotifications>
                    }
                </Container>
            );
        } else {
            return (
                <Container fluid className="cms__inner">
                    <Loader content={["Preparing Data", "Almost done..."]} width="full" />
                </Container>
            )
        }
    }
}


export default connect(
    (store) => {
        return {
            CMSTemplateData: store.cms.CMSTemplateData,
            cmsData: store.cms.cmsData
        }
    },
    { setCmsData, setCMSTemplateData })(Deployment)