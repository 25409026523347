import React from "react";
import {
    Nav,
    NavItem,
    NavLink,
    TabContent,
    TabPane
} from "reactstrap";
import TableauReport from 'tableau-react';
import { IframeSettings } from "./constants";



export default class ReportTabs extends React.Component {
    constructor(props) {
        super(props);

        this.toggle = this.toggle.bind(this);
        this.state = {
            activeTabId: null
        };
    }

    componentDidMount() {
        if (this.props.tabs.length > 0) {
            if (this.props.selectedLabel) {
                this.setState({ activeTabId: this.props.tabs.filter(tab => tab.tag === this.props.selectedLabel)[0].tabId });
            }
            else {
                this.setState({ activeTabId: this.props.tabs[0].tabId });
            }
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.selectedLabel !== this.props.selectedLabel || JSON.stringify(prevProps.tabs) !== JSON.stringify(this.props.tabs)) {
            if (this.props.selectedLabel) {
                this.setState({ activeTabId: this.props.tabs.filter(tab => tab.tag === this.props.selectedLabel)[0].tabId });
            } else {
                this.setState({ activeTabId: this.props.tabs[0].tabId });
            }
        }
    }

    toggle(tabId) {
        if (this.state.activeTabId !== tabId) {
            this.setState({
                activeTabId: tabId
            });
        }
    }
    addHideToolbar(options, tabItem) {
        if (tabItem.showToolbar === true) {
            options.hideToolbar = false;
        }
        return options;
    }

    render() {
        let tabsJsx = [];
        let tabsContentJsx = [];

        let tabsToDisplay = this.props.selectedLabel ? this.props.tabs.filter(tab => tab.tag === this.props.selectedLabel) : this.props.tabs;
        let iframeSettings = this.props.iframeSettings ? this.props.iframeSettings : IframeSettings;
        tabsToDisplay.forEach((tab, index) => {
            let tabId = tab.tabId;
            tabsJsx.push(
                <NavItem>
                    <NavLink
                        className={{ active: this.state.activeTabId === tabId }}
                        onClick={() => { this.toggle(tabId); }}
                    >
                        {tab.tabName}
                    </NavLink>
                </NavItem>
            );

            tabsContentJsx.push(
                <TabPane tabId={tabId}>
                    <TableauReport
                        render={true}
                        url={tab.url}
                        token={index == 0 ? this.props.trustedToken : null}
                        filters={iframeSettings.filters}
                        parameters={this.props.additionalParams}
                        options={this.addHideToolbar(iframeSettings.options, tab)}
                        query="?:jsdebug=n&:embed=yes&:refresh=yes"
                    />
                </TabPane>
            );
        })

        return (
            <div className="tab">
                <Nav tabs>
                    {tabsJsx}
                </Nav>
                <TabContent activeTab={this.state.activeTabId}>
                    {tabsContentJsx}
                </TabContent>
            </div>
        );
    }
}