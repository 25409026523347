import React from 'react';
import { withRouter } from "react-router";

import { Modal,Container,Card } from "react-bootstrap";


import UserEditForm from './UserEditForm';
import Loader from '../../../components/Loader'

import { genericFetcherFactory } from '../../../utils/requestUtils';

class NewUser extends React.PureComponent {

    constructor(props) {
        super(props);
        this.state = {
            formSaving: false
        }

        this.save = this.save.bind(this);
    }

    save(userObj) {
        this.setState({ ...this.state, formSaving: true })
        genericFetcherFactory("/api/users/Users", "SAVE_USER", "Failed to save user", "POST", {
            method: 'POST',
            body: JSON.stringify(userObj),
            headers: {
                "Content-Type": "application/json"
            }
        })().then(res => {
            if (res.success) {
                this.props.history.push('/user-mgmt/users')
            } else {
                this.setState({ ...this.state, formSaving: false });
            }
        });
    }

    render() {
        return (
            <>
                <Modal show={this.state.formSaving === true} centered>
                    <Modal.Header>
                        Saving User...
                    </Modal.Header>
                    <Modal.Body >
                        <Loader />
                    </Modal.Body>
                </Modal>

                <Container fluid className="p-0">
                    <h1 className="h3 mb-3">Create new user</h1>
                    <Card>
                        <Card.Body>
                            <UserEditForm submitCallback={this.save} />
                        </Card.Body>
                    </Card>
                </Container>
            </>
        )
    }
}

export default withRouter(NewUser);