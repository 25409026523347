import React from "react";

import {
    Button,
    Card,
    CardBody,
    CardHeader,
    CardTitle,
    Container,
    CustomInput,
    FormGroup,
    Modal,
    ModalBody,
    ModalHeader,
    UncontrolledTooltip
} from "reactstrap";

import Select from "react-select";

import BootstrapTable from "react-bootstrap-table-next";
import filterFactory, { selectFilter, textFilter } from 'react-bootstrap-table2-filter';

import * as Icon from "react-feather";
import { Info } from "react-feather";
import { toastr } from "react-redux-toastr";
import Loader from '../../components/Loader';

import GenericErrorAlert from "../../components/GenericErrorAlert";
import { CACHE_EXPIRATION_DEFAULTS } from "../../services/qsCache";
import { addHours } from "../../utils/dateUtils";
import { genericCachedFetcherFactory, genericFetcherFactory } from '../../utils/requestUtils';
import { Platforms } from "../prods-and-accounts/account/accountUtils";
import FunnelEditForm, { FormModes } from "./FunnelEditForm";
import {
    BASE_PIXEL_STRUCTURE,
    BingFunnelPixels,
    deepCloneFunnel,
    DEFAULT_FUNNEL_ID,
    DV360FunnelPixels,
    FacebookFunnelPixels,
    generateAccountsSelectOptions,
    getMainKpiDisplayForPixelBasedFunnels,
    GoogleFunnelPixels,
    isPixelBasedFunnelPlatform,
    KpiTypeOptions,
    PIXEL_FROM_DEFAULT_FUNNEL_ID,
    processFunnelDto,
    SnapchatFunnelPixels,
    TaboolaFunnelPixels,
    TikTokFunnelPixels,
    TwitterFunnelPixels
} from "./funnelUtils";

const funnelHistoryLink = "funnel-history";

export default class AccountFunnels extends React.PureComponent {
    constructor(props) {
        super(props);

        this.saveNewFunnel = this.saveNewFunnel.bind(this);
        this.updateFunnel = this.updateFunnel.bind(this);
        this.accountChanged = this.accountChanged.bind(this);
        this.toggleTableView = this.toggleTableView.bind(this);
        this.getEditFormJsx = this.getEditFormJsx.bind(this);
        this.toggleCreateNewFunnelFormExpand = this.toggleCreateNewFunnelFormExpand.bind(this);
        this.cloneFunnel = this.cloneFunnel.bind(this);
        this.cloneFormatter = this.cloneFormatter.bind(this);
        this.reloadFunnelGroups = this.reloadFunnelGroups.bind(this);

        this.state = {
            extendedTableView: false,
            funnels: [],
            accounts: [],
            accountFunnelGroups: [],
            products: [],
            accountPixels: null,
            selectedAccount: null,
            createNewFunnelFormExpanded: false,
            initSuccess: null,
            createNewFunnelTemplate: {},
            pageLoading: false,
            displayTipsTricksInfo: false

        };
    }

    componentDidMount() {
        this.setState({ pageLoading: true });

        genericCachedFetcherFactory("/api/accounts/Accounts", "ACCOUNTS", "Failed to fetch accounts", addHours(CACHE_EXPIRATION_DEFAULTS.Accounts))().then((result) => {
            if (result.success == true) {
                this.setState({ initSuccess: true, pageLoading: false, accounts: [...result.data] });
            } else {
                this.setState({ initSuccess: false, pageLoading: false });
            }
        });
    }

    // Saving callbacks

    updateFunnel(funnelObj) {
        this.setState({ formSaving: true });

        let funnelId = funnelObj.funnelId;
        funnelObj.funnelPlatform = this.state.selectedAccount.platform.platformName;

        genericFetcherFactory("/api/accounts/Funnels/Funnel/" + funnelObj.accountId,
            "UPDATE_FUNNEL",
            "Failed to update funnel",
            "PATCH",
            {
                method: "PATCH",
                body: JSON.stringify(funnelObj),
                headers: { 'Content-Type': 'application/json' }
            })().then(res => {
                if (res.success) {
                    let updatedFunnel = res.data;
                    let newFunnels = [...this.state.funnels];
                    let funnelIndex = newFunnels.findIndex(item => (item.funnelId === funnelId));
                    newFunnels.splice(funnelIndex, 1, processFunnelDto(updatedFunnel, this.state.selectedAccount, this.state.products, this.state.accountFunnelGroups, this.state.accountPixels));
                    this.setState({ funnels: newFunnels, formSaving: false });
                    toastr.success("Success", updatedFunnel.funnelName + " funnel was updated successfully.")
                } else {
                    this.setState({ formSaving: false });
                    toastr.error("Save Failed", "Error: " + res.message)                    
                }
            });
    }

    saveNewFunnel(funnelObj) {
        this.setState({ formSaving: true, createNewFunnelTemplate: deepCloneFunnel(funnelObj) });

        funnelObj.funnelPlatform = this.state.selectedAccount.platform.platformName;

        genericFetcherFactory("/api/accounts/Funnels/",
            "SAVE_FUNNELS",
            "Failed to save funnels",
            "POST",
            { method: "POST", body: JSON.stringify(funnelObj), headers: { 'Content-Type': 'application/json' } })
            ().then(res => {
                if (res.success) {
                    let insertedFunnel = res.data;
                    let newFunnels = [...this.state.funnels];
                    newFunnels.push(processFunnelDto(insertedFunnel, this.state.selectedAccount, this.state.products, this.state.accountFunnelGroups, this.state.accountPixels));
                    this.setState({ createNewFunnelTemplate: { accountId: this.state.selectedAccount.accountId }, funnels: newFunnels, formSaving: false, createNewFunnelFormExpanded: false });
                    toastr.success("Success", insertedFunnel.funnelName + " funnel was saved successfully.")
                } else {
                    this.setState({ formSaving: false });
                    toastr.error("Save Failed", "Error: " + res.message)                    
                }
            });
    }

    // Fetches

    async fetchFunnels(accountId) {
        return genericFetcherFactory("/api/accounts/Funnels/AccountFunnels/" + accountId, "FUNNELS", "Funnels fetch failed")();
    }

    async fetchProducts(accountProducts) {
        return genericFetcherFactory("/api/product/Products/byProductIdList", "PRODUCTS", "Failed to fetch products", "POST",
            {
                method: "POST",
                body: JSON.stringify(accountProducts),
                headers: { "Content-Type": "application/json" }
            })();
    }

    async fetchFunnelGroups(accountId) {
        return genericFetcherFactory("/api/accounts/FunnelGroups/AccountFunnelGroups/" + accountId, "FUNNELGROUPS", "Funnel groups fetch failed")();
    }

    async fetchPixels(accountId) {
        return genericFetcherFactory("/api/accounts/Pixels/Account/" + accountId, "PIXELS", "Pixels fetch failed")();
    }

    // Events

    async accountChanged(accountId) {
        let accountProducts = [];
        let accountFunnels = [];

        this.setState({ pageLoading: true });

        if (accountId === 0) {
            this.setState({ pageLoading: false, selectedAccount: null, products: accountProducts, funnels: accountFunnels, extendedTableView: false, createNewFunnelFormExpanded: false });
        } else {
            let selectedAccount = this.state.accounts.find(item => item.accountId == accountId);

            if (selectedAccount === undefined) {
                toastr.error("Selected account has no data. Please try to refresh the page and try again");
                this.setState({ initSuccess: false, pageLoading: false });
                return;
            }

            // Check whether to reset the extended view
            let extendedTableView = this.state.extendedTableView;
            if (this.state.selectedAccount == null || this.state.selectedAccount.platform.platformId !== selectedAccount.platform.platformId) {
                extendedTableView = false;
            }

            let accountPixels = null;

            let accountProductsFetch;
            let funnelGroupsFetch;
            let accountPixelsFetch;

            let promises = [
                this.fetchProducts(selectedAccount.accountProducts),
                this.fetchFunnelGroups(accountId)
            ];

            if (isPixelBasedFunnelPlatform(selectedAccount.platform.platformName)) {
                promises.push(this.fetchPixels(accountId));
                let fetchResult = await Promise.all(promises);
                [accountProductsFetch, funnelGroupsFetch, accountPixelsFetch] = fetchResult;

                if (accountPixelsFetch.success == true) {
                    if (accountPixelsFetch.data.length > 0) {
                        accountPixels = [...accountPixelsFetch.data];
                    } else {
                        accountPixels = [];
                        toastr.warning("This account has no events. Funnels might not be displayed properly and will not be updatable.");
                    }
                }
            } else {
                let fetchResult = await Promise.all(promises);
                [accountProductsFetch, funnelGroupsFetch] = fetchResult;
            }

            if (accountProductsFetch.success === true) {
                if (accountProductsFetch.data.length < 1) {
                    toastr.warning("This account has no products. Funnels might not be displayed properly and will not be updatable.");
                } else {
                    accountProducts = accountProductsFetch.data;
                }
            }

            let accountFunnelGroups = [];
            if (funnelGroupsFetch.success == true) {
                accountFunnelGroups = funnelGroupsFetch.data;
            }

            let accountFunnelsFetch = await this.fetchFunnels(accountId);

            if (accountFunnelsFetch.success) {
                if (accountFunnelsFetch.data.length > 0) {
                    // Make sure the DEFAULT funnel is first
                    let defaultFunnelIndex = accountFunnelsFetch.data.findIndex(funnel => funnel.funnelId === DEFAULT_FUNNEL_ID);
                    if (defaultFunnelIndex != -1) {
                        let defaultFunnel = accountFunnelsFetch.data.splice(defaultFunnelIndex, 1)[0];
                        accountFunnels.push(processFunnelDto(defaultFunnel, selectedAccount, accountProducts, accountFunnelGroups, this.state.accountPixels));
                    } else {
                        toastr.error("There is no default funnel. Without one additional funnels will not be created and any funnels that use the default settings will not function properly.");
                    }

                    // Push the rest of the funnels
                    accountFunnelsFetch.data.forEach(funnel => accountFunnels.push(processFunnelDto(funnel, selectedAccount, accountProducts, accountFunnelGroups, this.state.accountPixels)));
                } else {
                    toastr.warning("First thing first, please define a default funnel");
                }
            }

            this.setState({
                extendedTableView: extendedTableView,
                selectedAccount: selectedAccount,
                products: accountProducts,
                accountFunnelGroups: accountFunnelGroups,
                funnels: accountFunnels,
                accountPixels: accountPixels,
                createNewFunnelFormExpanded: false,
                createNewFunnelTemplate: { accountId: selectedAccount.accountId, targetCpaFromDefault: false, targetRoiFromDefault: false, _rctId: Date.now().toString() },
                pageLoading: false
            });
        }
    }

    toggleTableView(toggleValue) {
        this.setState({ extendedTableView: toggleValue });
    }

    reloadFunnelGroups(newFunnelGroup) {

        let accountFunnelGroups = [...this.state.accountFunnelGroups];
        accountFunnelGroups.push(newFunnelGroup);
        this.setState({ accountFunnelGroups: accountFunnelGroups });

    }

    toggleCreateNewFunnelFormExpand() {
        this.setState({ createNewFunnelTemplate: { accountId: this.state.selectedAccount.accountId, targetCpaFromDefault: false, targetRoiFromDefault: false, _rctId: Date.now().toString() }, createNewFunnelFormExpanded: !this.state.createNewFunnelFormExpanded });
    }


    // Utilities

    getPixelName(pixelId) {
        let foundPixel = this.state.accountPixels.find(pixel => pixel.pixelId == pixelId);
        let pixelName = "";

        if (foundPixel !== undefined) {
            if (foundPixel.isArchived === true) {
                pixelName += "[ARCHIVED]";
            }
            pixelName += foundPixel.pixelName;
        } else {
            pixelName = "Unknown event ID " + pixelId.toString()
        }
        return pixelName;
    }

    getPixelNameFromDefaultFunnel(pixelIndex, pixelType, defaultFunnel) {
        if (defaultFunnel == null) {
            return "[No data - default funnel not found]";
        } else {
            let pixelTypePixels = defaultFunnel.pixels[pixelType];
            if (pixelTypePixels && pixelTypePixels[pixelIndex]) {
                let defaultFunnelPixelId = pixelTypePixels[pixelIndex];
                return this.getPixelName(defaultFunnelPixelId) + " (from default)";
            } else {
                return "[No data - default funnel has no corresponding event]";
            }
        }
    }

    preparePixelsHtml(funnels, pixelsDictionary) {
        // Prepare the HTML lists of pixels 
        let defaultFunnel = funnels.find(funnel => funnel.funnelId == DEFAULT_FUNNEL_ID);
        defaultFunnel = defaultFunnel === undefined ? null : defaultFunnel;

        funnels.forEach(funnel => {
            let pixelsHtml = {};

            Object.values(pixelsDictionary).forEach(pixelType => {
                pixelsHtml[pixelType] = "";

                if (funnel.pixels[pixelType] && funnel.pixels[pixelType].length > 0) {
                    let currentPixels = funnel.pixels[pixelType].map((pixelId, pixelIndex) => {
                        let pixelName;
                        if (pixelId === PIXEL_FROM_DEFAULT_FUNNEL_ID) {
                            pixelName = this.getPixelNameFromDefaultFunnel(pixelIndex, pixelType, defaultFunnel);
                        } else {
                            pixelName = this.getPixelName(pixelId);
                        }

                        return <li>{pixelName}</li>
                    });
                    pixelsHtml[pixelType] = <ul>{currentPixels}</ul>;
                }
            });

            funnel.pixelsHtml = pixelsHtml;
        });

        return funnels;
    }

    cloneFunnel(funnelData) {
        let newFunnelTemplate = deepCloneFunnel(funnelData);

        newFunnelTemplate.funnelId = "";
        newFunnelTemplate.targetCpaFromDefault = funnelData.targetCpaFromDefault === null || funnelData.targetCpaFromDefault === undefined ? false : newFunnelTemplate.targetCpaFromDefault;
        newFunnelTemplate.targetRoiFromDefault = funnelData.targetRoiFromDefault === null || funnelData.targetRoiFromDefault === undefined ? false : newFunnelTemplate.targetRoiFromDefault;

        this.setState({ createNewFunnelTemplate: newFunnelTemplate, createNewFunnelFormExpanded: true });
    }

    // Rendering
    getPlatformFunnelJsx(selectedAccount) {
        let jsx;

        let platformName = null;
        if (selectedAccount != null) {
            platformName = selectedAccount.platform.platformName;
        }

        switch (platformName) {
            case Platforms.FACEBOOK:
                jsx = (
                    <BootstrapTable
                        bootstrap4
                        keyField="funnelId"
                        bordered={false}
                        striped
                        hover
                        data={this.state.funnels ? this.preparePixelsHtml([...this.state.funnels], FacebookFunnelPixels) : []}
                        columns={this.facebookFunnelPixelColumns}
                        expandRow={this.expandRow}
                        filter={filterFactory()}
                    />
                );
                break;
            case Platforms.GOOGLE:
                jsx = (
                    <BootstrapTable
                        bootstrap4
                        keyField="funnelId"
                        bordered={false}
                        striped
                        hover
                        data={this.state.funnels ? this.preparePixelsHtml([...this.state.funnels], GoogleFunnelPixels) : []}
                        columns={this.googleFunnelPixelColumns}
                        expandRow={this.expandRow}
                        filter={filterFactory()}
                    />
                );
                break;
            case Platforms.BING:
                jsx = (
                    <BootstrapTable
                        bootstrap4
                        keyField="funnelId"
                        bordered={false}
                        striped
                        hover
                        data={this.state.funnels ? this.preparePixelsHtml([...this.state.funnels], BingFunnelPixels) : []}
                        columns={this.bingFunnelPixelColumns}
                        expandRow={this.expandRow}
                        filter={filterFactory()}
                    />
                );
                break;
            case Platforms.TABOOLA:
                jsx = (
                    <BootstrapTable
                        bootstrap4
                        keyField="funnelId"
                        bordered={false}
                        striped
                        hover
                        data={this.state.funnels ? this.preparePixelsHtml([...this.state.funnels], TaboolaFunnelPixels) : []}
                        columns={this.taboolaFunnelPixelColumns}
                        expandRow={this.expandRow}
                        filter={filterFactory()}
                    />
                );
                break;
            case Platforms.TIKTOK:
                jsx = (
                    <BootstrapTable
                        bootstrap4
                        keyField="funnelId"
                        bordered={false}
                        striped
                        hover
                        data={this.state.funnels ? this.preparePixelsHtml([...this.state.funnels], TikTokFunnelPixels) : []}
                        columns={this.tiktokFunnelPixelColumns}
                        expandRow={this.expandRow}
                        filter={filterFactory()}
                    />
                );
                break;
            case Platforms.TWITTER:
                jsx = (
                    <BootstrapTable
                        bootstrap4
                        keyField="funnelId"
                        bordered={false}
                        striped
                        hover
                        data={this.state.funnels ? this.preparePixelsHtml([...this.state.funnels], TwitterFunnelPixels) : []}
                        columns={this.twitterFunnelPixelColumns}
                        expandRow={this.expandRow}
                        filter={filterFactory()}
                    />
                );
                break;
            case Platforms.DV360:
                jsx = (
                    <BootstrapTable
                        bootstrap4
                        keyField="funnelId"
                        bordered={false}
                        striped
                        hover
                        data={this.state.funnels ? this.preparePixelsHtml([...this.state.funnels], DV360FunnelPixels) : []}
                        columns={this.dv360FunnelPixelColumns}
                        expandRow={this.expandRow}
                        filter={filterFactory()}
                    />
                );
                break;
            case Platforms.SNAPCHAT:
                jsx = (
                    <BootstrapTable
                        bootstrap4
                        keyField="funnelId"
                        bordered={false}
                        striped
                        hover
                        data={this.state.funnels ? this.preparePixelsHtml([...this.state.funnels], SnapchatFunnelPixels) : []}
                        columns={this.snapchatFunnelPixelColumns}
                        expandRow={this.expandRow}
                        filter={filterFactory()}
                    />
                );
                break;
            default:
                jsx = (
                    <CardHeader>
                        <CardTitle tag="h5" className="mb-0">
                            No extended view available {platformName != null ? "for " + platformName + " platform" : ""}
                        </CardTitle>
                    </CardHeader>
                );
                break;
        }

        return jsx;
    }

    getEditFormJsx(funnelData, formMode, callback, displayAdditionalFields = false) {
        let platform = this.state.selectedAccount.platform.platformName;

        let prefetchedEntities = { products: this.state.products, funnelGroups: this.state.accountFunnelGroups };
        let defaultFunnelData = null;

        // Prepare default funnel or set this funnels data as default if it's the first one
        if (this.state.funnels.length > 0) {
            defaultFunnelData = this.state.funnels.find(funnel => funnel.funnelId === DEFAULT_FUNNEL_ID);
        } else {
            funnelData.funnelId = DEFAULT_FUNNEL_ID;
        }

        if (isPixelBasedFunnelPlatform(platform)) {
            prefetchedEntities.pixels = this.state.accountPixels;
        }

        return (
            <div className="funnel-id-wrap mt-3 mb-3">
                <FunnelEditForm
                    key={"editForm-" + funnelData._rctId}
                    additionalFields={displayAdditionalFields || this.state.extendedTableView}
                    platform={platform}
                    mode={formMode}
                    funnelData={funnelData}
                    prefetchedEntities={prefetchedEntities}
                    submitCallback={callback}
                    resetFunnelGroupTrigger={this.reloadFunnelGroups}
                    defaultFunnelData={defaultFunnelData}
                />
            </div>
        );
    }

    getBasicFunnelJsx() {
        let defaultFunnel = this.state.funnels.find(funnel => funnel.funnelId == DEFAULT_FUNNEL_ID);
        defaultFunnel = defaultFunnel === undefined ? null : defaultFunnel;

        let preparedFunnelView = this.state.funnels.map(funnel => {
            if (funnel.targetCpaFromDefault === true) {
                if (defaultFunnel == null) {
                    funnel.targetCpaDisplay = "[No data - default funnel not found]";
                } else {
                    funnel.targetCpaDisplay = defaultFunnel.targetCpa.toString() + " (from default)";
                }
            } else {
                funnel.targetCpaDisplay = funnel.targetCpa;
            }

            if (funnel.targetRoiFromDefault === true) {
                if (defaultFunnel == null) {
                    funnel.targetRoiDisplay = "[No data - default funnel not found]";
                } else {
                    funnel.targetRoiDisplay = defaultFunnel.targetRoi.toString() + " (from default)";
                }
            } else {
                funnel.targetRoiDisplay = funnel.targetRoi;
            }

            let kpiDisplay;
            if (isPixelBasedFunnelPlatform(this.state.selectedAccount.platform.platformName)) {
                kpiDisplay = getMainKpiDisplayForPixelBasedFunnels(funnel, defaultFunnel, this.state.accountPixels);
            } else {
                kpiDisplay = funnel.mainKpi;
            }
            funnel.mainKpiDisplay = kpiDisplay;

            return funnel;
        });

        return (
            <BootstrapTable
                bootstrap4
                keyField="funnelId"
                bordered={false}
                striped
                hover
                data={preparedFunnelView}
                columns={this.funnelBasicColumns}
                expandRow={this.expandRow}
                filter={filterFactory()}
            />
        )
    }

    render() {
        let groupedAccountsSelectOptions = generateAccountsSelectOptions([{ accountId: 0, accountName: "Select Account" }, ...this.state.accounts]);

        if (this.state.initSuccess === true) {
            return (
                <Container fluid className="p-0">
                    <Modal isOpen={this.state.formSaving === true} centered>
                        <ModalHeader>
                            Saving Funnel...
                        </ModalHeader>
                        <ModalBody className="text-center m-3">
                            <Loader />
                        </ModalBody>
                    </Modal>


                    <Modal
                        size={"lg"}
                        isOpen={this.state.displayTipsTricksInfo === true}
                        top>
                        <ModalHeader>
                            <p>Tips and Tricks:</p>
                        </ModalHeader>
                        <Button color="info" size="small"
                            style={{ position: "absolute", top: "10px", right: "10px" }}
                            onClick={e => this.setState({ displayTipsTricksInfo: false })}>
                            Close
                        </Button>
                        <ModalBody>
                            <p> Want to change funnel details prior to today? go to <a href={funnelHistoryLink} target="_blank">funnel history</a> <br></br>
                                Want to delete funnel from reports? Untick “Show In Report”.<br></br>
                                <strong>Funnel Group</strong>  is the name displayed in the reports.
                            </p>
                        </ModalBody>
                    </Modal >

                    <Card>
                        <CardHeader>
                            <CardTitle tag="h5" className="mb-0 funnels">
                                <div style={{ display: "flex", alignItems: "center" }}>
                                    Funnels
                                    <div className={"info-button"}
                                        title="Tips and Tricks"
                                        style={{ marginLeft: "10px"}}
                                        onClick={() => this.setState({ displayTipsTricksInfo: true })}>
                                        <Info size={38} color={"#FFA30F"} />
                                    </div>
                                </div>
                            </CardTitle>

                        </CardHeader>
                        <CardBody>
                            <FormGroup>
                                <CustomInput
                                    className="ps-4"
                                    type="checkbox"
                                    id="tableView"
                                    name="tableView"
                                    label="Basic/Extended Table View"
                                    checked={this.state.extendedTableView}
                                    onChange={event => this.toggleTableView(event.target.checked)}
                                />
                            </FormGroup>
                            <div>
                                <Select
                                    className="col-12 col-lg-9 col-xl-6"
                                    placeholder="Select an Account..."
                                    options={groupedAccountsSelectOptions}
                                    id="accountDropdown"
                                    onChange={selectedOption => this.accountChanged(parseInt(selectedOption.value))}
                                    getOptionLabel={option => <>{option.icon} {option.label}</>}
                                    filterOption={(item, input) => {
                                        if (input) {
                                            return item.data.label.toLowerCase().includes(input.toLowerCase());
                                        }
                                        return true;
                                    }}
                                />
                            </div>

                            {this.state.extendedTableView == false &&
                                this.getBasicFunnelJsx()
                            }

                            {this.state.extendedTableView == true &&
                                <div className="platformFunnel extended">{this.getPlatformFunnelJsx(this.state.selectedAccount)}</div>
                            }

                            {this.state.pageLoading === true &&
                                <Loader />
                            }

                            {this.state.selectedAccount !== null && !this.state.createNewFunnelFormExpanded &&
                                <Button className="title" color="outline-primary" onClick={this.toggleCreateNewFunnelFormExpand}>Create New Funnel</Button>
                            }

                            {this.state.selectedAccount !== null && this.state.createNewFunnelFormExpanded &&
                                <Card>
                                    <CardHeader><CardTitle>Create New Funnel</CardTitle></CardHeader>
                                    <CardBody>
                                        {this.getEditFormJsx(this.state.createNewFunnelTemplate, FormModes.NEW, this.saveNewFunnel, true)}
                                        <Button className="title" color="outline-primary" onClick={this.toggleCreateNewFunnelFormExpand}>Cancel</Button>
                                    </CardBody>
                                </Card>
                            }
                        </CardBody>
                    </Card>
                </Container>
            )
        } else if (this.state.initSuccess === false) {
            return (
                <GenericErrorAlert />
            );
        } else {
            return (
                <Card>
                    <CardHeader>
                        <CardTitle tag="h5" className="mb-0">
                            Funnels
                        </CardTitle>
                    </CardHeader>
                    <Loader />
                </Card>
            );
        }
    }

    // Table display

    cloneFormatter(cell, row, rowIndex, formatExtraData) {
        let cloneFormatterId = "cloneIcon-" + rowIndex.toString();
        return (
            <div tabindex="-1" style={{ cursor: "pointer" }}>
                <Icon.Copy id={cloneFormatterId} className="feather mr-2" onClick={() => { formatExtraData.cloneFunc(row) }} />
                <UncontrolledTooltip placement="top" target={cloneFormatterId}>
                    Clone Funnel
                </UncontrolledTooltip>
            </div>
        )
    }


    //Headers columns
    funnelBasicColumns = [
        { dataField: "funnelId", text: "Funnel ID", sort: true, filter: textFilter({ placeholder: "Search...", style: { width: "80%" } }) },
        { dataField: "funnelName", text: "Funnel Name", sort: true, filter: textFilter({ placeholder: "Search...", style: { width: "80%" } }) },
        { dataField: "funnelGroupName", text: "Funnel Group", sort: true, filter: textFilter({ placeholder: "Search...", style: { width: "80%" } }) },
        { dataField: "targetCpaDisplay", text: "Target Cpa", sort: true },
        { dataField: "targetRoiDisplay", text: "Target Roi", sort: true },
        { dataField: "mainKpiDisplay", text: "Main KPI", sort: true },
        { dataField: "productName", text: "Product", sort: true },
        { dataField: "showInReport", text: "Show In Report", sort: true, filter: selectFilter({ options: [{ value: true, label: "Yes" }, { value: false, label: "No" }] }), formatter: (cell, row) => { return row.showInReport === true ? "Yes" : "No" } },
        { dataField: "isActive", text: "Active", sort: true, filter: selectFilter({ options: [{ value: true, label: "Yes" }, { value: false, label: "No" }] }), formatter: (cell, row) => { return row.isActive === true ? "Yes" : "No" } },
        { dataField: "kpiType", text: "KPI Type", sort: true, filter: selectFilter({ options: KpiTypeOptions }), formatter: (cell, row) => { return KpiTypeOptions.find(option => option.value === row.kpiType).label } },
        { text: "", formatter: (cell, row, rowIndex) => this.cloneFormatter(cell, row, rowIndex, { cloneFunc: this.cloneFunnel }), headerStyle: { width: "30px" } }
    ]
    //Extended view columns
    pixelBaseColumns = [
        { dataField: "funnelId", text: "Funnel ID", sort: true, filter: textFilter({ placeholder: "Search...", style: { width: "80%" } }) },
        { dataField: "funnelName", text: "Funnel Name", sort: true, filter: textFilter({ placeholder: "Search...", style: { width: "80%" } }) },
        { dataField: "funnelGroupName", text: "Funnel Group", sort: true, sort: true, filter: textFilter({ placeholder: "Search...", style: { width: "80%" } }) },
        { dataField: "pixelsHtml." + BASE_PIXEL_STRUCTURE.Pre_LP, text: "Pre LP Events", },
        { dataField: "pixelsHtml." + BASE_PIXEL_STRUCTURE.LP, text: "LP Events", },
        { dataField: "pixelsHtml." + BASE_PIXEL_STRUCTURE.After_LP, text: "After LP Events", },
        { dataField: "pixelsHtml." + BASE_PIXEL_STRUCTURE.Lead, text: "Lead Events", },
        { dataField: "pixelsHtml." + BASE_PIXEL_STRUCTURE.Product_Page, text: "Product Page Events", },
        { dataField: "pixelsHtml." + BASE_PIXEL_STRUCTURE.Initiate_Checkout, text: "Initiate Checkout Events", },
        { dataField: "pixelsHtml." + BASE_PIXEL_STRUCTURE.Sale, text: "Sale Events", },
        { dataField: "pixelsHtml." + BASE_PIXEL_STRUCTURE.Joker, text: "Joker Events", },
        { dataField: "pixelsHtml." + BASE_PIXEL_STRUCTURE.Revenue, text: "Revenue Events", },
        { dataField: "kpiType", text: "KPI Type", sort: true, filter: selectFilter({ options: KpiTypeOptions }), formatter: (cell, row) => { return KpiTypeOptions.find(option => option.value === row.kpiType).label } },
        { text: "", formatter: (cell, row, rowIndex) => this.cloneFormatter(cell, row, rowIndex, { cloneFunc: this.cloneFunnel }), headerStyle: { width: "30px" } }
    ];

    facebookFunnelPixelColumns = [...this.pixelBaseColumns];
    googleFunnelPixelColumns = [...this.pixelBaseColumns];
    bingFunnelPixelColumns = [...this.pixelBaseColumns];
    taboolaFunnelPixelColumns = [...this.pixelBaseColumns];
    tiktokFunnelPixelColumns = [...this.pixelBaseColumns];
    twitterFunnelPixelColumns = [...this.pixelBaseColumns];
    dv360FunnelPixelColumns = [...this.pixelBaseColumns];
    snapchatFunnelPixelColumns = [...this.pixelBaseColumns];

    expandRow = {
        renderer: (row) => {
            return this.getEditFormJsx(row, FormModes.EDIT, this.updateFunnel);
        },
        expandColumnRenderer: ({ expanded }) => {
            if (expanded) {
                return (
                    <Icon.Edit className="feather align-middle" />
                );
            }
            return (
                <Icon.Edit2 className="feather align-middle" />
            );
        },
        expandHeaderColumnRenderer: ({ isAnyExpands }) => {
            return "";
        },
        showExpandColumn: true,
        expandByColumnOnly: true,
        expandColumnPosition: 'right'
    };
}