import { toastr } from "react-redux-toastr";

export function handleErrorResponse(res, titleMessage, popToaster = true) {
    if (!titleMessage || titleMessage.length < 1) {
        titleMessage = "Action Failed";
    }
    res.json().then(errorResponse => {
        let errorMessage = "Error " + res.status + ". " + errorResponse.response;
        if (popToaster) {
            toastr.error(titleMessage, errorMessage);
        } else {
            return titleMessage + ". " + errorMessage;
        }
    }).catch(reason => {
        console.error(reason);
        let errorMessage = "Error " + res.status + ". Unknown error.";
        if (popToaster) {
            toastr.error(titleMessage, errorMessage);
        } else {
            return titleMessage + ". " + errorMessage;
        }
    })
}