import React from "react";
import { Button, Card, Container, Form, Modal, OverlayTrigger, Tooltip } from "react-bootstrap";
import BootstrapTable from "react-bootstrap-table-next";
import cellEditFactory, { Type } from 'react-bootstrap-table2-editor';
import filterFactory, { textFilter } from 'react-bootstrap-table2-filter';
import 'react-bootstrap-table2-filter/dist/react-bootstrap-table2-filter.min.css';
import * as Icon from "react-feather";
import GenericErrorAlert from "../../../components/GenericErrorAlert";
import Loader from '../../../components/Loader';
import { CACHE_EXPIRATION_DEFAULTS } from "../../../services/qsCache";
import { addHours } from "../../../utils/dateUtils";
import { genericCachedFetcherFactory, genericFetcherFactory } from "../../../utils/requestUtils";
import UploadFolderManagementEditForm, { FormModes } from './UploadFolderManagementEditForm';

export default class UploadFolderManagement extends React.PureComponent {
    constructor(props) {
        super(props);

        this.toggleIsVisible = this.toggleIsVisible.bind(this);

        this.addNewMedia = this.addNewMedia.bind(this);

        this.updateMedia = this.updateMedia.bind(this);
        this.removeMedia = this.removeMedia.bind(this);
        this.revertRowChanges = this.revertRowChanges.bind(this);
        this.updateRows = this.updateRows.bind(this);

        this.state = {
            accounts: [],
            initSuccess: null,
            pageLoading: false,
            isClickedAddingNewMedia: false,
            initState: [],
            pristineState: null
        };
    }

    componentDidMount() {
        this.setState({ pageLoading: true });

        this.fetchAccounts();
    }

    updateMedia(mediaObj) {
        this.setState({ formSaving: true });
        genericFetcherFactory(`/api/media/Media/${mediaObj.id}`, "UPDATE_MEDIA", "Failed to update media", "PATCH", {
            method: "PATCH",
            body: JSON.stringify(mediaObj),
            headers: { "Content-Type": "application/json" }
        })().then(result => {
            if (result.success == true) {
                let updatedMedia = result.data;
                updatedMedia.accountName = mediaObj.accountName;
                let newMedia = [...this.state.media];
                let mediaIndex = newMedia.findIndex(item => (item.id === updatedMedia.id));
                newMedia.splice(mediaIndex, 1, this.normalizeMedia(updatedMedia));
                this.setState({ media: newMedia, formSaving: false });
            } else {
                this.setState({ initSuccess: false });
            }
        });
    }

    fetchMedia(mediaObj) {
        genericFetcherFactory(`/api/media/Media`, "FETCH_MEDIA", "Failed to  fetch media", "GET", {
            method: "GET",
            body: JSON.stringify(mediaObj),
            headers: { 'Content-Type': 'application/json' }
        })().then(result => {
            if (result.success == true) {
                let normalizeMedia = [];
                result.data.forEach(media => {
                    normalizeMedia.push(this.normalizeMedia(media));
                });
                this.setState({ media: normalizeMedia, initSuccess: true });
            } else {
                this.setState({ ...this.state, initSuccess: false })
            }
        });
    }

    addNewMedia(insertObject) {
        this.setState({ formSaving: true });
        genericFetcherFactory(`/api/media/Media`, "SAVE_MEDIA", "Failed to save a new media", "POST", {
            method: "POST",
            body: JSON.stringify(insertObject),
            headers: { 'Content-Type': 'application/json' }
        })().then(result => {
            if (result.success == true) {
                result.data.accountName = insertObject.accountName;
                let newMedia = [...this.state.media];
                newMedia.push(result.data);
                this.setState({ media: newMedia, formSaving: false, isClickedAddingNewMedia: false });
            } else {
                this.setState({ formSaving: false });
            }
        });
    }

    removeMedia(removeObject) {
        let confirmation = window.confirm("Are you sure you want to delete?");
        if (confirmation == true) {
            this.setState({ formSaving: true });

            genericFetcherFactory(`/api/media/Media/delete/${removeObject.id}`, "DELETE_MEDIA", "Failed to delete media", "DELETE", {
                method: "DELETE"
            })().then(result => {
                if (result.success == true) {
                    let newMedia = [...this.state.media];
                    let mediaIndex = newMedia.findIndex(item => (item.id === removeObject.id));
                    newMedia.splice(mediaIndex, 1);
                    this.setState({ media: newMedia, formSaving: false });
                } else {
                    this.setState({ formSaving: false });
                }
            });
        }
    }

    async fetchAccounts() {
        let response = await genericCachedFetcherFactory("/api/accounts/Accounts", "ACCOUNTS", "Fetch accounts failed", addHours(CACHE_EXPIRATION_DEFAULTS.Accounts))();
        if (response.success === true) {
            this.setState({ ...this.state, accounts: response.data });
            this.fetchMedia();
            return Promise.resolve(true);
        } else {
            return Promise.resolve(false);
        }
    }

    /*For each media get his accountName and set it in media entity */
    normalizeMedia(mediaEntity) {
        let media = {};
        let account = this.state.accounts.find((account) => { return account.accountId == mediaEntity.accountId })
        media.accountName = account !== undefined ? account.accountName : "[Not found]";
        media.accountId = mediaEntity.accountId;
        media.folder = mediaEntity.folder;
        media.id = mediaEntity.id;
        media.notifySlack = !mediaEntity.notifySlack ? false : mediaEntity.notifySlack;
        media.pristineState = { ...media };
        return media;
    }

    mediaColumns = [
        {
            dataField: "accountName",
            text: "Account Name",
            headerStyle: { width: "200px" },
            sort: true, editable: false,
            filter: textFilter()
        },
        {
            dataField: "folder", text: "Folder Name", sort: true,
            style: { cursor: "pointer" },
            filter: textFilter({ placeholder: '', style: { width: "80%" } }),
            editor: { type: Type.TEXT }
        },
        {
            dataField: "notifySlack", text: "Notify Slack",
            sort: true, headerStyle: { width: "75px" }, editable: false, style: { cursor: "pointer" },
            formatter: (cell, row, rowIndex) => this.checkboxFormatter(cell, row, rowIndex, { toggleChecked: this.toggleIsVisible })
        },
        {
            editable: false, isDummyField: true, headerStyle: { width: "80px", textAlign: "center" }, align: "center", //TODO  prepareForInsert
            formatter: (cell, row) => this.actionsFormatter(
                cell, row, { removeMedia: this.removeMedia, updateMedia: this.updateMedia, revertRowChanges: this.revertRowChanges }
            )
        }
    ];

    revertRowChanges(rowItem) {
        let newMedia = [...this.state.media];
        let index = newMedia.findIndex(_media => _media.id == rowItem.id);
        if (index != -1) {
            let pristineCopy = { ...rowItem.pristineState };
            let pristineItem = { ...rowItem.pristineState };
            pristineItem.pristineState = pristineCopy;
            newMedia.splice(index, 1, pristineItem);
            this.setState({ media: newMedia });
        }
    }

    checkboxFormatter(cell, row, index, { toggleChecked }) {
        return <Form.Check
            type="switch"
            name={`notify-${row.id}`}
            id={`notify-${row.id}`}
            checked={row.notifySlack}
            onChange={event => toggleChecked(event.target.checked, row)}
        />
    }

    toggleIsVisible(checked, row) {
        let newMedia = [...this.state.media];
        let rowIndex = newMedia.findIndex(media => media.id === row.id);
        if (rowIndex !== -1) {
            let updatedMedia = { ...newMedia[rowIndex], notifySlack: checked, isChanged: true };
            newMedia.splice(rowIndex, 1, updatedMedia);
            this.setState({ media: newMedia });
        }
    }

    actionsFormatter(cell, row, { removeMedia, updateMedia, revertRowChanges }) {
        let removeFormatterId = "deleteIcon-" + row.id;
        let saveFormatterId = "saveIcon-" + row.id;
        let undoFormatterId = "undoIcon-" + row.id;
        return (
            <div className="editButtons" tabindex="-1">
                <span>
                    <OverlayTrigger placement="top" key={removeFormatterId} overlay={
                        <Tooltip placement="top" id={removeFormatterId}>
                            Delete Media
                        </Tooltip>
                    }>
                        <Icon.Trash2 id={removeFormatterId} style={{ cursor: "pointer" }} className="feather mr-2"
                            onClick={() => { removeMedia(row) }} />

                    </OverlayTrigger>
                </span>
                <span>
                    <OverlayTrigger placement="top" key={saveFormatterId} overlay={
                        <Tooltip placement="top" id={saveFormatterId}>
                            {row.isChanged ? "Save Test" : "No changes made"}
                        </Tooltip>
                    }>
                        <Icon.Save id={saveFormatterId} style={{ cursor: "pointer" }} className="feather mr-2" color={row.isChanged ? "black" : "lightgrey"}
                            onClick={row.isChanged ? () => { updateMedia(row) } : null} />
                    </OverlayTrigger>
                </span>
                <span>
                    <OverlayTrigger placement="top" key={undoFormatterId} overlay={
                        <Tooltip placement="top" id={undoFormatterId}>
                            {row.isChanged ? "Undo Changes" : "No changes made"}
                        </Tooltip>
                    }>
                        <Icon.RotateCcw id={undoFormatterId} style={{ cursor: "pointer" }} className="feather mr-2" color={row.isChanged ? "black" : "lightgrey"}
                            onClick={row.isChanged ? () => { revertRowChanges(row) } : null} />
                    </OverlayTrigger>
                </span>
            </div>
        )
    }

    updateRows(media) {
        this.setState({ media: media });
    }

    cellSaved(oldValue, newValue, row, column, { state, updateRows }) {
        if (oldValue != newValue) {
            let media = state.media;
            let index = media.findIndex(test => test.id == row.id);
            if (index !== -1) {
                let newMedia = { ...media[index], isChanged: true };
                media.splice(index, 1, newMedia);
                updateRows(media);
            }
        }
    }

    render() {
        if (this.state.initSuccess === true) {
            this.setState({ pageLoading: false });
            return (
                <Container fluid className="p-0">
                    <Modal show={this.state.formSaving === true} centered>
                        <Modal.Header>
                            Saving Media...
                        </Modal.Header>
                        <Modal.Body className="text-center m-3">
                            <Loader />
                        </Modal.Body>
                    </Modal>
                    <Card>
                        <Card.Header>
                            <Card.Title tag="h5" className="mb-0">
                                Upload Folder Management
                            </Card.Title>
                        </Card.Header>
                        <Card.Body>
                            <BootstrapTable
                                keyField="id"
                                headerClasses="sticky"
                                bordered={false}
                                striped
                                hover
                                data={this.state.media}
                                columns={this.mediaColumns}
                                rowClasses={(row) => { return row.isChanged ? "row-pending-changes" : "" }}
                                filter={filterFactory()}
                                cellEdit={cellEditFactory({
                                    mode: 'click',
                                    blurToSave: true,
                                    afterSaveCell: (oldValue, newValue, row, column) =>
                                        this.cellSaved(
                                            oldValue, newValue, row, column,
                                            {
                                                state: { media: [...this.state.media] },
                                                updateRows: this.updateRows
                                            })
                                })
                                }
                            />

                            {!this.state.isClickedAddingNewMedia &&
                                <Button className="btn btn-success" onClick={() => this.setState({ isClickedAddingNewMedia: true })}>Add new folder</Button>
                            }

                            {this.state.isClickedAddingNewMedia && <div>
                                <UploadFolderManagementEditForm
                                    submitCallback={this.addNewMedia}
                                />
                            </div>}
                        </Card.Body>
                    </Card>
                </Container >
            )
        } else if (this.state.initSuccess === false) {
            this.setState({ pageLoading: false });
            return (
                <GenericErrorAlert />
            );
        } else {
            return (
                <Card>
                    <Card.Header>
                        <Card.Title tag="h5" className="mb-0">
                            Media
                        </Card.Title>
                    </Card.Header>
                    <Loader />
                </Card>
            );
        }
    }
}