import { Formik } from "formik";
import React from "react";
import { Button, Card, Container, Form, Modal, Row, Spinner } from "react-bootstrap";
import { toastr } from "react-redux-toastr";
import Select from "react-select";
import { Label } from "reactstrap";
import * as Yup from "yup";
import GenericErrorAlert from "../../../components/GenericErrorAlert";
import Loader from '../../../components/Loader';
import { CACHE_EXPIRATION_DEFAULTS } from "../../../services/qsCache";
import { addHours } from "../../../utils/dateUtils";
import { genericCachedFetcherFactory, genericFetcherFactory } from "../../../utils/requestUtils";

export default class ExternalUserPermissionMapping extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            initSuccess: null,
            clients: [],
            clientProducts: [],
            selectedClientId: null,
            selectedProductsIds: [],
            loadingClientProducts: false,
            canViewOverview: false,
            isMultiClient: false,
            multiClientChoices: null,
            allProducts: null,
            allBrands: null,
            clientIdAndProductsList: []
        }

        this.updateSelectedProducts = this.updateSelectedProducts.bind(this);
        this.savePermissions = this.savePermissions.bind(this);
        this.multiClientChanged = this.multiClientChanged.bind(this);
    }

    async componentDidMount() {
        let clientsListResult = await this.fetchClients();

        if (clientsListResult.success === true) {

            let [getProductsResult, getBrandsResult] = await Promise.all([this.fetchAllProducts(), this.fetchAllBrands()]);

            if (getProductsResult.success === true && getBrandsResult.success === true) {
                let newState = {
                    initSuccess: true,
                    clients: clientsListResult.data,
                    canViewOverview: this.props.user.canViewOverview,
                    allProducts: getProductsResult.data,
                    allBrands: getBrandsResult.data,
                    isMultiClient: this.props.user.isMultiClient
                };
                let userEntityPermissions = this.props.user.entityPermissions;
                if (userEntityPermissions.clients != null && userEntityPermissions.clients.length > 0) {
                    if (this.props.user.isMultiClient) {
                        newState.multiClientChoices = this.loadMultiClients(userEntityPermissions.clients, clientsListResult.data);
                        this.getClientsProducts(newState.multiClientChoices, this.state.clientIdAndProductsList, getBrandsResult.data, getProductsResult.data);

                        if (userEntityPermissions.products != null && userEntityPermissions.products.length > 0) {
                            newState.selectedProductsIds = userEntityPermissions.products.map(product => product.entityId);
                        } else {
                            newState.selectedProductsIds = [];
                        }
                    } else {
                        let selectedClientId = parseInt(userEntityPermissions.clients[0].entityId);
                        newState.selectedClientId = selectedClientId;

                        let fetchClientProductResult = await this.fetchClientProducts(selectedClientId);
                        if (fetchClientProductResult.success === true) {
                            newState.clientProducts = fetchClientProductResult.data;

                            if (userEntityPermissions.products != null && userEntityPermissions.products.length > 0) {
                                newState.selectedProductsIds = userEntityPermissions.products.map(product => product.entityId);
                            } else {
                                newState.selectedProductsIds = [];
                            }
                        } else {
                            // Init failed, overwrite state
                            newState = { initSuccess: false };
                        }
                    }
                } else {
                    newState.selectedClientId = null;
                    newState.selectedProductsIds = [];
                }

                this.setState(newState);
            }
        } else {
            this.setState({ initSuccess: false });
        }
    }

    async fetchClients() {
        return genericCachedFetcherFactory("/api/crm/Clients/", "CLIENTS", "Could not fetch clients", addHours(CACHE_EXPIRATION_DEFAULTS.Clients))();
    }

    async fetchClientProducts(clientId) {
        return genericCachedFetcherFactory("/aggregate-api/getProductsByClientId/" + clientId, "CLIENT_PRODUCTS", "Could not fetch client products", addHours(24))();
    }

    async fetchAllProducts() {
        return genericCachedFetcherFactory("/api/product/Products", "ALL_PRODUCTS", "Could not fetch all products", addHours(24))();
    }

    async fetchAllBrands() {
        return genericCachedFetcherFactory("/api/crm/Brands", "ALL_BRANDS", "Could not fetch all brands", addHours(24))();
    }

    async clientChanged(clientId) {
        this.setState({ loadingClientProducts: true })
        if (clientId != this.state.selectedClientId) {
            let clientProducts = await this.fetchClientProducts(clientId);
            if (clientProducts.success === true) {
                this.setState({
                    selectedClientId: clientId,
                    selectedProductsIds: [],
                    clientProducts: clientProducts.data,
                    loadingClientProducts: false
                });
            } else {
                this.setState({
                    loadingClientProducts: false,
                    initSuccess: false
                })
            }
        }
    }

    async multiClientChanged(values, event) {
        let updatedClientIdAndProducts = [...this.state.clientIdAndProductsList];

        if (event.action === "remove-value") {
            let updatedProducts = [...this.state.clientProducts];
            let clientsProductsToRemove = updatedClientIdAndProducts.find(products => products.clientId === event.removedValue.value);

            if (updatedClientIdAndProducts && updatedClientIdAndProducts.length > 0) {
                //remove client's products  dropdown list
                clientsProductsToRemove.products.forEach(productToRm => {
                    let index = updatedProducts.findIndex(p => p.productId === productToRm.productId);
                    if (index != -1)
                        updatedProducts.splice(index, 1);
                });

                //update clientAndProduct
                let index = updatedClientIdAndProducts.findIndex(products => products.clientId === event.removedValue.value);
                if (index != -1)
                    updatedClientIdAndProducts.splice(index, 1);
            }

            this.setState({
                clientProducts: updatedProducts,
                multiClientChoices: values,
                loadingClientProducts: false,
                clientIdAndProductsList: updatedClientIdAndProducts
            })
        } else if (values !== null) {
            this.setState({ loadingClientProducts: true })
            this.getClientsProducts(values, updatedClientIdAndProducts, this.state.allBrands, this.state.allProducts);
        }
    }

    getClientsProducts(chosenClients, updatedClientIdAndProducts, allBrands, allProducts) {
        let productsByBrandId = [];
        let clientProducts = [...this.state.clientProducts];

        chosenClients.forEach(client => {
            if (!client.isProductFetched) {
                productsByBrandId = [];
                client.isProductFetched = true;

                allBrands.filter(brand => {
                    //get brandIds by clients
                    if (brand.client.clientId === client.value) {
                        return allProducts.some(product => {
                            //get matching brands and products 
                            if (brand.brandId === product.brandId) {
                                productsByBrandId.push(product);
                            }
                        });
                    }
                });

                clientProducts.push.apply(clientProducts, productsByBrandId)

                //update clientId & Products List
                let clientProductMap = {};
                clientProductMap.clientId = client.value;
                clientProductMap.products = productsByBrandId;
                updatedClientIdAndProducts.push(clientProductMap);
            }
        })

        this.setState({
            multiClientChoices: chosenClients,
            clientProducts: clientProducts,
            loadingClientProducts: false,
            clientIdAndProductsList: updatedClientIdAndProducts
        });
    }

    loadMultiClients(userEntityPermissionsClients, fetchedClients) {
        let multiClientChoises = [];
        userEntityPermissionsClients.forEach(clientUserEntity => {
            fetchedClients.find(client => {
                if (client.clientId == clientUserEntity.entityId) {
                    multiClientChoises.push({ value: client.clientId, label: client.clientName });
                }
            })
        })
        return multiClientChoises;
    }

    updateSelectedProducts(selectedProductsOptions) {
        let clientProducts = this.state.clientProducts;
        if (selectedProductsOptions === null) {
            selectedProductsOptions = [];
            clientProducts = [];
        }
        this.setState({
            clientProducts: clientProducts,
            selectedProductsIds: selectedProductsOptions.map(productOption => productOption.value)
        })
    }

    savePermissions() {
        if (this.state.selectedClientId != null || this.state.multiClientChoices) {
            this.setState({ savingPermissions: true })
            let userObj = {
                userId: this.props.user.userId,
                canViewOverview: this.state.canViewOverview,
                isMultiClient: this.state.isMultiClient,
                entityPermissions: {
                    clients: this.state.isMultiClient ? this.state.multiClientChoices.clients = this.state.multiClientChoices.map(client => { return { entityId: client.value, permissions: ["READ"] } })
                        : [{ entityId: this.state.selectedClientId, permissions: ["READ"] }],
                    products: this.state.selectedProductsIds.map(productId => { return { entityId: productId, permissions: ["READ"] } })
                }
            }
            genericFetcherFactory(
                "/temp-api/externalUsers/setPermissions/" + this.props.user.userId,
                "SAVE_EXTERNAL_USER_CONFIG_OBJ",
                "Failed to save external user configuration",
                "POST",
                {
                    method: "POST",
                    body: JSON.stringify(userObj),
                    headers: {
                        'Content-Type': 'application/json',
                    }
                })()
                .then(
                    result => {
                        if (result.success) {
                            toastr.success("Successfully saved user");
                            this.props.savedUser({ ...userObj, userLogin: this.props.user.userLogin });
                        }
                        this.setState({ savingPermissions: false });
                    }
                );
        } else {
            toastr.warning("Cannot save user", "No client was selected");
        }
    }

    render() {
        let initSuccess = this.state.initSuccess;

        if (initSuccess === true) {
            let clientOptions = this.state.clients.map(client => { return { value: client.clientId, label: client.clientName } });
            let selectedClient = this.state.selectedClientId != null ? clientOptions.find(option => option.value == this.state.selectedClientId) : null;
            if (selectedClient === undefined) {
                selectedClient = null;
            };
            let productOptions = this.state.clientProducts.map(product => { return { value: product.productId, label: product.productName }; });
            let selectedProducts = this.state.selectedProductsIds.length > 0 ? productOptions.filter(productOption => {
                return this.state.selectedProductsIds.find(productId => { return productId == productOption.value }) !== undefined;
            }) : [];

            let userData = {
                selectedClient: selectedClient,
                multiClientChoices: this.state.multiClientChoices
            }

            return (
                <Container fluid className="p-0" >
                    <Modal show={this.state.savingPermissions === true} centered>
                        <Modal.Header>
                            Saving user data...
                        </Modal.Header>
                        <Modal.Body className="text-center m-3">
                            <Loader />
                        </Modal.Body>
                    </Modal>

                    <Card>
                        <Card.Header>
                            <Card.Title tag="h5" className="mb-0 funnels">
                                Select client and products to assign to {this.props.user.userLogin}
                            </Card.Title>
                        </Card.Header>
                        <Card.Body>
                            <div>
                                <Formik
                                    initialValues={userData}
                                    validationSchema={Yup.object().shape({
                                    })}
                                    onSubmit={(values) => this.sumbitForm(values)}
                                >
                                    {({ handleSubmit, handleChange, handleBlur, errors, touched, values }) => (
                                        <Form onSubmit={handleSubmit}>
                                            <Row className="gx-1">
                                                <Form.Group>
                                                    <Form.Check inline
                                                        type="switch"
                                                        name={`isMultiClient_${this.props.user.userId}`}
                                                        label="Multi Client"
                                                        value={values.isMultiClient}
                                                        id={`isMultiClient_${this.props.user.userId}`}
                                                        checked={this.state.isMultiClient}
                                                        onChange={e => this.setState({
                                                            isMultiClient: e.target.checked,
                                                            selectedProductsOptions: [],
                                                            clientProducts: [],
                                                        })}
                                                    />
                                                </Form.Group>

                                                {this.state.isMultiClient ?
                                                    <Form.Group>
                                                        <Label>Select Multiple Clients</Label>
                                                        <Select
                                                            options={clientOptions}
                                                            id="multiClientsDropdown"
                                                            value={this.state.multiClientChoices}
                                                            onChange={(value, event) => this.multiClientChanged(value, event)}
                                                            isMulti
                                                            filterOption={(item, input) => {
                                                                if (input) {
                                                                    return item.data.label.toLowerCase().includes(input.toLowerCase());
                                                                }
                                                                return true;
                                                            }}
                                                        />
                                                    </Form.Group>
                                                    :
                                                    <>
                                                        <Form.Group>
                                                            <Label>Select A Client</Label>
                                                            <Select
                                                                options={clientOptions}
                                                                id="clientsDropdown"
                                                                value={selectedClient}
                                                                onChange={selectedOption => this.clientChanged(parseInt(selectedOption.value))}
                                                                filterOption={(item, input) => {
                                                                    if (input) {
                                                                        return item.data.label.toLowerCase().includes(input.toLowerCase());
                                                                    }
                                                                    return true;
                                                                }}
                                                            />
                                                        </Form.Group>
                                                    </>
                                                }

                                                {this.state.loadingClientProducts === true ?
                                                    <Spinner color="dark" className="mr-2" />
                                                    :
                                                    <Form.Group>
                                                        <Label>Select products</Label>
                                                        <Select
                                                            className="react-select-container"
                                                            classNamePrefix="react-select"
                                                            value={selectedProducts}
                                                            options={productOptions}
                                                            onChange={this.updateSelectedProducts}
                                                            isMulti
                                                        />
                                                    </Form.Group>
                                                }
                                                <Form.Group>
                                                    <Form.Check inline
                                                        type="switch"
                                                        name={`canViewOverview_${this.props.user.userId}`}
                                                        label="Can access Client Overview"
                                                        value={this.state.canViewOverview}
                                                        id={`canViewOverview_${this.props.user.userId}`}
                                                        checked={this.state.canViewOverview}
                                                        onChange={e => this.setState({ canViewOverview: e.target.checked })}
                                                    />
                                                </Form.Group>
                                            </Row>
                                            {(this.state.selectedClientId != null || (this.state.multiClientChoices && this.state.multiClientChoices.length > 0)) &&
                                                <Button className="submit-btn" onClick={this.savePermissions}>Save</Button>}

                                        </Form>
                                    )}
                                </Formik>
                            </div>
                        </Card.Body>
                    </Card >
                </Container >
            );
        } else if (initSuccess === false) {
            return <GenericErrorAlert />
        } else {
            return <Loader />
        }
    }
}