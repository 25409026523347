import React from "react";
import { debugProps } from "./debug";

import { Button, Table, Spinner } from 'reactstrap';
import { genericFetcherFactory } from "../../../../utils/requestUtils";

const title = "Review the changed Ads. It is possible to revert the successfully changed Ads to the original Post";
export default class Step4ReviewAndRevert extends React.PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            isFetching: false,
        }

        this.revertPosts = this.revertPosts.bind(this);
    }

    revertPosts() {
        this.setState({ isFetching: true });

        let payload = {
            method: "POST",
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(this.props.stepData.switchResult)
        }

        genericFetcherFactory(`/api/facebook-interface/PostSwitchTool/revert`, "REVERT_POSTS", "Failed to revert posts", "POST", payload)()
            .then(result => {
                this.setState({ isFetching: false })
                if (result.success === true) {
                    this.props.updateRevertResult(result.data);
                }
            });
    }

    render() {
        let resultsTable;
        if (this.props.stepData.revertResult != null) {
            resultsTable = (
                <Table size="sm" striped className="wizard-table">
                    <thead>
                        <tr>
                            <th>Ad ID</th>
                            <th>Switch Status</th>
                            <th>Revert Status</th>
                            <th>Errors</th>
                        </tr>
                    </thead>
                    <tbody>
                        {this.props.stepData.switchResult.adUpdates.map(switchAdUpdate => {
                            let revertStatusText, errorMessage, className;
                            let switchStatusText = switchAdUpdate.isCreativeChanged === true ? "Switched" : "Failed to switch";

                            let revertAdUpdate = this.props.stepData.revertResult.adUpdates.find(result => result.adId == switchAdUpdate.adId);

                            // Was supposed to be reverted
                            if (switchAdUpdate.isCreativeChanged === true) {
                                if (revertAdUpdate === undefined) {
                                    revertStatusText = "Could not find revert result for this Ad ID.";
                                    errorMessage = "N/A";
                                    className = "error";
                                } else {
                                    let status = revertAdUpdate.isCreativeChanged === true;
                                    revertStatusText = status ? "Reverted" : "Failed to revert";
                                    errorMessage = status ? "N/A" : revertAdUpdate.errorMessage;
                                    className = status ? "success" : "error"
                                }
                            } else {
                                revertStatusText = "N/A";
                                errorMessage = switchAdUpdate.errorMessage;
                                className = "warning";
                            }

                            return (
                                <tr className={className}>
                                    <td>{switchAdUpdate.adId}</td>
                                    <td>{switchStatusText}</td>
                                    <td>{revertStatusText}</td>
                                    <td>{errorMessage}</td>
                                </tr>
                            )
                        })}
                    </tbody>
                </Table>
            );
        } else {
            resultsTable = (
                <Table size="sm" striped className="wizard-table">
                    <thead>
                        <tr>
                            <th>Ad ID</th>
                            <th>Status</th>
                            <th>Errors</th>
                        </tr>
                    </thead>
                    <tbody>
                        {this.props.stepData.switchResult.adUpdates.map(adUpdate => {
                            let status = adUpdate.isCreativeChanged === true;

                            return (
                                <tr className={status ? "success" : "error"}>
                                    <td>{adUpdate.adId}</td>
                                    <td>{status ? "Switched" : "Failed to switch"}</td>
                                    <td>{adUpdate.errorMessage ? adUpdate.errorMessage : "N/A"}</td>
                                </tr>
                            )
                        })}
                    </tbody>
                </Table>
            );
        }

        return (
            <div className={"wizard-step-container"}>
                <div className={"step-title"}>
                    {title}
                </div>
                <div className={"step-body"}>
                    <div className={"wizard-step-info"}>
                        <div>Original Post ID: {this.props.stepData.switchResult.originalPostId}</div>
                        <div>New Post ID: {this.props.stepData.switchResult.newPostId}</div>
                    </div>

                    <div style={{ marginTop: "2rem" }}>
                        <Button disabled={this.state.isFetching === true} onClick={this.revertPosts}>Revert Results</Button>
                        {this.props.stepData.revertResult !== null &&
                            <span style={{ marginLeft: "0.5rem", color: "red", fontWeight: "bold" }}>* Avoid reverting the results more than once. Reverting again should not be attempted unless the first time failed.</span>
                        }
                    </div>

                    {this.state.isFetching === true &&
                        <Spinner />
                    }

                    <div style={{ marginTop: "2rem" }}>
                        <h4 style={{ marginBottom: "0.5rem" }}>{this.props.stepData.revertResult == null ? "Switch Results" : "Revert Results"}</h4>
                        {resultsTable}
                    </div>

                    <div style={{ marginTop: "2rem" }}>
                        <Button disabled={this.state.isFetching === true} color="primary" onClick={this.props.resetWizard}>Finish and reset wizard</Button>
                    </div>
                </div>
                {/* {debugProps(this.props)} */}
            </div >
        );
    }
}