import React from "react";

import Select from "react-select";

import {
    Modal,
    ModalBody,
    ModalHeader,
    Spinner,
    Button
} from "reactstrap";

import { genericCachedFetcherFactory, genericFetcherFactory } from "../utils/requestUtils";
import { toastr } from "react-redux-toastr";
import { impersonateUser } from "../redux/actions/userContextActions";

import { connect } from 'react-redux';
import { addHours } from "../utils/dateUtils";
import CacheController from "../services/cache/CacheController";

class ImpersonateUser extends React.PureComponent {
    constructor() {
        super();

        this.state = {
            usersList: [],
            isLoadedUsersList: null,
            openModal: false,
            selectedUser: null
        }

        this.openPrompt = this.openPrompt.bind(this);
        this.stopImpersonating = this.stopImpersonating.bind(this);
    }

    async componentDidMount() {
        if (this.props.userContext && !this.props.userContext.userData.isExternalUser) {
            genericCachedFetcherFactory("/impersonateApi/getUserList", "IMPERSONATING_USERS", "Failed to fetch user list for impersonation", addHours(1), "GET", null, true, false)().then(fetchResult => {
                if (fetchResult.success === true) {
                    this.setState({ usersList: fetchResult.data, isLoadedUsersList: true });
                } else {
                    this.setState({ usersList: fetchResult.data, isLoadedUsersList: false });
                    console.error(fetchResult.message);
                }
            })
        }
    }

    openPrompt() {
        this.setState({ openModal: true });
    }

    stopImpersonating() {
        genericFetcherFactory("/impersonateApi/stopImpersonating", "STOP_IMPERSONATE", "Failed to stop impersonation.")().then(fetchResult => {
            if (fetchResult.success === true) {
                CacheController.clear();
                this.props.impersonateUser(null);
            } else {
                toastr.error("Try again or log out to reset impersonation.", { timeOut: 0 });
            }
        });
    }

    impersonateUser(userLogin) {
        genericFetcherFactory(`/impersonateApi/impersonateUser/${userLogin}`, "START_IMPERSONATE", "Failed to start impersonation.")().then(fetchResult => {
            this.setState({ openModal: false });
            if (fetchResult.success === true) {
                CacheController.clear();
                this.props.impersonateUser({ userLogin: fetchResult.data.email, userData: fetchResult.data.userData });
            }
        });
    }

    render() {
        let contextMenuItem;

        if (this.props.impersonatedUser == null) {
            contextMenuItem = <div onClick={this.openPrompt}>Impersonate User...</div>;
        } else {
            contextMenuItem = <div onClick={this.stopImpersonating}>Stop Impersonating {this.props.impersonatedUser.userLogin}</div >;
        }

        let userOptions = this.state.usersList.map(user => { return { value: user, label: user }; });
        if (userOptions.length < 1) {
            userOptions = null;
        }

        return (<>
            <Modal isOpen={this.state.openModal === true} toggle={() => this.setState({ openModal: false })} centered>
                <ModalHeader toggle={() => this.setState({ openModal: false })}>
                    <div>Select user to impersonate</div>
                    <div></div>
                </ModalHeader>
                <ModalBody className="text-center m-3">
                    {this.state.isLoadedUsersList == true &&
                        <>
                            <Select
                                placeholder="Select a user..."
                                options={userOptions}
                                id="usersDropdown"
                                onChange={selectedOption => this.setState(selectedOption == null ? { selectedUser: null } : { selectedUser: selectedOption.value })}
                                filterOption={(item, input) => {
                                    if (input) {
                                        return item.data.label.toLowerCase().includes(input.toLowerCase());
                                    }
                                    return true;
                                }}
                            />
                            <Button className="mt-2" onClick={() => this.impersonateUser(this.state.selectedUser)}>Impersonate {this.state.selectedUser ? this.state.selectedUser : ""}</Button>
                        </>
                    }
                    {this.state.isLoadedUsersList == false &&
                        "Failed to load user list. Please try refreshing the page and if the problem persists contact support."
                    }
                    {this.state.isLoadedUsersList == null &&
                        <Spinner color="dark" className="mr-2" />
                    }
                </ModalBody>
            </Modal>
            {contextMenuItem}
        </>);
    }
}


export default connect(
    (store) => {
        return {
            impersonatedUser: store.userContext.impersonatedUser,
            userContext: store.userContext.user
        }
    },
    { impersonateUser })(ImpersonateUser)