import React from 'react';

import {
    Label,
    FormText
} from "reactstrap";

import {
    AvGroup,
    AvInput,
    AvFeedback
} from "availity-reactstrap-validation";

import Select from "react-select";
import CreatableSelect from 'react-select/creatable';

import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";

import { toastr } from "react-redux-toastr";
import GenericErrorAlert from "../../../components/GenericErrorAlert";
import { DEFAULT_FUNNEL_ID, USE_DEFAULT_FUNNEL_KPI, isPixelBasedFunnelPlatform, KpiTypeOptions } from '../funnelUtils';
import { Platforms } from '../../prods-and-accounts/account/accountUtils';

export const FormModes = {
    NEW: "new",
    EDIT: "edit",
    READONLY: "readonly",
    HISTORY: "history"
}

export default class FunnelBasicFieldsEditForm extends React.PureComponent {
    constructor(props) {
        super(props);

        this.changeFunnelId = this.changeFunnelId.bind(this);
        this.changeFunnelName = this.changeFunnelName.bind(this);
        this.changeFunnelGroupId = this.changeFunnelGroupId.bind(this);
        this.changeTargetCpaFromDefault = this.changeTargetCpaFromDefault.bind(this);
        this.changeTargetCpa = this.changeTargetCpa.bind(this);
        this.changeTargetRoiFromDefault = this.changeTargetRoiFromDefault.bind(this);
        this.changeTargetRoi = this.changeTargetRoi.bind(this);
        this.changeProductId = this.changeProductId.bind(this);
        this.changeShowInReport = this.changeShowInReport.bind(this);
        this.changeIsActive = this.changeIsActive.bind(this);
        this.changeFunnelGroupId = this.changeFunnelGroupId.bind(this);
        this.generatePixelBasedKpiField = this.generatePixelBasedKpiField.bind(this);

        this.renderTargetCpaControl = this.renderTargetCpaControl.bind(this);
        this.renderTargetRoiControl = this.renderTargetRoiControl.bind(this);
        this.renderMainKpiField = this.renderMainKpiField.bind(this);
        this.renderFunnelGroupField = this.renderFunnelGroupField.bind(this);
        this.renderKpiType = this.renderKpiType.bind(this);
        let preInitCheckSuccess = this.checkPreInit(props);

        if (preInitCheckSuccess !== true) {
            toastr.warning("Error loading the editing interface. Please try refreshing the page.");
            this.state = { initSuccess: false };
            return;
        }

        let products = [{ productId: 0, productName: "Select Product" }, ...props.prefetchedEntities.products];
        let funnelGroups = [{ funnelGroupId: 0, funnelGroupName: "None" }, ...props.prefetchedEntities.funnelGroups];

        if (props.funnelData.funnelGroupId != 0) {

            if (funnelGroups.findIndex(group => group.funnelGroupId === props.funnelData.funnelGroupId) == -1) {
                toastr.warning("Funnel group ID " + props.funnelData.funnelGroupId + " cannot be found. If funnel is saved funnel group will be reset.");
            }
        }

        if (props.funnelData.productId != 0) {
            if (products.findIndex(product => product.productId === props.funnelData.productId) == -1) {
                toastr.warning("Product ID " + props.funnelData.productId + " cannot be found. If funnel is saved product will be reset.");
            }
        }

        this.state = {
            initSuccess: true,
            accountId: props.funnelData.accountId,
            funnelId: props.funnelData.funnelId,
            products: products,
            funnelGroups: funnelGroups
        };
    }

    checkPreInit(props) {
        let success = true;
        if (props.prefetchedEntities.products === null) {
            success = false;
            console.error("props.prefetchedEntities.products was not provided to the FunnelBasicFieldsEditForm");
        }

        if (props.prefetchedEntities.funnelGroups === null) {
            success = false;
            console.error("props.prefetchedEntities.funnelGroups was not provided to the FunnelBasicFieldsEditForm");
        }

        if (props.funnelData.accountId === null) {
            success = false;
            console.error("props.funnelData.accountId was not provided to the FunnelBasicFieldsEditForm");
        }

        if (props.mode === null) {
            success = false;
            console.error("props.mode was not provided to the FunnelBasicFieldsEditForm");
        }

        if (props.onChange === null || props.onChange === undefined) {
            console.warn("props.onChange function not bound for FunnelBasicFieldsEditForm");
        }

        return success;
    }

    // Event Handling


    setDate(date) {
        let funnelData = { ...this.props.funnelData };
        funnelData.fromDate = date;
        funnelData.fromDateString = funnelData.fromDate.toLocaleDateString('en-GB');
        this.props.onChange(funnelData);
    }

    changeFunnelId(newFunnelId) {
        if (newFunnelId === DEFAULT_FUNNEL_ID) {
            toastr.warning("Cannot use " + DEFAULT_FUNNEL_ID + " for funnel ID.");
        } else {
            let funnelData = { ...this.props.funnelData };
            funnelData.funnelId = newFunnelId;
            this.props.onChange(funnelData);
        }
    }

    changeFunnelName(newFunnelName) {
        let funnelData = { ...this.props.funnelData };
        funnelData.funnelName = newFunnelName;
        this.props.onChange(funnelData);
    }

    changeFunnelGroupId(funnelGroupOption) {
        if (funnelGroupOption == null) {
            let funnelData = { ...this.props.funnelData };
            funnelData.funnelGroupId = 0;
            this.props.onChange(funnelData);
        } else if (funnelGroupOption.value != null) {
            let funnelData = { ...this.props.funnelData };
            funnelData.funnelGroupId = funnelGroupOption.value;
            this.props.onChange(funnelData);
        }
    }

    changeTargetCpa(newTargetCpa) {
        let funnelData = { ...this.props.funnelData };
        funnelData.targetCpa = newTargetCpa;
        this.props.onChange(funnelData);
    }

    changeTargetRoi(newTargetRoi) {
        let funnelData = { ...this.props.funnelData };
        funnelData.targetRoi = newTargetRoi;
        this.props.onChange(funnelData);
    }

    changeMainKpi(newMainKpi) {
        let funnelData = { ...this.props.funnelData };
        funnelData.mainKpi = newMainKpi;
        this.props.onChange(funnelData, "mainKpi");
    }

    changeProductId(newProductId) {
        let funnelData = { ...this.props.funnelData };
        funnelData.productId = newProductId;
        this.props.onChange(funnelData);
    }

    changeShowInReport(showInReport) {
        let funnelData = { ...this.props.funnelData };
        funnelData.showInReport = showInReport;
        this.props.onChange(funnelData);
    }

    changeIsActive(isActive) {
        let funnelData = { ...this.props.funnelData };
        funnelData.isActive = isActive;
        this.props.onChange(funnelData);
    }

    changeTargetCpaFromDefault(newValue) {
        let funnelData = { ...this.props.funnelData };
        funnelData.targetCpa = null;
        funnelData.targetCpaFromDefault = newValue;
        this.props.onChange(funnelData);
    }

    changeTargetRoiFromDefault(newValue) {
        let funnelData = { ...this.props.funnelData };
        funnelData.targetRoi = null;
        funnelData.targetRoiFromDefault = newValue;
        this.props.onChange(funnelData);
    }

    changeKpiType(newKpiType) {
        let funnelData = { ...this.props.funnelData };
        funnelData.kpiType = newKpiType;
        this.props.onChange(funnelData);
    }
    // Render

    renderTargetCpaControl() {
        let inputJsx = (
            <AvGroup>
                <Label for="targetCpa">Target CPA</Label>
                <AvInput
                    name="targetCpa"
                    id="targetCpa"
                    type="number"
                    readOnly={this.props.mode === FormModes.READONLY}
                    min={0.1}
                    required
                    onChange={event => this.changeTargetCpa(parseFloat(event.target.value))}
                    value={this.props.funnelData.targetCpa} />
                <AvFeedback>Target CPA is required</AvFeedback>
            </AvGroup>
        );

        if (this.state.funnelId === DEFAULT_FUNNEL_ID) {
            return inputJsx;
        } else {
            return (
                <div className="targetWrap">
                    {this.props.funnelData.targetCpaFromDefault === true &&
                        <label>Target CPA</label>
                    }
                    {this.props.funnelData.targetCpaFromDefault === false &&
                        inputJsx
                    }
                    <AvGroup className="useDefault">
                        <AvInput
                            name="targetCpaFromDefault"
                            id="targetCpaFromDefault"
                            type="checkbox"
                            disabled={this.props.mode === FormModes.READONLY}
                            defaultChecked={this.props.funnelData.targetCpaFromDefault}
                            onChange={event => this.changeTargetCpaFromDefault(event.target.checked)} />
                        <Label for="targetCpaFromDefault">Use default funnel value</Label>
                    </AvGroup>


                </div>
            );
        }
    }

    renderTargetRoiControl() {
        let inputJsx = (
            <AvGroup>
                <Label for="targetRoi">Target ROI</Label>
                <AvInput
                    name="targetRoi"
                    id="targetRoi"
                    type="number"
                    min={0.1}
                    max={10.1}
                    readOnly={this.props.mode === FormModes.READONLY}
                    required
                    onChange={event => this.changeTargetRoi(parseFloat(event.target.value))}
                    value={this.props.funnelData.targetRoi} />
                <AvFeedback>Target ROI is required, max value 10</AvFeedback>
            </AvGroup>
        );

        if (this.state.funnelId === DEFAULT_FUNNEL_ID) {
            return inputJsx;
        } else {
            return (
                <div className="targetWrap">
                    {this.props.funnelData.targetRoiFromDefault === true &&
                        <label>Target ROI</label>
                    }
                    {this.props.funnelData.targetRoiFromDefault === false &&
                        inputJsx
                    }
                    <AvGroup className="useDefault">
                        <AvInput
                            name="targetRoiFromDefault"
                            id="targetRoiFromDefault"
                            type="checkbox"
                            disabled={this.props.mode === FormModes.READONLY}
                            defaultChecked={this.props.funnelData.targetRoiFromDefault}
                            onChange={event => this.changeTargetRoiFromDefault(event.target.checked)} />
                        <Label for="targetRoiFromDefault">Use default funnel value</Label>
                    </AvGroup>


                </div>
            );
        }
    }

    renderFunnelGroupField() {
        let selectOptions = [];
        selectOptions.push(
            ...this.props.prefetchedEntities.funnelGroups.map(fg => {
                return { value: fg.funnelGroupId, label: fg.funnelGroupName };
            })
        );
        let selected = this.props.funnelData.funnelGroupId != null ? selectOptions.find(option => option.value === this.props.funnelData.funnelGroupId) : null;
        if (selected === undefined) {
            selected = null;
        }

        return (
            <div class="form-group" style={{ minWidth: "180px" }}>
                <Label for="FunnelGroupId">Funnel Group</Label>
                <CreatableSelect id="FunnelGroupId"
                    isClearable
                    isDisabled={this.props.mode === FormModes.READONLY || this.props.mode === FormModes.HISTORY}
                    onChange={event => this.changeFunnelGroupId(event)}
                    onCreateOption={event => this.props.createFunnelGroupCallback(event)}
                    options={selectOptions}
                    value={selected}
                />
            </div>
        );
    }

    generatePixelBasedKpiField() {
        let jsx = "";
        let selectOptions = [];

        if (this.props.funnelData.funnelId !== DEFAULT_FUNNEL_ID) {
            selectOptions.push({
                value: USE_DEFAULT_FUNNEL_KPI,
                label: "Use Default Funnel Value"
            });
        }

        selectOptions.push(
            ...this.props.prefetchedEntities.pixels.map(pixel => {
                let label = "";
                if (pixel.isArchived === true) {
                    label += "[ARCHIVED]";
                }
                label += pixel.pixelName;
                if (pixel.accountId !== null) {
                    label += " (" + pixel.lastExternalUpdate + ")";
                }
                return { value: pixel.pixelId, label: label };
            })
        );

        let selected = this.props.funnelData.mainKpi != null ? selectOptions.find(option => option.value === this.props.funnelData.mainKpi) : null;
        if (selected === undefined) {
            selected = null;
        }

        jsx = (
            <div class="form-group" style={{ minWidth: "300px" }}>
                <Label for="mainKpi">Main KPI</Label>
                <Select
                    isDisabled={this.props.mode === FormModes.READONLY}
                    options={selectOptions}
                    id="mainKpi"
                    value={selected}
                    onChange={selectedOption => this.changeMainKpi(selectedOption.value)}
                />
                {this.props.propagatedValidationErrors.mainKpi === true &&
                    <FormText color="danger">Main KPI Must be selected</FormText>
                }
            </div>
        );

        return jsx;
    }

    renderMainKpiField() {
        let jsx = "";

        if (isPixelBasedFunnelPlatform(this.props.platform)) {
            jsx = this.generatePixelBasedKpiField();
        } else {
            //funnel history field
            jsx = (
                <AvGroup>
                    <Label for="mainKpi">Main KPI</Label>
                    <AvInput name="mainKpi" id="mainKpi"
                        onChange={event => this.changeMainKpi(event.target.value)}
                        value={this.props.funnelData.mainKpi}
                        readOnly={this.props.mode === FormModes.READONLY}
                        required />
                    <AvFeedback>Main KPI must be provided</AvFeedback>
                </AvGroup>
            );
        }

        return jsx;
    }

    renderKpiType() {
        let selected = KpiTypeOptions.find(option => option.value === this.props.funnelData.kpiType);
        if (selected === undefined) {
            selected = null;
        }

        return (
            <div class="form-group" style={{ minWidth: "300px" }}>
                <Label for="kpiType">KPI Type</Label>
                <Select
                    options={KpiTypeOptions}
                    id="kpiType"
                    value={selected}
                    onChange={selectedOption => this.changeKpiType(selectedOption.value)}
                />
                {this.props.propagatedValidationErrors.kpiType === true &&
                    <FormText color="danger">KPI Type Must be selected</FormText>
                }
            </div>
        );

    }

    render() {
        if (this.state.initSuccess === false) {
            return (
                <GenericErrorAlert />
            );
        } else if (this.state.initSuccess === true) {
            let productsSelectOptions = this.state.products.map(product => <option key={"fnl-" + this.state.funnelId.toString() + "-grp-" + product.productId.toString()} value={product.productId} disabled={this.props.mode === FormModes.READONLY || this.props.mode === FormModes.HISTORY}>{product.productName}</option>);
            // let groupSelectOptions = this.state.funnelGroups.map(group => <option key={"fnl-" + this.state.funnelId.toString() + "-grp-" + group.funnelGroupId.toString()} value={group.funnelGroupId} disabled={this.props.mode === FormModes.READONLY || this.props.mode === FormModes.HISTORY}>{group.funnelGroupName}</option>);

            let targetCpaControl = this.renderTargetCpaControl();
            let targetRoiControl = this.renderTargetRoiControl();
            let mainKpiField = this.renderMainKpiField();
            let funnelGroupField = this.renderFunnelGroupField();
            let renderKpiType = this.renderKpiType();
            return (
                <div className="form-group-wrap">
                    {this.props.mode === FormModes.HISTORY &&
                        <div class="form-group">
                            <Label>Start Date</Label>
                            <DatePicker
                                dateFormat="dd/MM/yyyy"
                                // minDate={(new Date()).setDate((new Date()).getDate() - 30)}
                                maxDate={new Date()}
                                selected={this.props.funnelData.fromDate}
                                onChange={date => this.setDate(date)} />
                        </div>
                    }
                    <AvGroup>
                        <Label for="funnelId">Funnel ID</Label>
                        <AvInput name="funnelId" id="funnelId"
                            onChange={event => this.changeFunnelId(event.target.value)}
                            value={this.props.funnelData.funnelId}
                            readOnly={this.props.mode === FormModes.READONLY || this.props.mode === FormModes.HISTORY || this.props.funnelData.funnelId === DEFAULT_FUNNEL_ID || this.props.mode === FormModes.EDIT}
                            validate={{ required: true, pattern: { value: /^[a-z0-9]{4}$|^DEFAULT$/ } }} />
                        <AvFeedback>Funnel ID must be exactly 4 lowercase letters or numbers</AvFeedback>
                    </AvGroup>
                    <AvGroup>
                        <Label for="funnelName">Funnel Name</Label>
                        <AvInput name="funnelName" id="funnelName" readOnly={this.props.mode === FormModes.READONLY || this.props.mode === FormModes.HISTORY} required onChange={event => this.changeFunnelName(event.target.value)} value={this.props.funnelData.funnelName} />
                        <AvFeedback>Funnel name is required</AvFeedback>
                    </AvGroup>

                    {funnelGroupField}

                    {targetCpaControl}
                    {targetRoiControl}

                    {mainKpiField}
                    <AvGroup>
                        <Label for="productId">Product</Label>
                        <AvInput type="select" readOnly={this.props.mode === FormModes.READONLY || this.props.mode === FormModes.HISTORY} name="productId" id="productId" value={this.props.funnelData.productId} onChange={event => this.changeProductId(parseInt(event.target.value))} validate={{ required: true, min: { value: 1 } }}>
                            {productsSelectOptions}
                        </AvInput>
                        <AvFeedback>Product is required</AvFeedback>
                    </AvGroup>
                    <AvGroup className="text-center">
                        <AvInput
                            name="showInReport"
                            id="showInReport"
                            type="checkbox"
                            disabled={this.props.mode === FormModes.READONLY || this.props.mode === FormModes.HISTORY}
                            defaultChecked={this.props.funnelData.showInReport}
                            onChange={event => this.changeShowInReport(event.target.checked)} />
                        <Label for="showInReport">Show In Report</Label>
                    </AvGroup>

                    <AvGroup className="text-center">
                        <AvInput
                            name="isActive"
                            id="isActive"
                            type="checkbox"
                            disabled={this.props.mode === FormModes.READONLY || this.props.mode === FormModes.HISTORY}
                            defaultChecked={this.props.funnelData.isActive}
                            onChange={event => this.changeIsActive(event.target.checked)} />
                        <Label for="isActive">Active</Label>
                    </AvGroup>

                    {this.props.mode !== FormModes.READONLY && renderKpiType} {/* KPI Type is not required in funnel history */}
                </div>
            );
        }
    }
}

FunnelBasicFieldsEditForm.defaultProps = {
    funnelData: {
        accountId: null,
        funnelId: "",
        funnelName: "",
        targetRoi: null,
        targetCpa: null,
        funnelGroupId: 0,
        productId: 0,
        mainKpi: null,
        kpiType: null,
    },
    prefetchedEntities: {
        products: null,
        funnelGroups: null,
        pixels: null
    },
    mode: null,
    platform: null,
    propagatedValidationErrors: {}
}