import { Form } from 'react-bootstrap';
import { DeactivateAccountWindows } from './accountUtils';

export function GenerateDeactivateJSx(props) {
    let deactivateAccountOptions = [<option value="">Select Reason</option>];
    DeactivateAccountWindows.forEach(value => {
        deactivateAccountOptions.push(<option key={"act-" + props.values.accountId.toString() + "-dctv-" + value} value={value}>{value}</option>)
    })
    let deactivateReason = props.values.deactivateReason;
    if (deactivateReason !== null && deactivateReason.includes(',')) {
        deactivateReason = deactivateReason.split(',')[2];
    }

    return (
        <>
            {props.values.isActive === false &&
                //show deactivateReason dropdown
                <>
                    <Form.Group className="col-12 col-sm-auto mb-4 gx-2">
                        <Form.Label htmlFor="deactivateReason">Deactivating Reason</Form.Label>
                        <Form.Control
                            as="select"
                            name="deactivateReason"
                            id="deactivateReason"
                            value={deactivateReason}
                            onChange={(e) => {
                                props.setFieldValue('deactivateReason', e.target.value)
                            }}
                            isInvalid={!!props.errors.deactivateReason && !!props.touched.deactivateReason}
                        >
                            {deactivateAccountOptions}
                        </Form.Control>
                        <Form.Control.Feedback>{props.errors.deactivateReason}</Form.Control.Feedback>
                    </Form.Group>
                    {(props.values.deactivateReason !== null && props.values.deactivateReason == "Other") &&

                        <Form.Group className="col-12 col-sm-auto mb-4 gx-2">
                            <Form.Label htmlFor="deactivateReasonFromUser">Write Your Reason:</Form.Label>
                            <Form.Control
                                name="deactivateReasonFromUser"
                                id="deactivateReasonFromUser"
                                onChange={props.handleChange}
                                onBlur={props.handleBlur}
                                value={props.values.deactivateReasonFromUser}
                                isInvalid={!!props.errors.deactivateReasonFromUser && !!props.touched.deactivateReasonFromUser}
                            />

                            <Form.Control.Feedback>{props.errors.deactivateReasonFromUser}</Form.Control.Feedback>
                        </Form.Group>
                    }
                </>
            }



        </>
    )
}