import React from "react";
import { Container, Card, Form } from "react-bootstrap";
import Loader from '../../../components/Loader';
import { connect } from 'react-redux';
import { setDeploymentSharedComponent, setUserStatus, setCmsData } from "../../../redux/actions/cmsActions"
import { cmsUtils } from '../utils';
import { SharedComponentUtils } from '../SharedComponentUtils';
import { UserStatusUtils } from '../UserStatusUtils';
import { genericFetcherFactory } from '../../../utils/requestUtils';

const jsons = { 
    sharedComponentsFile: "shared-components.json"
}
const sharedComponents = 'shared-components';
class SharedComponentList extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            error: null
        };

        this.utils = new cmsUtils();
        this.userStatusUtils = new UserStatusUtils();
        this.SharedComponentUtils = new SharedComponentUtils();
        this.handleChange = this.handleChange.bind(this);
        this.fetchFileFromS3 = this.fetchFileFromS3.bind(this);
    }

    componentDidMount() {
        this.SharedComponentUtils.fetchSharedComponentsList();
        this.fetchFileFromS3(jsons.sharedComponentsFile);
    }

    handleChange(event) {
        const { name, value } = event.target;
        this.setState({
            [name]: value
        });

    }

    fetchFileFromS3(file) {
        let rest = {
            folder: sharedComponents,
            file: file
        }
        genericFetcherFactory(
            "/lambda-proxy/lambdaProxy/lambdas3download",
            file,
            "Failed to fetch " + file,
            "POST",
            {
                method: 'POST',
                body: JSON.stringify(rest)
            })().then(res => {
                if (res.success !== true) {
                    this.props.setCmsData({
                        ...this.props.cmsData,
                        "fetchedSharedComponentsMapping": null,
                        error: 'Shared Components Mapping not found'
                    });
                } else {
                    let fetchedData = JSON.parse(res.data);
                    this.props.setCmsData({
                        ...this.props.cmsData,
                        'fetchedSharedComponentsMapping': fetchedData
                    });
                }
            }
            );
    }

    render() {

        if (this.props.cmsData.sharedComponentsListIsLoaded === true && this.props.cmsData.sharedComponentsAvailable !== null) {
            return (
                <Container fluid className="cms__inner">
                    <h1>Choose a Component to Deploy to all projects:</h1>
                    <Card>
                        <Card.Body>
                            <Form>
                                {this.props.cmsData.sharedComponentsAvailable.map((data, value) => {
                                    return (
                                        <Form.Group key={value}>
                                            <Form.Check
                                                type="radio"
                                                value={data}
                                                id={data}
                                                key={data}
                                                onChange={this.utils.handleChange}
                                                checked={this.props.cmsData.deploySelectedComponent == data}
                                                name='deploySelectedComponent'
                                                label={data}
                                            />
                                        </Form.Group>
                                    )
                                })}
                            </Form>
                        </Card.Body>
                    </Card>
                </Container>

            );
        } else {
            return (
                <Container fluid className="cms__inner">
                    <h1>Choose a Component to update:</h1>
                    <Loader content={["Fetching " + "Shared Components data", "Almost done..."]} width="full" />
                </Container>
            )
        }
    }
}

export default connect(
    (store) => {
        return {
            deploymentSharedComponent: store.cms.deploymentSharedComponent,
            cmsData: store.cms.cmsData,
            userStatus: store.cms.userStatus,
            user: store.userContext.user
        }
    },
    { setCmsData, setDeploymentSharedComponent, setUserStatus })(SharedComponentList)