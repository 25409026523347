import React from "react";
import { debugProps } from "./debug";

import { Table, Button, Spinner, Label } from 'reactstrap';
import DatePicker from 'react-datepicker';
import { genericFetcherFactory } from "../../../../utils/requestUtils";

const title = "Extend the end date for Adsets that contain the Post that have expired";
export default class Step2ExpiredAdsets extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            extensionDate: props.stepData.extensionDate ? props.stepData.extensionDate : new Date(new Date().setHours(new Date().getHours() + 6)),
            isFetching: false,
            displayErrorMessage: false,
            displayNextStepButton: false
        }

        this.extendAdsets = this.extendAdsets.bind(this);
    }

    extendAdsets() {
        this.setState({ isFetching: true });
        let accountTimezone = this.props.account.gmtId;

        genericFetcherFactory(`/api/facebook-interface/PostSwitchTool/extendExpiredAdsets/${this.props.stepData.originalPostId}?newTime=${this.state.extensionDate.toISOString()}&timezoneName=${accountTimezone}`, "EXTEND_ADSETS", "Failed to extend expired adsets", "POST", null)()
            .then(result => {
                if (result.success !== true) {
                    this.setState({ isFetching: false, displayErrorMessage: true });
                } else {
                    this.setState({ isFetching: false, displayNextStepButton: true });
                    this.props.updateExtendedAdsets(result.data, this.state.extensionDate);
                }
            })
    }

    componentDidMount() {
        if (this.props.stepData.expiredAdsets.length == 0 && this.props.stepData.extendedAdsets == null) {
            this.props.updateExtendedAdsets([]);
            this.setState({ displayNextStepButton: true });
        }
    }

    render() {
        let staticJsx = <>
            <div className="general-message">Please note that data updates every 6 hours and these might not reflect real time values.</div>
            <div className={"wizard-step-info"}>
                <div>Original Post ID: {this.props.stepData.originalPostId}</div>
            </div>

            {this.state.displayNextStepButton &&
                <Button onClick={() => {
                    this.setState({ displayNextStepButton: false });
                    this.props.nextStep();
                }}>Next step</Button>
            }
        </>;

        if (this.props.stepData.expiredAdsets.length == 0) {
            return (
                <div className={"wizard-step-container"}>
                    <div className={"step-title"}>
                        {title}
                    </div>
                    <div className={"step-body"}>
                        {staticJsx}
                        <div>
                            Could not find <b>expired</b> Adsets for this post.
                        </div>
                    </div>

                    {/* {debugProps(this.props)} */}
                </div>
            )
        } else {
            let extendedAdsetsIds = this.props.stepData.extendedAdsets != null ? Object.keys(this.props.stepData.extendedAdsets) : null;

            let expiredAdsetsTable = (
                <Table size="sm" striped className="wizard-table">
                    <thead>
                        <tr>
                            <th>Adset ID</th>
                            <th>Adset Name</th>
                            <th>End Date</th>
                            <th>Status</th>
                        </tr>
                    </thead>
                    <tbody>
                        {this.props.stepData.expiredAdsets.map(adset => {
                            let status = null;

                            if (extendedAdsetsIds != null) {
                                let foundId = extendedAdsetsIds.includes(adset.adsetId);
                                status = foundId ? this.props.stepData.extendedAdsets[adset.adsetId] : null;
                            }

                            return (
                                <tr className={status != null ? status ? "success" : "error" : ""}>
                                    <td>{adset.adsetId}</td>
                                    <td>{adset.adsetName}</td>
                                    <td>{adset.adsetEndTime}</td>
                                    <td>{status != null ? status ? "Extended" : "Failed to extend" : ""}</td>
                                </tr>
                            )
                        })}
                    </tbody>
                </Table>
            );

            return (
                <div className={"wizard-step-container"}>
                    <div className={"step-title"}>
                        {title}
                    </div>
                    <div className={"step-body"}>
                        {staticJsx}
                        <div className={"wizard-input-and-button"}>
                            <Label for="datePicker">Select new expiration date for Adsets (UTC):</Label>
                            <DatePicker
                                name="datePicker"
                                id="datePicker"
                                readOnly={this.props.readOnly}
                                dateFormat="Pp"
                                showTimeSelect
                                minDate={new Date(new Date().setHours(new Date().getHours() + 6))}
                                selected={this.state.extensionDate}
                                onChange={date => this.setState({ extensionDate: date })} />
                            <Button disabled={this.props.readOnly || this.state.isFetching === true} onClick={this.extendAdsets}>Extend Adsets</Button>
                        </div>

                        {this.state.isFetching ?
                            <Spinner />
                            :
                            <div style={{ marginTop: "1rem" }}>
                                {expiredAdsetsTable}
                            </div>
                        }

                        {this.state.displayErrorMessage &&
                            <div className={"wizard-error-display"}>
                                Failed to extend the Adsets expiration. Please try again.
                        </div>
                        }

                        {this.state.displayNextStepButton &&
                            <Button disabled={this.props.readOnly} onClick={() => {
                                this.setState({ displayNextStepButton: false });
                                this.props.nextStep();
                            }}>Next step</Button>
                        }
                    </div>

                    {/* {debugProps(this.props)} */}
                </div>
            );
        }
    }
}