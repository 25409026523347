import React from "react";
import { Button, Card, Form, Modal, Row } from "react-bootstrap";
import * as Icon from "react-feather";
import { toastr } from "react-redux-toastr";
import Select from "react-select";
import DraggableTable from "../../../components/DraggableTable";
import GenericErrorAlert from "../../../components/GenericErrorAlert";
import Loader from '../../../components/Loader';
import { addHours } from "../../../utils/dateUtils";
import { genericCachedFetcherFactory, genericFetcherFactory } from "../../../utils/requestUtils";
import { ExternalReportScopes, ReportTypes } from "../constants";
import CreateNewTabForm from "./CreateNewTabForm";

const defaultReportType = ReportTypes.WEEKLY;
const defaultEntityType = ExternalReportScopes.CLIENT;
const reportTypes = [{ value: ReportTypes.MONTHLY, label: "Monthly Report" }, { value: ReportTypes.WEEKLY, label: "Weekly Report" }];
const entityTypes = [{ value: ExternalReportScopes.CLIENT, label: "Client" }, { value: ExternalReportScopes.PRODUCT, label: "Product" }];

export default class TabManagement extends React.PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            initSuccess: null,
            products: [],
            clients: [],
            selectedEntityType: null,
            selectedEntityId: null,
            projects: [],
            tabsDataWeekly: [],
            tabsDataMonthly: [],
            savingData: false
        };

        this.entityChanged = this.entityChanged.bind(this);
        this.addNewTab = this.addNewTab.bind(this);
        this.orderChanged = this.orderChanged.bind(this);
        this.deleteRow = this.deleteRow.bind(this);
        this.saveData = this.saveData.bind(this);
    }

    async componentDidMount() {
        let [getProductsResult, getClientsResult, getProjectsResult] = await Promise.all([this.getProducts(), this.getClients(), this.getProjects()]);

        if (getProductsResult.success === true && getClientsResult.success === true && getProjectsResult.success === true) {
            this.setState({
                initSuccess: true,
                products: getProductsResult.data,
                clients: getClientsResult.data,
                selectedEntityType: defaultEntityType,
                selectedEntityId: null,
                selectedReportType: defaultReportType,
                projects: getProjectsResult.data,
            });

        } else {
            this.setState({ initSuccess: false });
        }
    }

    async getProducts() {
        return genericCachedFetcherFactory("/api/product/Products", "PRODUCTS", "Failed to fetch product list", addHours(1))();
    }

    async getClients() {
        return genericCachedFetcherFactory("/api/crm/Clients", "CLIENTS", "Failed to fetch clients list", addHours(1))();
    }

    async getProjects() {
        return genericCachedFetcherFactory("/api/tableaus/TabMenuItems/TableauProjectsTree", "TABLEAU_PROJECTS", "Failed to fetch projects", addHours(1))();
    }

    async getEntityTableauTabs(entityType, entityId) {
        return genericFetcherFactory(`/api/tableaus/TabMenuItems/External/${entityType}/${entityId}?isManagement=1`, "EXTERNAL_TABLEAU_TABS", "Failed to fetch existing tabs")();
    }

    async entityChanged(entityType, newEntityId) {
        if (newEntityId != this.state.selectedEntityId) {
            let result = await this.getEntityTableauTabs(entityType, newEntityId);
            if (result.success === true) {
                let weeklyReportTabs = result.data.filter(tab => tab.reportType === ReportTypes.WEEKLY);
                let monthlyReportTabs = result.data.filter(tab => tab.reportType === ReportTypes.MONTHLY);
                this.setState({ selectedEntityId: newEntityId, tabsDataWeekly: weeklyReportTabs, tabsDataMonthly: monthlyReportTabs });
            }
            else {
                this.setState({ initSuccess: false });
            }
        }
    }

    orderChanged(data) {
        switch (this.state.selectedReportType) {
            case ReportTypes.WEEKLY:
                this.setState({ tabsDataWeekly: [...data] });
                break;
            case ReportTypes.MONTHLY:
                this.setState({ tabsDataMonthly: [...data] });
                break;
            default:
                console.error("Invalid report type selected");
                break;
        }
    }

    addNewTab(tabData) {
        let newTabsData;
        switch (this.state.selectedReportType) {
            case ReportTypes.WEEKLY:
                newTabsData = [...this.state.tabsDataWeekly];
                if (this.validateAddTab(newTabsData, tabData)) {
                    newTabsData.push(tabData);
                    this.setState({ tabsDataWeekly: newTabsData });
                }
                break;
            case ReportTypes.MONTHLY:
                newTabsData = [...this.state.tabsDataMonthly];
                if (this.validateAddTab(newTabsData, tabData)) {
                    newTabsData.push(tabData);
                    this.setState({ tabsDataMonthly: newTabsData });
                }
                break;

            default:
                console.error("Invalid report type selected");
                break;
        }
    }

    validateAddTab(existingTabs, tabData) {
        let maybeTableauIdExists = existingTabs.find(tab => (tab.tableauViewId == tabData.tableauViewId));
        if (maybeTableauIdExists !== undefined) {
            toastr.warning("Cannot Add Tab", "View already exits: " + maybeTableauIdExists.tabName);
            return false;
        }

        let maybeTabNameExist = existingTabs.find(tab => (tab.tableauViewId == tabData.tableauViewId));
        if (maybeTabNameExist !== undefined) {
            toastr.warning("Cannot Add Tab", "Tab name already exits: " + maybeTabNameExist.tabName);
            return false;
        }
        return true;
    }

    saveData() {
        let dataToSave;
        switch (this.state.selectedReportType) {
            case ReportTypes.WEEKLY:
                dataToSave = this.state.tabsDataWeekly;
                break;
            case ReportTypes.MONTHLY:
                dataToSave = this.state.tabsDataMonthly;
                break;
            default:
                console.error("Invalid report type selected");
                break;
        }

        if (dataToSave) {
            let reportScope = this.state.selectedEntityType;
            let entityId = this.state.selectedEntityId;
            let reportType = this.state.selectedReportType;
            this.setState({ savingData: true })
            dataToSave.map((item) => {
                item.entityId = entityId;
                item.reportType = reportType;
                item.reportScope = reportScope;
                item.tabMenuItemType = 'External';
            });
            let payload = {
                method: "POST",
                body: JSON.stringify(dataToSave),
                headers: {
                    'Content-Type': 'application/json',
                }
            }

            genericFetcherFactory(`/api/tableaus/TabMenuItem/External/${reportScope}/${entityId}/${reportType}`, "SAVE_DATA", "Failed to save tabs", "POST", payload)().then(result => {
                this.setState({ savingData: false });
                if (result.success) {
                    toastr.success("Saved tabs successfully");
                }
            });
        }
    }

    render() {
        if (this.state.initSuccess === true) {
            let selectedEntityType = this.state.selectedEntityType;
            let entityOptions;

            switch (selectedEntityType) {
                case ExternalReportScopes.CLIENT:
                    entityOptions = this.state.clients
                    .filter(client => client.isActive == true)
                    .map(client => { return { value: client.clientId, label: client.clientName }; });
                    break;
                case ExternalReportScopes.PRODUCT:
                    entityOptions = this.state.products.map(product => { return { value: product.productId, label: product.productName }; });
                    break;
                default:
                    entityOptions = [];
                    console.error(`Unsuppored entity type ${selectedEntityType}`);
                    break;
            }

            if (entityOptions.length < 1) {
                entityOptions = null;
            }
            let selectedEntity = this.state.selectedEntityId != null ? entityOptions.find(option => option.value == this.state.selectedEntityId) : null;
            if (selectedEntity === undefined) {
                selectedEntity = null;
            };

            let dataToDisplay;
            switch (this.state.selectedReportType) {
                case ReportTypes.WEEKLY:
                    dataToDisplay = [...this.state.tabsDataWeekly];
                    break;
                case ReportTypes.MONTHLY:
                    dataToDisplay = [...this.state.tabsDataMonthly];
                    break;
                default:
                    console.error("Invalid report type selected");
                    break;
            }

            return (
                <>
                    <Card>
                        <Card.Body flex>
                            <Modal
                                show={this.state.savingData === true}
                                centered
                            >
                                <Modal.Header>
                                    Saving Tabs...
                                </Modal.Header>
                                <Modal.Body className="text-center m-3">
                                    <Loader />
                                </Modal.Body>
                            </Modal>
                            <Row>
                                <Form.Group style={{ maxWidth: "200px" }}>
                                    <Form.Control as="select"
                                        id="entityType"
                                        onChange={(event) => { this.setState({ selectedEntityType: event.target.value, selectedEntityId: null }); }}>

                                        {entityTypes.map(type => { return <option selected={selectedEntityType === type.value} value={type.value}>{type.label}</option> })}
                                    </Form.Control>
                                </Form.Group>
                                <Form.Group style={{ maxWidth: "400px" }}>
                                    <Select
                                        placeholder={`Select a ${entityTypes.find(entityType => entityType.value === selectedEntityType).label}...`}
                                        options={entityOptions}
                                        id="entitiesDropdown"
                                        value={selectedEntity}
                                        onChange={selectedOption => this.entityChanged(selectedEntityType, parseInt(selectedOption.value))}
                                        filterOption={(item, input) => {
                                            if (input) {
                                                return item.data.label.toLowerCase().includes(input.toLowerCase());
                                            }
                                            return true;
                                        }}
                                    />
                                </Form.Group>
                                <Form.Group style={{ maxWidth: "200px" }}>
                                    <Form.Control as="select" id="reportType" onChange={(event) => { this.setState({ selectedReportType: event.target.value }); }}>
                                        {reportTypes.map(type => { return <option selected={this.state.selectedReportType === type.value} value={type.value}>{type.label}</option> })}
                                    </Form.Control>
                                </Form.Group>
                                <Button onClick={() => { this.saveData(); }} variant="secondary" className="col-auto">Save all changess</Button>
                            </Row>
                            {this.state.selectedEntityId != null &&
                                <DraggableTable columns={this.columns} data={dataToDisplay} onChange={this.orderChanged} />
                            }
                            {this.state.selectedEntityId != null &&
                                <>
                                    <h3>Add New Tab</h3>
                                    <CreateNewTabForm projects={this.state.projects} selectedReportType={this.state.selectedReportType} callback={this.addNewTab} />
                                </>
                            }
                        </Card.Body>
                    </Card>
                </>
            )
        } else if (this.state.initSuccess === false) {
            return (
                <GenericErrorAlert />
            )
        } else {
            return <Loader />
        }
    };

    deleteRow(rowIndex) {
        let workingSet;
        switch (this.state.selectedReportType) {
            case ReportTypes.WEEKLY:
                workingSet = [...this.state.tabsDataWeekly];
                workingSet.splice(rowIndex, 1);
                this.setState({ tabsDataWeekly: workingSet });
                break;
            case ReportTypes.MONTHLY:
                workingSet = [...this.state.tabsDataMonthly];
                workingSet.splice(rowIndex, 1);
                this.setState({ tabsDataMonthly: workingSet });
                break;
            default:
                console.error("Invalid report type selected");
                break;
        }
    }

    columns = [
        { text: "", isIndex: true },
        { text: "Tab Name", dataField: "tabName" },
        { text: "Project", dataField: "project" },
        { text: "Workbook", dataField: "workbook" },
        { text: "View", dataField: "view" },
        { text: "URL", dataField: "url" },
        { text: "Report Type", dataField: "reportType" },
        { text: "", formatter: (rawColumnValue, rowData, rowIndex) => { return <Icon.Trash2 className="feather align-middle" onClick={() => { this.deleteRow(rowIndex) }} /> } }
    ]
}
