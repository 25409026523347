import React from "react";
import { connect } from "react-redux";
import { NavLink, withRouter } from "react-router-dom";

import { Badge, Collapse } from "reactstrap";
import PerfectScrollbar from "react-perfect-scrollbar";
import logo from '../assets/img/bar-icon.png';

const checkIfMenuItemActive = (currentPath, routeObject) => {
    if (routeObject.checkActiveOverride && routeObject.checkActiveOverride instanceof Function) {
        return routeObject.checkActiveOverride(currentPath, routeObject);
    } else {
        return currentPath.indexOf(routeObject.path) === 0;
    }
}

const SidebarCategory = withRouter(
    ({
        name,
        badgeColor,
        badgeText,
        icon: Icon,
        isOpen,
        children,
        rawChildren,
        onClick,
        location,
        to,
        checkActiveOverride,
    }) => {
        const getSidebarItemClass = (routeObject) => {
            return (location.pathname === "/" && routeObject.path === "/dashboard") || checkIfMenuItemActive(location.pathname, routeObject) ? "active" : "";
        };

        return (
            <li className={"sidebar-item " + getSidebarItemClass({ path: to, children: rawChildren, checkActiveOverride: checkActiveOverride })}>
                <span
                    data-bs-toggle="collapse"
                    className={"sidebar-link " + (!isOpen ? "collapsed" : "")}
                    onClick={onClick}
                    aria-expanded={isOpen ? "true" : "false"}
                >
                    <Icon size={18} className="align-middle mr-3" />
                    <span className="align-middle">{name}</span>
                    {badgeColor && badgeText ? (
                        <Badge color={badgeColor} size={18} className="sidebar-badge">
                            {badgeText}
                        </Badge>
                    ) : null}
                </span>
                <Collapse isOpen={isOpen}>
                    <ul id="item" className={"sidebar-dropdown list-unstyled"}>
                        {children}
                    </ul>
                </Collapse>
            </li>
        );
    }
);

const SidebarItem = withRouter(
    ({ name, badgeColor, badgeText, icon: Icon, location, to }) => {
        const getSidebarItemClass = path => {
            return location.pathname === path ? "active" : "";
        };

        return (
            <li className={"sidebar-item " + getSidebarItemClass(to)}>
                <NavLink to={to} className="sidebar-link" activeClassName="active">
                    {Icon ? <Icon size={18} width={18} height={18} className="align-middle mr-3" /> : null}
                    {name}
                    {badgeColor && badgeText ? (
                        <Badge color={badgeColor} size={18} className="sidebar-badge">
                            {badgeText}
                        </Badge>
                    ) : null}
                </NavLink>
            </li>
        );
    }
);

class Sidebar extends React.Component {
    constructor(props) {
        super(props);
        let state = {};

        /* Open collapse element that matches current url */
        const pathName = this.props.location.pathname;
        this.props.sidebar.routes.forEach((route, index) => {
            const isActive = checkIfMenuItemActive(pathName, route);
            const isOpen = route.open;
            const isHome = route.containsHome && pathName === "/" ? true : false;

            state[index] = isActive || isOpen || isHome;
        });

        this.state = state;

    }

    

    componentDidUpdate(prevProps) {
        if (prevProps.sidebar.routes.length != this.props.sidebar.routes.length) {
            /* Open collapse element that matches current url */
            const pathName = this.props.location.pathname;
            this.props.sidebar.routes.forEach((route, index) => {
                const isActive = checkIfMenuItemActive(pathName, route);
                const isOpen = route.open;
                const isHome = route.containsHome && pathName === "/" ? true : false;

                this.setState(() => ({
                    [index]: isActive || isOpen || isHome
                }));
            });
        }
    }

    shouldDisplayNavCategory(category, allowedModules) {
        // If category is not managed itself and has children check that at least one of the children is viewable
        if (!category.appModuleId) {
            if (category.children) {
                for (const child of category.children) {
                    if (!child.appModuleId || allowedModules.find(mod => mod === child.appModuleId)) {
                        return true;
                    }
                }
            } else {
                return true;
            }
        } else if (allowedModules.find(mod => mod === category.appModuleId)) {
            return true;
        }

        return false;
    }

    toggle = index => {
        // Collapse all elements
        Object.keys(this.state).forEach(
            item =>
                this.state[index] ||
                this.setState(() => ({
                    [item]: false
                }))
        );

        // Toggle selected element
        this.setState(state => ({
            [index]: !state[index]
        }));
    };

    render() {
        const sidebar = this.props.sidebar;
        const allowedModules = this.props.allowedModules;
        const allowedModuleIds = allowedModules.map(mod => mod.moduleProxy);

        return (
            <nav
                className={
                    "sidebar" +
                    (!sidebar.isOpen ? " toggled collapsed" : "") +
                    (sidebar.isSticky ? " sidebar-sticky" : "")
                }
            >
                <div className="sidebar-content">
                    <PerfectScrollbar>
                        <a className="sidebar-brand" href="/">
                            <img src={logo} alt="Logo" />
                        </a>

                        <ul className="sidebar-nav">
                            {this.props.sidebar.routes.map((category, index) => {

                                // If appModuleId is not defined on the parent then check
                                if (this.shouldDisplayNavCategory(category, allowedModuleIds)) {
                                    return (
                                        <React.Fragment key={index}>
                                            {category.isHeader ? (
                                                <li className="sidebar-header">{category.header}</li>
                                            ) : (
                                                    category.children ? (
                                                        <SidebarCategory
                                                            name={category.name}
                                                            badgeColor={category.badgeColor}
                                                            badgeText={category.badgeText}
                                                            icon={category.icon}
                                                            to={category.path}
                                                            isOpen={this.state[index]}
                                                            onClick={() => this.toggle(index)}
                                                            rawChildren={category.children}
                                                            checkActiveOverride={category.checkActiveOverride}
                                                        >
                                                            {category.children.map((route, index) => {
                                                                if (!route.appModuleId || allowedModuleIds.find(mod => mod === route.appModuleId)) {
                                                                    return (
                                                                        <SidebarItem
                                                                            key={index}
                                                                            name={route.name}
                                                                            to={route.path}
                                                                            badgeColor={route.badgeColor}
                                                                            badgeText={route.badgeText}
                                                                        />
                                                                    )
                                                                }
                                                            })}
                                                        </SidebarCategory>
                                                    ) : (
                                                            <SidebarItem
                                                                name={category.name}
                                                                to={category.path}
                                                                icon={category.icon}
                                                                badgeColor={category.badgeColor}
                                                                badgeText={category.badgeText}
                                                            />
                                                        )
                                                )}
                                        </React.Fragment>
                                    );
                                }
                            })
                            }
                        </ul>
                    </PerfectScrollbar>
                </div>
            </nav>
        );
    }
}

export default withRouter(
    connect(store => ({
        sidebar: store.sidebar,
        layout: store.layout,
        allowedModules: store.userContext.allowedModules,
        tableauMenus: store.tableau.tableauMenus
    }))(Sidebar)
);
