import React from "react";

import {
  Card,
  CardBody,
} from "reactstrap";

import googleButton from "../assets/img/btn_google_signin_dark_normal_web.png";
import qsLogo from "../assets/img/QualityScore_Logo.png";

const SignIn = () => (
  <React.Fragment>
    <Card>
      <CardBody>
        <div className="text-center mt-4">
          <img src={qsLogo}></img>
        </div>

        <div className="text-center mt-4">
          <p className="lead">Sign in to your account to continue</p>
        </div>

        <div className="text-center mt-3">
          <a href="/oauth2/authorization/googleClient">
            <img src={googleButton}></img>
          </a>
        </div>
      </CardBody>
    </Card>
  </React.Fragment>
);

export default SignIn;
