import AWS from 'aws-sdk';
import { toastr } from "react-redux-toastr";

export const S3_BUCKET = "DEFAULT";
export const DELIMITER = "/";

// Initialize the Amazon Cognito credentials provider
AWS.config.region = 'us-east-1';
AWS.config.credentials = new AWS.CognitoIdentityCredentials({
    IdentityPoolId: 'us-east-1:276420ee-1216-4750-8371-fecc87d8c1e1',
});

// Create a new service object
const s3 = new AWS.S3({
    params: { Bucket: S3_BUCKET }
});


/*Return true/false if folder exist in S3  */
export function listFolderInS3(bucket, prefix, maxKeys) {
    const bucketParams = {
        Bucket: bucket,
        Delimiter: DELIMITER,
        MaxKeys: maxKeys,
        Prefix: prefix
    };
    return new Promise((resolve, reject) => {
        let isExist = false;
        s3.listObjectsV2(bucketParams, function (err, data) {
            if (err) {
                toastr.error('Error fetching bucket from S3: ' + err.message);
                reject(err);
            } else {
                if (data.CommonPrefixes.length > 0) {
                    isExist = true;
                }
                resolve(isExist);
            }
        })
    });
}



