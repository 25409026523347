import React from "react";

import {
    Card,
    CardBody,
    CardHeader,
    CardTitle,
    Container,
    Modal,
    ModalBody,
    ModalHeader
} from "reactstrap";
import BootstrapTable from "react-bootstrap-table-next";
import * as Icon from "react-feather";

import { toastr } from "react-redux-toastr";
import { handleErrorResponse } from "../../../utils/ajaxErrorHandler";

import Loader from '../../../components/Loader'
import UserEditForm, { FormModes } from './UserEditForm';
import { flattenUser } from './UserHelpers';
import GenericErrorAlert from "../../../components/GenericErrorAlert";
import { RelatedEntityFetchers } from './userUtils';
import { genericFetcherFactory } from "../../../utils/requestUtils";

export default class Users extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = { users: [], initSuccess: null };
        this.updateUser = this.updateUser.bind(this);
    }

    updateUser(userObj, userId) {
        this.setState({ ...this.state, formSaving: true })
        genericFetcherFactory("/api/users/User/" + userId, "UPDATE_USER", "Failed to update user", "PATCH", {
            method: "PATCH",
            body: JSON.stringify(userObj),
            headers: {
                "Content-Type": "application/json"
            }
        })().then(res => {
            if (res.success) {
                let updatedUser = res.data;
                let newUsers = [...this.state.users];
                let userIndex = newUsers.findIndex(item => (item.userId === userId));

                let flatUser = flattenUser(updatedUser);

                newUsers.splice(userIndex, 1, flatUser);
                this.setState({ ...this.state, users: newUsers, formSaving: false });
                toastr.success("Update Success", updatedUser.userLogin + " was updated successfully.")

            } else {
                this.setState({ ...this.state, formSaving: false });
                toastr.error("Failed to update user");
            }
        });
    }

    componentDidMount() {
        (async () => {
            let fetchSuccessful = false;

            let fetchUsersResult = await RelatedEntityFetchers.fetchUsers();

            if (fetchUsersResult.success === true) {
                fetchSuccessful = true;

                // Flatten structure
                let flatUsers = fetchUsersResult.data.map(((user) => {
                    return flattenUser(user);
                }));
                this.setState({ ...this.state, users: flatUsers, initSuccess: true });

            }

            if (fetchSuccessful !== false) {
                this.setState({ ...this.state, initSuccess: true });
            } else {
                this.setState({ ...this.state, initSuccess: false });
            }
        })();
    }

    // Boostrap table configuration 
    // Expand row configuration for editing rows in the Products table
    expandRow = {
        renderer: row => {
            return (
                <div className="ml-5 mr-5 mt-3 mb-3" key={row.userId}>
                    <UserEditForm key={"form"+row.userId} mode={FormModes.EDIT} userData={row} submitCallback={this.updateUser} preloadedUsers={this.state.users} />
                </div>
            )
        },
        expandColumnRenderer: ({ expanded }) => {
            if (expanded) {
                return (
                    <Icon.Edit className="feather align-middle" />
                );
            }
            return (
                <Icon.Edit2 className="feather align-middle" />
            );
        },
        expandHeaderColumnRenderer: ({ isAnyExpands }) => {
            return "";
        },
        showExpandColumn: true,
        expandByColumnOnly: true,
        expandColumnPosition: 'right'
    };

    // Column configuration for the Users table
    usersColumns = [
        {
            dataField: "userId",
            text: "ID",
            headerStyle: { width: "30px" },
        },
        {
            dataField: "userLogin",
            text: "User Login",
            sort: true
        },
        {
            dataField: "userRoleDisplayName",
            text: "User Role",
            sort: true
        },
        {
            dataField: "parentLogin",
            text: "Parent",
            sort: true
        },
        {
            dataField: "departmentName",
            text: "Department",
            sort: true
        }
    ]

    render() {
        if (this.state.initSuccess === true) {
            return (
                <Container fluid className="p-0">
                    <Modal isOpen={this.state.formSaving === true} centered>
                        <ModalHeader>
                            Saving User...
                        </ModalHeader>
                        <ModalBody className="text-center m-3">
                            <Loader />
                        </ModalBody>
                    </Modal>
                    <Card>
                        <CardHeader>
                            <CardTitle tag="h5" className="mb-0">
                                Users
                            </CardTitle>
                        </CardHeader>
                        <CardBody>
                            <BootstrapTable
                                bootstrap4
                                keyField="userId"
                                bordered={false}
                                striped
                                hover
                                data={this.state.users ? this.state.users : []}
                                columns={this.usersColumns}
                                expandRow={this.expandRow}
                            />
                        </CardBody>
                    </Card>
                </Container>
            )
        } else if (this.state.initSuccess === false) {
            return (
                <GenericErrorAlert />
            );
        } else {
            return (
                <Card>
                    <CardHeader>
                        <CardTitle tag="h5" className="mb-0">
                            Users
                        </CardTitle>
                    </CardHeader>
                    <Loader />
                </Card>
            );
        }
    }
}