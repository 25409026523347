import React from 'react';
import * as Yup from "yup";
import { Formik } from "formik";
import { Button, Form, Row } from "react-bootstrap";

import { toastr } from "react-redux-toastr";

const FormModes = {
    NEW: "new",
    EDIT: "edit"
}

export { FormModes };

export default class ContactEditForm extends React.PureComponent {

    constructor(props) {
        super(props);
        this.sumbitForm = this.sumbitForm.bind(this);
    }

    /**
 * Does various validation, data manipulation and runs the callback provided on component's submitCallback function
 * with a FormData object constructed from the form.
 * @param {*} event 
 * @param {*} errors 
 * @param {*} values 
 */
    sumbitForm(values) {
        // Logic for when adding a new contact
        if (this.props.mode == FormModes.NEW) {
            // All data will exist
            let contactData = {
                contactName: values.contactName,
                phoneNumber: values.phoneNumber,
                position: values.position,
                email: values.email,
            };

            this.props.submitCallback(contactData);
        }
        // Logic for when editing an existing contact
        else if (this.props.mode == FormModes.EDIT) {
            let changeMade = false;
            let contactData = {};

            // Iterate through the form data
            for (let prop in values) {
                // TODO: Change this check to use an object defition rather than relying on the components properties
                if (this.props.contactData[prop] !== undefined) {
                    // Check if the field was edited
                    if (values[prop] != this.props.contactData[prop]) {
                        changeMade = true;
                        contactData[prop] = values[prop];
                    }
                } else {
                    console.warn("field " + prop + " doesn't exist");
                }
            }
            // Add contactDetails to the FormData object if it has actually changed
            if (changeMade === true) {
                // Append the contactId for completion
                contactData["contactId"] = this.props.contactData.contactId;
                this.props.submitCallback(contactData, this.props.contactData.contactId);
            } else if (changeMade === false) {// Finally check if there was actually a change made before proceeding to the callback
                toastr.warning("Cannot save contact", "No changes were made");
                return;
            }
        }
    }

    render() {
        return (
            <Formik
                initialValues={this.props.contactData}
                validationSchema={Yup.object().shape({
                    contactName: Yup.string().required(),
                    position: Yup.string().required(),
                    phoneNumber: Yup.string().required(),
                    email: Yup.string().email('Please fill in a valid email').required("Email is required"),
                })}
                onSubmit={(values) => this.sumbitForm(values)}
            >
                {({ handleSubmit, handleChange, handleBlur, errors, touched, values }) => (
                    <Form onSubmit={handleSubmit}>
                        <Row className="gx-1">
                            <Form.Group  className="col gx-1" >
                                <Form.Label htmlFor="contactName">Contact name</Form.Label>
                                <Form.Control name="contactName" id="contactName"
                                    value={values.contactName}
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    isInvalid={!!errors.contactName && !!touched.contactName}
                                />
                                {!!touched.contactName &&
                                    <Form.Control.Feedback type="invalid">Contact name is required</Form.Control.Feedback>
                                }
                            </Form.Group>
                            <Form.Group  className="col gx-1">
                                <Form.Label htmlFor="position">Position</Form.Label>
                                <Form.Control name="position" id="position"
                                    value={values.position}
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    isInvalid={!!errors.position && !!touched.position}
                                />
                                {!!touched.position &&
                                    <Form.Control.Feedback type="invalid">Position is required</Form.Control.Feedback>
                                }
                            </Form.Group>
                            <Form.Group  className="col gx-1">
                                <Form.Label htmlFor="phoneNumber">Phone Number</Form.Label>
                                <Form.Control name="phoneNumber" id="phoneNumber"
                                    value={values.phoneNumber}
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    isInvalid={!!errors.phoneNumber && !!touched.phoneNumber}
                                />
                                {!!touched.phoneNumber &&
                                    <Form.Control.Feedback type="invalid">Phone number is required</Form.Control.Feedback>
                                }
                            </Form.Group>
                            <Form.Group  className="col gx-1">
                                <Form.Label htmlFor="email">email</Form.Label>
                                <Form.Control name="email" id="email"
                                    value={values.email}
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    isInvalid={!!errors.email && !!touched.email}
                                />
                                {!!touched.email &&
                                    <Form.Control.Feedback type="invalid">{errors.email}</Form.Control.Feedback>
                                }
                            </Form.Group>
                            <Button type="submit" className="submit-btn col-auto align-self-start" color="primary">Submit</Button>
                        </Row>
                    </Form>
                )}
            </Formik>
        )
    }
}

ContactEditForm.defaultProps = {
    contactData: {
        contactId: null,
        contactName: "",
        position: "",
        email: "",
        phoneNumber: "",
    },
    mode: FormModes.NEW
}
