import React from "react";

import { debugProps } from "./debug";

import { Label, Input, Button } from 'reactstrap';

const title = "Load Post data and expired Adsets";
export default class Step1LoadPost extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            postId: this.props.stepData.originalPostId ? this.props.stepData.originalPostId : ""
        }
    }

    render() {
        return (
            <div className={"wizard-step-container"}>
                <div className={"step-title"}>
                    {title}
                </div>
                <div className={"step-body"}>
                    {this.props.errorDisplay != null &&
                        <div class="wizard-error-display">{this.props.errorDisplay}</div>
                    }
                    <div className={"wizard-input-and-button"}>
                        <Label for="originalPost">Original Post ID:</Label>
                        <Input
                            readOnly={this.props.readOnly}
                            type="textbox"
                            name="originalPost"
                            id="originalPost"
                            value={this.state.postId}
                            placeholder="Original Post ID"
                            onChange={(event) => this.setState({ postId: event.target.value })}
                        />
                        <Button disabled={this.props.readOnly || this.state.postId.length < 1} onClick={() => {
                            this.setState({ showSpinner: true });
                            this.props.changePostId(this.state.postId);
                        }}>Find Adsets</Button>
                    </div>
                </div>
                {/* {debugProps(this.props)} */}
            </div>
        );
    }
}