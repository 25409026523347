
export default class CountryDisplaySelectionHelper {
    constructor() {
    
    }
    generateKey(countryDisplaySelection) {
        return `${countryDisplaySelection.countryId}-${countryDisplaySelection.productId}-${countryDisplaySelection.platform}`.replace(/\s+/g, "_");
    }

    getProductName(productId,accountProducts) {
        let productName = "N/A";   
        if (productId != undefined) {            
            let product = accountProducts.find(product => product.productId === productId);
            if (product != undefined) {
                productName = product.productName;
            }
        }

        return productName;
    }
}