export function createTimezonesArrayFromMap(timeZonesMap) {
    const popularTimeZoneNames = ["America/Los_Angeles", "America/New_York", "America/Denver", "Asia/Jerusalem", "GMT"];
    let timeZones = [];

    timeZones.push(
        ...Object.entries(timeZonesMap)
            .map((timeZoneTuple) => {
                return { gmtId: timeZoneTuple[0], gmtOffset: timeZoneTuple[1], tzDisplay: timeZoneTuple[0].replace("_", " ") + " " + timeZoneTuple[1] }
            }).sort((a, b) => {
                let offsetMinutesA = getOffsetInMinutes(a.gmtOffset);
                let offsetMinutesB = getOffsetInMinutes(b.gmtOffset);

                return offsetMinutesA - offsetMinutesB;
            })
    )

    let popularTimeZones = timeZones.filter(tz => {
        return popularTimeZoneNames.includes(tz.gmtId);
    });
    let otherTimezones = timeZones.filter(tz => {
        return !popularTimeZoneNames.includes(tz.gmtId);
    });

    return popularTimeZones.concat(otherTimezones);
}

function getOffsetInMinutes(gmtOffset) {
    let offsetClean = gmtOffset.replace("(GMT", "").replace(")", "");;
    return (parseInt(offsetClean.split(":")[0]) * 60) + (offsetClean[0] == "-" ? (parseInt(offsetClean.split(":")[1]) * -1) : parseInt(offsetClean.split(":")[1]));
}