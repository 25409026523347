import React from "react";
import Templates from './Templates';
import PageList from './PageList';
import ComponentsFull from './ComponentsFull';
import { toastr } from "react-redux-toastr";
import Deployment from './Deployment';
import {
    ArrowUpRight as UpIcon
} from "react-feather";
import { connect } from 'react-redux';
import { cmsUtils } from './utils';
import { setUserStatus, setCmsData, setCMSTemplateData } from "../../redux/actions/cmsActions"
import { UserStatusUtils } from './UserStatusUtils';
import Loader from '../../components/Loader';
import SockJsClient from 'react-stomp';
import {
    Button,
    Modal,
    Alert,
} from "react-bootstrap";
import {
    Edit as EditIcon
} from "react-feather";

const timeoutTime = 1000 * 60 * 60;
class CMSMain extends React.PureComponent {
    constructor(props) {
        super(props)
        this.state = {
            currentStep: 1,
            error: null
        }

        this.utils = new cmsUtils();
        this.userStatusUtils = new UserStatusUtils();
        this._next = this._next.bind(this)
        this._prev = this._prev.bind(this)
        this.setTimerProjectName = this.setTimerProjectName.bind(this)
        this.forceStopEditing = this.forceStopEditing.bind(this)
        this.unsaveDataToasterTrigger = this.unsaveDataToasterTrigger.bind(this)

    }
    timer;

    componentWillUnmount() {
        // console.log('The component is going to be unmounted');
        clearTimeout(this.timer);
        this.handleUserStatusMessege('done', "user left cms" , "no project selected" )
    }

    componentDidMount() {
        this.userStatusUtils.userInitStatus();
    }

    //start timer for checking if the user still editing
    setTimerProjectName(projectName) {
        this.setState({ 'modalExit': false, 'modalProjectName': projectName })
        clearTimeout(this.timer)
        this.timer = setTimeout(() => this.forceExitEditProject(projectName), timeoutTime);
    }

    //force stop-editing
    forceStopEditing() {
        clearTimeout(this.timer);
        if (this.props.cmsData.template !== null) {
            this.handleUserStatusMessege('done')
        }
        if (this.props.cmsData.template === this.state.modalProjectName) {
            this.updateStep("reset")
        }
        this.setState({ 'modalExit': false })
    }

    //start a timer for force logout project
    forceExitEditProject(projectName) {
        clearTimeout(this.timer);
        this.timer = setTimeout(() => this.forceStopEditing(), 1000 * 60 * 10);
        this.setState({ 'modalExit': true })
    }

    //handle websocket User Status
    onMessageReceive = (msg, topic) => {
        //update income message to redux
        this.userStatusUtils.handleEditedProject(msg);

        //update ViewMode
        if (!msg.isEditing && this.props.cmsData.viewOnly && msg.projectName === this.props.cmsData.template) {
            this.props.setCmsData({
                ...this.props.cmsData,
                viewOnly: false
            });
            this.handleUserStatusMessege('edit');
        }

        //show income message notification
        let userName = msg.userLogin.replace('@qualityscore.co', '');
        let userNameToUpperCase = userName.charAt(0).toUpperCase() + userName.slice(1);

        let userDisplayName,
            pronoun;
        if (msg.userLogin === this.props.user.userLogin) {
            userDisplayName = 'You'
            pronoun = " are "
        } else {
            userDisplayName = userNameToUpperCase
            pronoun = " is "
        }
        this.showToastr(
            userDisplayName + (msg.isEditing ? pronoun + "editing" : pronoun + "done editing") + " " + msg.projectName,
            msg.isEditing ? "warning" : "info",
            10000,
            <EditIcon />
        );
    }

    //Send message to WS
    sendMessage = (msg) => {
        try {
            this.clientRef.sendMessage("/consumer/projectStatus", JSON.stringify(msg));
            //timer handler
            if (msg.isEditing) {
                //start timer for checking if the user still editing
                this.setTimerProjectName(msg.projectName);
            } else if (!msg.isEditing) {
                clearTimeout(this.timer)
            }
            return true;
        } catch (e) {
            return false;
        }
    }

    handleUserStatusMessege(isEditing, message, projectName) {
        let messege = {
            "userId": this.props.user.userData.userId,
            "userLogin": this.props.user.userLogin,
            "projectName": projectName ? projectName : this.props.cmsData.template,
            "isEditing": isEditing === 'edit' ? true : false,
            "message": message
        }
        this.sendMessage(messege);
    }

    //notification on save data
    showToastr(message, type, timeOut = 10000, icon) {
        const options = {
            timeOut: timeOut,
            removeOnHoverTimeOut: 10000,
            showCloseButton: true,
            progressBar: true,
            position: "top-center",
            icon: (icon)
        };
        let toastrInstance;
        switch (type) {
            case "info":
                toastrInstance = toastr.info;
                break;
            case "warning":
                toastrInstance = toastr.warning;
                break;
            case "error":
                toastrInstance = toastr.error;
                break;
            default:
                toastrInstance = toastr.success;
                break;
        }

        toastrInstance(
            message,
            options
        );
    }

    unsaveDataToasterTrigger(message, disableCancel, currentStep, type) {
        let unsavedData = this.props.cmsData.unsavedData;
        const toastrConfirmOptions = {
            onOk: () => currentStep == 4,
            onCancel: () => currentStep ? this.updateStep(currentStep, type) : '',
            okText: "OK, I'll save the data",
            cancelText: 'Continue without saving',
            id: 'edit_comp_confirm_btn',
            disableCancel: disableCancel,
            component: () => (
                <>
                    <div className="confirm_message">
                        {message}:
                        <ul>
                            {
                                unsavedData.map((item) => <li key={item}> {item}</li>)
                            }
                        </ul>
                    </div>
                </>
            )
        };

        toastr.confirm(null, toastrConfirmOptions);
    }

    //next & prev btn's
    _next(type) {
        let currentStep = this.state.currentStep;
        let viewMode = this.props.cmsData.viewOnly;
        //check if current user !== editing user => update viewOnly to true
        if (this.userStatusUtils.checkUserEdit(this.props.cmsData.template) !== this.props.user.userLogin.replace('@qualityscore.co', '')) {
            let viewOnly = this.props.userStatus.inEditMode.includes(this.props.cmsData.template) ? true : false;
            viewMode = viewOnly
        }

        //send ws update
        if (currentStep === 1 && !viewMode) {
            this.handleUserStatusMessege('edit');
        } else if (currentStep === 4) {
            this.handleUserStatusMessege('done');
        }

        if (currentStep === 3 && this.props.cmsData.unsavedData.length > 0) {
            this.unsaveDataToasterTrigger('You have unsaved data', false, currentStep, type);
        } else {
            currentStep = this.updateStep(currentStep, type, viewMode, 'next');
        }

        this.scrollTotop();
    }

    updateStep(currentStep, type, viewMode = this.props.cmsData.viewOnly, clickedStep) {

        this.props.setCmsData({
            ...this.props.cmsData,
            lastEditedTemplate: this.props.cmsData.template,
            viewOnly: viewMode,
            clickedStep: clickedStep
        });
        currentStep = currentStep === 'reset' ? 4 : currentStep;
        //**Before change page**
        //clear user selection
        if (currentStep === 4) {
            this.props.setCmsData({
                ...this.props.cmsData,
                deploydPages: [],
                template: null,
                component: null,
                page: null,
                deletePages: [],
                deletePagesHTML: [],
                deletePagesJSON: [],
                currentPage: null,
                deployment: null,
                templateIsSharedCompCompatible: null,
                selectedNewComponent: null,
                sharedComponentsAdded: [],
                sharedComponentsUpdated: [],
                editOrCreate: 'edit'
            });
        }

        // If the current step is 1 - 4, then add one on "next" button click
        currentStep = currentStep >= 4 ? 1 : currentStep + 1;
        if (type === 'skip') {
            currentStep = 4;
        }

        //**After change page**
        //set active page
        this.setState({
            currentStep: currentStep
        })

        if (currentStep === 1 || currentStep === 3) {
            this.utils.resetDataModel();
        } else if (type === 'skip' && currentStep === 4) {
            this.props.setCmsData({
                ...this.props.cmsData,
                unsavedData: [],
                deployment: "prod"
            });
        } else if (currentStep === 4) {
            this.props.setCmsData({
                ...this.props.cmsData,
                unsavedData: []
            });
        }
    }

    _prev() {
        let currentStep = this.state.currentStep;
        let viewMode = this.props.cmsData.viewOnly;

        if (currentStep === 2 && !this.props.cmsData.viewOnly) {
            //send ws update (not on viewOnly mode)
            this.handleUserStatusMessege('done');
        } else if (currentStep === 2 && this.props.cmsData.viewOnly) {
            //leave viewOnly mode
            viewMode = false;
        }

        // If the current step is 2 or 3, then subtract one on "previous" button click
        currentStep = currentStep <= 1 ? 1 : currentStep - 1
        if (this.props.cmsData.deployment === 'prod') {
            currentStep = 2;
        }

        this.setState({
            currentStep: currentStep
        })
        if (currentStep === 1) {
            this.props.setCmsData({
                ...this.props.cmsData,
                deploydPages: [],
                template: null,
                component: null,
                page: null,
                deletePages: [],
                deletePagesHTML: [],
                deletePagesJSON: [],
                currentPage: null,
                deployment: null,
                editOrCreate: 'edit',
                templateIsSharedCompCompatible: null,
                selectedNewComponent: null,
                sharedComponentsAdded: [],
                sharedComponentsUpdated: [],
                viewOnly: viewMode,
                clickedStep: 'back'
            });
        } else {
            this.props.setCmsData({ ...this.props.cmsData, deployment: null, clickedStep: 'back' });
        }
        this.scrollTotop();
    }

    scrollTotop() {
        document.body.scrollTop = 0; // For Safari
        document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
    }

    get previousButton() {
        let currentStep = this.state.currentStep;
        // If the current step is not 1, then render the "previous" button
        if (currentStep !== 1) {
            return (
                <button
                    className="prevBtn btn btn-secondary sticky"
                    type="button" onClick={this._prev}>
                    Previous
                </button>
            )
        }
        return null;
        
    }

    isNextStepAvailable() {
        let checkResult;

        switch (this.state.currentStep) {
            case 1:
                checkResult = this.props.cmsData.template && this.props.cmsData.templatesIsLoaded;
                break;
            case 2:
                checkResult = this.props.cmsData.page && this.props.cmsData.pageListIsLoaded && (this.props.cmsData.editOrCreate === 'create' ? this.props.cmsData.newPageName : true);
                break;
            case 3:
                checkResult = !this.props.cmsData.viewOnly && this.props.CMSTemplateData.dataIsLoaded;
                break;
            case 4:
                checkResult = true;
                break;
            case 5:
                checkResult = true;
                break;
            default:
                checkResult = false;
                break;
        }

        return checkResult;
    }

    get nextButton() {
        let buttonText = this.state.currentStep < 4 ? "Next" : "Update Another Template";
        return (
            <button
                disabled={!this.isNextStepAvailable()}
                className="nextBtn btn btn-secondary float-right sticky"
                type="button" onClick={this._next}>
                {this.props.cmsData.viewOnly ? buttonText + ' - View Only Mode' : buttonText}
            </button>
        )
    }

    get skip() {
        let buttonText = "Skip To: Deploy to PROD";
        return (
            <button
                className="prevBtn skip btn btn-primary ml-2 float-right sticky"
                type="button-danger" onClick={() => this._next('skip')}>
                <UpIcon /> {buttonText}
            </button>
        )
    }

    render() {
        let envPrefix = "";
        if (process.env.NODE_ENV === "development") {
            envPrefix = "https://localhost:8050";
        } else {
            envPrefix = window.location.protocol + "//" + window.location.host;
        }
        const url = envPrefix + '/websocket';

        return (
            <>
                {this.props.cmsData.viewOnly ? <Alert varian="info" className="viewOnly__alert"><div className="alert-message">You are currently in <b>View only</b> mode</div></Alert> : ''}
                <p>Step <b>{this.state.currentStep}</b> out of 4 </p>
                <div className="cms">
                    {this.state.currentStep === 1 &&
                        <Templates />
                    }

                    {this.state.currentStep === 2 &&
                        <PageList
                            next={this._next}
                        />
                    }

                    {this.state.currentStep === 3 && this.props.CMSTemplateData.dataIsLoaded === true &&
                        <ComponentsFull
                            unsaveDataToasterTrigger={this.unsaveDataToasterTrigger}
                        />
                    }
                    {this.state.currentStep === 3 && this.props.CMSTemplateData.dataIsLoaded === null &&
                        <Loader content={["Fetching " + this.props.cmsData.page + " data", "Almost done..."]} width="full" />
                    }

                    {this.state.currentStep === 4 &&
                        <Deployment
                            deployType='page'
                        />
                    }

                    {this.previousButton}
                    {(this.state.currentStep === 2 || this.state.currentStep === 3) && !this.props.cmsData.viewOnly && this.skip}
                    {this.nextButton}

                </div>

                {this.state.modalExit &&
                    <Modal
                        show={this.state.modalExit === true}
                        centered
                        className="exitProjectEdit"
                    >
                        <Modal.Body className="text-center mt-3">
                            <h3 className="mb-3">Are you still editing <b>{this.state.modalProjectName}</b>?</h3>
                            <div className="buttonsWrap">
                                <Button variant="primary" className="me-2" onClick={() => this.setTimerProjectName(this.state.modalProjectName)}>Yes</Button>
                                <Button variant="secondary" onClick={() => {
                                    this.forceStopEditing()
                                }}>No, please exit editing mode</Button>
                            </div>
                        </Modal.Body>
                        <Modal.Footer className="justify-content-center">
                            <em>If you won't click anything for 10 minutes you would be logged out</em>
                        </Modal.Footer>
                    </Modal>
                }

                <SockJsClient url={url} topics={['/topic/projectStatus']}
                    onMessage={this.onMessageReceive}
                    ref={(client) => { this.clientRef = client }}
                // debug
                />
            </>
        )
    }
}

export default connect(
    (store) => {
        return {
            CMSTemplateData: store.cms.CMSTemplateData,
            cmsData: store.cms.cmsData,
            userStatus: store.cms.userStatus,
            user: store.userContext.user
        }
    },
    { setCmsData, setCMSTemplateData, setUserStatus })(CMSMain)